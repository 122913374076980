<p-multiSelect
  [class]="'u-multi-select ' + multiSelectSize"
  [ngClass]="{
    'no-color-checkbox': isNoColorCheckbox,
    'hide-footer': selectedItems == null || selectedItems?.length === 0,
    'has-values': selectedItems?.length > 0 && showHasValues,
    'one-value': oneValue,
    'hide-selection': hideSelection,
    'custom-empty-message': emptyFilterTemplate,
    'short-selected-items': shortSelected,
    'wide-multi-select': wideWidth,
    'no-checkbox': noCheckbox,
    'hide-search-filter': hideSearchFilter,
    'show-clear-search': showClear,
  }"
  [panelStyleClass]="
    'u-multi-select-panel ' +
    (isNoColorCheckbox ? 'no-color-checkbox' : '') +
    (noCheckbox ? ' no-checkbox ' : ' ') +
    (panelStyleClass ? panelStyleClass : '') +
    (hideSearchFilter ? 'hide-search-filter' : '')
  "
  [(ngModel)]="selectedItems"
  (onChange)="onChangeEvent($event)"
  (onClear)="onClearEvent()"
  (onFilter)="onFilterEvent($event)"
  (onClick)="onClick.emit($event)"
  (onFocus)="onFocus.emit($event)"
  (onBlur)="onBlur.emit($event)"
  (onPanelShow)="onPanelShowEvent()"
  (onPanelHide)="onPanelHideEvent()"
  [options]="options"
  [placeholder]="placeholder"
  [optionLabel]="optionLabel"
  [appendTo]="appendTo"
  [emptyFilterMessage]="emptyFilterMessage"
  [emptyMessage]="emptyMessage"
  [showToggleAll]="showToggleAll"
  [display]="display"
  [style]="styles"
  [filterPlaceHolder]="filterPlaceholder"
  [resetFilterOnHide]="resetFilterOnHide"
  [disabled]="disabled"
  [selectionLimit]="selectionLimit"
  [virtualScrollItemSize]="itemSize || options?.[0]?.height || null"
  [virtualScroll]="!(itemSize || options?.[0]?.height) ? false : virtualScroll"
  (keydown)="onKeydown($event)"
  [filterBy]="filterBy"
  [filterMatchMode]="filterMatchMode"
  [showClear]="showClear"
  [readonly]="readonly"
  [overlayOptions]="overlayOptions"
>
  <ng-template let-value pTemplate="selectedItems">
    <ng-container *ngIf="!selectedItemsTemplate; else showSelectedItems">
      <ng-container>
        <div class="p-multiselect-label-container new-selectedItems">
          <div class="multi-select-icon" *ngIf="multiSelectIcon">
            <u-icon [styles]="{ fontSize: '14px', width: '16px' }" [model]="multiSelectIcon" class="padding-left"></u-icon>
          </div>
          <div *ngIf="selectedItems?.length > 0; else regularView" class="new-selectedItem-container">
            <div class="placeholder" *ngIf="keepPlaceholder">
              {{ placeholder + (excludeSelectedFromHeader ? '' : ':') }}
            </div>
            <div
              *ngFor="
                let item of excludeSelectedFromHeader ? [] : selectedItems.slice(0, limitShowSelectedLabels);
                let last = last;
                let first = first
              "
              class="selected-items"
              [class.padding-left]="!keepPlaceholder"
            >
              <u-icon
                *ngIf="showSelectedItemIcons"
                class="selected-icon"
                [styles]="{ fontSize: '14px', width: '14px' }"
                [model]="item.icon"
              ></u-icon>
              <div
                class="p-multiselect-label"
                [class.no-right-padding]="!last"
                [class.padding-left]="first"
                tooltipPosition="top"
                [pTooltip]="selectedItemShowLabel(item)?.includes('...') ? getSelectedItem(item) : null"
                tooltipStyleClass="u-tooltip"
              >
                {{ selectedItemShowLabel(item) }}
              </div>
              <ng-container *ngIf="!last">
                <div class="coma">,</div>
              </ng-container>
            </div>
            <ng-container *ngIf="selectedItems.length > limitShowSelectedLabels && !disableShowMoreLabels">
              <div *ngIf="showTooltipIcons; else regularTooltip">
                <div #actualTarget (mouseover)="op.show($event, actualTarget)" (mouseleave)="onHidePanel(op)">
                  <div class="p-multiselect-label no-padding">
                    {{ ' +' + selectedItems.slice(limitShowSelectedLabels).length }}
                  </div>
                </div>
                <p-overlayPanel #op [style]="{ width: 'auto' }">
                  <ng-template pTemplate>
                    <div class="table-apps-icon-section" [class.grid-table]="selectedItems.slice(limitShowSelectedLabels).length > 5">
                      <ng-container *ngFor="let item of selectedItems.slice(limitShowSelectedLabels)">
                        <div class="apps-wrapper">
                          <u-icon
                            [model]="item.icon"
                            [reverseTheme]="true"
                            [styles]="{ width: '15px', height: '15px' }"
                            class="app-icon-size"
                          ></u-icon>
                          <div class="app-name" [class.grid-table-app]="selectedItems.slice(limitShowSelectedLabels).length > 5">
                            {{ item[optionLabel] }}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </div>
              <ng-template #regularTooltip>
                <div
                  class="p-multiselect-label no-padding"
                  tooltipPosition="top"
                  [pTooltip]="getMoreLabelsTooltip()"
                  tooltipStyleClass="u-tooltip"
                >
                  {{ ' +' + selectedItems.slice(limitShowSelectedLabels).length }}
                </div>
              </ng-template>
            </ng-container>
          </div>
          <ng-template #regularView>
            <div class="selectedItems-regular-container">
              <div
                class="p-multiselect-label new-selectedItem-label padding-left"
                [ngClass]="{ 'p-placeholder': multiSelect?.valuesAsString === (multiSelect?.defaultLabel || placeholder) }"
              >
                {{ placeholder }}
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #showSelectedItems>
      <ng-container *ngTemplateOutlet="selectedItemsTemplate; context: { $implicit: value }"></ng-container>
    </ng-template>
  </ng-template>

  <ng-template let-item pTemplate="item">
    <div
      data-cy="dropdown-filter-item"
      style="width: 100%"
      class="multi-select-item"
      [pTooltip]="showItemTooltip && item[tooltipLabel || optionLabel]?.length > maxCharacters ? item[tooltipLabel || optionLabel] : ''"
      tooltipPosition="top"
      tooltipStyleClass="u-tooltip"
      [attr.id]="item.id"
    >
      <ng-container *ngIf="!itemTemplate; else showItem">
        <span>{{ item[optionLabel] }}</span>
      </ng-container>
      <ng-template #showItem>
        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
      </ng-template>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div *ngIf="displayClearAllFooter" class="clear-all-section" (click)="onClearAll($event)">
      <u-button
        [label]="oneValue ? SINGLE_CHOICE_FOOTER_LABEL : DEFAULT_FOOTER_LABEL"
        type="secondary"
        class="no-state clear-all"
        [styles]="{ marginTop: '8px' }"
      >
      </u-button>
    </div>
  </ng-template>

  <ng-container *ngIf="emptyFilterTemplate">
    <ng-template pTemplate="emptyfilter">
      <ng-container *ngTemplateOutlet="emptyFilterTemplate"></ng-container>
    </ng-template>
  </ng-container>

  <ng-template pTemplate="empty">
    <ng-container *ngIf="liveSearchActive; else defaultEmptyTemplate">
      <div class="empty-ghost-container">
        <div *ngFor="let i of [].constructor(ghostLinesCount)" class="ghost-item">
          <div ghost [ghostStyle]="{ width: '16px', height: '16px', borderRadius: '2px' }"></div>
          <div ghost [ghostStyle]="{ width: '95px', height: '8px', borderRadius: '2px' }"></div>
        </div>
      </div>
    </ng-container>
    <ng-template #defaultEmptyTemplate>
      <div>{{ emptyFilterMessage }}</div>
    </ng-template>
  </ng-template>

  <ng-template pTemplate="filtericon">
    <ng-container>
      <div *ngIf="displayBackIcon; else defaultFilterIcon" class="back-icon-container" (click)="onBack.emit()">
        <u-icon [model]="{ type: 'font', value: 'icon-chevron-left' }" [styles]="{ fontSize: '14px' }"></u-icon>
      </div>
      <ng-template #defaultFilterIcon>
        <div>
          <u-icon [model]="{ type: 'font', value: 'icon-search-icon-fixed' }" [styles]="{ fontSize: '13px' }"></u-icon>
        </div>
      </ng-template>
    </ng-container>
  </ng-template>
</p-multiSelect>

<div class="filter-input-placeholder" #filterInputPlaceholder style="display: none">{{ inlineFilterPlaceHolder }}</div>
