import { Injectable } from '@angular/core';
import { Collections, Search } from '@local/client-contracts';
import { PopupRef, PopupService } from '@local/ui-infra';
import { Subject, filter } from 'rxjs';
import { WorkspacesService } from 'src/app/bar/services';
import { AvatarListService } from 'src/app/bar/services/avatar-list.service';
import { BlobsService } from 'src/app/bar/services/blobs/blob.service';
import { CollectionsUtilService } from 'src/app/bar/services/collections-util.service';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { PreviewService } from 'src/app/bar/services/preview.service';
import { StaticCollectionItemBuilder } from 'src/app/bar/services/search/client/static-collection-items/static-collection-item-builder';
import { FilePreviewPopupComponent } from '../../preview/file-preview/components/file-preview-popup/file-preview-popup.component';
import { SearchResults } from '../../results';
import { UrlResolverService } from 'src/app/bar/services/url-resolver.service';

@Injectable({
  providedIn: 'root',
})
export class BlobPreviewService {
  private readonly previewType = 'collection-file';
  private popupRef: PopupRef<FilePreviewPopupComponent, Search.ResultResourceItem>;
  private staticCollectionItemBuildResultView: StaticCollectionItemBuilder;
  private _popupRefOpen$: Subject<boolean> = new Subject();

  get popupRefOpen$(): Subject<boolean> {
    return this._popupRefOpen$;
  }

  get blobPopupRef() {
    return this.popupRef;
  }

  constructor(
    private blobsService: BlobsService,
    private popupService: PopupService,
    private collectionsService: CollectionsService,
    private collectionsHelperService: CollectionsUtilService,
    private previewService: PreviewService,
    private workspaceService: WorkspacesService,
    private avatarListService: AvatarListService,
    private urlResolverService: UrlResolverService
  ) {
    this.previewService.openPreviewPopup$.pipe(filter((i) => i.previewType === this.previewType)).subscribe(({ data }) => {
      this.openFilePopUp(data.item, data.preventClearQueryParams);
    });

    this.previewService.clearPreviewPopup$.pipe(filter((previewType) => previewType === this.previewType)).subscribe(() => {
      if (this.popupRef) {
        this.popupRef.destroy();
        this.popupRef = null;
      }
    });

    this.staticCollectionItemBuildResultView = new StaticCollectionItemBuilder(
      this.collectionsService,
      this.collectionsHelperService,
      this.blobsService,
      this.workspaceService,
      this.avatarListService,
      this.urlResolverService
    );
  }

  getBlobPreviewItem(res: any) {
    return {
      ...res,
      dynamicCommands: [],
      resource: {
        traits: {
          mimeType: res.meta?.mimeType,
        },
        name: res.meta?.name,
        appId: 'file',
      },
      srcUrl: res.blobUrl,
    };
  }

  openFilePopUp(
    item: Search.ResultResourceItem | SearchResults,
    preventClearQueryParams?: boolean
  ): PopupRef<FilePreviewPopupComponent, Search.ResultResourceItem> {
    const fileItem = this.getBlobPreviewItem(item);

    if (this.popupRef) {
      this.popupRef.destroy();
    }
    this.popupRef = this.popupService.open('center', FilePreviewPopupComponent, fileItem, {
      position: 'center',
      backdropStyle: 'blur-2',
    });
    this.popupRefOpen$.next(true);
    this.popupRef.destroy$.subscribe(() => {
      this.popupRef = null;
      this.previewService.onDestroyPreview(item as Search.ResultResourceItem, this.previewType, preventClearQueryParams);
      this.popupRefOpen$.next(false);
    });
    return this.popupRef;
  }

  async openBlobPreview(collection: Collections.StaticCollection, blobId: string) {
    const item = await this.convertToResultItem(collection, blobId);
    this.openFilePopUp(item as SearchResults);
  }

  async convertToResultItem(collection: Collections.StaticCollection, blobId: string) {
    const file = collection.items?.find((item) => item.id === blobId) as Collections.FileItem;
    if (!file) {
      return;
    }
    const meta = await this.blobsService.getBlobMetadata(file.fileId, file.uploadTime);
    return await this.staticCollectionItemBuildResultView.buildFileView({ ...file, meta }, collection);
  }
}
