<div class="hub-container" [ngClass]="{ 'collapsed-sidebar-hub': sidebarState === 'closed', bar: isBarMode }">
  <div data-cy="loading-indicator" [attr.is-loaded]="isLoaded" style="display: none"></div>
  <as-split
    direction="horizontal"
    unit="pixel"
    id="split"
    class="split-wrapper"
    [ngClass]="{
      disabled: routingService.currentAppModule === 'settings',
      'mac-minimized': !screenMaximized && isMac && isNative && sidebarState === 'closed',
    }"
    [disabled]="routingService.currentAppModule === 'settings' || isLauncher"
    (dragEnd)="dragEnd($event)"
  >
    <as-split-area
      *ngIf="showSideBar"
      [order]="sidebarOrder"
      [(size)]="menuWidth"
      [(maxSize)]="maxMenuWidth"
      [(minSize)]="MINIMIZED_SIDEBAR_WIDTH"
      [style]="{ backgroundColor: 'transparent' }"
      class="split-area"
    >
      <ng-container *ngIf="routingService.currentAppModule === 'settings'">
        <settings-sidebar
          class="settings-sidebar"
          (trialBannerClick)="openTrialUpgradePopup()"
          [onTrialPlan]="onTrialPlan"
          [planDaysLeft]="planDaysLeft"
        ></settings-sidebar>
      </ng-container>

      <ng-container *ngIf="showSideBar">
        <div *ngIf="!screenMaximized && isMac && isNative && sidebarState === 'closed'" class="mac-top-margin"></div>
        <div
          *ngIf="routingService.currentAppModule !== 'settings'"
          class="sidebar-container"
          [ngClass]="{ 'mac-height': !screenMaximized && isMac && isNative && sidebarState === 'closed' }"
          (mouseenter)="onMouseEnterSidebar()"
          (mouseleave)="onMouseleaveSidebar()"
        >
          <sidebar
            class="sidebar"
            *ngIf="sidebarState === 'opened'"
            [workspace]="workspace"
            [workspaceIcon]="workspaceIcon"
            [onTrialPlan]="onTrialPlan"
            [planDaysLeft]="planDaysLeft"
            [user]="user"
            (helpClick)="onHelpClick($event)"
            (fyiClick)="onFyiClick($event)"
            (trialBannerClick)="openTrialUpgradePopup()"
          >
          </sidebar>
          <minimized-sidebar
            class="sidebar minimized"
            *ngIf="sidebarState === 'closed'"
            [workspace]="workspace"
            [workspaceIcon]="workspaceIcon"
            [user]="user"
            [isLauncher]="isLauncher"
            [onTrialPlan]="onTrialPlan"
            [planDaysLeft]="planDaysLeft"
            (emitLauncherAvatarPopup)="onLauncherAvatarClick('avatarIcon')"
            (helpClick)="onHelpClick($event)"
            (fyiClick)="onFyiClick($event)"
            (trialBannerClick)="openTrialUpgradePopup()"
          >
          </minimized-sidebar>
        </div>
      </ng-container>
    </as-split-area>

    <button
      data-cy="menu-toggle-sidebar-btn"
      class="toggle-button"
      *ngIf="routingService.currentAppModule !== 'settings' && showToggleButton"
      [pTooltip]="toggleTooltip"
      tooltipPosition="right"
      tooltipStyleClass="u-tooltip"
      (mouseenter)="onMousenterToggleButton()"
      (mouseleave)="onMouseLeaveButton()"
      (click)="toggleSidebar()"
      [ngStyle]="toggleButtonSide === 'left' ? { left: toggleButtonPosition } : { right: toggleButtonPosition }"
      [ngClass]="sidebarState === 'closed' ? 'toggle-button-closed' : 'toggle-button-opened'"
      [class]="{ 'bar-mode-toggle': isLauncher }"
    >
      <i class="icon-chevron-down sidebar-toggle-icon" *ngIf="!isLauncher"></i>
      <img src="./assets/sidebar/full-app.svg" class="full-app-icon" *ngIf="isLauncher" />
    </button>

    <as-split-area [order]="mainOrder" class="split-area">
      <main class="main" #main [class.scroll-visible]="scrollVisible" (scroll)="onScroll($event)">
        <banner class="hub-banner" *ngIf="!isLauncher && !isEmbed" [shortWidth]="sidebarState === 'closed' && isMac && isNative"></banner>
        <app-title-bar
          *ngIf="!isLauncher && !isExtension"
          [controls]="isLauncher ? [] : ['maximize', 'minimize', 'close']"
          [ngClass]="{
            'app-title-bar-embed-standard': isEmbed && !isLauncher,
            'app-title-bar-embed-inline': embedInline,
            'title-bar-inline-embed-clean-background': inlineEmbedCleanBackground,
          }"
        ></app-title-bar>
        <main
          id="main"
          [class]="{ 'hide-search': hideSearch && !isLauncher, 'inline-embed-clean-background': inlineEmbedCleanBackground }"
          class="mainpanel"
        >
          <div *ngIf="!isLauncher" class="draggable-area-top"></div>
          <div
            *ngIf="isLauncher || routingService.currentAppModule !== 'settings'"
            class="search-view"
            [class]="{ 'search-view-embed': isEmbed }"
          >
            <!--TODO: Move inputs to their own component -->
            <div
              data-cy="searchbar-container"
              id="inputContainer"
              #inputContainer
              class="input-container"
              [class.input-launcher]="isLauncher"
              [style]="{
                borderTopRightRadius: searchBarBorderRadius + 'px',
                borderTopLeftRadius: searchBarBorderRadius + 'px',
              }"
            >
              <div
                *ngIf="workspace && isLauncher && !isEmbed"
                #launcherAvatarElement
                class="launcher-avatar"
                (click)="onLauncherAvatarClick('avatarIcon')"
                [pTooltip]="workspace.name"
                tooltipPosition="right"
                tooltipStyleClass="u-tooltip"
              >
                <u-icon class="main-icon" [model]="workspaceIcon"></u-icon>
              </div>

              <tag-list
                #tagList
                [model]="tags$ | async"
                tabindex="-1"
                class="tag-container"
                [class.empty]="(tags$ | async)?.length === 0"
                (markedChanged)="onTagsMarkedChange($event)"
                (remove)="onRemoveTag($event)"
              ></tag-list>

              <div class="stack inputs-stack">
                <div
                  class="placeholder visible"
                  (click)="onPlaceholderClick($event)"
                  tabindex="-1"
                  style="pointer-events: none"
                  *ngIf="!inputQuery && !suggestionQuery"
                  #autoComplete
                  data-cy="searchbar-placeholder"
                  [innerHTML]="placeholder$ | async"
                ></div>
                <suggestions-autocomplete
                  *ngIf="showAutoComplete && (autoComplete$ | async) as autocomplete"
                  class="autocomplete-container placeholder visible search-box"
                  (select)="onAutoCompleteSelected()"
                  [query]="inputQuery"
                  [model]="autocomplete"
                ></suggestions-autocomplete>
                <input
                  name="search-box"
                  data-cy="searchBar-input"
                  [ngModel]="suggestionQuery || inputQuery"
                  (ngModelChange)="suggestionQuery || (inputQuery = $event)"
                  #searchInput
                  (keydown)="onKeyDown($event)"
                  class="search-box"
                  [class.transparent]="hideCursor && (tags$ | async).length"
                  (input)="onInput($event)"
                  (paste)="onPaste($event)"
                  (focus)="changeFocusState(true)"
                  (blur)="changeFocusState(false)"
                  [autocomplete]="'off'"
                />
              </div>
              <div
                (mousedown)="onEnterPressed()"
                class="enter-indication"
                *ngIf="
                  displayEnterIndication &&
                  (hubService.focusPosition$ | async) === 'searchBar' &&
                  (hubService.focusInputState$ | async) &&
                  !isLauncher
                "
              >
                <u-icon [model]="{ type: 'font', value: 'icon-key-enter' }"></u-icon>
                <span>to search</span>
              </div>
              <button
                data-cy="searchBar-clear-btn"
                *ngIf="inputQuery?.length > 0 || (tags$ | async)?.length > 0"
                (click)="onXClicked()"
                class="btn icon-btn clear error"
                [tabindex]="inputQuery?.length > 0 || (tags$ | async)?.length > 0 ? 0 : -1"
                tooltipPosition="top"
                tooltipStyleClass="u-tooltip"
                [pTooltip]="tooltipClearTemp"
                [escape]="false"
              >
                <i class="font-icon icon-times-large"></i>
              </button>
              <div
                class="collection-icon-wrapper"
                *ngIf="hubService.enableCollectionIconFunc && !isLauncher && !isEmbed && !disableCollection"
              >
                <div class="vertical-separator" *ngIf="inputQuery?.length > 0 || (tags$ | async)?.length > 0"></div>
                <u-button
                  style="margin-left: 8px; margin-right: 6px"
                  class="icon-button-clean"
                  fontIcon="add-collection"
                  type="secondary"
                  [styles]="{ fontSize: '16px' }"
                  pTooltip="Save your search as a new Live Collection"
                  tooltipPosition="bottom"
                  tooltipStyleClass="u-tooltip"
                  (click)="hubService.openCollection.next()"
                >
                </u-button>
              </div>

              <bar-badge
                *ngIf="(isOnline$ | async) === false && isLauncher"
                class="offline-badge"
                pTooltip="Offline mode. Unleash has the best experience when you are online"
                tooltipPosition="bottom"
                tooltipStyleClass="u-tooltip"
                tabindex="0"
                [text]="'Offline mode'"
              >
                <u-icon #icon [model]="{ type: 'font', value: 'icon-offline' }" [styles]="{ fontSize: '14px' }"></u-icon>
              </bar-badge>

              <ng-container *ngIf="isEmbed && isLauncher">
                <div class="separator" *ngIf="inputQuery?.length > 0 || (tags$ | async)?.length > 0"></div>

                <div
                  #embeddedAvatarElement
                  *ngIf="!externalEmbed"
                  class="search-bar-button embedded-settings"
                  (click)="onLauncherAvatarClick('searchBarButton')"
                >
                  <i class="font-icon icon-cog2"></i>
                </div>
              </ng-container>

              <ng-container>
                <suggestions-dropdown
                  #suggestionsDropdown
                  tabindex="-1"
                  [show]="hubService.suggestionsDropdownVisible"
                  [class.hidden]="!hubService.suggestionsDropdownVisible"
                  (onSelect)="onSuggestionSelected($event)"
                  (onBoxInput)="onSuggestionBoxInput($event)"
                  [provider]="getBoxSuggestions"
                  (close)="setSuggestionsDropdownVisibility(false, { preserveChar: null }, 'esc_key')"
                  (groupSelected)="groupSelected($event)"
                  (datePickerVisible)="toggleDatePickerVisible($event)"
                ></suggestions-dropdown>
              </ng-container>

              <div class="loader-container" *ngIf="!(hubService.readOnly$ | async)" [class.trim]="animatePanel === 'close'">
                <div class="loader" [class]="{ visible: loading }"></div>
              </div>
            </div>

            <trial-banner
              *ngIf="onTrialPlan && isLauncher && !inputQuery"
              [bannerType]="'launcher'"
              [bannerText]="bannerText"
              (onClick)="openTrialUpgradePopup()"
            ></trial-banner>
          </div>
          <div class="router-outlet-area">
            <router-outlet (activate)="onNavigation($event)"></router-outlet>
          </div>
          <div *ngIf="showBranding" class="footer-credit">
            <powered-by [displayBackground]="true"></powered-by>
          </div>
        </main>
      </main>
    </as-split-area>
  </as-split>
</div>

<div class="pin-wrapper"></div>

<downloads></downloads>
