<div class="banner-container" *ngIf="message" [style]="message.style" [class]="message.class" [ngClass]="{ short: shortWidth }">
  <div class="main">
    <div class="content">
      <u-icon class="banner-icon" *ngIf="message.icon" [model]="message.icon"></u-icon>
      <div class="message" [innerHtml]="message.content"></div>
    </div>
    @if (message.buttonText) {
      <u-button
        class="banner-button"
        [versionNumber]="2"
        (onClick)="onClick()"
        [typeElement]="'secondary'"
        [styleType]="'outlined'"
        [sizeElement]="'small'"
        [label]="message.buttonText"
        [styles]="message.buttonStyle ?? buttonStyle"
      >
      </u-button>
    }
  </div>
  @if (message.enableClosing) {
    <u-button
      (onClick)="onClose()"
      [versionNumber]="2"
      [typeElement]="'secondary'"
      [styleType]="'text'"
      [fontIcon]="'Windows-close'"
      [styles]="buttonStyle"
    >
    </u-button>
  }
</div>
