import { Injectable } from '@angular/core';
import { PcPreferences, Workspace } from '@local/client-contracts';
import { PcPreferencesRpcInvoker } from '@local/common';
import { isNativeWindow } from '@local/common-web';
import { NativeServicesRpcService, ServicesRpcService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { LinksService } from '@shared/services/links.service';
import { NativeAppLinkService } from '@shared/services/native-app-link.service';
import { filter, firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { WorkspacesService } from './workspaces.service';

@Injectable({
  providedIn: 'root',
})
export class PcPreferencesService implements PcPreferences.Service {
  private service: PcPreferences.Service;
  private isNativeRunning: boolean;
  private readonly isNativeWindow = isNativeWindow();
  private _current$ = new ReplaySubject<PcPreferences.PcPreferences>(1);
  private workspace: Workspace.Workspace;
  openPcBanner$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private services: ServicesRpcService,
    private nativeServices: NativeServicesRpcService,
    private nativeAppLinkService: NativeAppLinkService,
    private links: LinksService,
    private appsService: ApplicationsService,
    private workspacesService: WorkspacesService
  ) {
    this.service = (this.nativeServices || this.services).invokeWith(PcPreferencesRpcInvoker, 'pcpreferences');

    this.workspacesService.current$.pipe(filter((w) => !!w)).subscribe((w) => {
      this.workspace = w;
      this.manageShowingPcBanner();
    });

    if (!this.isNativeWindow) {
      this.nativeAppLinkService.status$?.subscribe(async (status) => {
        this.isNativeRunning = status === 'running';
        this.manageShowingPcBanner();
      });
    } else {
      this.isNativeRunning = true;
    }

    this.service.current$.subscribe((p) => {
      this._current$.next(p);
      this.manageShowingPcBanner();
    });
  }

  private isPcAllowed(): boolean {
    return this.workspace?.settings?.linking?.allowedApps?.includes('pc');
  }

  private async shouldShowPcBanner(): Promise<boolean> {
    if (!this.isPcAllowed()) {
      return false;
    }
    const pcPreferences = await firstValueFrom(this.current$);
    const isPcExists = this.appsService.apps['pc']?.links?.find((l) => l.appId === 'pc');
    return (
      !isPcExists && !pcPreferences?.isBannerClosed && pcPreferences?.enabled === undefined && (this.isNativeRunning || this.isNativeWindow)
    );
  }

  private async manageShowingPcBanner() {
    const shouldShowPcBanner: boolean = await this.shouldShowPcBanner();
    this.openPcBanner$.next(shouldShowPcBanner);
  }

  set isPcBannerClosed(value: boolean) {
    this.updateStorage({ isBannerClosed: value });
  }

  get current$(): Observable<PcPreferences.PcPreferences> {
    return this._current$;
  }

  async createPcLink(): Promise<void> {
    const res = await this.service.createPcLink();
    setTimeout(() => {
      this.links.refreshAll();
    }, 500);
    return res;
  }

  async deletePcLink(): Promise<void> {
    const res = await this.service.deletePcLink();
    setTimeout(() => {
      this.links.refreshAll();
    }, 500);
    return res;
  }

  async updateStorage(value: Partial<PcPreferences.PcPreferences>): Promise<void> {
    return this.service.updateStorage(value);
  }
}
