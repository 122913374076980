import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  TrackByFunction,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Collections, Filters, Results } from '@local/client-contracts';
import { isEmbed } from '@local/common-web';
import { Icon, PopupService, UButtonComponent, UInputComponent } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Filter, FilterChangeData } from '@shared/components/filters/models';
import { DatePickerService } from '@shared/services/date-picker.service';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { isEnterKey, isKey, isModifierKey, keyCodes, KeyName } from '@local/ts-infra';
import { windowSizeObserver } from '@shared/utils';
import { getWidthBreakpointScreen } from '@shared/utils/break-point.utils';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { NgScrollbar } from 'ngx-scrollbar';
import { combineLatest, distinctUntilChanged, filter, map, startWith } from 'rxjs';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { FiltersService } from 'src/app/bar/services/filters.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { SearchOptions, SearchResultContext, SearchService, SearchSession } from 'src/app/bar/services/search';
import { SearchParamsService } from 'src/app/bar/services/search-params.service';
import { CollectionsSourceSettings } from 'src/app/bar/services/search/client/collections';
import { SidebarService } from 'src/app/bar/services/sidebar.service';
import { SortService } from 'src/app/bar/services/sort.service';
import { TagsService } from 'src/app/bar/services/tags.service';
import { KeyboardHelperService, ScrollMode } from '../../../../../shared/helper/keyboard-helper.service';
import { getSortOptions, HeaderItem, ScrollDirection, SortOption } from '../../../results';
import { ScrollService } from '../../../results/services/scroll.service';
import { ResultSettings } from '../../../results/utils/results-settings';
import { collectionContent } from '../../helpers/collection.content';
import { CollectionRouteType, CollectionTag, CollectionViewMode } from '../../models';
import { COLLECTIONS_CARD_JUMP } from '../../models/collection-card.model';
import { CollectionViewType } from '../../models/collection-view-type.model';
import { CollectionPopupService } from '../../services/collection-popup.service';
import { NavTreeService } from 'src/app/bar/services/nav-tree.service';
import { RouterService } from '@shared/services/router.service';
import { WikiCardsService } from 'src/app/bar/services/wikis/wiki-cards.service';
import { WikiCardPreviewService } from '../../services/wiki-card-preview.service';
import { WikiDraftsService } from 'src/app/bar/services/wikis/wiki-drafts.service';
import { Config } from '@environments/config';

@UntilDestroy()
@Component({
  selector: 'collections-container',
  templateUrl: './collections-container.component.html',
  styleUrls: ['./collections-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionsContainerComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly TAG_FILTER_NAME = 'collection-tags';
  private readonly TYPE_FILTER_NAME = 'collection-type';
  readonly ALL_CARDS_ID = 'all-cards';
  readonly ALL_DRAFTS_ID = 'all-drafts';
  readonly emptyStateIcon: Icon = {
    lightUrl: 'assets/empty-state/no-collections/empty-collection-light.svg',
    darkUrl: 'assets/empty-state/no-collections/empty-collection-dark.svg',
  };

  private clearAllClicked: boolean;
  private cardsCount: number;
  private draftsCount: number;
  contentType: CollectionRouteType = 'collections';
  emptyState = false;
  displayContext: SearchResultContext;
  collections: Collections.Collection[];
  allCollections: Collections.Collection[];
  loaded: boolean;
  collectionContent = collectionContent;
  smallInputSearchFocus = false;
  tagFilter: string[];
  typeFilters: string[];
  duplicateMode = false;
  isLauncher: boolean;

  // keyboard
  private keysHandlerId: string;
  selectedIndex = 0;
  private keyboardHelperService: KeyboardHelperService;

  // scroll
  private scrollService: ScrollService<any>;
  @ViewChild(CdkVirtualScrollViewport) scrollViewport: CdkVirtualScrollViewport;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  @ViewChild('openCollectionButtonRef', { read: UButtonComponent }) openCollectionButtonRef: UButtonComponent;
  @ViewChildren('collectionItem', { read: ElementRef }) collectionsElements: QueryList<ElementRef>;

  // Search
  private searchSession: SearchSession;
  private query: string;
  private disableAllCardsResult: boolean;
  selectedCollectionType: Collections.Kind[];

  get selected(): Collections.Collection {
    return this.collections?.[this.selectedIndex];
  }

  get isViewPageActive() {
    return this.collections?.[0]?.id === this.ALL_CARDS_ID || this.collections?.[1]?.id === this.ALL_DRAFTS_ID;
  }

  get showHeader() {
    return this.contentType !== 'wikis' || !this.isViewPageActive || this.collectionItemViewMode === 'card';
  }

  get showSubHeader() {
    return this.isViewPageActive && this.contentType === 'wikis' && this.collectionItemViewMode === 'list';
  }

  get title() {
    return this.tagFilter?.[0] ?? this.contentType;
  }

  get collectionTypeFilter(): Collections.Kind[] {
    return this.contentType === 'collections' ? this.selectedCollectionType || ['Live', 'Static'] : ['Wiki'];
  }

  get routeByType(): CollectionRouteType {
    return this.contentType === 'collections' ? 'collections' : 'wikis';
  }

  trackByCollection: TrackByFunction<Collections.Collection> = (index, collection) => collection.id;
  header: HeaderItem;
  filters: Filter[];

  isEmbed = isEmbed();
  currentState: any;

  @ViewChild('contextContainer') contextContainer: ElementRef;
  private windowSize$ = windowSizeObserver();
  private contextObserver: ResizeObserver;
  private skipFilters: string[] = [];
  private disableMarkSelectedFilters: Set<string> = new Set();
  @ViewChild('smallInputSearch')
  smallInputSearch: UInputComponent;
  sortOptions: SortOption[];
  nextLayoutMode: Results.LayoutType = 'gallery';
  currentLayoutMode: Results.LayoutType = 'list';
  collectionItemViewMode: CollectionViewMode = 'search-result';
  smallScreen: boolean;

  constructor(
    private ngZone: NgZone,
    public hubService: HubService,
    private cdr: ChangeDetectorRef,
    public collectionsService: CollectionsService,
    private keyboardService: KeyboardService,
    private searchService: SearchService,
    public collectionPopupService: CollectionPopupService,
    private tagsService: TagsService,
    private filtersService: FiltersService,
    private datePickerService: DatePickerService,
    private searchParamsService: SearchParamsService,
    private sidebarService: SidebarService,
    private sortService: SortService,
    private navTreeService: NavTreeService,
    private routerService: RouterService,
    private wikiCardsService: WikiCardsService,
    private wikiDraftsService: WikiDraftsService,
    private wikiCardPreviewService: WikiCardPreviewService, //for initialize
    private popupService: PopupService
  ) {
    this.hubService.isLauncher$.pipe(untilDestroyed(this)).subscribe((l) => (this.isLauncher = l));
  }

  ngOnDestroy(): void {
    this.hubService.autoCompleteEnabled = true;
    this.keyboardService.unregisterKeyHandler(this.keysHandlerId);
    this.tagsService.reset();
    this.searchSession?.destroy();
    this.contextObserver?.disconnect();
  }

  ngOnInit() {
    this.routerService.active$
      .pipe(
        untilDestroyed(this),
        filter((r) => this.collectionsService.isCollectionsRoute(r)),
        map((r): CollectionRouteType => (r.startsWith('wikis') ? 'wikis' : 'collections'))
      )
      .subscribe((r) => {
        this.contentType = r;
        if (this.contentType === 'wikis') {
          this.wikiCardsService.refresh();
        }
      });
    this.collectionsService.refresh();
    if (this.hubService.state.collection) {
      this.emptyState = true; // issue with native , with open collection page from luncher
      this.hubService.readOnly = true;
    }
    this.hubService.autoFocus = true;
    this.hubService.changeFocusStateMultiCalls(true, true);
    this.hubService.searchMethod = 'Quick-Search';
    this.collectionsService.all$.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.markForCheck();
    });
    this.setHubServiceData();
    let first = true;
    let nodeClicked = false;
    this.sidebarService.onNodeClick$.pipe(untilDestroyed(this)).subscribe((node) => {
      if (node === 'collections' && this.query?.length) {
        nodeClicked = true;
      }
    });
    combineLatest([
      this.hubService.state$.pipe(untilDestroyed(this), startWith(null)),
      this.collectionsService.all$.pipe(untilDestroyed(this)),
      this.wikiCardsService.cardsCount$.pipe(untilDestroyed(this), startWith(null)),
      this.wikiDraftsService.draftsCount$.pipe(untilDestroyed(this), startWith(null)),
    ]).subscribe(([state, collections, cardsCount, draftsCount]) => {
      if (!collections) {
        return;
      }
      this.emptyState = collections?.length === 0 || Config.search.emptyStates.collection;
      this.allCollections = collections;

      if (!this.allCollections.length && !isEmpty(this.currentState)) {
        this.filtersService.removeAllFilters();
      }

      if (this.emptyState) {
        return;
      }
      const newState = cloneDeep(state);
      delete newState.fyi; // ignore fyi state change
      if (!isEqual(newState, this.currentState)) {
        this.currentState = newState;
        this.search(first || nodeClicked ? 'navigation_tree' : null);
        first = false;
        nodeClicked = false;
      }
      this.cardsCount = cardsCount;
      this.draftsCount = draftsCount;
    });
    this.scrollService = new ScrollService(this.ngZone);
    this.setUpKeyboardHelperService(this.getScrollMode());
    this.setFilters();
    this.sortOptions = getSortOptions('collections');
    this.initLayout();
  }

  getScrollMode(): ScrollMode {
    if (this.isLauncher) {
      return 'vertical';
    }
    return this.currentLayoutMode === 'gallery' ? 'horizontal' : 'vertical';
  }

  ngAfterViewInit(): void {
    this.initNgScrollerHeight();
  }

  initNgScrollerHeight() {
    this.windowSize$.pipe(untilDestroyed(this)).subscribe(() => {
      this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
      this.checkSmallScreen();
    });
    if (this.contextContainer) {
      const { nativeElement: el } = this.contextContainer;
      this.contextObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        this.calculateNgScrollerHeight(entries[0]?.target);
      });
      this.contextObserver.observe(el);
    }
  }

  private checkSmallScreen() {
    this.smallScreen = getWidthBreakpointScreen() === 'small';
    this.cdr.markForCheck();
  }

  calculateNgScrollerHeight(el) {
    if (!el) return;
    const boundingClientRect = el.getBoundingClientRect();
    el['style'].height = window.innerHeight - boundingClientRect.top + 'px';
  }

  setFilters() {
    combineLatest([this.filtersService.allFilters$, this.navTreeService.currentNode$.pipe(distinctUntilChanged())])
      .pipe(untilDestroyed(this))
      .subscribe(([_, currentNode]) => {
        const selectedFilters = this.filtersService.getPreFilters(true);
        const kinds: Collections.Kind[] = this.contentType === 'wikis' ? ['Wiki'] : ['Live', 'Static'];
        const filters = this.collectionsService.getPageFiltersByType(kinds);
        this.handleTagFilter(selectedFilters, currentNode);
        const collectionTypeFilter = selectedFilters[this.TYPE_FILTER_NAME];
        let callSearch; // needed because state$ happen before this logic and need to trigger search again after filters updated
        if (collectionTypeFilter) {
          if (collectionTypeFilter.length === 1 && this.selectedCollectionType !== collectionTypeFilter) {
            this.selectedCollectionType = collectionTypeFilter as Collections.Kind[];
            callSearch = true;
          }
          if (collectionTypeFilter.length === 1 && collectionTypeFilter[0] === 'Wiki') {
            this.skipFilters.push(this.TYPE_FILTER_NAME);
          } else {
            if (!this.selectedCollectionType) {
              this.disableMarkSelectedFilters.add(this.TYPE_FILTER_NAME);
            } else {
              this.disableMarkSelectedFilters.delete(this.TYPE_FILTER_NAME);
            }
          }
        } else {
          this.selectedCollectionType = null;
          this.disableMarkSelectedFilters.add(this.TYPE_FILTER_NAME);
        }
        this.checkAllCardsVisibility(selectedFilters);
        this.filters = this.collectionsService.buildCollectionsFilters(
          filters,
          selectedFilters,
          this.skipFilters,
          this.disableMarkSelectedFilters
        );
        this.cdr.markForCheck();
        if (callSearch) {
          this.search();
        }
      });
  }

  private handleTagFilter(selectedFilters: Filters.Values, currentNode: any) {
    const tagFilter = currentNode?.data?.filters?.[this.TAG_FILTER_NAME];
    if (tagFilter) {
      if (Object.keys(selectedFilters || {}).length === 1 && isEqual(this.tagFilter, tagFilter)) {
        if (!this.clearAllClicked) return;
        this.clearAllClicked = false;
      }
      this.tagFilter = tagFilter;
      this.filtersService.setFilters(this.TAG_FILTER_NAME, [tagFilter], 'pre', false);
      this.skipFilters.push(this.TAG_FILTER_NAME);
    }
  }

  private checkAllCardsVisibility(selectedFilters: Filters.Values) {
    this.disableAllCardsResult =
      (selectedFilters[this.TYPE_FILTER_NAME] && Object.keys(selectedFilters).length > 1) || !!this.hubService.inputQuery;
  }

  onTagClick(tag: CollectionTag) {
    this.filtersService.setFilters('collection-tags', [tag.value], 'pre', false);
  }

  onFilterChange(data: FilterChangeData) {
    const name = data.name;
    if (data.name === this.TYPE_FILTER_NAME && ['ClearAll', 'Remove'].includes(data.action)) {
      this.clearTypeFilters();
    }
    if (data.action === 'ClearAll') {
      this.clearAllClicked = true;
      this.filtersService.setFilters(name, [], 'pre');
      return;
    }
    if (this.isTypeFilter(data)) {
      this.selectedCollectionType = data.current.values.map((v) => v.value);
    }
    this.filtersService.setFilters(
      data.name,
      data.current?.values.map((v) => v.value),
      'pre',
      false
    );
  }

  private isTypeFilter(data: FilterChangeData) {
    return data.current?.values?.[0]?.filterName === this.TYPE_FILTER_NAME;
  }

  clearAllFilters() {
    this.clearAllClicked = true;
    this.clearTypeFilters();
    this.filtersService.removeAllFilters('all', false, this.tagFilter ? [this.TAG_FILTER_NAME] : []);
  }

  private clearTypeFilters() {
    this.filtersService.routeFilters = this.selectedCollectionType = null;
  }

  setHubServiceData() {
    this.hubService.placeholder = 'Search in collections';
    this.hubService.autoCompleteEnabled = false;
    this.hubService.suggestionsEnabled = false;
    this.hubService.readOnly = true;
  }

  private getPreFilters(): Filters.Values {
    return {
      ...this.filtersService.getPreFilters(true),
      'collection-type': this.collectionTypeFilter,
    };
  }

  async search(trigger?: string) {
    this.searchSession = this.searchService.getOrCreateSearchSession('collections');
    const source = this.getSource();
    const searchTrigger = this.hubService.getState('search-trigger')[0] || trigger;
    this.query = this.hubService.query?.toLowerCase();
    const sort = this.searchParamsService.getSort();
    const options: SearchOptions = {
      resetSession: true,
      query: this.query,
      sources: [
        {
          ...source,
          filters: { preFilters: this.getPreFilters(), postFilters: this.filtersService.postFilters },
          sorting: sort,
          tag: 'get-collections-container',
          debounceTime: 30,
        } as CollectionsSourceSettings,
      ],
      trigger: searchTrigger ? searchTrigger : 'collections/user_query',
      telemetrySearchMethod: 'Quick-Search',
    };
    this.searchSession
      .search$(options)
      .pipe(untilDestroyed(this))
      .subscribe((ctx: SearchResultContext) => {
        this.displayContext = ctx;
        if (!this.allCollections?.length) {
          return;
        }
        if (ctx.searchCompleted) {
          const prevCollectionsLength = this.collections?.length;
          this.collections = ctx?.items.filter((i) => i.type === 'collection') as Collections.Collection[];

          if (this.contentType === 'wikis' && this.collections?.length && !this.disableAllCardsResult) {
            this.collections.unshift(
              {
                kind: 'Wiki',
                title: 'Cards',
                id: this.ALL_CARDS_ID,
                layout: this.currentLayoutMode,
                resultsCount: this.cardsCount,
              },
              { kind: 'Wiki', title: 'My Drafts', id: this.ALL_DRAFTS_ID, layout: this.currentLayoutMode, resultsCount: this.draftsCount }
            );
          }
          if (this.collections.length > prevCollectionsLength && this.duplicateMode) {
            this.updateSelectedIndex(0);
            this.scrollViewport.scrollTo({ top: 0 });
            this.duplicateMode = false;
          }
          this.keyboardHelperService?.onInit(this.selectedIndex, this.collections, this.scrollService);
          this.emptyState = this.collections?.length === 0 && isEmpty(this.currentState);
          this.loaded = this.collections && this.displayContext.searchCompleted;
          if (this.collectionsElements?.length) {
            this.refreshScrollService();
          }
          this.header = this.displayContext.items.find((i) => i.type === 'header') as HeaderItem;
          this.cdr.markForCheck();
          setTimeout(() => {
            this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
          }, 0);
        }
      });
  }

  private getSource() {
    return !isEmpty(this.currentState) ? ResultSettings.searchViewCollections : ResultSettings.defaultCollections;
  }

  private initLayout() {
    if (this.contentType === 'collections') {
      this.collectionsService.collectionsLayoutMode$.pipe(untilDestroyed(this)).subscribe((layout: Results.LayoutType) => {
        this.updateCurrentLayout(layout);
        this.cdr.markForCheck();
      });
    } else if (this.contentType === 'wikis') {
      this.collectionsService.wikisLayoutMode$.pipe(untilDestroyed(this)).subscribe((layout: Results.LayoutType) => {
        this.updateCurrentLayout(layout);
        this.cdr.markForCheck();
      });
    }
  }

  setUpKeyboardHelperService(scrollMode: ScrollMode) {
    this.keyboardHelperService = new KeyboardHelperService(scrollMode);
    this.keyboardHelperService.updateCdr.pipe(untilDestroyed(this)).subscribe(() => this.cdr.markForCheck());
    this.keyboardHelperService.updateSelectedIndex.pipe(untilDestroyed(this)).subscribe((index) => {
      if (this.selectedIndex === null) {
        this.updateSelectedIndex(0);
      } else if (index === undefined) {
        this.selectedIndex = null;
      } else if (this.currentLayoutMode === 'gallery') {
        this.selectedIndex = index;
      }
      this.cdr.markForCheck();
    });
    this.registerKeyHandler();
  }

  private registerKeyHandler() {
    if (this.keysHandlerId) return;
    this.keysHandlerId = this.keyboardService.registerKeyHandler(async (keys, event) => {
      const key = keys[0];
      if (!!this.collectionPopupService.deletePopupRef && !isEnterKey(key)) {
        return;
      }
      this.keyboardHelperService.handleArrows(keys, event);
      this.scrollService.scrollIfNeeded(this.selectedIndex, this.collections);
      await this.handleKeys(keys, event);
    }, 5);
  }

  private refreshScrollService() {
    this.scrollService.items = this.collections;
    this.scrollService.itemHeights = this.collections.map((c) => (c.tags?.length ? 60 : 40));
    this.scrollService.init(this.scrollbarRef, this.collectionsElements, this.scrollViewport);
    if (!this.scrollViewport?.elementRef?.nativeElement) {
      this.scrollService?.destroy();
    }
  }

  openNewCollectionPopup(elm: UButtonComponent) {
    this.collectionsService.openNewCollectionPopup(elm.button.nativeElement, false, this.tagFilter, this.contentType);
  }

  private async handleKeys(eventKeys: Array<KeyName>, event: CustomKeyboardEvent): Promise<void> {
    const key = eventKeys[0];
    const modifiers: KeyName[] = eventKeys.filter((k) => isModifierKey(k));

    if (isEnterKey(key)) {
      if (this.datePickerService.isVisible || !this.selected) return;
      if (this.collectionPopupService.deletePopupRef) {
        this.collectionsService.deleteCollection(this.selected).then(() => {
          this.closePopups();
        });
      } else {
        await this.openCollection(this.selected, 'collections-view');
      }
      this.endKeyboardClick(event);
      event.stopPropagation();
      return;
    }
    if (isKey(event, keyCodes.escape)) {
      this.closePopups();
    }

    if (isKey(event, keyCodes.c) && !this.smallInputSearchFocus && !this.popupService.hasDialog) {
      this.openNewCollectionPopup(this.openCollectionButtonRef);
      event.stopPropagation();
      return;
    }

    if (!this.isLauncher && (isKey(event, keyCodes.ArrowDown) || isKey(event, keyCodes.ArrowUp))) {
      if (this.selectedIndex === null && isKey(event, keyCodes.ArrowDown)) {
        this.updateSelectedIndex(0);
        this.hubService.focusPosition = null;
      } else {
        this.moveUpOrDown(isKey(event, keyCodes.ArrowDown) ? 'down' : 'up');
        if (this.smallInputSearch) {
          event.preventDefault();
        }
      }
    }
    if (modifiers.length === 1 && modifiers.every((m) => ['control'].includes(m))) {
      if (isKey(event, keyCodes.g)) {
        this.collectionsService.duplicateCollection(this.selected);
        this.endKeyboardClick(event);
        this.duplicateMode = true;
      }
      if (isKey(event, keyCodes.f)) {
        this.collectionsService.toggleFavorite(this.selected);
        this.endKeyboardClick(event);
      }
    }
    if (modifiers.length === 2 && modifiers.includes('shift') && modifiers.includes('control')) {
      if (isKey(event, keyCodes.c)) {
        this.collectionsService.copyUrl(this.selected);
        this.endKeyboardClick(event);
      }
    }
    if (key === 'slash') {
      if (this.smallInputSearch && !this.smallInputSearchFocus) {
        this.smallInputSearch.focusInput();
        event.stopPropagation();
        event.preventDefault();
      }
    }
    this.cdr.markForCheck();
  }

  private moveUpOrDown(direction: ScrollDirection) {
    const jump = this.currentLayoutMode === 'gallery' ? COLLECTIONS_CARD_JUMP[getWidthBreakpointScreen()] : 1;
    switch (direction) {
      case 'up': {
        if (!this.selectedIndex) {
          this.selectedIndex = null;
          this.hubService.focusPosition = 'filters';
          this.cdr.markForCheck();
          return;
        }
        if (this.selectedIndex - jump > 0) {
          this.updateSelectedIndex((this.selectedIndex -= jump));
        } else {
          this.updateSelectedIndex(0);
        }
        break;
      }
      case 'down': {
        if (this.selectedIndex + jump < this.collections.length) {
          this.updateSelectedIndex((this.selectedIndex += jump));
        } else {
          this.updateSelectedIndex(this.collections.length - 1);
        }
        break;
      }
    }
  }

  private endKeyboardClick(event: CustomKeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  closePopups() {
    this.collectionPopupService.deletePopupRef?.destroy();
    this.registerKeyHandler();
  }

  async openCollection(collection: Collections.Collection, viewType: CollectionViewType) {
    if (collection.id === this.ALL_CARDS_ID) {
      await this.wikiCardsService.openAllCards();
      return;
    }
    if (collection.id === this.ALL_DRAFTS_ID) {
      await this.wikiDraftsService.openAllDrafts();
      return;
    }
    this.collectionsService.openCollection(collection, viewType);
  }

  onHoverItem(i: number) {
    this.hubService.focusPosition = null;
    this.cdr.markForCheck();
  }

  updateSelectedIndex(index: number) {
    this.selectedIndex = this.keyboardHelperService.selectedIndex = index;
    this.cdr.markForCheck();
  }

  updateQuery(event: string) {
    this.disableAllCardsResult = !!event;
    this.hubService.inputQuery = event;
    this.hubService.focusPosition = null;
    this.cdr.markForCheck();
  }

  openSort(elm: UButtonComponent) {
    const popupRefSort = this.sortService.openSortPopup(elm, { sortOptions: this.sortOptions });

    popupRefSort.close$.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  changeLayout() {
    this.switchLayout();
    this.updateLayoutRelated();
    this.collectionsService.saveLayoutModeRoaming(this.currentLayoutMode, this.contentType);
    this.updateSelectedIndex(0);
    this.cdr.markForCheck();
  }

  private updateCurrentLayout(layout: Results.LayoutType = 'list') {
    this.currentLayoutMode = layout;
    this.updateLayoutRelated();
  }

  private switchLayout() {
    this.currentLayoutMode = this.currentLayoutMode === 'gallery' ? 'list' : 'gallery';
  }

  private updateLayoutRelated() {
    const isList = this.currentLayoutMode === 'list';
    this.nextLayoutMode = isList ? 'gallery' : 'list';
    this.collectionItemViewMode = isList ? 'list' : 'card';
    this.keyboardHelperService.updateScrollMode(isList ? 'vertical' : 'horizontal');
  }
}
