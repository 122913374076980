import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core';
import { UTextareaComponent } from '../u-textarea/u-textarea.component';
@Component({
  selector: 'u-textarea-interactive',
  templateUrl: './u-textarea-interactive.component.html',
  styleUrls: ['./u-textarea-interactive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UTextareaInteractiveComponent {
  readonly MAX_INPUT_WORDS_REACHED_MSG = 'Word limit reached';

  isFocus = signal(false);
  isValidLength = signal(true);

  @Input() rows = 1;
  @Input() cols = 1;
  @Input() placeholder = '';
  @Input() styles: any = {};
  @Input() autoResize = true;
  @Input() autofocus = true;
  @Input() disabled = false;
  @Input() model: string;
  @Input() ignoreEnter: boolean;
  @Input() readonly = false;
  @Input() disableAutoResize = true;
  @Input() maxLength: number;
  @Input() setMaxLength: boolean;
  @Input() minHeight = 24;
  @Input() displayLimitWarning: boolean;
  @Input() rightSideTemplate: any;
  @Input() leftSideTemplate: any;
  @Input() footerTemplate: any;
  @Input() maxRowLimit = 5;
  @Input() maxWords: number;

  @ViewChild(UTextareaComponent) textAreaComp: UTextareaComponent;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleValidationResult: EventEmitter<boolean> = new EventEmitter<boolean>();

  onChangeEvent($event) {
    this.onChange.emit($event);
  }

  onFocusEvent($event) {
    this.isFocus.set(true);
    this.onFocus.emit($event);
  }

  onBlurEvent($event) {
    this.isFocus.set(false);
    this.onBlur.emit($event);
  }

  onEnterEvent($event) {
    this.onEnter.emit($event);
  }

  handleMaxContentLengthEvent($event) {
    this.isValidLength.set($event);
    this.handleValidationResult.emit($event);
  }

  clearInput() {
    this.textAreaComp.clearInput();
  }

  setFocus() {
    this.textAreaComp.setFocus();
  }
}
