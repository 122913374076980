import { Platform as PlatformInt } from '@local/common';

export function getOS() {
  const userAgent = self.navigator.userAgent,
    platform = self.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'MacOS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

export function isMac() {
  return getOS() == 'MacOS';
}

export function isLinux() {
  return getOS() == 'Linux';
}

export function isWindows() {
  return getOS() == 'Windows';
}

export function isNativeWindow() {
  return navigator.userAgent.indexOf('unleash-desktop') != -1;
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function isEmbed(): boolean {
  return !!(((self.top && self != self.top) || (<any>self).__embed) && !(<any>self).newTabPage) && (<any>self).testEmbed !== false;
}

export function isExtension(): boolean {
  return isEmbed() && (<any>self).__embedId?.startsWith('extension:');
}

export function isExternalEmbed(x: string) {
  return x && !x?.startsWith('extension:') && !x?.startsWith('addon:');
}

export function isTrustedOrigin(org: string) {
  if (!org) return false;

  const h1 = location.host
    .split('.')
    .reverse()
    .filter((x) => x);
  const h2 = org
    .split('.')
    .reverse()
    .filter((x) => x);

  if (h1.length >= 3 && h2.length >= 3) {
    // we consider an org to be in another org if it has 3 parts min
    const root_domain1 = '.' + h1[2] + '.' + h1[1] + '.' + h1[0];
    const root_domain2 = '.' + h2[2] + '.' + h2[1] + '.' + h2[0];
    if (root_domain1 == root_domain2) return true;
  }

  if (
    org == location.origin ||
    org.startsWith('http://localhost:') ||
    org.startsWith('https://localhost:') ||
    org.endsWith('.unlsh.me') ||
    org.endsWith('.unleash.so') ||
    org.endsWith('.unleash.team')
  )
    return true;

  return false;
}

export function isSafari() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') != -1 && !(ua.indexOf('chrome') != -1) && ua.indexOf('version/') != -1;
}

export function isEdge() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf(' edg/') != -1;
}

export function isChrome() {
  return (
    /Chrome/.test(navigator.userAgent) &&
    /Google Inc/.test(navigator.vendor) &&
    JSON.stringify((<any>navigator).userAgentData)
      ?.toLowerCase()
      ?.indexOf('google')
  );
}

export function isOpera() {
  return navigator.userAgent.match(/Opera|OPR\//);
}

export function isBrowserExtension(id, config) {
  const res =
    id == 'extension:' + config.edge.id ||
    id == 'extension:' + config.chromium.id ||
    id == 'extension:' + config.gecko.id ||
    id == 'extension:' + config.webkit.id;
  return res;
}

export function isFirefox() {
  const ua = navigator.userAgent.toLowerCase();
  return /firefox\/(\S+)/.test(ua);
}

export type BrowserName = 'Chrome' | 'Edge' | 'Safari' | 'Firefox' | 'Opera' | 'Other';

export function getBrowser(): BrowserName {
  if (isEdge()) {
    return 'Edge';
  }
  if (isSafari()) {
    return 'Safari';
  }
  if (isFirefox()) {
    return 'Firefox';
  }
  if (isOpera()) {
    return 'Opera';
  }

  if (isChrome()) {
    return 'Chrome';
  }
  return 'Other';
}

export class Platform implements PlatformInt {
  isWeb() {
    return true;
  }
  isMac(): boolean {
    return isMac();
  }
  isWindows(): boolean {
    return isWindows();
  }
  isLinux(): boolean {
    return isLinux();
  }
}
