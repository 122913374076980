import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Commands, Search } from '@local/client-contracts';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoaderService } from '@shared/loader.service';
import { RouterService } from '@shared/services/router.service';
import { take } from 'rxjs';
import { ResultsService } from 'src/app/bar/services/results.service';
import { WikiCardsService } from 'src/app/bar/services/wikis/wiki-cards.service';
import { isWikiCardFile, isWikiCardResource } from '../../utils/results.util';

@UntilDestroy()
@Component({
  selector: 'resource-redirector',
  styleUrl: './resource-redirector.component.scss',
  templateUrl: './resource-redirector.component.html',
})
export class ResourceRedirectorComponent implements OnInit {
  constructor(
    loaderService: LoaderService,
    private resultsService: ResultsService,
    private route: ActivatedRoute,
    private routerService: RouterService,
    private wikiCardService: WikiCardsService
  ) {
    loaderService.ready$.next(false);
  }

  ngOnInit() {
    const resourceId = this.route.snapshot.params.id;
    let index = 0;
    this.resultsService
      .getItems$({
        cache: 'first',
        includeDeleted: true,
        includeHiddenLinks: true,
        excludeLocal: true,
        idType: 'internal',
        resources: [{ resourceId, linkId: null }],
      })
      .pipe(untilDestroyed(this), take(2))
      .subscribe((res) => {
        const resource = res?.[0];
        if (resource) {
          const url = this.getResourceLink(resource);
          if (url) {
            location.href = url;
            return;
          } else {
            this.routerService.navigateByUrl('/not-found');
          }
        }
        if (index++ === 1) {
          this.routerService.navigateByUrl('/not-found');
        }
      });
  }
  private getResourceLink(resource: Search.ResultResourceItem) {
    if (isWikiCardResource(resource)) {
      return this.wikiCardService.getCardUrl('', resource.resource?.externalId, true);
    }
    if (isWikiCardFile(resource)) {
      return this.wikiCardService.getCardUrl('', resource.resource?.data?.cardId, true);
    }
    return (<Commands.OpenUrl>resource.view?.title?.onClick)?.url;
  }
}
