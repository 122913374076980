import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Stats, Verifications, Wiki } from '@local/client-contracts';
import { ManualPromise } from '@local/common';
import { generateId, isEmbed, isNativeWindow } from '@local/common-web';
import { KeyName, generateTitleUrl, isCtrlOrCommandCombination, isKey, keyCodes } from '@local/ts-infra';
import { DynamicComponentBase, PopupRef, PopupService, UTextareaComponent } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ContextMenuService } from '@shared/components';
import { EmbedService } from '@shared/embed.service';
import { LoaderService } from '@shared/loader.service';
import { EventsService, WindowService } from '@shared/services';
import { Breadcrumb, BreadcrumbsService } from '@shared/services/breadcrumbs.service';
import { KeyboardService } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { SessionService } from '@shared/services/session.service';
import { windowSizeObserver } from '@shared/utils';
import { stopEvent } from '@shared/utils/elements-util';
import { cloneDeep, concat, isNil, map } from 'lodash';
import { Subject, Subscription, debounceTime, filter, take, takeUntil } from 'rxjs';
import { CollectionsUtilService } from 'src/app/bar/services/collections-util.service';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { DRAFT_CARD_URL_PARAM, NEW_CARD_URL_PARAM } from 'src/app/bar/services/preview.service';
import { WikiCardsVerificationsService } from 'src/app/bar/services/wikis/wiki-cards-verifications.service';
import { WikiCardsService } from 'src/app/bar/services/wikis/wiki-cards.service';
import { EMBED_SAVE_CARD_KEY_STORAGE } from '../../../preview/helpers/preview.utils';
import { PreviewMode, PreviewWidthBreakpoint } from '../../../preview/model/preview-mode';
import { collectionContent } from '../../helpers/collection.content';
import { DraftSaveModel, WikiCardSaveFlowService } from '../../services/wiki-card-save-flow.service';
import { AutoSaveStatus, SwitchViewState, WikiCardPageMode, WikiCardPopupModel, WikiCardStorageModel } from '../../models/wiki-card-model';
import { WikiCardPreviewService } from '../../services/wiki-card-preview.service';
import { WikiItemSelectionPopupService } from '../../services/wiki-item-selection-popup.service';
import { CollectionTagSectionComponent } from '../collection-tag-section/collection-tag-section.component';
import { FrameEditorConstants } from '../../../frame-editor/constants/frame-editor.constants';
import { WikiPopupsService } from '../../services/wiki-popups.service';
import { FrameEditorContextMenuService } from 'src/app/bar/views/frame-editor/services/frame-editor-context-menu.service';
import { FrameEditorRenderHtmlService } from '../../../frame-editor/services/frame-editor-render-html.service';
import tinymce from 'tinymce';
import { TelemetryTarget } from '../../../special-views/search-popup/model';
import { BlobsStorageService } from 'src/app/bar/services/blobs/blobs-storage.service';

@UntilDestroy()
@Component({
  selector: 'wiki-card-popup',
  templateUrl: './wiki-card-popup.component.html',
  styleUrls: ['./wiki-card-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WikiCardPopupComponent implements OnInit, OnDestroy, DynamicComponentBase<any>, AfterViewInit {
  private readonly isEmbed: boolean = isEmbed();
  private readonly LINE_HEIGHT = 32;
  private readonly AUTO_SAVE_DEBOUNCE_MS = 2000;
  private readonly IMMEDIATE_SAVE_THRESHOLD_MS = 30000;
  private readonly destroy$ = new Subject();
  readonly STAT_WAITING_TIME: number = 5000;
  readonly COLLECTION_CONTENT = collectionContent;
  readonly FRAME_EDITOR_ID = `unleash_editor_${generateId()}`;
  readonly READONLY_FRAME_EDITOR_ID = `readonly_unleash_editor_${generateId()}`;

  //Auto save
  private lastAutoSaveTimestamp: number;
  private lastChangeAutoSave: number;
  autoSaveStatus: AutoSaveStatus;
  private autoSaveSubject$: Subject<number>;
  private autoSaveSub: Subscription;

  private popupRef: PopupRef<WikiCardPopupComponent, WikiCardPopupModel>;
  private prevAutoFocus: boolean;
  private _model: WikiCardPopupModel;
  private _containerSize: { width: number; height: number };
  private _title: string;
  private windowSize$ = windowSizeObserver();
  private contextObserver: ResizeObserver;
  private verificationSubscription: Subscription;
  private cardUpdated = new ManualPromise<void>();
  private keyHandlerId: string;
  private embedInline: boolean;
  private accountId: string;
  private currentHtml = null;
  private statTimeoutId: any;
  private cardPublished: boolean;

  closeDropdownTags = 0;
  initialTitle: boolean;
  data: { cardId: string };
  componentFather: any;
  contentLoaded = false;
  breadcrumbsItems: Breadcrumb[];
  pageMode: WikiCardPageMode;
  collection: Wiki.WikiCollection;
  card: Wiki.CardPath | Wiki.Card;
  initialHtml = '';
  tags: string[];
  updatedVerification: Verifications.Verification;
  textareaRows = 1;
  publishInProgress: boolean;
  showCloseButton: boolean;
  errorState: boolean;

  @Input() set model(val: WikiCardPopupModel) {
    if (!val) {
      return;
    }
    if (this.model) {
      if (this.pageMode === 'inline') {
        this.showSavedDraftToaster();
      }
      this.finalSaveDraftIfNeeded(true);
    }
    this.resetProperties(true);
    this._model = val;
    if (this.model?.pageMode) {
      this.pageMode = this.model.pageMode;
    }
    this.initModel();
    this.cdr.markForCheck();
  }

  @Input() previewWidthBreakpoint: PreviewWidthBreakpoint;
  @Input() size: PreviewMode = 'popup';

  @Input() set containerSize(val: { width: number; height: number }) {
    this._containerSize = val;
    this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
    this.cdr.markForCheck();
  }

  get model(): WikiCardPopupModel {
    return this._model;
  }

  get containerSize(): { width: number; height: number } {
    return this._containerSize;
  }

  get hasRequests(): boolean {
    const hasPolicy: boolean = this.collection?.policy?.disabled === false;
    return !!this.updatedVerification?.requests?.length && hasPolicy;
  }

  private get isNewMode() {
    return this.model?.isNewMode;
  }

  get publishedMode() {
    return this.model?.viewMode === 'published';
  }

  get draftMode() {
    return this.model?.viewMode === 'draft';
  }

  get currentContent() {
    return this.draftMode ? this.card.draft?.content : this.card?.content;
  }

  get currentTitle() {
    return this.draftMode ? this.card.draft?.title : this.card?.title;
  }

  get tooltipText(): string {
    const el: HTMLElement = this.textAreaTitle?.textArea?.nativeElement;
    if (el?.offsetHeight < el?.scrollHeight) {
      return this.title;
    }
    return null;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
    this.cdr.markForCheck();
    this.updateBreadcrumbsAndTitle();
  }

  @ViewChild(CollectionTagSectionComponent) tagSection: CollectionTagSectionComponent;
  @ViewChild('contextContainer') contextContainer: ElementRef;
  @ViewChild('textAreaTitle') textAreaTitle: UTextareaComponent;

  @HostBinding('attr.fullPage')
  get isFullPage() {
    return this.pageMode === 'full';
  }

  @HostBinding('attr.pageMode')
  get pageModeAttr() {
    return this.pageMode;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler() {
    this.finalSaveDraftIfNeeded(true);
  }

  constructor(
    private popupService: PopupService,
    private collectionsService: CollectionsService,
    public wikiCardsService: WikiCardsService,
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService,
    private injector: Injector,
    private keyboardService: KeyboardService,
    private windowService: WindowService,
    private activeRoute: ActivatedRoute,
    private embedService: EmbedService,
    private hubService: HubService,
    private eventsService: EventsService,
    private wikiCardPreviewService: WikiCardPreviewService,
    private collectionsHelperService: CollectionsUtilService,
    private wikiCardsVerificationsService: WikiCardsVerificationsService,
    private routerService: RouterService,
    private breadcrumbsService: BreadcrumbsService,
    private titleService: Title,
    private contextMenuService: ContextMenuService,
    private wikiItemSelectionPopupService: WikiItemSelectionPopupService,
    private wikiCardSaveFlowHandler: WikiCardSaveFlowService,
    private sessionService: SessionService,
    private wikiPopupsService: WikiPopupsService,
    private frameEditorRenderHtmlService: FrameEditorRenderHtmlService,
    private frameEditorContextMenuService: FrameEditorContextMenuService,
    private frameEditorStorageAttachmentsService: BlobsStorageService<Wiki.CardAttachment>
  ) {
    this.sessionService.current$.pipe(untilDestroyed(this)).subscribe((sessionInfo) => {
      this.accountId = sessionInfo?.workspace?.accountId;
    });
  }

  ngOnInit() {
    if (this.popupService.hasDialog && this.pageMode !== 'inline') {
      this.popupRef = this.injector.get(PopupRef);
      this.pageMode = 'popup';
    }
    if (this.popupRef && !this.model) {
      this.model = this.popupRef?.data;
    }
    this.initPageMode();

    this.prevAutoFocus = this.hubService.autoFocus;
    if (!['inline', 'full'].includes(this.pageMode)) {
      this.wikiCardPreviewService.wikiCardPopupStatus.next(true);
    }

    if (this.isEmbed) {
      this.embedService.visible$.pipe(takeUntil(this.destroy$)).subscribe(async (res) => {
        if (!res) {
          this.onDestroy();
        }
      });
    }

    this.registerKeyboardHandler();
    this.cdr.markForCheck();
  }

  ngAfterViewInit() {
    this.initNgScrollerHeight();
    this.wikiPopupsService.onRestoreVersion$.pipe(untilDestroyed(this)).subscribe((updatedCard: Wiki.Card) => {
      if (!updatedCard || !this.card || updatedCard.id !== this.card.id) {
        return;
      }
      this.card = { ...(this.card || {}), ...cloneDeep(updatedCard) };
      this.onSwitchView('reloadCard');
    });
    if (this.pageMode === 'popup') {
      const body = document.getElementsByTagName('app-root');
      body[0]['style'].pointerEvents = 'none';
    }
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  private onDestroy() {
    this.destroy$.next(undefined);
    this.destroy$.complete();
    this.hubService.autoFocus = this.prevAutoFocus;
    this.unregisterKeyHandler();
    this.contextObserver?.disconnect();

    this.showSavedDraftToaster();

    if (this.pageMode === 'popup' && !this.isNewMode) {
      this.hubService.removeState('purl', 'popup');
    }
    this.frameEditorContextMenuService.destroyHandlers();

    if (this.pageMode === 'full') {
      this.hubService.readOnly = false;
    } else if (this.pageMode === 'popup') {
      const body = document.getElementsByTagName('app-root');
      body[0]['style'].pointerEvents = 'all';
    }

    this.finalSaveDraftIfNeeded(true);
  }

  private resetProperties(modelChanged?: boolean) {
    this.publishInProgress = this.cardPublished = this.initialTitle = this.errorState = false;
    this.cardUpdated = new ManualPromise();
    this.autoSaveStatus = undefined;
    this.currentHtml = null;
    this.contentLoaded = false;
    if (modelChanged && this.statTimeoutId) {
      clearTimeout(this.statTimeoutId);
      this.statTimeoutId = null;
    }
  }

  private async initPageMode() {
    const routeSnapShot: ActivatedRouteSnapshot = this.activeRoute.snapshot;
    const queryParams = routeSnapShot?.queryParams?.purl;
    if (queryParams) {
      this.pageMode = 'popup';
    } else if (routeSnapShot.data.windowMode) {
      this.pageMode = 'window';
    } else if (
      this.pageMode !== 'inline' &&
      routeSnapShot?.params?.param === this.wikiCardsService.FULL_PAGE_CARD_PARAM &&
      !this.isNewMode
    ) {
      this.pageMode = 'full';
    }

    if (this.isEmbed) {
      this.embedInline = await this.embedService?.isInline();
      if (this.embedInline) {
        this.loaderService.ready$.next(true);
        const initFromStorage = await this.initEmbedInline();
        if (initFromStorage) {
          return;
        }
      }
    }

    if (this.pageMode === 'full') {
      this.initFullPage(routeSnapShot);
    } else if (this.pageMode === 'window') {
      this.loaderService.ready$.next(true);
      const cardId = this.data.cardId;
      this.model = { cardId };
    }

    this.cdr.markForCheck();
  }

  private async initEmbedInline(): Promise<boolean> {
    //State after create a card from answer in ticket
    const newAux = localStorage.getItem(EMBED_SAVE_CARD_KEY_STORAGE);
    if (!newAux) {
      return false;
    }
    const model: WikiCardStorageModel = JSON.parse(newAux);
    localStorage.removeItem(EMBED_SAVE_CARD_KEY_STORAGE);
    const card: Wiki.Card = { content: model?.content, title: model?.title, collectionId: model?.collectionId };
    if (model.collectionId) {
      const cardRes = await this.wikiCardSaveFlowHandler.saveCardNewMode(card, model?.collectionId);
      this.model = { cardId: cardRes.id, isNewMode: true };
      return;
    }
    const id = generateId();
    this.wikiItemSelectionPopupService.openWikiItemSelectionPopup(
      'create-card',
      id,
      { item: card, styleOptions: { disableCloseButton: true } },
      false
    );
    this.wikiItemSelectionPopupService.destroySelectPopup$
      .pipe(
        untilDestroyed(this),
        filter((r) => r.processId === id)
      )
      .subscribe((res) => {
        this.model = { cardId: res.newId, isNewMode: true };
      });
    return true;
  }

  private initFullPage(routeSnapShot: ActivatedRouteSnapshot) {
    const cardId = routeSnapShot.params?.id?.split('-')?.slice(-1)?.[0];
    this.hubService.readOnly = true;
    this.model = { cardId };
    this.breadcrumbsService.showGhost = true;
  }

  private callStats() {
    if (this.draftMode || this.statTimeoutId) {
      return;
    }
    this.statTimeoutId = setTimeout(() => {
      if (!this.destroy$.closed && this.card) {
        const stat: Stats.Stat = {
          type: 'card',
          viewers: [{ id: this.accountId, lastViewTime: Date.now(), views: 1 }],
          itemId: this.card.id,
        };
        this.wikiCardsService.upsertStat(stat);
      }
    }, this.STAT_WAITING_TIME);
  }

  private changeFocus(focus: string) {
    this.collectionsService.onFocusChange = focus;
  }

  private async initModel() {
    this.initDraftMode();
    const fullAppInline = await this.embedService?.isFullApp();
    this.showCloseButton = !['inline', 'full'].includes(this.pageMode) && !(this.isEmbed && this.embedInline && !fullAppInline);

    if (this.model?.cardId) {
      this.initCard(this.model.cardId);
    }

    this.cdr.markForCheck();
  }

  private initCard(cardId: string) {
    let initial = true;
    this.wikiCardsService
      .getCard$(cardId)
      .pipe(
        untilDestroyed(this),
        filter((card) => !!card && card.id === cardId)
      )
      .subscribe({
        next: async (card) => {
          //Hack: when opening a card using URL and there is not published version - change mode to draft
          if (initial && this.publishedMode && !card.publishedTime) {
            const canEdit = card.effectiveScopes?.find((e) => e.scope === 'write');
            if (canEdit) {
              this.model.viewMode = 'draft';
            } else {
              this.errorState = true;
              this.cdr.markForCheck();
            }
          }
          if (this.errorState) {
            return;
          }
          this.card = cloneDeep(card);
          await this.onCardUpdate(initial, initial);
          initial = false;
        },
        error: () => {
          this.errorState = true;
          this.cdr.markForCheck();
        },
      });
  }

  private async onCardUpdate(initial?: boolean, modelChanged?: boolean) {
    const content = this.currentContent;
    const hasContent = !isNil(content);
    if (hasContent) {
      this.initialHtml = (await this.frameEditorRenderHtmlService.renderHtmlToEditor(content)) || '';
    } else {
      this.contentLoaded = false;
      this.cdr.markForCheck();
    }

    if (initial) {
      if (modelChanged) {
        await this.initOnModelChange();
        //When changing a model - init storage with all the attachments of the draft (and when a card is closed, all the attachments that the user deleted should be deleted from blob)
        this.frameEditorStorageAttachmentsService.initAttachments(this.card.id, this.card.draft?.attachments || []);
      }
      this.initBreadcrumbsAndTitle();
      this.initializeAutoSave();
    }

    if (hasContent) {
      this.contentLoaded = true;
      if (!this.cardUpdated.status) {
        this.cardUpdated.resolve();
      }
    }

    this.cdr.markForCheck();
  }

  private initBreadcrumbsAndTitle() {
    const title = this.currentTitle;
    if (title?.length > 0) {
      this.title = title;
    }

    setTimeout(() => {
      this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
      this.calculateTextRowsNumber();
    }, 200);
    this.focusInputOnLoad();

    this.updateBreadcrumbsAndTitle();
    this.breadcrumbsService.showGhost = false;

    if (this.isNewMode) {
      this.initialTitle = true;
    }
  }

  private async initCollectionAndPermission() {
    if (this.card?.collectionId?.length > 0) {
      this.collection = <Wiki.WikiCollection>await this.collectionsService.getById(this.card.collectionId);
      if (this.collection) {
        const canEdit = this.collectionsHelperService.canEdit(this.collection);
        this.model.permissionRole = canEdit ? 'write' : 'read';
      }
    }
  }

  private async initOnModelChange() {
    this.tags = this.card?.tags;
    await this.initCollectionAndPermission();

    if (this.verificationSubscription) {
      this.verificationSubscription.unsubscribe();
      this.verificationSubscription = null;
    }

    if (this.card?.id) {
      this.verificationSubscription = this.wikiCardsService
        .getVerificationByCardId$(this.card.id)
        .pipe(untilDestroyed(this))
        .subscribe((v) => {
          this.updatedVerification = cloneDeep(v);
          this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
          this.cdr.markForCheck();
        });
    }
  }

  private showSavedDraftToaster(displayOpenButton = true) {
    if (!this.cardPublished && !this.publishInProgress && this.draftMode && (this.autoSaveStatus || this.model.isNewDraft)) {
      this.wikiCardsService.showSavedDraftToaster(this.card?.id, this.card?.draft?.title, displayOpenButton);
    }
  }

  private focusInputOnLoad() {
    setTimeout(() => {
      if (this.isNewMode && this.pageMode !== 'inline') {
        this.textAreaTitle?.textArea?.nativeElement.setSelectionRange(0, 0);
        this.textAreaTitle?.textArea?.nativeElement.focus();
        this.cdr.markForCheck();
      }
    }, 200);
  }

  private initNgScrollerHeight() {
    this.windowSize$.pipe(untilDestroyed(this)).subscribe(() => {
      this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
    });
    if (this.contextContainer) {
      const { nativeElement: el } = this.contextContainer;
      this.contextObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        this.calculateNgScrollerHeight(entries[0]?.target);
      });
      this.contextObserver.observe(el);
    }
  }

  private calculateTextRowsNumber() {
    const el: HTMLElement = this.textAreaTitle?.textArea?.nativeElement;
    const calculatedRows = el?.scrollHeight / this.LINE_HEIGHT;
    if (this.pageMode === 'inline') {
      this.textareaRows = calculatedRows > 2 ? 2 : calculatedRows;
    } else {
      this.textareaRows = calculatedRows > 3 ? 3 : calculatedRows;
    }
    this.cdr.markForCheck();
  }

  private calculateNgScrollerHeight(el) {
    if (!el || this.pageMode !== 'inline') {
      return;
    }

    const elBound = el.getBoundingClientRect();
    const contentHeight = window.innerHeight - elBound.top;
    el['style'].height = contentHeight + 'px';
    this.cdr.markForCheck();
  }

  private initializeAutoSave() {
    this.resetAutoSave();
    if (!this.draftMode) {
      return;
    }
    this.lastAutoSaveTimestamp = Date.now();
    this.autoSaveSubject$ = new Subject<number>();
    this.autoSaveSub = this.autoSaveSubject$
      .pipe(
        untilDestroyed(this),
        filter((now) => this.shouldPerformImmediateSave(now)),
        debounceTime(this.AUTO_SAVE_DEBOUNCE_MS)
      )
      .subscribe((now) => {
        this.executeAutoSave(now);
      });
  }

  private resetAutoSave() {
    this.autoSaveStatus = undefined;
    this.autoSaveSub?.unsubscribe();
    this.autoSaveSub = null;
    if (this.autoSaveSubject$) {
      this.autoSaveSubject$.unsubscribe();
      this.autoSaveSubject$ = null;
    }
  }

  private shouldPerformImmediateSave(currentTimestamp: number): boolean {
    const isMoreThan30Seconds = currentTimestamp - this.lastAutoSaveTimestamp > this.IMMEDIATE_SAVE_THRESHOLD_MS;
    if (isMoreThan30Seconds) {
      this.executeAutoSave(currentTimestamp);
      return false;
    }
    return true;
  }

  private async startAutoSave() {
    //Enable to save only after all content is updated (content, title, attachments)
    await this.cardUpdated;
    if (this.publishedMode) {
      return;
    }
    const now = Date.now();
    this.lastChangeAutoSave = now;
    this.autoSaveSubject$.next(now);
    this.autoSaveStatus = 'saving';
    this.cdr.markForCheck();
  }

  private executeAutoSave(time: number) {
    if (!this.draftMode) {
      return;
    }
    const contentText = tinymce.get(this.FRAME_EDITOR_ID)?.getContent({ format: 'text' });
    const saveModel: DraftSaveModel = {
      cardId: this.card.id,
      htmlContent: this.currentHtml,
      title: this.title,
      contentText,
      accountId: this.accountId,
    };
    const updatedDraft = this.wikiCardSaveFlowHandler.saveDraftCard(saveModel);
    this.card.draft = updatedDraft;
    this.lastAutoSaveTimestamp = time;
    if (time >= this.lastChangeAutoSave) {
      this.autoSaveStatus = 'saved';
      this.cdr.markForCheck();
    }
  }

  private finalSaveDraftIfNeeded(resetCurrentDraftAttachments?: boolean) {
    if (this.draftMode) {
      if (this.autoSaveStatus === 'saving') {
        this.lastChangeAutoSave = Date.now();
        this.executeAutoSave(this.lastChangeAutoSave);
      }
      this.resetAutoSave();
    }
    if (resetCurrentDraftAttachments) {
      //When changing a model or closing the card - all files removed from the card, their blobIDS should be deleted
      this.resetCardAttachments();
    }
  }

  private resetCardAttachments() {
    if (!this.card) {
      return;
    }
    const allAttachments = concat(this.card.draft?.attachments || [], this.card.attachments || []);
    const currentAttachmentIds = map(allAttachments, 'blobId');
    this.frameEditorStorageAttachmentsService.resetAttachments(this.card.id, currentAttachmentIds);
  }

  private closePopupWindow() {
    if (isNativeWindow()) {
      this.finalSaveDraftIfNeeded(true);
      this.windowService.close();
      return;
    }
    this.embedService.close();
  }

  private initDraftMode() {
    if (this.model?.viewMode) {
      return;
    }
    const isDraftMode = this.activeRoute.snapshot?.queryParams?.[DRAFT_CARD_URL_PARAM];
    this.model.viewMode = isDraftMode ? 'draft' : 'published';
    if (isDraftMode) {
      const isNewContentMode = this.activeRoute.snapshot?.queryParams?.[NEW_CARD_URL_PARAM];
      if (isNewContentMode) {
        this.model.isNewMode = true;
      }
      this.routerService.removeQueryParam([DRAFT_CARD_URL_PARAM, NEW_CARD_URL_PARAM], true);
      this.model.isNewDraft = this.model?.isNewMode;
    }
  }

  private removeToolbar() {
    const floatingToolbar = document.getElementsByClassName('tox-pop')[0];
    if (floatingToolbar) {
      floatingToolbar.remove();
    }
  }

  onSwitchView(view: SwitchViewState, saveIfNeeded = true) {
    this.changeFocus('switchView');
    switch (view) {
      case 'editCard': {
        const updatedDraft = this.wikiCardSaveFlowHandler.createDraft(this.card, this.accountId);
        this.card.draft = updatedDraft;
        this.frameEditorStorageAttachmentsService.initAttachments(this.card.id, this.card.draft?.attachments || []);
        this.model.viewMode = 'draft';
        this.model.isNewDraft = true;
        break;
      }
      case 'closeDraft': {
        if (saveIfNeeded) {
          this.showSavedDraftToaster(false);
          this.finalSaveDraftIfNeeded();
        }
        this.model.viewMode = 'published';
        this.model.isNewDraft = false;
        break;
      }
      case 'deleteDraft': {
        this.model.viewMode = 'published';
        break;
      }
      case 'editDraft': {
        this.model.viewMode = 'draft';
        break;
      }
    }
    this.resetProperties();
    this.cdr.markForCheck();
    setTimeout(() => {
      this.onCardUpdate(true, false);
    }, 0);
  }

  async updateBreadcrumbsAndTitle(newPath?: Wiki.Path[]) {
    if (newPath?.length) {
      (this.card as Wiki.CardPath).path = newPath;
      const collectionId = newPath?.[0]?.id;
      const collectionChanged = collectionId !== this.collection.id;
      if (collectionChanged) {
        this.card.collectionId = collectionId;
        this.collection = <Wiki.WikiCollection>await this.collectionsService.getById(collectionId);
      }
    }
    const collectionUrl = generateTitleUrl('wikis', this.collection?.title, this.collection?.id);
    const breadcrumbsItems: Breadcrumb[] = this.wikiCardsService.buildCardBreadcrumbs((this.card as Wiki.CardPath)?.path, collectionUrl);
    if (this.pageMode === 'full') {
      breadcrumbsItems.unshift({
        title: 'Wikis',
        path: 'wikis',
        icon: {
          type: 'font-icon',
          value: 'icon-wiki',
        },
      });
      breadcrumbsItems.push({
        title: this.title,
        path: this.wikiCardsService.getCardUrl(this.title, this.card?.id, true),
        icon: {
          type: 'font-icon',
          value: 'icon-card',
        },
      });
      this.breadcrumbsService.items = breadcrumbsItems.filter((b) => b.title);
      this.titleService.setTitle(`${this.title} | Unleash.so`);
      this.breadcrumbsItems = null;
    } else {
      this.breadcrumbsItems = breadcrumbsItems;
    }
    this.cdr.markForCheck();
  }

  closePopup() {
    if (['inline', 'full'].includes(this.pageMode)) return;

    if (this.pageMode === 'window') {
      this.closePopupWindow();
      return;
    }

    this.popupRef?.close();
    this.wikiCardPreviewService?.popupRef?.close();
    return true;
  }

  onInitEditor() {
    this.currentHtml = this.initialHtml;
    setTimeout(() => {
      if (this.draftMode && !this.isNewMode && ['popup', 'full'].includes(this.size)) {
        tinymce.get(this.FRAME_EDITOR_ID)?.focus();
      }
      this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
      this.hubService.autoFocus = false;
      this.callStats();
    }, 0);
  }

  async duplicateWikiCard() {
    this.wikiItemSelectionPopupService.duplicateCard(this.card);
  }

  onCloseDropdownTags() {
    this.closeDropdownTags++;
    this.cdr.markForCheck();
  }

  onHtmlChange(updatedHtml) {
    const cleanHtml = this.frameEditorRenderHtmlService.removeClassFromAttachment(
      updatedHtml || '',
      FrameEditorConstants.CLASS_ACTIVE_ATTACHMENT
    );
    const cleanPrevHtml = this.frameEditorRenderHtmlService.removeClassFromAttachment(
      this.currentHtml || '',
      FrameEditorConstants.CLASS_ACTIVE_ATTACHMENT
    );
    if (this.currentHtml === null || cleanHtml === cleanPrevHtml) {
      this.currentHtml = cleanHtml;
      return;
    }
    this.currentHtml = cleanHtml;
    this.startAutoSave();
  }

  titleChanged($event) {
    this.calculateTextRowsNumber();
    this.title = $event;
    this.initialTitle = false;
    this.startAutoSave();
  }

  onInputClick() {
    this.changeFocus('card-title');
    this.cdr.markForCheck();
  }

  inputKeyDown(event) {
    if (isKey(event, keyCodes.enter) || isKey(event, keyCodes.ArrowDown)) {
      if (isKey(event, keyCodes.enter)) {
        stopEvent(event);
      }
      tinymce.get(this.FRAME_EDITOR_ID)?.focus();
      this.textAreaTitle?.onBlurEvent(event);
    }
    event.stopPropagation();
  }

  async publishCardAndOpenNotes(eventLabel: TelemetryTarget = 'mouse_click') {
    const isVerifier = this.updatedVerification?.policy?.isVerifier;
    if (!this.card.publishedTime) {
      if (isVerifier) {
        this.openOfferVerifyPopup(eventLabel);
      } else {
        this.publishCard(eventLabel);
      }
      return;
    }
    const notesPopupRef = this.wikiPopupsService.openNotesPopup(isVerifier);
    let verifyCard = true;
    notesPopupRef.compInstance.checkBoxChanged.pipe(untilDestroyed(this)).subscribe((value) => {
      verifyCard = value;
    });
    notesPopupRef.compInstance.primaryButton.pipe(take(1)).subscribe((notes: string) => {
      this.publishCard(eventLabel, notes, verifyCard && isVerifier);
    });
  }

  private openOfferVerifyPopup(eventLabel: TelemetryTarget) {
    let verifyCard = false;
    const verifyPopupRef = this.wikiPopupsService.openOfferVerifyPopup();
    verifyPopupRef.compInstance.primaryButton.pipe(take(1)).subscribe(() => {
      verifyCard = true;
    });
    verifyPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.publishCard(eventLabel, null, verifyCard);
    });
  }

  private publishCard(eventLabel: TelemetryTarget, notes?: string, verify?: boolean) {
    this.publishInProgress = true;
    this.cdr.markForCheck();
    this.removeToolbar();
    this.finalSaveDraftIfNeeded(true);

    //When create card with content (create from answer) need to update the contentText
    if (!this.card.draft?.contentText) {
      this.card.draft.contentText = tinymce.get(this.READONLY_FRAME_EDITOR_ID)?.getContent({ format: 'text' });
    }
    const publishedTime = Date.now();
    this.eventsService.event('wikis.publish_card', {
      label: eventLabel,
      location: { title: this.getTelemetryLocation() },
    });
    const unPublishCard = this.card.draft && !this.card.publishedTime;
    const req: Wiki.PublishDraftRequest = {
      cardId: this.card.id,
      collectionId: this.card.collectionId,
      draft: this.card.draft,
      publishedTime,
      versionNumber: this.card.version || 0,
      unPublishCard,
      versionChangeLog: notes,
    };
    if (verify) {
      this.wikiCardsService.updateVerificationStatus('Verified', [this.card.id], false, publishedTime);
    }
    this.wikiCardSaveFlowHandler
      .publishCard(req)
      .then(async (updatedCard) => {
        this.cardPublished = true;
        if (['inline', 'full'].includes(this.pageMode)) {
          this.card = { ...(this.card || {}), ...cloneDeep(updatedCard) };
          this.model.isNewMode = false;
          this.onSwitchView('closeDraft', false);
        }
      })
      .catch(() => {
        this.initializeAutoSave();
      })
      .finally(() => {
        this.publishInProgress = false;
        this.cdr.markForCheck();
      });

    this.closePopup();
  }

  isExternalMenuOpen(event) {
    const menu = document.getElementsByClassName('tox-tiered-menu');
    const tooltip = document.getElementsByClassName('tox-pop');
    const toolbar = document.getElementsByClassName('tox-toolbar');
    const smallMenu = document.getElementsByClassName('tox-menu');
    const dialog = document.getElementsByClassName('tox-dialog-wrap');
    const isTagsMultiSelectOpen = this.tagSection?.isMultiSelectOpen();
    const isToolbarClick = !!event?.target?.closest('.tox.tox-tinymce-inline') || !!event?.target?.closest('.tox-tinymce-aux');

    return (
      isToolbarClick ||
      isTagsMultiSelectOpen ||
      menu.length > 0 ||
      tooltip.length > 0 ||
      toolbar.length > 0 ||
      smallMenu.length > 0 ||
      dialog.length > 0 ||
      this.frameEditorContextMenuService?.renameAttachmentPopupOpen ||
      this.contextMenuService.currentOpenRefs() > 0 ||
      this.frameEditorContextMenuService?.contextMenuOpened ||
      event?.target?.tagName.toLowerCase() === 'a' // click on image with link in editor
    );
  }

  onTagChange($event) {
    this.tags = $event;
    this.wikiCardSaveFlowHandler.updatePublishedCard({ tags: this.tags, id: this.card.id, collectionId: this.card.collectionId });
    this.card.tags = this.tags;
  }

  openRequestsHistory() {
    this.changeFocus('card-request-history');
    this.wikiCardsVerificationsService.openRequestHistoryPopup({ ...this.card, verification: this.updatedVerification });
  }

  async openExternal(externalClick: boolean) {
    this.finalSaveDraftIfNeeded(true);
    this.eventsService.event('collections.full_size', {
      location: { title: this.getTelemetryLocation() },
    });
    let url = `${this.wikiCardsService.getCardUrl(this.currentTitle || 'Untitled', this.card.id)}`;
    if (this.draftMode) {
      url += `?${DRAFT_CARD_URL_PARAM}=true`;
    }
    if (this.isEmbed) {
      this.embedService.openUrl(url);
      this.popupRef?.close();
      return;
    }
    if (this.pageMode === 'window') {
      this.windowService.maximize(externalClick);
      return;
    }
    this.popupRef?.close();
    this.routerService.navigateByUrl(url);
  }

  //@@@ KEY HANDLING
  private registerKeyboardHandler() {
    if (this.keyHandlerId) return;
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => {
      this.handleKeys(event);
    }, 9);
    this.cdr.markForCheck();
  }

  private unregisterKeyHandler() {
    if (!this.keyHandlerId) return;
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    this.keyHandlerId = null;
  }

  private async handleKeys(event: KeyboardEvent) {
    if (isKey(event, keyCodes.escape)) {
      const success = this.closePopup();
      if (success) {
        event.stopPropagation();
      }
    }
    if (isKey(event, keyCodes.tab)) {
      event.stopPropagation();
    }
    if (isCtrlOrCommandCombination(event, keyCodes.g as KeyName)) {
      this.duplicateWikiCard();
      stopEvent(event);
    }
  }

  getTelemetryLocation() {
    const cardId = this.card?.id ?? 'new';
    return `cards/Wiki/${cardId}`;
  }
}
