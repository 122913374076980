import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
  afterNextRender,
} from '@angular/core';
import { PopupRef, PopupService, UTextareaComponent } from '@local/ui-infra';
import { take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { KeyName, isEnterKey } from '@local/ts-infra';
import { AppPopupComponent, AppPopupData, CheckboxOption } from '../app-popup/app-popup.component';
import { merge } from 'lodash';

export const enum FocusState {
  TextArea = 'textArea',
  LeftButton = 'leftButton',
  RightButton = 'rightButton',
}

export interface EditPopupData {
  title: string;
  subtitle?: string;
  primaryButtonLabel: string;
  secondaryButtonLabel: string;
  textareaPlaceholder?: string;
  rows?: number;
  minLength?: number;
  showCloseIcon: boolean;
  warning_app_data?: AppPopupData;
  footer?: string;
  popupStyles?: object;
  textAreaHeight?: number;
  defaultTextContent?: string;
  textareaMaxLength?: number;
  displayWarningPopup?: boolean;
  checkbox?: CheckboxOption;
}

@UntilDestroy()
@Component({
  selector: 'edit-popup',
  templateUrl: './edit-popup.component.html',
  styleUrls: ['./edit-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditPopupComponent implements OnInit, OnDestroy {
  private keyHandlerId: string;
  private warningPopupRef: PopupRef<AppPopupComponent, AppPopupData>;
  popupConfig: EditPopupData = {
    title: '',
    primaryButtonLabel: 'Confirm',
    secondaryButtonLabel: 'Cancel',
    showCloseIcon: true,
    minLength: 0,
    warning_app_data: {
      showButtons: true,
      rightButtonStyle: { type: 'primary', size: 132, fontSize: 14, elementSize: 'small' },
      leftButtonStyle: { size: 126, fontSize: 14, elementSize: 'small' },
      content: {
        secondaryButton: 'Cancel',
        primaryButton: 'Confirm',
      },
      popupStyle: { paddingTop: '32px' },
      message: '',
      messageStyle: { fontSize: '14px' },
    },
    rows: 4,
    textAreaHeight: 88,
    popupStyles: { width: '308px' },
    textareaMaxLength: 300,
    displayWarningPopup: true,
  };

  currentFocus: FocusState;
  textContent = signal<string>('');
  isValid = computed(() => {
    const textContent = this.textContent()?.trim();
    const minLength = this.popupConfig.minLength || 0;
    const defaultTextContent = this.popupConfig.defaultTextContent;
    const hasValidLength = (textContent?.length || 0) >= minLength;
    const isContentModified = textContent !== defaultTextContent || !defaultTextContent;
    return hasValidLength && isContentModified;
  });

  @Output() primaryButton = new EventEmitter<string>();
  @Output() secondaryButton = new EventEmitter<string>();
  @Output() checkBoxChanged = new EventEmitter<boolean>();

  @ViewChild('textArea') textArea: UTextareaComponent;

  constructor(
    private ref: PopupRef<EditPopupComponent, any>,
    private popupService: PopupService,
    private keyboardService: KeyboardService
  ) {
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys: Array<KeyName>, event) => this.handleKeys(keys, event), 9);
    afterNextRender(() => {
      this.focusTextArea();
    });
  }

  ngOnInit(): void {
    this.popupConfig = merge({}, this.popupConfig, this.ref.data || {});
    this.textContent.set(this.popupConfig.defaultTextContent);
  }

  ngOnDestroy(): void {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    }
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    event.stopPropagation();
    const key = keys[0];

    if (key === 'escape') {
      this.closePopup();
      return;
    }

    if (isEnterKey(key)) {
      this.handleEnterKey();
      return;
    }

    this.handleArrowKey(key);
  }

  private handleEnterKey(): void {
    switch (this.currentFocus) {
      case FocusState.LeftButton:
        this.closePopup();
        break;
      case FocusState.RightButton:
        this.onPrimaryClick();
        break;
    }
  }

  private handleArrowKey(key: KeyName): void {
    switch (key) {
      case 'ArrowDown':
        if (this.currentFocus === FocusState.TextArea) {
          this.currentFocus = this.isValid() ? FocusState.RightButton : FocusState.LeftButton;
          this.textArea?.textArea?.nativeElement?.blur();
        }
        break;

      case 'ArrowUp':
        if (this.isButtonFocused()) {
          this.focusTextArea();
        }
        break;

      case 'ArrowRight':
        if (this.currentFocus === FocusState.LeftButton && this.isValid()) {
          this.currentFocus = FocusState.RightButton;
        }
        break;

      case 'ArrowLeft':
        if (this.currentFocus === FocusState.RightButton) {
          this.currentFocus = FocusState.LeftButton;
        }
        break;
    }
  }

  private isButtonFocused(): boolean {
    return [FocusState.LeftButton, FocusState.RightButton].includes(this.currentFocus);
  }

  onPrimaryClick(): void {
    if (!this.isValid()) return;
    this.primaryButton.emit(this.textContent());
    this.closePopup();
  }

  onChangeCheckbox(event) {
    this.checkBoxChanged.emit(event.checked);
  }

  closePopup(): void {
    this.secondaryButton.emit('');
    this.ref.destroy();
  }

  focusTextArea(): void {
    this.textArea?.textArea?.nativeElement.focus();
    this.currentFocus = FocusState.TextArea;
  }

  clickOutPopup(): void {
    if (this.warningPopupRef) {
      this.closeWarningPopup();
      return;
    }
    if (this.isValid() && this.popupConfig.displayWarningPopup) {
      this.openWarningPopup();
    } else {
      this.closePopup();
    }
  }

  private openWarningPopup(): void {
    this.warningPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      this.popupConfig.warning_app_data,
      {
        position: 'center',
        closeOnClickOut: true,
        backdropStyle: 'blur-2',
      }
    );
    this.warningPopupRef.compInstance.primaryButton.pipe(take(1), untilDestroyed(this)).subscribe(() => {
      this.closeWarningPopup();
      this.closePopup();
    });
    this.warningPopupRef.compInstance.secondaryButton.pipe(take(1), untilDestroyed(this)).subscribe(() => {
      this.closeWarningPopup();
    });
  }

  private closeWarningPopup(): void {
    if (this.warningPopupRef) {
      this.warningPopupRef.destroy();
      this.warningPopupRef = null;
    }
  }
}
