import { ChangeDetectionStrategy, Component, computed, input, model, output, TemplateRef, ViewChild } from '@angular/core';
import { Listbox, ListboxChangeEvent } from 'primeng/listbox';

@Component({
  selector: 'u-list-box',
  templateUrl: './u-list-box.component.html',
  styleUrl: './u-list-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UListBoxComponent<T> {
  items = input<T[]>([]);
  labelField = input('name');
  filter = input(true);
  group = input(false);
  itemTemplate = input<TemplateRef<any> | undefined>(undefined);
  groupTemplate = input<TemplateRef<any> | undefined>(undefined);
  listStyle = input<Record<string, string>>({ 'max-height': '100%' });
  selectOnFocus = input<boolean>();
  selectedItem = model<T | null>(null);

  selectedChange = output<{ item: T | null; index: number }>();

  @ViewChild('listbox', { static: false }) listbox!: Listbox;

  flatItems = computed(() => {
    if (this.group()) {
      return this.items().flatMap((parent) => parent['items']);
    }
    return this.items();
  });

  onChangeEvent(event: ListboxChangeEvent): void {
    const selectedIndex = this.flatItems().findIndex((item: T) => item === event.value);
    if (selectedIndex !== -1) {
      this.selectedChange.emit({ item: event.value, index: selectedIndex });
    }
  }
}
