import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UToggleSwitchComponent } from './u-toggle-switch.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [UToggleSwitchComponent],
  imports: [CommonModule, InputSwitchModule, FormsModule],
  exports: [UToggleSwitchComponent],
})
export class UToggleSwitchModule {}
