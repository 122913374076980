import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { EventsService } from '@shared/services';
import { take } from 'rxjs';
import { WorkspacesService } from 'src/app/bar/services';
import { ImageService } from 'src/app/bar/views/settings/service/image.service';

type WorkspaceNameData = { name: string; icon: string };

@Component({
  selector: 'enter-workspace-name',
  templateUrl: './enter-workspace-name.component.html',
  styleUrls: ['./enter-workspace-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnterWorkspaceNameComponent implements OnInit {
  readonly accept = ['image/png', 'image/jpeg', 'image/svg+xml'];
  @HostListener('document:keydown.enter', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.nameDone();
  }

  @Output() done: EventEmitter<WorkspaceNameData> = new EventEmitter<WorkspaceNameData>();

  errorMessage: string;
  maxFileSize = 5 * 1024 * 1024;
  name: string = '';
  icon: string;
  nameValid: boolean = false;

  private get location() {
    return { title: 'create_workspace' };
  }

  constructor(
    private eventsService: EventsService,
    private workspacesService: WorkspacesService,
    private cdr: ChangeDetectorRef,
    private imageService: ImageService
  ) {}

  ngOnInit(): void {
    this.eventsService.event('pageview', { location: this.location });
  }

  nameDone() {
    if (!this.name && !this.icon) {
      return;
    }
    this.eventsService.event('enter_workspace_name', { target: 'continue', label: this.name, location: this.location });
    this.done.emit({ icon: this.icon, name: this.name === '' ? null : this.name });
  }

  skip() {
    this.eventsService.event('enter_workspace_name', { target: 'skip', location: this.location });
    this.done.emit(null);
  }

  onNameChange(name: string) {
    this.nameValid = this.workspacesService.workspaceNameValid(name);
    if (this.nameValid) {
      this.name = name;
    } else {
      this.name = '';
    }
    this.cdr.markForCheck();
  }

  onUpload(file: File) {
    this.eventsService.event('workspace.upload_photo', { location: this.location });

    const reader = new FileReader();

    const imgtag = document.getElementById('workspace-image');
    imgtag.title = file.name;

    reader.onload = function (event) {
      imgtag['src'] = event.target.result;
    };

    reader.readAsDataURL(file);

    this.imageService
      .uploadImage(file)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.icon = this.imageService.getImageUrl(res.file);
          this.errorMessage = null;
          this.cdr.markForCheck();
        },
        error: (err) => {
          this.errorMessage = 'Upload failed. Please try again';
          this.cdr.markForCheck();
        },
      });
  }

  onFileUploadError(err) {
    this.eventsService.event('status_view', {
      location: this.location,
      label: 'upload_failed',
    });
  }
}
