import { Constants } from '@local/common';

export const wikiContent = {
  wikiEmptyStateTitle1: 'Wikis',
  wikiEmptyStateSubtitle1: 'Create bite-sized knowledge cards and categorize them by',
  wikiEmptyStateSubtitle2: 'topic to provide your team with quick, easily accessible',
  wikiEmptyStateSubtitle3: 'information.',
  wikiEmptyStateBtnText: 'Create New Wiki',
  learnMore: 'Learn More',
  cardEmptyStateTitle: 'Cards',
  cardEmptyStateSubtitle: 'Streamline knowledge sharing by creating cards to answer',
  cardEmptyStateSubtitle2: 'FAQs like ‘What is our remote work policy?’ and give your',
  cardEmptyStateSubtitle3: 'team access to the knowledge they need',
  cardEmptyStateBtnText: 'Create New Card',
  moveToWikiWarningMessage:
    'When moving a folder or card to another wiki, it will adopt the verification policy of the destination wiki. A folder will move with all the cards it contains.',
  folderEmptyStateTitle: 'Folders',
  folderEmptyStateSubtitle: 'Create folders within wikis to subdivide and organize cards',
  folderEmptyStateSubtitle2: 'into relevant categories, ensuring efficient access and',
  folderEmptyStateSubtitle3: 'streamlined navigation of knowledge',
  folderEmptyStateBtnText: 'Create New Folder',
  draftEmptyStateTitle: "You don't have any saved drafts",
  draftEmptyStateSubtitle: 'Your drafts of unsaved changes to cards are available here,',
  draftEmptyStateSubtitle2: 'ready to pick up where you left off.',
  addToWikiPopupMessage: 'Select a Wiki to add the card to',
  addToWikiPopupMessageOptional: 'Select a folder (optional)',
  moveToWikiPopupMessage: 'Select a Wiki to move the card/folder to',
  moveToWiki: 'Move to...', // or create card
};

export const cardEmptyStateContent = [
  {
    type: 'card',
    titleLine: wikiContent.cardEmptyStateTitle,
    subtitleLine: wikiContent.cardEmptyStateSubtitle,
    subtitleLine2: wikiContent.cardEmptyStateSubtitle2,
    subtitleLine3: wikiContent.cardEmptyStateSubtitle3,
    iconSvg: {
      lightUrl: 'assets/empty-state/wiki/empty-cards-light.svg',
    },
    btnText: wikiContent.cardEmptyStateBtnText,
    learnMoreLink: Constants.LEARN_MORE_CARDS_URL,
  },
];

export const wikiEmptyStateContent = [
  {
    type: 'folder',
    titleLine: wikiContent.folderEmptyStateTitle,
    subtitleLine: wikiContent.folderEmptyStateSubtitle,
    subtitleLine2: wikiContent.folderEmptyStateSubtitle2,
    subtitleLine3: wikiContent.folderEmptyStateSubtitle3,
    iconSvg: {
      lightUrl: 'assets/empty-state/wiki/empty-folders-light.svg',
    },
    btnText: wikiContent.folderEmptyStateBtnText,
    learnMoreLink: Constants.LEARN_MORE_FOLDER_WIKIS_URL,
  },
  ...cardEmptyStateContent,
];

export const DraftEmptyStateContent = [
  {
    type: 'draft',
    titleLine: wikiContent.draftEmptyStateTitle,
    subtitleLine: wikiContent.draftEmptyStateSubtitle,
    subtitleLine2: wikiContent.draftEmptyStateSubtitle2,
    iconSvg: {
      lightUrl: 'assets/empty-state/wiki/empty-drafts-light.svg',
    },
    learnMoreLink: Constants.LEARN_MORE_DRAFTS_URL,
  },
];
