import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Config } from '@environments/config';
import { SessionConfig } from '@environments/config-interface';
import {
  CURRENT_SESSION_VERSION,
  EmailInfo,
  InviteRequestInfo,
  OnboardingState,
  SessionInfo,
  User,
  Workspace,
} from '@local/client-contracts';
import { Constants, OAuthAuthCodeRequest, OAuthRefreshTokenRequest, Session as SessionInterface, observable } from '@local/common';
import { EmailLoginStatus, EmbedSignInRequest, Session, SignInResponse, isEmbed, isNativeWindow } from '@local/common-web';
import { sanitize } from '@local/ts-infra';
import { EmbedService } from '@shared/embed.service';
import { SessionRpcInvoker } from '@shared/rpcs';
import { isProdEnv } from '@shared/utils';
import { getOnboardingStateObject } from '@shared/utils/onboarding-utils';
import Cookies from 'js-cookie';
import { cloneDeep } from 'lodash';
import { ReplaySubject, Subject, combineLatest, filter, firstValueFrom, map } from 'rxjs';
import { GlobalErrorHandler } from 'src/app/global-error-handler';
import { InviteService } from 'src/app/onboarding-web/services/invite.service';
import * as uuid from 'uuid';
import { EventsService, LogService, NativeMainRpcService, ServicesRpcService } from '.';
import { BrowserExtensionService } from './browser-extension.service';
import { KeyboardService } from './keyboard.service';
import { LeadService } from './lead.service';
import { LocalStorageService } from './local-storage.service';
import { RouterService } from './router.service';
import { SessionStorageService } from './session-storage.service';
import { FlagsService } from './testim-flags.service';

export interface SessionProvider {
  name: string;
  image: string;
  url: string;
  displayName: string;
  state: string;
  redirectUri: string;
  endpoint: string;
  clientId: string;
  scopes: string[];
  hidden?: boolean;
}

type AuthType = 'oauth-refresh' | 'oauth-code' | 'saml' | 'external' | 'email';

export interface AuthState {
  t: AuthType;
  r: string;
  n: string;
  p: string;
  o: string;
  ro: string;
  f?: { noUpdatePopups: boolean; noWalkthrough: boolean };
  s?: boolean;
  to: string;
}
interface Nonce {
  nonce: string;
  expires: number;
}
@Injectable({
  providedIn: 'root',
})
export class SessionService implements SessionInterface {
  private storageNonce: Nonce;
  private _current$: ReplaySubject<SessionInfo> = new ReplaySubject<SessionInfo>(1);
  private _accountChanged$: ReplaySubject<SessionInfo> = new ReplaySubject<SessionInfo>(1);
  private service: Session;
  private config: SessionConfig;
  private readonly isEmbed = isEmbed();
  private readonly isNative = isNativeWindow();
  private readonly TARGET_ORIGIN_DOMAIN = 'unleash.so';
  signout$: Subject<void> = new Subject();

  constructor(
    private routerService: RouterService,
    services: ServicesRpcService,
    nativeMainRpc: NativeMainRpcService,
    private sessionStorageService: SessionStorageService,
    private embedService: EmbedService,
    private globalError: GlobalErrorHandler,
    private flagsService: FlagsService,
    private inviteService: InviteService,
    private eventsService: EventsService,
    private localStorageService: LocalStorageService,
    private logService: LogService,
    private leadService: LeadService,
    private sanitizer: DomSanitizer,
    private keyboardService: KeyboardService,
    private extensionService: BrowserExtensionService
  ) {
    this.service = services.invokeWith(SessionRpcInvoker, 'session');

    this.config = Config.session;
    if (this.embedService)
      this.embedService.init(
        services.rpc,
        this,
        this.globalError,
        this.routerService,
        this.eventsService,
        this.logService,
        this.keyboardService
      );

    if (!this.isNative && !this.isEmbed) {
      const raw = sessionStorage.getItem('sno') || localStorage.getItem('sno');
      let shouldCreate = !raw;
      if (raw) {
        try {
          this.storageNonce = JSON.parse(raw);
        } catch (error) {
          shouldCreate = true;
        }
        if (!shouldCreate && this.storageNonce.expires < Date.now()) {
          shouldCreate = true;
        }
      }
      if (shouldCreate) {
        this.createNewStorageNonce();
      }

      if (!isEmbed()) {
        combineLatest([this.extensionService.port$, this._current$]).subscribe(([port, session]) => {
          if (!port) return;

          port.postMessage({ type: 'unleash:ext:session', session });
        });
      }

      this._current$.subscribe((s) => {
        this.syncExternalContexts(s);
        if (!s) return;
      });
    }

    if (!this.isNative) {
      this.initSessionFromStorage();
    } // for native we are using session from native main to speed up boot
    else {
      nativeMainRpc.invokeWith(SessionRpcInvoker, 'session').current$.subscribe((s) => {
        this.setNext(s);
      });
    }

    this.service.current$.subscribe((x) => {
      this.setNext(x);
    });

    this.service.accountChanged$.subscribe((x) => {
      this._accountChanged$.next(x);
    });
    this.current$.subscribe((s) => {
      if ((<any>self).setSessionTags)
        (<any>self).setSessionTags({
          userId: s?.user?.id,
          userEmail: s?.user?.email,
          userName: [s?.user?.firstName, s?.user?.lastName].filter((x) => x).join(' '),
          accountId: s?.workspace?.accountId,
          workspaceId: s?.workspace?.id,
          sessionId: s?.id,
        });
    });
  }

  private createNewStorageNonce() {
    const time = Config.session.nonceTimeoutMinutes * 60 * 1000;
    this.storageNonce = { nonce: uuid.v4(), expires: Date.now() + time };
    localStorage.setItem('sno', JSON.stringify(this.storageNonce));
    sessionStorage.setItem('sno', JSON.stringify(this.storageNonce));
  }

  async embedSignIn(r: EmbedSignInRequest) {
    return this.service.embedSignIn(r);
  }

  private sessionExpired(session: SessionInfo) {
    return session?.expiresIn && session.createTime - Date.now() + session.expiresIn * 1000 <= 0;
  }

  async initSessionFromStorage() {
    const store = await this.localStorageService.create('session');
    let si = await store.entry<SessionInfo>('__info').get();

    if (si?.ver != CURRENT_SESSION_VERSION || this.sessionExpired(si)) {
      si = null;
    }

    const xWebSite = await this.embedService?.isExternalWebSite();

    if (!si || !xWebSite) {
      this.setNext(si);
    }
  }

  private setNext(so: SessionInfo) {
    const s = cloneDeep(so);
    if (s?.user) {
      s.user.firstName = sanitize(s.user.firstName);
      s.user.lastName = sanitize(s.user.lastName);
      s.user.email = sanitize(s.user.email);
    }
    if (s?.workspace) {
      s.workspace.name = sanitize(s.workspace.name);
    }
    this._current$.next(s);
  }
  signalOnBoardingDone() {
    return this.service.signalOnboardingDone();
  }

  verifyNonce(non: string) {
    return this.service.verifyNonce(non);
  }

  async fork(target: 'desktop' | 'web'): Promise<SessionInfo & { nonce: string }> {
    return this.service.fork(target);
  }

  async getNativeNonce(): Promise<string> {
    return this.service.getNativeNonce();
  }

  async syncApp() {
    await this.service.syncApp('desktop');
  }

  emailLogin(email: string, inviteCode?: string): Promise<EmailLoginStatus> {
    return this.service.emailLogin(email, inviteCode);
  }

  updateUserInfo(name: string): Promise<void> {
    return this.service.updateUserInfo(name);
  }

  async injectSession(newSession: SessionInfo, force?: boolean): Promise<'same' | 'injected' | 'conflict'> {
    const currentSession = await firstValueFrom(this.current$);

    if (currentSession?.user?.id == newSession?.user?.id) return 'same';

    if (!currentSession || force) {
      await this.service.inject(newSession);
      await firstValueFrom(this._current$.pipe(filter((x) => x?.user?.id === newSession.user.id)));
      return 'injected';
    }

    return 'conflict';
  }

  // once something change in the login context of app.unleash.so we also update
  // the context of unleash.so, and go.unleash.so, and browser extension if installed
  private async syncExternalContexts(s: SessionInfo) {
    if (this.isEmbed) {
      return;
    }

    const domain = location.hostname.replace('app.', 'www.');
    const options = {
      domain: location.hostname.replace('app.', '.'),
      path: '/',
      expires: 365 * 10,
      secured: location.protocol.startsWith('https'),
    };
    Cookies.set('usi', s ? '1' : '0', options);

    if (typeof (<any>window).cookieStore == 'undefined') {
      // Only Chrome and Edge support accessing cookies directly from SW via CookieStore
      const ifrm = document.createElement('iframe');
      ifrm.src = location.protocol + '//' + domain + '/usi.htm';
      ifrm.onload = () => {
        ifrm.contentWindow.postMessage({ type: 'usi', value: s ? '1' : '0' }, domain);
      };
    }

    const gfrm = document.createElement('iframe');
    gfrm.src = location.protocol + '//' + domain.replace('app.', 'go.');
    gfrm.onload = () => {
      const f = (e) => {
        if (e.data?.type == 'unleash:go:init:ack') {
          gfrm.remove();
          window.removeEventListener('message', f);
        }
      };

      window.addEventListener('message', f);
      gfrm.contentWindow.postMessage({ type: 'unleash:go:init', session: s }, domain);
    };
  }

  async signOut(silent?: boolean) {
    if (!silent) await this.ensureSignedIn();
    else if (!(await this.isSignedIn())) return;

    const nextSession = this.service.current$.pipe(filter((s) => !s));
    this.service.signOut();
    this.signout$.next();
    this._current$.next(null);

    await firstValueFrom(nextSession);
    if (!silent && !this.isNative) {
      this.routerService.navigateByUrl('/signin');
    }
  }

  private async isSignedIn(): Promise<boolean> {
    return !!(await firstValueFrom(this.current$));
  }

  private async ensureSignedIn() {
    if (!this.isSignedIn()) throw new Error('User is not signed in');
  }

  async deleteAccount() {
    await this.ensureSignedIn();
    await this.service.deleteAccount();
    const userId = (await firstValueFrom(this.current$)).user.id;
    location.href = this.config.deletedUserFeedbackUrl + '?userId=' + userId;
  }

  async authEmail(email: string): Promise<EmailInfo> {
    const state = this.createState('saml');
    return this.service.email(email, state.o, this.encodeState(state));
  }

  async inviteEmailRequest(email: string): Promise<InviteRequestInfo> {
    const result = await this.service.inviteEmailRequest(email);
    if (result.success) {
      this.inviteService.setInviteUser(result);
      this.routerService.navigateByUrl('/user-created');
    }
    return result;
  }

  async activateUser() {
    await this.service.activateUser();
  }

  updateWorkspace(w: Workspace.Workspace): Promise<void> {
    return this.service.updateWorkspace(w);
  }

  handleFlags(disableOptions: object) {
    if (disableOptions) {
      for (const key in disableOptions) {
        if (Object.prototype.hasOwnProperty.call(disableOptions, key)) {
          this.flagsService.set(key, disableOptions[key]);
        }
      }
    }
  }

  async completeSignIn(stateStr: string, code: string, context: string): Promise<SessionInfo> {
    const state: AuthState = JSON.parse(atob(stateStr));
    if (isProdEnv() && state?.t != 'email' && !this.isNonceLegit(state.n)) {
      throw new Error('bad nonce got ' + state.n + ' ' + this.storageNonce.nonce);
    }

    if (state.s) {
      await this.signOut(true);
    }
    const origin = this.getTargetOrigin(state.to);
    const opener = origin && window.opener?.postMessage ? window.opener : null;
    const reportState = { provider: state.p, authType: state.t, redirect: state.r, status: 'success' };
    if (opener) {
      opener.postMessage({ type: 'unleash:complete-provider-signin', ...reportState }, origin);
    }

    let info: SignInResponse;

    if (this.flagsService.isNotProd) {
      this.handleFlags(state.f);
    }

    const onBoardingStage = this.flagsService.get<string>('onBoardingStage');
    const nextSession = this.service.current$.pipe(
      filter((s) => {
        return info && s?.user?.id === info?.session?.user?.id;
      })
    );

    // check host to avoid in production
    const appToken = this.getAppToken();
    if (state.t === 'oauth-refresh' && Config.session.allowRefreshTokenFlow) {
      let details: OAuthAuthCodeRequest | OAuthRefreshTokenRequest = { refreshToken: code };
      if (state.p === 'unleash') {
        details = {
          code,
          redirectUrl: 'https://dummy-url.com',
        };
      }
      info = await this.service.signInOAuth({
        provider: state.p,
        grantType: 'refresh_token',
        details,
        appToken,
        completeOnBoarding: false,
      });
    } else {
      const provider = this.config.providers.find((p) => p.name === state.p);
      const redirectUrl = provider?.redirectUri || this.config.redirectUri;

      info = await this.service.signInOAuth({
        provider: state.t == 'email' ? 'email' : state.p,
        grantType: 'authorization_code',
        details: { code, context, redirectUrl, user: null },
        appToken,
      });

      if (info.status === 'user_cannot_be_registered') {
        window.location.href = Constants.WEBFLOW_ACCOUNT_SETUP;
        return;
      }

      if (info.status === 'user_not_invited' || info.status == 'user_uses_public_email_domain') {
        this.trackLeadRegistration(state.p, info.user?.email, info.user?.firstName + ' ' + info.user?.lastName);
        this.inviteService.setInviteUser(info);
        await this.routerService.navigateByUrl('/user-created');
        return;
      }
    }

    let r = state.r || '';
    if (!r.startsWith('?')) {
      r = '/' + r;
    }

    const sessionInfo = await firstValueFrom(nextSession);
    if (opener) {
      opener.postMessage(
        {
          type: 'unleash:complete-signin',
          ...reportState,
          userId: sessionInfo.user?.id,
          email: sessionInfo.user?.email,
          status: 'success',
        },
        origin
      );
    }

    if (onBoardingStage) {
      const onBoardingState = getOnboardingStateObject(onBoardingStage);
      const storage = this.sessionStorageService.getStore('roaming', 'account');
      if (storage) {
        const onboardingStorage = storage.entry<OnboardingState>('onboarding');
        await onboardingStorage.set(<OnboardingState>onBoardingState);
      }
    }
    if (sessionInfo?.workspace?.id && !this.isEmbed && !this.isNative) {
      Cookies.set('workspaceId', sessionInfo.workspace.id);
    }
    await this.routerService.navigateByUrl(r);

    return sessionInfo;
  }

  signInOAuth() {
    return;
  }

  private trackLeadRegistration(source: string, email: string, name: string) {
    this.leadService.track({
      Action: 'Sign Up',
      Email: email,
      EmailSource: source,
      Name: name,
    });
  }

  private isNonceLegit(nonce: string) {
    if (nonce === this.storageNonce.nonce && this.storageNonce.expires > Date.now()) {
      return true;
    }
    const cookieNonce = Cookies.get('nonce');
    return !!nonce && nonce === cookieNonce;
  }

  private getAppToken(): 'desktop' | null {
    if (new URL(location.href).searchParams.get('r')?.startsWith('/desktop') || location.href.startsWith('/desktop')) return 'desktop';

    return null;
  }

  encodeState(state: AuthState): string {
    return btoa(JSON.stringify(state));
  }

  createState(type: 'saml' | 'oauth-code' | 'oauth-refresh', origin?: string, providerName?: string): AuthState {
    if (!this.storageNonce || this.storageNonce.expires < Date.now()) {
      this.createNewStorageNonce();
    }
    const to = this.getTargetOrigin(origin);
    const state: AuthState = {
      t: type,
      r: new URL(location.href).searchParams.get('r'),
      n: this.storageNonce.nonce,
      p: providerName,
      o: `${location.origin}/signin`,
      ro: origin,
      to,
    };

    return state;
  }

  getTargetOrigin(origin: string) {
    return origin && (origin === this.TARGET_ORIGIN_DOMAIN || origin.endsWith(`.${this.TARGET_ORIGIN_DOMAIN}`)) ? origin : null;
  }

  getProviders(targetOrigin?: string, subdomain?: string): SessionProvider[] {
    if (this.isEmbed) {
      return [];
    }
    const providers = this.config.providers
      .filter((p) => !p.disabled)
      .map((provider) => {
        const providerRefreshToken = this.flagsService.get<string>(`${provider.name}-refresh-token`);

        let url: string;
        const query: string[] = [];
        let state: AuthState;

        const redirectUri = provider.redirectUri || this.config.redirectUri;
        if (!providerRefreshToken) {
          url = provider.endpoint;
          if (subdomain) {
            url = url.replace('${SUBDOMAIN}', subdomain);
          }
          query.push(
            ...[
              'response_type=code',
              `redirect_uri=${redirectUri}`,
              `client_id=${provider.clientId}`,
              `scope=${escape(provider.scopes.join(' '))}`,
            ]
          );
          state = this.createState('oauth-code', targetOrigin, provider.name);
        } else {
          url = redirectUri;
          state = this.createState('oauth-refresh', targetOrigin, provider.name);
          query.push(...[`code=${escape(providerRefreshToken)}`]);
        }

        query.push(`state=${this.encodeState(state)}`);

        if (url.indexOf('?') === -1) {
          url += '?';
        } else if (!url.endsWith('?')) {
          url += '&';
        }

        url += query.join('&');
        return {
          url,
          image: provider.image,
          name: provider.name,
          displayName: provider.displayName,
          endpoint: provider.endpoint,
          redirectUri,
          clientId: provider.clientId,
          scopes: provider.scopes,
          state: this.encodeState(state),
          hidden: provider.hidden,
        };
      });
    return providers;
  }

  async getMyUser(): Promise<User.Info> {
    return this.service.getMyUser();
  }

  @observable
  get current$() {
    return this._current$;
  }

  @observable
  get accountChanged$() {
    return this._accountChanged$;
  }

  @observable
  get user$() {
    return this.current$.pipe(map((s) => s?.user));
  }
}
