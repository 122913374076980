import { Injectable } from '@angular/core';
import { ContextMenuComponent, ContextMenuData, ContextMenuItem, ContextMenuService } from '@shared/components';
import { FrameEditorConstants } from '../constants/frame-editor.constants';
import { PopupRef, PopupService } from '@local/ui-infra';
import tinymce, { Editor } from 'tinymce';
import { ReplaySubject, take } from 'rxjs';
import { EventInfo, EventsService, LogService } from '@shared/services';
import { getExtensionByFileName } from '@local/ts-infra';
import { observable } from '@local/common';
import { BlobsService } from 'src/app/bar/services/blobs/blob.service';
import { Logger } from '@unleash-tech/js-logger';
import {
  RenameAttachmentPopupComponent,
  RenameAttachmentPopupData,
} from '../components/rename-attachment-popup/rename-attachment-popup.component';
import { FrameEditorContextMenuActionsService } from './frame-editor-context-menu-actions.service';
import { BlobsStorageService } from 'src/app/bar/services/blobs/blobs-storage.service';
import { Wiki } from '@local/client-contracts';
import { ContextMenuItemsService } from 'src/app/bar/services/commands/context-menu-items.service';
import { ContextMenuItemsType } from 'src/app/bar/services/commands/context-menu-items-map';

export type EventPosition = { x: number; y: number };
export type EventData = { selectedElement: HTMLElement; editor: Editor };

@Injectable()
export class FrameEditorContextMenuService {
  private logger: Logger;
  private _saveEditor$ = new ReplaySubject<void>(1);
  private renameAttachmentPopup: PopupRef<RenameAttachmentPopupComponent, RenameAttachmentPopupData>;
  private contextMenuPopup: PopupRef<ContextMenuComponent, ContextMenuData>;
  private _contextMenuOpened: boolean;

  @observable
  get saveEditor$() {
    return this._saveEditor$.asObservable();
  }

  get contextMenuOpened(): boolean {
    return this._contextMenuOpened;
  }

  get renameAttachmentPopupOpen() {
    return this.renameAttachmentPopup;
  }

  constructor(
    logService: LogService,
    private contextMenuService: ContextMenuService,
    private eventsService: EventsService,
    private frameEditorMenuActionsService: FrameEditorContextMenuActionsService,
    private popupService: PopupService,
    private blobsService: BlobsService,
    private frameEditorStorageAttachmentsService: BlobsStorageService<Wiki.CardAttachment>,
    private contextMenuItemsService: ContextMenuItemsService
  ) {
    this.logger = logService.scope('FrameEditorContextMenuService');
  }

  sendEvent(key: string, locationTitle: string, info: Partial<EventInfo>): void {
    this.eventsService.event(key, {
      ...info,
      location: { title: locationTitle },
    });
  }

  //attachment - context menu
  openAttachmentContextMenu(
    event: EventPosition,
    attachment: any,
    collectionId: string,
    canEdit: boolean,
    locationTitle: string,
    editorPosition: EventPosition,
    followingId: string,
    editorId: string
  ) {
    if (this.contextMenuPopup) {
      this.contextMenuPopup.destroy();
    }
    this._contextMenuOpened = true;
    attachment.classList.add(FrameEditorConstants.CLASS_ACTIVE_ATTACHMENT);
    const items = this.buildAttachmentContextMenuItems(attachment, collectionId, canEdit, editorPosition);
    const selectedNode = tinymce.get(editorId).selection.getNode();
    this.contextMenuPopup = this.contextMenuService.open(
      event,
      {
        items,
        onInvoke: (item: ContextMenuItem) => this.handleAttachmentCommand(item, locationTitle, followingId),
        minWidthItem: 90,
      },
      { position: 'right' }
    );
    setTimeout(() => {
      selectedNode.setAttribute(FrameEditorConstants.CLASS_TINYMCE_FOCUS_BORDER, '1');
    }, 0);
    this.contextMenuPopup.destroy$.pipe(take(1)).subscribe(() => {
      attachment.classList.remove(FrameEditorConstants.CLASS_ACTIVE_ATTACHMENT);
      setTimeout(() => {
        selectedNode.removeAttribute(FrameEditorConstants.CLASS_TINYMCE_FOCUS_BORDER);
      }, 0);
      this._contextMenuOpened = false;
    });
    return this.contextMenuPopup;
  }

  private buildAttachmentContextMenuItems(
    attachment: any,
    collectionId: string,
    canEdit: boolean,
    editorPosition: EventPosition
  ): ContextMenuItem[] {
    const contextMenuItems: ContextMenuItem[] = [];
    contextMenuItems.push(
      this.contextMenuItemsService.getContextMenuItem('preview', { attachment, collectionId }),
      this.contextMenuItemsService.getContextMenuItem('download', { attachment })
    );
    if (canEdit) {
      contextMenuItems.push(
        this.contextMenuItemsService.getContextMenuItem('rename', { attachment, editorPosition }),
        this.contextMenuItemsService.getContextMenuItem('delete', { attachment })
      );
    }
    return contextMenuItems;
  }

  private async handleAttachmentCommand(command: ContextMenuItem, locationTitle: string, followingId: string) {
    const attachment = command.data.attachment;
    const blobId = attachment.id;
    this.sendEvent('context_menu.open', locationTitle, { label: command.id });
    switch (command.id) {
      case 'preview': {
        const iconUrl = attachment.querySelector('img')?.src;
        this.frameEditorMenuActionsService.openPreview(attachment.id, iconUrl, command.data.collectionId);
        break;
      }
      case 'download':
        this.frameEditorMenuActionsService.startDownload(blobId);
        break;
      case 'rename':
        this.renameAttachment(attachment, command.data.editorPosition, followingId);
        break;
      case 'delete':
        attachment.remove();
        this._saveEditor$.next();
        break;
    }
    this.sendEvent('results.action', locationTitle, { label: command.id, target: 'mouse_click' });
  }

  private renameAttachment(attachment: any, editorPosition: EventPosition, followingId: string) {
    const attachmentName = attachment.querySelector(`.${FrameEditorConstants.CLASS_NAME_ATTACHMENT}`);
    const { top, right, left } = attachmentName.getBoundingClientRect();
    const position = { top: top + 25 + editorPosition.y, right, left: left + editorPosition.x };
    if (this.renameAttachmentPopup) {
      this.renameAttachmentPopup.destroy();
    }
    setTimeout(() => {
      attachment.classList.add(FrameEditorConstants.CLASS_ACTIVE_ATTACHMENT);
    }, 0);
    this.renameAttachmentPopup = this.popupService.open(
      position,
      RenameAttachmentPopupComponent,
      { text: attachmentName?.textContent },
      { hasBackdrop: false }
    );
    this.renameAttachmentPopup.destroy$.pipe(take(1)).subscribe(() => {
      attachment.classList.remove(FrameEditorConstants.CLASS_ACTIVE_ATTACHMENT);
    });
    this.renameAttachmentPopup.compInstance.onRename.pipe(take(1)).subscribe(async (newName) => {
      await this.onRenameAttachment(newName, attachment, followingId);
    });
  }

  private async onRenameAttachment(newName: string, attachment: any, followingId: string) {
    const attachmentName = attachment.querySelector(`.${FrameEditorConstants.CLASS_NAME_ATTACHMENT}`);
    this.renameAttachmentPopup.destroy();
    if (!newName) return;
    const blobId = attachment.id;
    const attachmentObj = this.frameEditorStorageAttachmentsService.getAttachment(followingId, blobId);
    if (!attachmentObj) {
      return;
    }
    const currentName = attachmentObj.name;
    const extension = `.${getExtensionByFileName(currentName)}`;
    const newNameWithExtension = newName.endsWith(extension) ? newName : newName + extension;
    this.updateAttachmentName(blobId, newNameWithExtension);
    this.frameEditorStorageAttachmentsService.updateAttachmentName(followingId, blobId, newName);
    attachmentName.innerText = newName;
    this._saveEditor$.next();
  }

  private async updateAttachmentName(id: string, newName: string) {
    return this.blobsService.update(id, newName);
  }

  //video, code, link, hr - context menu
  openContextMenu(type: string, editorId: string, editorPosition: EventPosition, data: EventData, locationTitle: string, readonly = false) {
    let items: ContextMenuItem[];
    switch (type) {
      case 'hr':
        items = this.contextMenuItemsService.getContextMenuItems(readonly ? ['copy'] : ['copy', 'duplicate', 'delete'], data);
        break;
      case 'a':
        items = this.contextMenuItemsService.getContextMenuItems(readonly ? ['openLink'] : ['link', 'unlink', 'openLink'], data);
        break;
      case 'code':
        items = this.contextMenuItemsService.getContextMenuItems(['copy'], data);
        break;
      default:
        items = this.buildVideoContextMenuItems(data, readonly);
        break;
    }
    return this.openExternalContextMenu(editorPosition, items, data.selectedElement, locationTitle, editorId);
  }

  private buildVideoContextMenuItems(data: { selectedElement: HTMLElement; editor }, readonly = false): ContextMenuItem[] {
    const items: ContextMenuItemsType[] = [];
    const id = data.selectedElement.id || data.selectedElement.getAttribute('data-mce-p-id');
    if (!readonly) {
      items.push('copyVideo', 'delete');
    }
    if (id) {
      items.push('download');
    }
    return this.contextMenuItemsService.getContextMenuItems(items, data);
  }

  private openExternalContextMenu(
    editorPosition: EventPosition,
    items: ContextMenuItem[],
    selectedElement: HTMLElement,
    locationTitle: string,
    editorId: string
  ) {
    if (this.contextMenuPopup) {
      this.contextMenuPopup.destroy();
    }
    this._contextMenuOpened = true;
    this.contextMenuPopup = this.contextMenuService.open(
      editorPosition,
      {
        items,
        onInvoke: (item: ContextMenuItem) => this.handleContextMenuCommand(item, locationTitle, editorId),
        minWidthItem: 90,
      },
      { position: 'right' }
    );

    setTimeout(() => {
      selectedElement?.setAttribute(FrameEditorConstants.CLASS_TINYMCE_FOCUS_BORDER, '1');
    }, 0);

    this.contextMenuPopup.destroy$.pipe(take(1)).subscribe(() => {
      this._contextMenuOpened = false;
      selectedElement?.removeAttribute(FrameEditorConstants.CLASS_TINYMCE_FOCUS_BORDER);
    });

    return this.contextMenuPopup;
  }

  private handleContextMenuCommand(command: ContextMenuItem, locationTitle: string, editorId: string) {
    this.sendEvent('context_menu.open', locationTitle, { label: command.id });
    this.frameEditorMenuActionsService.handleContextMenuCommand(command, editorId);
    setTimeout(() => {
      this._saveEditor$.next(); // this needed for the affect of the insert will happen
    }, 50);
    this.sendEvent('results.action', locationTitle, { label: command.id, target: 'mouse_click' });
  }

  destroyHandlers() {
    this.contextMenuPopup?.destroy();
    this.renameAttachmentPopup?.destroy();
  }
}
