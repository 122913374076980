import { afterNextRender, ChangeDetectionStrategy, Component, effect, input, model, signal } from '@angular/core';
import { UTypeElement, UTypeSizeElement, UTypeStateElement } from '../types/types';
import { isEmpty } from 'lodash';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class UBaseInfraComponent {
  readonly ICON_CLASS = 'font-icon icon-';
  styleClass = signal<string>(null);

  typeElement = model<UTypeElement>('secondary');
  stateElement = model<UTypeStateElement>('regular');
  sizeElement = input<UTypeSizeElement>('medium');
  iconStyles = input<any>({});

  constructor() {
    effect(
      () => {
        if (this.sizeElement() || this.stateElement()) {
          this.setStyleClass();
        }
        if (this.iconStyles()) {
          this.setIconStyles();
        }
      },
      { allowSignalWrites: true }
    );
    afterNextRender(() => {
      if (!this.sizeElement() && !this.stateElement()) {
        this.setStyleClass();
      }
    });
  }

  abstract getIconRef(): any;

  getBaseClasses(): any {
    return {
      'u-element-hover': this.stateElement() === 'hover',
      'u-element-active': this.stateElement() === 'active',
      'u-element-focus': this.stateElement() === 'focus',
      'u-element-xs': this.sizeElement() === 'extra-small',
      'u-element-sm': this.sizeElement() === 'small',
      'u-element-md': this.sizeElement() === 'medium',
      'u-element-lg': this.sizeElement() === 'large',
      'u-element-xl': this.sizeElement() === 'extra-large',
    };
  }

  setStyleClass() {
    const classes = this.getBaseClasses();

    this.styleClass.set(
      Object.keys(classes)
        .filter((key) => classes[key])
        .join(' ')
    );
  }

  private setIconStyles() {
    const iconRef = this.getIconRef();
    if (!iconRef) {
      return;
    }
    if (isEmpty(this.iconStyles())) {
      iconRef.removeAttribute('style');
    } else {
      Object.keys(this.iconStyles()).forEach((key) => {
        iconRef.style[key] = this.iconStyles()[key];
      });
    }
  }
}
