@switch (versionNumber()) {
  @case (2) {
    <p-button
      #pButton
      class="u-button-v2"
      [label]="stateElement() === 'loading' ? null : label()"
      [icon]="fontIcon() && stateElement() !== 'loading' ? ICON_CLASS + fontIcon() : null"
      [severity]="typeElement()"
      [outlined]="styleType() === 'outlined'"
      [text]="styleType() === 'text'"
      [link]="styleType() === 'link'"
      [iconPos]="iconPos()"
      [disabled]="['disabled', 'loading'].includes(stateElement())"
      [style]="styles()"
      [styleClass]="styleClass()"
      [class.force-secondary-dark-mode]="forceSecondaryDarkMode()"
      [class.loading]="stateElement() === 'loading'"
      (click)="click($event)"
    >
      <ng-content></ng-content>
      @if (stateElement() === 'loading') {
        <i class="pi pi-spin pi-spinner" style="font-size: 14px"></i>
      }
    </p-button>
  }
  @default {
    <button
      pButton
      #button
      type="button"
      [iconPos]="iconPos()"
      [ngClass]="[type + '-button', withIcon ? ' with-icon' : '', onlyIcon ? 'only-icon' : '', elementSize ?? '', active ? 'active' : '']"
      class="u-button"
      [label]="loading ? null : label()"
      [disabled]="disabled || loading"
      (click)="click($event)"
      [style]="styles()"
      [icon]="fontIcon() && !loading ? ICON_CLASS + fontIcon() : null"
      [class.force-secondary-dark-mode]="forceSecondaryDarkMode()"
    >
      <ng-content></ng-content>
      <i *ngIf="loading" class="pi pi-spin pi-spinner" style="font-size: 14px"></i>
    </button>
  }
}
