import { Injectable } from '@angular/core';
import {  Preferences } from '@local/client-contracts';
import { DEFAULT_PREFERENCES, observable } from '@local/common';
import { isNativeWindow } from '@local/common-web';
import { isGeneralPreferencesCommand } from '@shared/utils';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep, isEqual } from 'lodash';
import { distinctUntilChanged, filter, firstValueFrom, map, Observable, ReplaySubject } from 'rxjs';
import { EventsService } from '.';
import { GeneralPreferencesCommand } from '../../bar/models/preferences-command';
import { CommandBarService } from '../../bar/services/command-bar.service';
import { PreferencesToSearchPopUpBuilder } from '../../bar/services/preferences-search-popup.builder';
import { ClientStorageService } from './client-storage.service';
import { LocalStorageService } from './local-storage.service';
import { LogService } from './log.service';


@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  private _current$: ReplaySubject<Preferences.Preferences>;
  static COMMAND_BAR_ID = 'preferences-general';
  private logger: Logger;

  private generalCommandBarBuilder: PreferencesToSearchPopUpBuilder;
  private commandBarId: string;
  

  private commandBarService?: CommandBarService;
  readonly storageKey = 'SETTINGS_VERSION';
  private readonly isNativeWindow = isNativeWindow();

  set current(value: Preferences.Preferences) {
    this._current$.next(value);
  }

  constructor(
    private clientStorage: ClientStorageService,
    protected eventsService: EventsService,
    logService: LogService,
    private localStorage: LocalStorageService /*@Optional() private commandBarService?: CommandBarService // Needs to be provided in the module level (by mentioning this class name in the providers)*/
  ) {
    this.logger = logService.scope('PreferencesService');

    this.registerToCommandBar();
    this._current$ = new ReplaySubject(1);
 
    this.initStorage();
  }

  private async initStorage() {
    const clientStorage = await this.localStorage.create('client');

    let store = clientStorage.entry<Preferences.Preferences>('preferences');
    let read = false;

    const handle = async (x, latest) => {
      if (read && !latest) return;
      read = true;

      if (!x) {
        x = DEFAULT_PREFERENCES;
        if (!this.isNativeWindow) this.update(x);
      }
      if (this.isNativeWindow) store.set(x);
      this.current = x;
      this.trySendUpgradeTelemetry(x);
    };

    // first time we load it without the underline service to accelerate speed
    store.get().then((x) => {
      if (!x?.version) x = DEFAULT_PREFERENCES; // override old non version preferences
      handle(x, false);
    });

    this.clientStorage.current$<Preferences.Preferences>('preferences').subscribe((res) => {
      let x = cloneDeep(res);
      if (!x?.version) x = DEFAULT_PREFERENCES;
      handle(x, true);
    });
  }
  private async trySendUpgradeTelemetry(preferences) {
    try {
      const storedVersion = await this.clientStorage.get(this.storageKey);
      if (storedVersion === process.env.UNLEASH_APP_VERSION) {
        return;
      }
      // app main version was updated, report an upgrade
      await this.eventsService.preferences('upgrade', preferences);
      await this.clientStorage.set(this.storageKey, process.env.UNLEASH_APP_VERSION);
    } catch (err) {
      this.logger.error(`Failed while sending preferences telemetry on preferences init`, err);
    }
  }

  async update(preferences: Preferences.Preferences) {
 
    return this.clientStorage.set('preferences', preferences);
  }

  @observable
  get current$(): Observable<Preferences.Preferences> {
    return this._current$;
  }

  @observable
  section$<K extends keyof Preferences.PreferencesSection>(section: K): Observable<Preferences.Preferences[K]> {
    return this._current$.pipe(
      filter((p) => !!p),
      map((p) => p[section]),
      distinctUntilChanged()
    );
  }

  @observable
  property$<K extends keyof Preferences.PreferencesSection, P extends keyof Preferences.Preferences[K]>(
    section: K,
    property: P
  ): Observable<Preferences.Preferences[K][P]> {
    return this._current$.pipe(
      filter((p) => !!p),
      map((p) => p[section][property]),
      distinctUntilChanged()
    );
  }

  getValue<T, K extends keyof T>(result: { value: T }, key: K): T[K] {
    return result.value[key];
  }

  private registerToCommandBar() {
    if (!this.commandBarService) {
      return;
    }
    this.generalCommandBarBuilder = new PreferencesToSearchPopUpBuilder();
    this.section$('general')
      .pipe(distinctUntilChanged((prev, next) => isEqual(prev, next)))
      .subscribe((p) => {
        const children = this.generalCommandBarBuilder.build(p);
        this.unregisterFromCommandBar();
        const id = 'preferences-general';
        this.commandBarService
          .add({
            id,
            visibility: null,
            icon: null,
            type: 'parent',
            command: null,
            title: 'Preferences',
            children,
            data: { sortId: 'PREFERENCES' },
            sortBy: 'default',
          })
          .subscribe(({ item: { command } }) => isGeneralPreferencesCommand(command) && this.onCommandBarInvoke(command));
        this.commandBarId = id;
      });
  }

  private unregisterFromCommandBar() {
    if (this.commandBarId) {
      this.commandBarService.remove(this.commandBarId);
    } else if (this.commandBarService.all.some(({ id }) => id === PreferencesService.COMMAND_BAR_ID)) {
      this.logger.error('mismatch of Command Bar registration status');
    }
  }

  private async onCommandBarInvoke({ value: { value, property } }: GeneralPreferencesCommand) {
    const current = await firstValueFrom(this.current$);
    this.update({ ...current, general: { ...current.general, [property]: value } });
  }
}
