import {
  Component,
  ChangeDetectionStrategy,
  computed,
  effect,
  Input,
  TemplateRef,
  model,
  EventEmitter,
  Output,
  input,
  signal,
} from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';

export interface RowsManagerModel {
  content: any;
  mode: 'editableRow' | 'constantRow' | 'newRow';
}

@Component({
  selector: 'u-row-manager',
  templateUrl: './u-row-manager.component.html',
  styleUrl: './u-row-manager.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class URowManagerComponent {
  private currentEditIndex: number = null;
  private prevEditRow: RowsManagerModel;
  disableSaveIndex = signal<number | null>(null);
  disableAddButton = signal<boolean>(true);

  editingModel = model<RowsManagerModel[]>([]);
  state = input<'disabled' | 'readonly'>();
  addButtonText = input<string>('Add');
  limit = input<number>();
  editingModelLength = computed(() => this.editingModel().length);

  @Input() model: RowsManagerModel[] = [];
  @Input({ required: true }) isRowValid: (row: RowsManagerModel) => boolean;
  @Input() modelItem: RowsManagerModel;
  @Input() rowTemplate: TemplateRef<any>;

  @Output() onUpdatedRows = new EventEmitter<{ model: RowsManagerModel[]; action: string }>();
  @Output() saveRowFailed = new EventEmitter<number>();

  constructor() {
    effect(
      () => {
        if (this.editingModel().length) {
          const newModeRow = this.editingModel().find((m) => m.mode === 'newRow');
          if (newModeRow && this.currentEditIndex === null) {
            this.currentEditIndex = 0;
            this.prevEditRow = cloneDeep(newModeRow);
          }
          if (this.editingModelLength() === this.limit() || newModeRow) {
            this.disableAddButton.set(true);
          } else {
            this.disableAddButton.set(false);
          }
          if (this.currentEditIndex !== null) {
            const index = this.currentEditIndex;
            const isRowUnchanged = isEqual(this.editingModel()[index!], this.prevEditRow);
            this.disableSaveIndex.set(isRowUnchanged ? index : null);
          }
        }
      },
      { allowSignalWrites: true }
    );
  }

  trackByIndex(index: number): number {
    return index;
  }

  editRow(index: number) {
    if (this.currentEditIndex !== null) {
      this.updateEditingModel();
    }
    this.editingModel.update((rows) => {
      const updatedRows = [...rows];
      updatedRows[index].mode = 'editableRow';
      return updatedRows;
    });
    this.prevEditRow = cloneDeep(this.editingModel()[index]);
    this.currentEditIndex = index;
    this.onUpdatedRows.emit({ model: this.editingModel(), action: 'edit' });
  }

  deleteRow(index: number) {
    if (this.editingModelLength() === 1) {
      this.editingModel.set([cloneDeep(this.modelItem)]);
    } else {
      if (this.currentEditIndex !== null) {
        this.updateEditingModel();
      }
      this.editingModel.update((rows) => {
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        return updatedRows;
      });
    }
    this.onUpdatedRows.emit({ model: this.editingModel(), action: 'delete' });
  }

  cancelRow() {
    this.updateEditingModel();
  }

  saveRow(index: number) {
    if (!this.isRowValid(this.editingModel()[index])) {
      this.saveRowFailed.emit(index);
      return;
    }
    this.editingModel.update((rows) => {
      const updatedRows = [...rows];
      updatedRows[index].mode = 'constantRow';
      return updatedRows;
    });
    this.currentEditIndex = null;
    this.prevEditRow = null;
    this.onUpdatedRows.emit({ model: this.editingModel(), action: 'save' });
  }

  addButton() {
    if (this.disableAddButton()) {
      return;
    }
    this.disableAddButton.set(true);
    if (this.editingModelLength() === this.limit()) {
      return;
    }
    if (this.currentEditIndex !== null) {
      this.updateEditingModel();
    }
    this.editingModel.update((rows) => [...rows, cloneDeep(this.modelItem)]);
    this.currentEditIndex = this.editingModelLength() - 1;
    this.prevEditRow = cloneDeep(this.modelItem);
    this.onUpdatedRows.emit({ model: this.editingModel(), action: 'add' });
  }

  private updateEditingModel() {
    this.currentEditIndex = null;
    this.prevEditRow = null;
    this.editingModel.set(cloneDeep(this.model));
  }
}
