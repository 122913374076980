import { ItemsCache } from './items-cache';

export class ItemsCacheService {
  private itemsCacheMap: { [key: string]: ItemsCache<any> } = {};

  getItemsCache<T>(type: string): ItemsCache<T> {
    if (!this.getItemsCacheByType(type)) {
      const itemsCache = new ItemsCache<T>();
      this.itemsCacheMap[type] = itemsCache;
    }
    return this.getItemsCacheByType(type);
  }

  getItemsCacheByType<T>(type: string): ItemsCache<T> {
    return this.itemsCacheMap[type];
  }
}
