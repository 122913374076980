export interface EventInfo {
  category: string;
  name: string;
  target?: string;
  label?: string;
  nonInteraction?: boolean;
  location?: Partial<EventInfoLocation>;
  search?: Partial<EventInfoSearch>;

  /**
   * resources list to be used in impression events
   */
  resources?: Array<Partial<EventInfoResource>>;
  exception?: string;

  jsonData?: string | any;
}

export interface EventInfoLocation {
  path: string;
  title: string;
  pageNumber?: number;
  pageQuery?: string;
  hostName: string;
  loadTrigger: string;
}

export interface EventInfoSearch {
  query: string;
  trigger: string;
  offline: boolean;
  scope: { type: string; values: string[] }[];
  filter: { type: string; values: string[] }[];
  clientSearchId: string;
  sessionId: string;
  searchId?: string;
  sources: string[];
  source: string;
  origin: string;
  resultsCount?: number;
  cacheHit?: boolean;
  responseStatus?: string;
  responseIgnored?: boolean;
  stepDuration: number;
}

export interface EventInfoResource {
  /**
   * id of resource
   */
  id: string;

  /**
   * list in which resource appeared (e.g. search_bar_results)
   */
  list: string;

  appId: string;

  /**
   * full type of resource
   */
  type: string;

  /**
   * category of resource, e.g. mail, task etc.
   */
  category: string;

  /**
   * position of resource in results
   */
  position: number;

  linkId: string;
  clientLink?: string;
  name?: string;
}
