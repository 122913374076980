import { Injectable } from '@angular/core';
import { StaticData } from '@local/client-contracts';
import { ServicesRpcService } from '@shared/services';
import { StaticDataRpcInvoker } from './invokers/static-data-rpc-invoker';
import { filter, map, Observable, ReplaySubject } from 'rxjs';
import { observable } from '@local/common';

@Injectable({
  providedIn: 'root',
})
export class StaticDataService {
  private _data$ = new ReplaySubject<StaticData.StaticDataAttributes>(1);
  private service: StaticData.Service;

  constructor(services: ServicesRpcService) {
    this.service = services.invokeWith(StaticDataRpcInvoker, 'staticdata');
    this.initData();
  }

  private initData() {
    this.service.data$.pipe(filter((d) => !!d)).subscribe((data) => {
      this._data$.next(data);
    });
  }

  @observable
  get data$() {
    return this._data$.asObservable();
  }

  assistantSupportedFilters$() {
    return this._data$.pipe(map((d) => d.assistantSupportedFilters));
  }

  getAppsByView$(view: StaticData.ViewName): Observable<string[]> {
    return this.service.getAppsByView$(view);
  }

  getFiltersPolicy$(): Observable<StaticData.FiltersPolicy> {
    return this.service.filtersPolicy$;
  }
}
