<div
  *ngIf="model"
  class="item"
  [ngClass]="{
    deleted: model.deleted,
    height: model.actions?.length > 0 || model.deleted,
    read: model.read == false,
    'failed-deleted': model.deleted && !this.model.subtitle,
    'long-user-state': userStateRef?.nativeElement?.offsetHeight > 60,
  }"
>
  <div class="status">
    <u-icon
      [model]="model.appIconUrl"
      [styles]="{ width: '32px', height: '32px' }"
      [ngClass]="{ 'user-icon': this.model.isUserIcon }"
    ></u-icon>
    <indicator
      *ngIf="model.indicatorIntent !== 'none'"
      [intent]="model.indicatorIntent"
      [blink]="model.indicatorIntent === 'warning'"
    ></indicator>
  </div>

  <div class="fyi-inner" [ngClass]="{ bold: model.type === 'link_staled' || model.read == false }">
    <div class="content">
      <div class="time" [pTooltip]="getFullLastUpdateTime()" tooltipStyleClass="u-tooltip" tooltipPosition="bottom">
        {{ lastUpdateTime | date: 'hh:mm a' }}
      </div>
      <div *ngIf="model.title" class="title">
        <div *ngIf="model.app" class="app">
          <span class="app-name">{{ model.app.appName }}</span>
          <div class="dot">•</div>
        </div>
        <span class="text-status">{{ model.title }} </span>
      </div>

      <div *ngIf="model.user" class="user-content" #userState>
        <span *ngIf="model.user?.userName" class="user-name" [ngClass]="{ 'long-name': model.user.userName.length > 35 }">{{
          model.user.userName
        }}</span>
        <span>{{ model.user.text }}</span>
        <span class="user-text" [ngClass]="{ 'long-name': model.user.boldText?.length > 20, 'user-text-full': model?.user?.emoji }">
          <u-emoji *ngIf="model?.user?.emoji; else fallbackIconUser" [spriteEmojiSize]="14" [unicode]="model.user.emoji"></u-emoji>
          <span class="user-bold-text">{{ model.user.boldText }}</span>
        </span>
        <span *ngIf="model.user.subtext">{{ model.user.subtext }}</span>
        <span *ngIf="fyi.deleted" class="fyi-content-deleted">(Deleted)</span>
      </div>

      <div class="subtitle">
        <div class="link-name">{{ model.link?.linkName }}</div>
        <span class="dot" *ngIf="model.displayDot && !model.deleted">•</span>
        <span
          class="subtitle-content"
          *ngIf="model.subtitle"
          [ngClass]="{ 'subtitle-content-short': !(model.displayDot && !model.deleted) }"
        >
          {{ model.subtitle }}
        </span>
      </div>
    </div>
    <span *ngIf="model.deleted" class="deleted-note"> Link Deleted </span>
    <div class="actions" *ngIf="model.hasActions || model.isLinkExists">
      <ng-container *ngFor="let action of model.actions">
        <u-button
          [versionNumber]="2"
          [typeElement]="action.type"
          [sizeElement]="'small'"
          [label]="action.label"
          (click)="fyi.deleted ? undefined : onAction()"
          [disabled]="fyi.deleted"
        ></u-button>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #fallbackIconUser>
  <u-icon [model]="model.user?.fallbackIcon"></u-icon>
</ng-template>
