import { DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, forwardRef, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { STYLE_SERVICE } from '@local/ui-infra';
import { ArmEmulatorPopupComponent } from '@shared/components/arm-emulator-popup/arm-emulator-popup.component';
import { SessionExpirePopupComponent } from '@shared/components/session-expire-popup/session-expire-popup.component';
import { WorkerChangePopupComponent } from '@shared/components/worker-change-popup/worker-change-popup.component';
import { EmbedService } from '@shared/embed.service';
import {
  globalNativeMainRpcProvider,
  globalNativeServiceRpcProvider,
  globalServicesRpcProvider,
  LogService,
  NativeMainRpcService,
  WindowService,
} from '@shared/services';
import { SessionService } from '@shared/services/session.service';
import { StyleService } from '@shared/services/style.service';
import { SharedModule } from '@shared/shared.module';
import { AppComponent } from './app.component';
import { GoLinkRedirectComponent } from './bar/views/results-views/go-links/go-links-redirector.component';
import { GlobalErrorHandler } from './global-error-handler';
import { SlackInstallModule } from './install-slack/install-slack.module';
import { ZendeskInstallModule } from './install-zendesk/install-zendesk.module';
import { AppRoutingModule } from './routes/app-routing.module';

@NgModule({
  declarations: [AppComponent, SessionExpirePopupComponent, WorkerChangePopupComponent, ArmEmulatorPopupComponent, GoLinkRedirectComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    SlackInstallModule,
    ZendeskInstallModule
  ],

  providers: [
    globalNativeMainRpcProvider,
    globalNativeServiceRpcProvider,
    {
      provide: WindowService,
      useFactory: (host: NativeMainRpcService) => {
        return host ? new WindowService(host) : null;
      },
      deps: [NativeMainRpcService],
    },
    globalServicesRpcProvider,
    DecimalPipe,
    {
      provide: EmbedService,
      useFactory: () => (<any>window).__embedService,
    },
    SessionService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler, deps: [LogService] },
    { provide: GlobalErrorHandler, useExisting: forwardRef(() => ErrorHandler), deps: [LogService] },
    {
      provide: LogService,
      useFactory: () => {
        return new LogService((<any>window).__module.logger);
      },
      deps: [],
    },
    { provide: STYLE_SERVICE, useClass: StyleService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
