import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { BlobsService } from 'src/app/bar/services/blobs/blob.service';
import { observable } from '@local/common';
import { keyBy } from 'lodash';

export type UploadFileStatus = 'uploading' | 'completed' | 'error';
export type UpdatedStorageType = 'init' | 'add' | 'delete' | 'updateName' | 'updateStatus' | 'reset';

export interface BaseFile {
  blobId: string;
  name: string;
  mimeType: string;
  size?: number;
  status?: UploadFileStatus;
  icon?: string;
}

export interface StorageUpdated {
  followingId: string;
  type: UpdatedStorageType;
  blobId?: string;
}

export type AttachmentsStorage<T> = { [blobId: string]: T };

@Injectable()
export class BlobsStorageService<T extends BaseFile> {
  private storageByFollowingId: { [followingId: string]: AttachmentsStorage<T> } = {};
  private _updatedStorageByFollowingId$ = new ReplaySubject<StorageUpdated>(1);

  @observable
  get updatedStorageByFollowingId$() {
    return this._updatedStorageByFollowingId$.asObservable();
  }

  private set updatedStorageByFollowingId(value: StorageUpdated) {
    this._updatedStorageByFollowingId$.next(value);
  }

  constructor(private blobsService: BlobsService) {}

  initAttachments(followingId: string, attachments: T[]) {
    const storage = keyBy(attachments || [], 'blobId');
    this.storageByFollowingId[followingId] = storage;
    this.updatedStorageByFollowingId = { followingId, type: 'init' };
  }

  addAttachment(followingId: string, attachment: T) {
    if (!attachment.blobId) {
      return;
    }
    if (!this.storageByFollowingId[followingId]) {
      this.storageByFollowingId[followingId] = {};
    }
    this.storageByFollowingId[followingId][attachment.blobId] = attachment;
    this.updatedStorageByFollowingId = { followingId, type: 'add' };
  }

  getAttachment(followingId: string, blobId: string) {
    return this.storageByFollowingId[followingId]?.[blobId];
  }

  updateBlobId(followingId: string, prevBlobId: string, newBlobId: string): void {
    const attachment = this.storageByFollowingId[followingId]?.[prevBlobId];
    if (attachment) {
      attachment.blobId = newBlobId;
      delete this.storageByFollowingId[followingId][prevBlobId];
      this.addAttachment(followingId, attachment);
    }
  }

  updateAttachmentName(followingId: string, blobId: string, newName: string) {
    if (!this.storageByFollowingId[followingId]?.[blobId]) {
      return;
    }
    this.storageByFollowingId[followingId][blobId].name = newName;
    this.updatedStorageByFollowingId = { followingId, type: 'updateName', blobId };
  }

  updateAttachmentStatus(followingId: string, blobId: string, status: UploadFileStatus) {
    if (!this.storageByFollowingId[followingId]?.[blobId]) {
      return;
    }
    this.storageByFollowingId[followingId][blobId].status = status;
    this.updatedStorageByFollowingId = { followingId, type: 'updateStatus', blobId };
  }

  getAttachments(followingId: string, blobIds: string[]) {
    if (!blobIds?.length) {
      return [];
    }
    return blobIds.map((blobId) => this.getAttachment(followingId, blobId)).filter((a) => !!a);
  }

  getAttachmentsByFollowingId(followingId: string) {
    return Object.values(this.storageByFollowingId?.[followingId] || {}) || [];
  }

  deleteAttachment(followingId: string, blobId: string, queryParams: string = '') {
    if (!this.storageByFollowingId[followingId]?.[blobId]) {
      return;
    }
    delete this.storageByFollowingId[followingId][blobId];
    this.blobsService.delete({ ids: [blobId], queryParams });
    this.updatedStorageByFollowingId = { followingId, type: 'delete', blobId };
  }

  resetAttachments(followingId: string, currentBlobIds: string[], queryParams: string = '') {
    if (!this.storageByFollowingId?.[followingId]) {
      return;
    }
    this.deleteUnRelevantAttachments(followingId, currentBlobIds, queryParams);
    delete this.storageByFollowingId[followingId];
    this.updatedStorageByFollowingId = { followingId, type: 'reset' };
  }

  private deleteUnRelevantAttachments(followingId: string, currentBlobIds: string[], queryParams: string = '') {
    const currentBlobIdsSet = new Set(currentBlobIds || []);
    const unRelevantIds = Object.keys(this.storageByFollowingId[followingId] || {}).filter((id) => !currentBlobIdsSet.has(id));
    if (unRelevantIds?.length) {
      this.blobsService.delete({ ids: unRelevantIds, queryParams });
    }
  }
}
