import { Preferences } from '@local/client-contracts';
import { EmptyStateModel } from '@shared/components/app-empty-state/app-empty-state.component';
import { ConnectAppsModel } from '../../hub/connect-apps/connect-apps.component';

function getViewIconPath(view: string, theme: Preferences.Theme) {
  return `assets/empty-state/views/${view}/${view}-empty-state-${theme}.gif`;
}

export const EMPTY_STATE_VIEWS: Record<string, ConnectAppsModel> = {
  mail: {
    apps: [],
    title: { app: 'Connect your mail accounts for an aggregated view of your mailbox', launcher: 'Want to access your emails quickly?' },
    subtitle: { app: 'Connect your email apps now!', launcher: 'Connect your email apps now!' },
    icon: { lightUrl: getViewIconPath('mail', 'light'), darkUrl: getViewIconPath('mail', 'dark') },
    viewName: 'mail',
    disableAppsTitle: 'To connect your email contact workspace administrator',
  },
  files: {
    apps: [],
    title: {
      app: 'Connect with any of your accounts for an aggregated view of your files',
      launcher: 'Want to access your files quickly?',
    },
    subtitle: { app: 'Connect your apps now!', launcher: 'Connect all your apps now!' },
    icon: { lightUrl: getViewIconPath('files', 'light'), darkUrl: getViewIconPath('files', 'dark') },
    viewName: 'files',
  },
  people: {
    apps: [],
    appsHeader: {
      title: "Connect your company's HRIS tool",
      step: '1',
      lineBreakAt: 22,
    },
    title: {
      step: '2',
      app: 'Select the HRIS connection to use in this page.',
      launcher: 'Select the HRIS connection to use in this page.',
    },
    launcherConnect: {
      title: 'Want to access employee directory?',
      subtitle: 'Connect your HR app now!',
    },
    subtitle: { app: 'Connect your people now!', launcher: '' },
    icon: { lightUrl: getViewIconPath('people', 'light'), darkUrl: getViewIconPath('people', 'dark') },
    viewName: 'people',
  },
  analytics: {
    apps: [],
    title: {
      app: 'We are now collecting data for you.. Dashboards and reports will be displayed here soon',
      launcher: 'We are now collecting data for you.. Dashboards and reports will be displayed here soon',
    },
    subtitle: {
      app: 'Dashboards and reports will be displayed here soon',
      launcher: '',
    },
    icon: { lightUrl: '', darkUrl: '' },
    viewName: 'analytics',
  },
};

export const EMPTY_STATE_LIST: Record<string, EmptyStateModel> = {
  favorites: {
    title: 'Favorites',
    iconSvg: {
      lightUrl: './assets/bar/favorites/empty-state-favorites-light.json',
      darkUrl: './assets/bar/favorites/empty-state-favorites-dark.json',
    },
    iconType: 'lottie-animation',
    viewName: 'favorites',
    subtitle:
      'Star your most important resources as a Favorites and have quick access to them from your Homepage, Launcher, or this dedicated view.',
  },
};
