import { Experiences, Filters, Links } from '@local/client-contracts';
import { ApplicationsService } from '@shared/services/applications.service';
import { LinksService } from '@shared/services/links.service';
import { AssistantConst } from './assistant.const';
import { filterSourceWarning } from './assistant.content';
import { Injectable } from '@angular/core';
import { FilterSourceViewModel, SourceUnavailableState } from '../models/filter-source-view-model';
import { AssistantFilterPriority } from '../enum/assistant-filter-priority.enum';
import { keyBy } from 'lodash';
import { hasTimestampPassed } from '@local/ts-infra';
import { ManualPromise } from '@local/common';

@Injectable()
export class AssistantViewHelper {
  private linksByApp: { [appName: string]: { [linkName: string]: Links.DisplayItem } };
  private linksById: { [id: string]: Links.DisplayItem };
  private linksDataReady: ManualPromise<void> = new ManualPromise();

  constructor(
    private linksService: LinksService,
    private applicationsService: ApplicationsService
  ) {
    this.initLinksData();
  }

  async initFilterSourceView(
    filterSource: Experiences.FilterDataSource,
    assistant: Experiences.ExperienceItem,
    objectsShared: Experiences.ExperienceSharedObjects
  ) {
    await this.linksDataReady;
    const filterSourceView: FilterSourceViewModel = { filterSource };
    filterSourceView.readonly = assistant.permissionRole === 'viewer';
    const unavailableState: SourceUnavailableState = this.getDataSourceUnavailableState(
      filterSource,
      assistant.permissionRole === 'creator',
      objectsShared
    );
    filterSourceView.unavailableState = unavailableState;
    if (unavailableState) {
      filterSourceView.disabled = ['deleted', 'creatorNoAccess'].includes(unavailableState);
      if (unavailableState === 'noAccess') {
        filterSourceView.readonly = true;
      }
      if (['deleted', 'noAccess'].includes(unavailableState)) {
        filterSourceView.preventResourcesSearch = true;
      }
      filterSourceView.warningMessage = this.getUnavailableWarning(unavailableState, assistant.permissionRole);
    }
    this.updateLinkState(filterSourceView);
    return filterSourceView;
  }

  initEmptyFilterSourceView(): FilterSourceViewModel {
    const filterSource = this.getEmptyFilterSource();
    const filterSourceView: FilterSourceViewModel = { filterSource };
    return filterSourceView;
  }

  getEmptyFilterSource() {
    return { filters: {}, resources: [], tier: AssistantFilterPriority.SECOND };
  }

  updateLinkState(filterSourceView: FilterSourceViewModel) {
    const filters = filterSourceView.filterSource?.filters;
    const link = this.findLink(filters);
    if (!link) {
      return;
    }
    const isPrivate = link?.shareOptions?.level === 'Private';
    filterSourceView.linkState = {
      isPrivate,
    };
    if (filterSourceView.warningMessage) {
      //Display sync warning (stale/failed/connected in-24 hours) only if the link is available
      return;
    }
    filterSourceView.warningMessage = this.getSyncWarningMessage(link);
  }

  private getSyncWarningMessage(link: Links.DisplayItem) {
    const isStale = link?.syncStatus === 'stale';
    if (isStale) {
      return filterSourceWarning.staleLink;
    }
    const isFailedLink = link?.syncStatus === 'failed';
    if (isFailedLink) {
      return filterSourceWarning.failedLink;
    }
    const connectedRecently = link.syncStatus === 'completed' && !hasTimestampPassed(link?.createTime, { amount: 24, type: 'hour' });
    if (connectedRecently) {
      return filterSourceWarning.recentlyConnectedLink;
    }
  }

  resetLinkState(filterSourceView: FilterSourceViewModel) {
    filterSourceView.linkState = {};
    filterSourceView.warningMessage = null;
  }

  private getUnavailableWarning(unavailableState: SourceUnavailableState, permissionRole: Experiences.PermissionRole) {
    switch (unavailableState) {
      case 'deleted':
        return filterSourceWarning.deletedLink;
      case 'noAccess':
        return filterSourceWarning.noAccess;
      case 'creatorNoAccess':
        return permissionRole === 'editor' ? filterSourceWarning.creatorNoAccessForEditor : filterSourceWarning.creatorNoAccessForViewer;
    }
  }

  private initLinksData() {
    this.linksService.all$.subscribe((links) => {
      this.linksById = keyBy(links, 'id');
      const linksByApp: { [appName: string]: { [linkName: string]: Links.DisplayItem } } = {};
      for (const link of links) {
        const appName = this.applicationsService.apps[link.appId]?.name;
        if (!appName) {
          continue;
        }
        if (!linksByApp[appName]) {
          linksByApp[appName] = {};
        }
        linksByApp[appName][link.name] = link;
      }
      this.linksByApp = linksByApp;
      if (!this.linksDataReady.status) {
        this.linksDataReady.resolve();
      }
    });
  }

  private findLink(filters: Filters.Values): Links.DisplayItem {
    const appName = filters?.[AssistantConst.APP_FILTER_NAME]?.[0];
    const account = filters?.[AssistantConst.ACCOUNT_FILTER_NAME]?.[0];
    const link = this.linksByApp?.[appName]?.[account];
    return link;
  }

  private getDataSourceUnavailableState(
    filterSource: Experiences.FilterDataSource,
    isExperienceCreator: boolean,
    objectsShared: Experiences.ExperienceSharedObjects
  ): SourceUnavailableState {
    const backendFilters = filterSource.backendFilters;
    const linkIds = backendFilters?.linkId;
    if (!linkIds?.length) {
      return null;
    }
    const links = this.filterLinksByApps(linkIds, backendFilters?.appId || []);
    if (!links?.length) {
      return isExperienceCreator ? 'deleted' : 'noAccess';
    }
    if (isExperienceCreator) {
      return null;
    }
    const isRlpLink = links.some((l) => l.resourcePermissions?.enabled);
    if (isRlpLink) {
      return 'noAccess';
    }
    const creatorHasAccess = this.creatorHasAccess(linkIds, objectsShared);
    return creatorHasAccess ? null : 'creatorNoAccess';
  }

  private filterLinksByApps(linkIds: string[], appIds: string[]): Links.DisplayItem[] {
    const appValue = appIds?.[0];
    const matchLinks: Links.DisplayItem[] = [];
    for (const id of linkIds || []) {
      const link = this.linksById[id];
      if (!link) {
        continue;
      }
      if (appValue === link.appId) {
        matchLinks.push(link);
      }
    }
    return matchLinks;
  }

  private creatorHasAccess(linkIds: string[], objectsShared: Experiences.ExperienceSharedObjects): boolean {
    const linksSharedWithCreator = objectsShared?.links;
    const creatorHasAccess = linkIds.some((id) => linksSharedWithCreator?.has(id));
    return creatorHasAccess;
  }
}
