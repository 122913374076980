export type UTypeElement = 'primary' | 'secondary' | 'danger' | 'success';

export type UTypeText = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'regular' | 'paragraph' | 'code';

export type UTypeFormatText = 'capital' | 'upper' | 'lower';

export type UTypeWeightText = 'regular' | 'semi-bold' | 'bold' | 'extra-bold';

export type UTypeSizeElement = 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';

export type UTypeStateElement = 'regular' | 'hover' | 'active' | 'focus' | 'disabled' | 'readonly' | 'completed' | 'loading' | 'error';

// Missing: margin, padding, radius, border
