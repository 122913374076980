<div
  #contenteditable
  class="contenteditable"
  [attr.contenteditable]="true"
  [class.focus-no-color]="focusNoColor"
  (input)="handleInput($event)"
  (blur)="handleBlur()"
  (click)="handleClick()"
  (keydown)="handleKeydown($event)"
  (paste)="handlePaste($event)"
>
  <span class="placeholder">{{ placeholder }}</span>
</div>
