import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { PopupRef } from '@local/ui-infra';

export interface SlackActionButtonsPopupModel {
  popupTitle: string;
  title?: string;
  titleTooltip?: string;
  description?: string;
  descriptionTooltip?: string;
  actionButtons: { text: string; tooltip?: string }[];
}

@Component({
  selector: 'slack-action-buttons-popup',
  templateUrl: './slack-action-buttons-popup.component.html',
  styleUrl: './slack-action-buttons-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlackActionButtonsPopupComponent implements OnInit {
  model = signal<SlackActionButtonsPopupModel>(null);

  constructor(private ref: PopupRef<SlackActionButtonsPopupComponent, SlackActionButtonsPopupModel>) {}

  ngOnInit(): void {
    this.model.set(this.ref.data);
  }

  closePopup() {
    this.ref.destroy();
  }
}
