import { Injectable } from '@angular/core';
import { PopupRef, PopupService, UGalleriaModel } from '@local/ui-infra';
import { CreatePostPopupData, CreatePostWidget } from 'src/app/bar/views/home-page/components/create-post-popup/create-post-popup-data';
import { CreatePostPopupComponent } from 'src/app/bar/views/home-page/components/create-post-popup/create-post-popup.component';
import { ImageCropperPopupData, SizeImage } from '@shared/components/image-cropper-popup/image-cropper-popup-data';
import { ImageCropperPopupComponent } from '@shared/components/image-cropper-popup/image-cropper-popup.component';
import { HomeTabsService } from '../../../services/home-tabs.service';
import { HomeTabs, Permissions, Posts } from '@local/client-contracts';
import { generateId } from '@local/common-web';
import { take, takeUntil } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash';
import { BlobsService } from 'src/app/bar/services/blobs/blob.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { SETTINGS_WIDGET, WidgetsService } from './widgets.service';
import { Config } from '@environments/config';
import { AppPopupComponent, AppPopupData } from 'src/app/bar/components/app-popup/app-popup.component';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';

type CarouselCache = { postId: string; carousel: UGalleriaModel[] };
@Injectable()
export class PostWidgetService {
  private readonly WARNING_IMAGE_POPUP_DATA: AppPopupData = {
    message: 'Image sizes may change.',
    showButtons: true,
    rightButtonStyle: { type: 'primary' },
    messageStyle: { 'font-size': '14px' },
    popupStyle: { padding: '24px 32px' },
    content: {
      secondaryButton: 'Cancel',
      primaryButton: 'Save',
      title: 'Are you sure you want to <br> apply these changes?',
    },
  };
  private createPostPopupRef: PopupRef<CreatePostPopupComponent, CreatePostPopupData>;
  private cropImagePopupRef: PopupRef<ImageCropperPopupComponent, ImageCropperPopupData>;
  private warningImagePopupRef: PopupRef<AppPopupComponent, AppPopupData>;
  private createPostPopupData: CreatePostPopupData;
  private carouselCache: CarouselCache[] = [];

  constructor(
    private popupService: PopupService,
    private homeTabsService: HomeTabsService,
    private widgetsService: WidgetsService,
    private blobsService: BlobsService,
    private hubService: HubService,
    private showToasterService: ShowToasterService
  ) {}

  get popupsOpen() {
    return this.createPostPopupRef || this.cropImagePopupRef;
  }

  updatePost(tabId: string, widget: HomeTabs.Widget, clearCache: boolean, showToaster: boolean = true) {
    if (clearCache) {
      this.removeFromCache(widget.id);
    }
    this.widgetsService.update(tabId, [widget]).then(() => {
      if (!showToaster) return;
      this.showToasterService.showToaster({
        id: 'update-post',
        content: `Post ${(widget as HomeTabs.PostWidget)?.post?.title}  was updated successfully`,
      });
    });
  }

  private async addPostWidget(createPost: CreatePostWidget, isShared: boolean) {
    const widgetId: string = generateId();
    const post = createPost.post;
    const widget: HomeTabs.PostWidget = {
      post,
      tabId: createPost.tabId,
      id: widgetId,
      type: 'post',
      viewSetting: {
        cols: createPost.size === 'small' ? SETTINGS_WIDGET.minCols : SETTINGS_WIDGET.maxCols,
        rows: SETTINGS_WIDGET.baseRows,
        x: 0,
        y: 0,
      },
      isShared,
    };
    this.widgetsService.add(widget.tabId, widget, createPost.size === 'large', false).then(() => {
      this.showToasterService.showToaster({
        id: 'upload-post',
        content: `Post ${createPost.post.title}  was upload successfully`,
      });
    });
  }

  deletePost(post: HomeTabs.PostWidget, tabId: string) {
    this.widgetsService.delete(tabId, post.id);
  }

  // POPUPS SECTION
  openNewPostPopup(data?: CreatePostPopupData): void {
    this.createPostPopupData = cloneDeep(data);
    this.closePopups();
    this.createPostPopupRef = this.popupService.open<CreatePostPopupComponent, CreatePostPopupData>(
      'center',
      CreatePostPopupComponent,
      data,
      { closeOnClickOut: true, position: 'center', backdropStyle: 'blur-2', panelClass: 'create-post-popup' }
    );
    this.createPostPopupRef.compInstance.onCancel.subscribe(() => {
      this.createPostPopupRef.destroy();
      this.createPostPopupData = null;
    });
    this.createPostPopupRef.compInstance.onSaveState.subscribe((data: CreatePostPopupData) => {
      this.createPostPopupData = data;
    });
    this.createPostPopupRef.compInstance.switchToImageCropper.subscribe((sizeImage: SizeImage) => {
      this.openImageCropperPopup(sizeImage);
    });
    this.createPostPopupRef.compInstance.onPublish.subscribe(async (updatedPost: CreatePostWidget) => {
      if (updatedPost.warningImageSize) {
        this.openWarningPopup(data, updatedPost);
      } else {
        await this.onPublish(data, updatedPost);
      }
    });
    this.createPostPopupRef.outsidePointerEvents$.subscribe((e) => {
      // todo continue
    });
    this.createPostPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.createPostPopupRef = null;
    });
  }

  private async onPublish(original: CreatePostPopupData, updated: CreatePostWidget) {
    this.closePopups();
    if (!updated.post.carousel.length) {
      this.deletePost(original.currentPostWidget, updated.tabId);
    } else if (original.isEditMode) {
      const widget: HomeTabs.PostWidget = {
        ...original.currentPostWidget,
        post: updated.post,
        viewSetting: {
          ...original.currentPostWidget.viewSetting,
          cols: updated.size === 'small' ? SETTINGS_WIDGET.minCols : SETTINGS_WIDGET.maxCols,
        },
      };
      const currentWidget = this.createPostPopupData.currentPostWidget;
      const currentTabId = currentWidget.tabId;
      const newTabId = updated.tabId;
      const demoMove = updated.warningImageSize && updated.size === 'large';
      if ((newTabId && currentTabId !== newTabId) || demoMove) {
        await this.homeTabsService.moveWidgetToTab(
          widget,
          currentTabId,
          newTabId,
          this.createPostPopupData.isSharedHomePost,
          widget.viewSetting.cols === SETTINGS_WIDGET.maxCols,
          true
        );
        if (demoMove) {
          this.widgetsService.published = widget;
          return;
        }
      }
      const clearCache = this.checkChanges(updated, currentWidget);
      this.updatePost(
        newTabId,
        {
          ...widget,
          isShared: newTabId !== this.hubService.HOME_TAB_ID || this.createPostPopupData.isSharedHomePost,
          tabId: newTabId,
        },
        clearCache
      );
      this.widgetsService.published = widget;
    } else {
      this.addPostWidget(updated, this.createPostPopupData.isSharedHomePost);
    }
    const updatedTabId = await this.getRealTabId(updated.tabId);
    this.updateCarouselPermissions(updated.post.carousel, updatedTabId);
  }

  async openImageCropperPopup(sizeImage: SizeImage): Promise<void> {
    this.closePopups();
    const tabId = await this.getRealTabId(this.createPostPopupData.tab.id);
    this.cropImagePopupRef = this.popupService.open<ImageCropperPopupComponent, ImageCropperPopupData>(
      'center',
      ImageCropperPopupComponent,
      {
        imageToCrop: this.createPostPopupData.uploadedImage,
        tabId,
        carousel: this.createPostPopupData.carousel,
        carouselEditIndex: this.createPostPopupData.carouselEditItemIndex,
        size: sizeImage,
      },
      {
        closeOnClickOut: false,
        position: 'center',
        backdropStyle: 'blur-2',
      }
    );
    this.cropImagePopupRef.compInstance.onCancel.subscribe(() => {
      this.cropImagePopupRef.destroy();
      this.openNewPostPopup({ ...this.createPostPopupData, uploadedImage: null, sizeImage });
    });
    this.cropImagePopupRef.compInstance.onImageReady.subscribe((carousel: Posts.CarouselData[]) => {
      this.createPostPopupData.carousel = carousel;
      this.openNewPostPopup({ ...this.createPostPopupData, sizeImage });
    });
    this.cropImagePopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.cropImagePopupRef = null;
    });
  }

  closePopups() {
    const popupRefs = [this.createPostPopupRef, this.cropImagePopupRef, this.warningImagePopupRef];
    popupRefs.forEach((ref) => {
      ref?.destroy();
      ref = null;
    });
  }

  openWarningPopup(original: CreatePostPopupData, updated: CreatePostWidget) {
    if (this.warningImagePopupRef) {
      this.warningImagePopupRef.destroy();
    }
    this.warningImagePopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      this.WARNING_IMAGE_POPUP_DATA,
      {
        position: 'center',
      }
    );
    this.warningImagePopupRef.compInstance.primaryButton.pipe(takeUntil(this.warningImagePopupRef.destroy$)).subscribe(async () => {
      await this.onPublish(original, updated);
    });
    this.warningImagePopupRef.compInstance.secondaryButton.pipe(takeUntil(this.warningImagePopupRef.destroy$)).subscribe(() => {
      this.warningImagePopupRef.destroy();
      this.warningImagePopupRef = null;
    });
  }

  async getRealTabId(id: string, isPrivate?: boolean): Promise<string> {
    if (id === this.hubService.HOME_TAB_ID) {
      return this.homeTabsService.getHomeTabId();
    }
    return id;
  }

  updateCarouselPermissions(carouselData: Posts.CarouselData[], tabId: string) {
    const shareOptions: Permissions.ShareOptions = {
      level: 'Following',
      followingIds: [tabId],
    };
    for (const c of carouselData) {
      this.blobsService.update(c.imageId, null, null, shareOptions);
    }
  }

  saveCarouselInCache(carouselCacheItem: CarouselCache) {
    const index = this.carouselCache.findIndex((item) => item.postId === carouselCacheItem.postId);
    if (index !== -1) {
      this.carouselCache.splice(index, 1);
      this.carouselCache.push(cloneDeep(carouselCacheItem));
      return;
    }
    this.carouselCache.push(cloneDeep(carouselCacheItem));
    if (this.carouselCache.length > Config.widgetsCache.postWidgetLimit) {
      this.carouselCache.shift();
    }
  }

  private removeFromCache(postId: string) {
    this.carouselCache = this.carouselCache.filter((a) => a.postId !== postId);
  }

  getCarouselFromCache(postId: string) {
    return this.carouselCache.find((item) => item.postId === postId);
  }

  checkChanges(updatedPost: CreatePostWidget, currentWidget: HomeTabs.PostWidget): boolean {
    const hasDiffImages = !isEqual(
      updatedPost.post.carousel.map((a) => a.imageId),
      currentWidget?.post?.carousel?.map((a) => a.imageId)
    );
    const hasDiffLinks = !isEqual(
      updatedPost.post.carousel.map((a) => a.link),
      currentWidget?.post?.carousel?.map((a) => a.link)
    );
    return hasDiffImages || hasDiffLinks || updatedPost.size !== this.createPostPopupData.sizeImage;
  }
}
