<p-toggleButton
  #pToggleButton
  class="u-toggle-button"
  [(ngModel)]="checked"
  [onIcon]="onIcon() ? ICON_CLASS + onIcon() : null"
  [offIcon]="offIcon() || onIcon() ? ICON_CLASS + (offIcon() || onIcon()) : null"
  [onLabel]="onLabel()"
  [offLabel]="offLabel() || onLabel()"
  [styleClass]="styleClass()"
  [disabled]="stateElement() === 'disabled'"
  (onChange)="onChange.emit($event)"
></p-toggleButton>
