<div>
  <div class="side-panel">
    <u-tab-view
      class="tabs"
      [hasDelayTab]="false"
      [tabsStatic]="sidePanelTabs"
      [openLeftMenuBar]="true"
      (onChangeActiveTab)="setActiveTab($event.tab)"
      [selectedTabId]="selectedTab.id"
      [hideMoreOptions]="true"
    ></u-tab-view>
    <u-button
      class="button-new-tab"
      [versionNumber]="2"
      typeElement="secondary"
      fontIcon="launch"
      [sizeElement]="'extra-large'"
      (onClick)="openInNewTab()"
      [pTooltip]="'Open in Unleash'"
      tooltipPosition="bottom"
      tooltipStyleClass="u-tooltip"
    ></u-button>
  </div>

  <router-outlet></router-outlet>
</div>
