<div
  class="router-outlet-container"
  [ngClass]="{ 'router-outlet-isExtension': isExtension }"
  [style.display]="ready ? 'block' : 'none'"
  [style.height]="'100%'"
>
  <router-outlet> </router-outlet>
</div>

<iframe *ngFor="let x of swPrefetch" frame-src="self" style="display: none" [src]="x | safe: 'resource'"></iframe>

<ng-container #testim></ng-container>
