import { observable } from '@local/common';
import { cloneDeep, get, isEqual, pick } from 'lodash';
import { BehaviorSubject, distinctUntilChanged, firstValueFrom, map, Observable } from 'rxjs';
import { Lock } from 'semaphore-async-await';

export class ItemsCache<T> {
  private _itemsCache$ = new BehaviorSubject<{ [key: string]: T }>({});
  private updateCacheLock = new Lock();

  get itemsCache$() {
    return this._itemsCache$;
  }

  async updateItemsInCache(items: T[], idKey: string) {
    if (!items?.length) {
      return;
    }
    try {
      await this.updateCacheLock.acquire();
      const updatedCache = cloneDeep((await firstValueFrom(this.itemsCache$)) || {});
      for (const r of items || []) {
        if (!r) {
          continue;
        }
        const id = get(r, idKey);
        if (id) {
          updatedCache[id] = r;
        }
      }
      this._itemsCache$.next(updatedCache);
    } finally {
      this.updateCacheLock.release();
    }
  }

  @observable
  getItemsByIds$(ids: string[]): Observable<T[]> {
    return this.itemsCache$.pipe(
      map((all) => {
        const relevantItems = pick(all, ids);
        return Object.values(relevantItems);
      }),
      distinctUntilChanged((prev, current) => isEqual(prev, current))
    );
  }

  async resetCache() {
    try {
      await this.updateCacheLock.acquire();
      this._itemsCache$.next({});
    } finally {
      this.updateCacheLock.release();
    }
  }
}
