<div class="u-row-manager-container">
  @for (row of editingModel(); track trackByIndex; let idxLine = $index) {
    <div class="row-container">
      <ng-template *ngTemplateOutlet="rowTemplate; context: { $implicit: row, index: idxLine }"> </ng-template>
      @if (state() !== 'readonly') {
        <div class="buttons-container">
          @if (row.mode === 'constantRow') {
            <u-button
              [versionNumber]="2"
              fontIcon="edit"
              [styleType]="'outlined'"
              [stateElement]="state() === 'disabled' ? 'disabled' : 'regular'"
              (onClick)="editRow(idxLine)"
              tooltipPosition="top"
              [pTooltip]="'Edit'"
              tooltipStyleClass="u-tooltip"
            ></u-button>
            <u-button
              [versionNumber]="2"
              fontIcon="delete"
              [styleType]="'outlined'"
              [stateElement]="state() === 'disabled' ? 'disabled' : 'regular'"
              (onClick)="deleteRow(idxLine)"
              tooltipPosition="top"
              [pTooltip]="'Delete'"
              tooltipStyleClass="u-tooltip"
            ></u-button>
          } @else {
            @if (!(row.mode === 'newRow' && editingModelLength() === 1)) {
              <u-button
                [versionNumber]="2"
                fontIcon="Windows-close"
                [styleType]="'outlined'"
                (onClick)="cancelRow()"
                tooltipPosition="top"
                [pTooltip]="'Cancel'"
                tooltipStyleClass="u-tooltip"
              ></u-button>
            }
            <u-button
              [versionNumber]="2"
              fontIcon="check"
              [styleType]="'outlined'"
              [stateElement]="disableSaveIndex() == idxLine ? 'disabled' : 'regular'"
              (onClick)="saveRow(idxLine)"
              tooltipPosition="top"
              [pTooltip]="'Save'"
              tooltipStyleClass="u-tooltip"
            ></u-button>
          }
        </div>
      }
    </div>
  }
</div>

@if (state() !== 'readonly') {
  <u-button
    class="add-button"
    [versionNumber]="2"
    [label]="addButtonText()"
    fontIcon="mac-maximize-plus"
    [styleType]="'text'"
    [typeElement]="'primary'"
    [stateElement]="disableAddButton() || editingModelLength() >= limit() || state() === 'disabled' ? 'disabled' : 'regular'"
    [sizeElement]="'large'"
    (onClick)="addButton()"
  ></u-button>
}
