import { Injectable } from '@angular/core';
import { Config } from '@environments/config';
import { Commands, NavTree, Style, Workspace } from '@local/client-contracts';
import { observable } from '@local/common';
import { ContextMenuItem } from '@shared/components';
import { LogService } from '@shared/services';
import { Breadcrumb } from '@shared/services/breadcrumbs.service';
import { FlagsService } from '@shared/services/flags.service';
import { RouterService } from '@shared/services/router.service';
import { flatByProperty, unique } from '@shared/utils';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, map, mergeMap } from 'rxjs';
import { MainRoute, routes } from '../routes/main-routes-config';
import { sideBarOptionsData } from '../views/hub/components/settings-sidebar/sidebar-options.data';
import { HELP_MENU_ITEMS } from '../views/hub/shared/sidebar/menu-items';
import { GoToItem } from '../views/results/models/results-types';
import { NavTreeService } from './nav-tree.service';
import { SubscriptionsService } from './subscriptions.service';
import { WorkspacesService } from './workspaces.service';
@Injectable()
export class GoToService {
  public readonly helpItems$: BehaviorSubject<GoToItem[]> = new BehaviorSubject<GoToItem[]>([]);
  private logger: Logger;
  private workspace: Workspace.Workspace;
  private _gotoItems$ = new BehaviorSubject<GoToItem[]>(null);
  private isOwnerOrAdmin: boolean;

  private readonly BLACK_LIST: string[] = ['error', 'calendar'];
  private readonly NODE_DEPENDANT: string[] = ['assistants'];

  @observable
  get gotoItems$(): Observable<GoToItem[]> {
    return this._gotoItems$;
  }

  set gotoItems(items: GoToItem[]) {
    this._gotoItems$.next(items);
  }

  constructor(
    private navTreeService: NavTreeService,
    private routerService: RouterService,
    private subscriptionsService: SubscriptionsService,
    private flags: FlagsService,
    log: LogService,
    private workspaceService: WorkspacesService
  ) {
    this.logger = log.scope('GoToService');
    this.buildHelpItems();
    this.workspaceService.current$.subscribe((workspace) => {
      this.workspace = workspace;
    });
    this.workspaceService.ownerOrAdmin$.subscribe(async (s) => {
      this.isOwnerOrAdmin = s;
      await this.initRoutesItems();
    });
  }

  private initAssistantsRoutesItems(list: GoToItem[]): GoToItem[] {
    list = list.filter((a) => a.id.startsWith('assistants/'));
    const assistantNodes = Object.entries(this.navTreeService.nodes).filter(([key, _]) => key.startsWith('assistants/'));
    if (assistantNodes.length === list.length) {
      return list;
    }
    return list.filter((item) => assistantNodes.some(([key, _]) => key === item.id));
  }

  private async initRoutesItems() {
    const blackList = [...this.BLACK_LIST];
    // Static
    if (this.workspace && (!this.isOwnerOrAdmin || this.workspace.type === 'Personal')) {
      blackList.push('analytics');
    }
    const staticRoutes = (await this.getFlatRoutes())
      .filter((r) => !r.path?.includes(':') && r.path && !blackList.some((i) => r.data?.id?.toLowerCase() === i)) // Remove dynamic and black list
      .map((r) => this.routeToGoToItem(r))
      .filter((r) => !!r);

    // Nodes
    const dynamicNodesRoutes$ = this.navTreeService.roots$.pipe(
      mergeMap((nodes) => {
        const allNodes = nodes.flatMap((n) => [n, ...(n.children || [])]);
        return Promise.all(
          allNodes
            .filter((n) => !!n && !blackList.includes(n.id as Lowercase<string>) && n.type === 'standard')
            .map(async (n) => this.nodeToGotoItem(n, allNodes))
        );
      }) // conversion NavTree.Node -> GotoItem
    );
    combineLatest([this.routerService.active$.pipe(distinctUntilChanged()), dynamicNodesRoutes$, this.getSettingsItems()])
      .pipe(
        map(([route, nodes, settings]) => {
          let staticList = staticRoutes;
          for (const nodeId of this.NODE_DEPENDANT) {
            if (!nodes.find((a) => a.id === nodeId)) {
              staticList = staticList.filter((r) => r.id !== nodeId && !r.id.startsWith(`${nodeId}/`));
            } else {
              const assistantStaticItems = staticList.filter((route) => route.id.startsWith(`${nodeId}/`));
              staticList = staticList.filter((route) => !route.id.startsWith(`${nodeId}/`));
              staticList.push(...this.initAssistantsRoutesItems(assistantStaticItems));
            }
          }
          const items: GoToItem[] = unique([...nodes, ...staticList, ...settings], 'id', 'last');
          return items.filter((r) => route !== r.id); // Remove duplicates and current page
        })
      )
      .subscribe((items) => (this.gotoItems = items));
  }

  private async getFlatRoutes(): Promise<MainRoute[]> {
    const child = cloneDeep(routes[routes.length - 1].children);
    const flat = flatByProperty(child, 'children');
    const res = [];
    for (const item of flat) {
      if (item.data?.disableFlag) {
        const disabled = await this.flags.isEnabled(item.data?.disableFlag);
        if (disabled) {
          continue;
        }
      }
      res.push(item);
    }
    return res.slice(1, flat.length);
  }

  private routeToGoToItem(route: MainRoute): GoToItem {
    if (!route?.data) return;

    const { path } = route;
    const { id, icon, title } = route.data ?? {};
    return {
      id: id ?? path,
      command: { type: 'open-page', url: path } as Commands.OpenPageCommand,
      icon: icon ?? { type: 'font-icon', value: 'icon-external' },
      type: 'goto',
      title: title ?? path,
      subtitle: null,
      state: 'static',
    };
  }

  private async nodeToGotoItem(node: NavTree.Node, nodes: NavTree.Node[]): Promise<GoToItem> {
    const { id, title, type, data, parentId } = node;
    const breadcrumbs = Object.keys(data?.filters ?? {}).length > 1 ? await this.navTreeService.nodeToBreadcrumbs(node) : null;
    const titleAndSubtitleEqual = breadcrumbs?.length === 1 && breadcrumbs[0].title === title;
    const subtitle: Breadcrumb[] = titleAndSubtitleEqual ? null : breadcrumbs;
    return {
      type: 'goto',
      id,
      icon: this.getIcon(node, nodes),
      title,
      subtitle,
      command: { type: 'open-page', url: this.navTreeService.getNodeUrl(node) } as Commands.OpenPageCommand,
      state: type === 'standard' ? 'standard' : 'dynamic',
      parentId,
    };
  }

  private getIcon(node: NavTree.Node, nodes: NavTree.Node[]): Style.EntityIcon<Style.EntityIconType> {
    // TODO: resource icon fallback
    while (node) {
      if (node.icon) {
        return node.icon;
      }
      if (node.parentId && nodes.find((n) => n.id === node.parentId)) {
        node = nodes.find((n) => n.id === node.parentId);
        continue;
      }
      node = null;
    }
  }

  private async getSettingsItems() {
    const blackList: string[] = [];
    const workspace = this.workspace;
    if (!this.workspace?.isOwner || !workspace.hasPurchased || !workspace.plan || Config.isSelfHosted) {
      blackList.push('Your Plan');
    }
    const items: GoToItem[] = [];

    const openUrlCommand = { type: 'open-url' } as Commands.OpenUrl;
    const openPageCommand = { type: 'open-page' } as Commands.OpenPageCommand;

    sideBarOptionsData.forEach((sb) => {
      sb.sections
        .filter((route) => !blackList.includes(route.title))
        .forEach(async (route) => {
          const isPlanRoute = route.title === 'Your Plan';
          if (isPlanRoute) {
            openUrlCommand.url = (await this.subscriptionsService.getYourPlanUrl()).sessionUrl;
          } else {
            openPageCommand.url = `settings/${route.path}`;
          }

          items.push({
            id: route.title,
            title: route.title,
            subtitle: route.telemetry.replace('.', ' / '),
            icon: { type: 'font-icon', value: route.icon.value },
            type: 'goto',
            command: isPlanRoute ? { ...openUrlCommand } : { ...openPageCommand },
            state: 'static',
            parentId: 'settings',
          });
        });
    });
    return items;
  }

  private async buildHelpItems(): Promise<void> {
    const items = HELP_MENU_ITEMS.map((i) => this.helpToGoToItem(i)).filter((i) => !!i);
    this.helpItems$.next(items);
  }

  private helpToGoToItem(item: ContextMenuItem): GoToItem {
    if (!item || item.data?.page || item.data?.popup) return;
    const { id, icon, text: title } = item;
    const url = item.data?.url;
    if (!url) {
      this.logger.warn(`No url found for ${id}-${title}, it won't be built to the command bar`);
      return;
    }
    return {
      id,
      title,
      subtitle: `Help/${title}`,
      command: <Commands.OpenUrl>{ type: 'open-url', url },
      icon,
      type: 'goto',
      state: 'static',
      parentId: 'help_items',
    };
  }
}
