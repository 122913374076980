import { Injectable } from '@angular/core';
import { Search, Results } from '@local/client-contracts';
import { uniq } from 'lodash';
import { HubService } from 'src/app/bar/services/hub.service';
import { ResultsService } from 'src/app/bar/services/results.service';
import { SearchResults } from '../../results';
import { ItemsCache } from 'src/app/bar/services/items-cache';
import { ItemsCacheService } from 'src/app/bar/services/items-cache.service';

@Injectable()
export class ChatResourcesService {
  private readonly ID_KEY_CACHE = 'resource.externalId';
  private readonly CACHE_TYPE = 'chat-resources';
  private cacheResources: ItemsCache<Search.ResultResourceItem>;

  constructor(
    private resultsService: ResultsService,
    private hubService: HubService,
    private itemsCacheService: ItemsCacheService
  ) {
    this.cacheResources = this.itemsCacheService.getItemsCache(this.CACHE_TYPE);
  }

  async updateRemoteResources(externalIds: string[]) {
    const assistantId = await this.hubService.globalAssistantId;
    const uniqExternalIds = uniq(externalIds);
    const resources: Results.LinkResource[] = (uniqExternalIds || []).map((id) => ({ linkId: null, resourceId: null, externalId: id }));
    const getItemsRequest: Results.GetItemsRequest = {
      cache: 'first',
      resources,
      includeHiddenLinks: !!assistantId,
      idType: 'external',
      includeDeleted: true,
    };
    this.resultsService.getItems$(getItemsRequest).subscribe(async (res) => {
      this.cacheResources.updateItemsInCache(await this.updateResourcesAction(res), this.ID_KEY_CACHE);
    });
  }

  private async updateResourcesAction(res: Search.ResultResourceItem[]) {
    for (const r of res) {
      if (!r) {
        continue;
      }
      (r as SearchResults).action = await this.resultsService.getResultAction(r);
    }
    return res;
  }

  async updateResourceInCache(resources: Search.ResultResourceItem[]) {
    this.cacheResources.updateItemsInCache(resources, this.ID_KEY_CACHE);
  }

  getResourcesByIds$(ids: string[]) {
    return this.cacheResources.getItemsByIds$(ids);
  }

  async resetCache() {
    return this.cacheResources.resetCache();
  }
}
