import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationBehaviorOptions, NavigationEnd, Params } from '@angular/router';
import { Commands, NavTree, Omnibox, Window } from '@local/client-contracts';
import { observable } from '@local/common';
import { isEmbed, isNativeWindow, isExtension } from '@local/common-web';
import { capitalCase, isHttpOrHttps, isKey, isNullOrUndefined, keyCodes } from '@local/ts-infra';
import { PopUpOptions, PopupRef, PopupService } from '@local/ui-infra';
import { ContextMenuService } from '@shared/components';
import { EmbedService } from '@shared/embed.service';
import { HighlightStyle } from '@shared/pipes';
import { EventsService, LogService, WindowService } from '@shared/services';
import { AppService } from '@shared/services/app.service';
import { ApplicationsService } from '@shared/services/applications.service';
import { Breadcrumb, BreadcrumbsService } from '@shared/services/breadcrumbs.service';
import { ClientStorageService } from '@shared/services/client-storage.service';
import { DatePickerService } from '@shared/services/date-picker.service';
import { KeyboardService } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { SessionStorageService } from '@shared/services/session-storage.service';
import { FlagsService } from '@shared/services/testim-flags.service';
import { TitleBarService } from '@shared/services/title-bar.service';
import { isOpenPageCommand } from '@shared/utils';
import { Logger } from '@unleash-tech/js-logger';
import { isEmpty, isEqual } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, combineLatest, firstValueFrom, fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { GlobalErrorHandler } from 'src/app/global-error-handler';
import { SuggestResyncOverlayComponent } from '../components/suggest-resync-overlay/suggest-resync-overlay.component';
import { WalkthroughGalleryComponent } from '../components/walkthrough-gallery/walkthrough-gallery.component';
import { MenuItem, WEB_SEARCH_ITEMS } from '../views/hub/shared/sidebar/menu-items';
import { GoToItem } from '../views/results/models/results-types';
import { PageType } from '../views/results/models/view-filters';
import { SearchPopupItem } from '../views/special-views/search-popup/model';
import { CommandBarService } from './command-bar.service';
import { CommandsService } from './commands/commands.service';
import { ExperienceErrorsService } from './experience-errors.service';
import { GoToService } from './go-to.service';
import { NavTreeService } from './nav-tree.service';
import { TagsService } from './tags.service';
import { WorkspacesService } from './workspaces.service';
import { AvatarListService } from './avatar-list.service';
import moment from 'moment';
import { DateFormat } from '@shared/consts';
import { getTimeFromNowInText } from '@shared/utils/date-format.util';
import { PARAMS_STATE_KEYS } from './search-params.service';

const WALKTHROUGH_KEY = 'walkthroughStep';
const WALKTHROUGH_LOCAL_KEY = 'WALKTHROUGH_STEP';
const WALKTHROUGH_MAX_STEPS = 8;
const INSTRUCTIONS_KEY = 'INSTRUCTIONS_SEEN';
export type FocusState = { value: boolean; force: boolean; multi: boolean };
export type SearchMethod = 'Quick-Search' | 'Search-On-Enter';
export type FocusPosition = 'filters' | 'searchBar' | 'results';
export type TimePhrases = { createdBy?: string; createdTime?: number; modifiedBy?: string; modifiedTime?: number };

@Injectable()
export class HubService {
  disableLoaderRemoval: boolean;

  private readonly _loading$: BehaviorSubject<boolean>;
  private readonly _placeholder$: BehaviorSubject<string>;
  private readonly _textCleared$: Subject<boolean>;
  readonly HOME_TAB_ID = 'home';

  private _readonly$: BehaviorSubject<boolean>;
  private _intercom$: BehaviorSubject<boolean>;
  private _panelVisible$: BehaviorSubject<boolean>;
  private _autoCompleteEnabled$: BehaviorSubject<boolean>;
  private _suggestionsEnabled$: BehaviorSubject<boolean>;
  private _autoFocus$: BehaviorSubject<boolean>;
  private _state$: BehaviorSubject<{ [name: string]: string[] }>;
  private _overlayShown$: BehaviorSubject<boolean>;
  private _inputQuery$: BehaviorSubject<string>;
  private _isLauncher$: ReplaySubject<boolean>;

  private _openCollection$: Subject<void>;
  private _queryParams: Params = {};
  private logger: Logger;
  public context: any = {};
  public fullFocus: boolean;
  private history: string[] = [];
  private viewReady: { [id: string]: BehaviorSubject<boolean> };
  private shouldSetViewReady: { [id: string]: boolean };
  private _preventSearch$: BehaviorSubject<boolean>;
  private routerNavigation$: Observable<NavigationEnd>;
  private lazyModules = ['settings'];
  private _location: string;
  private currentRoute: string;
  private goToCommandBarSubscription: Subscription;
  private isEmbed = isEmbed();
  private isNative = isNativeWindow();
  private isExtension = isExtension();
  private helpSubscription: Subscription;
  private currentNode: NavTree.Node;
  private _enableCollectionIconFunc;
  private dialogRef: PopupRef<SuggestResyncOverlayComponent | WalkthroughGalleryComponent, any>;
  private _instructionsShown$ = new BehaviorSubject<boolean>(undefined);
  private _searchMethod: SearchMethod;
  private _suggestionsDropdownVisible = false;
  private _enablePagesSuggestion = true;

  public readonly defaultPage = 'search';
  private readonly PAGE_STATE_KEY: string = 'ap';
  private _focusInputState$ = new BehaviorSubject<boolean>(false);
  private _focusInput$: Subject<FocusState> = new Subject();
  private _focusPosition$ = new BehaviorSubject<FocusPosition>('searchBar');
  stateChangedTime: number;
  windowStyle: Window.WindowStyle;
  isBarMode: boolean;
  private _globalAssistantId$ = new ReplaySubject<string>(1);

  canFocus(force?: boolean) {
    if (this.suggestionsDropdownVisible || this.datePickerService.isVisible || (!force && !this.autoFocus) || this.popupService.hasDialog) {
      return false;
    }

    return this.fullFocus;
  }

  constructor(
    private eventsService: EventsService,
    private route: ActivatedRoute,
    private titleBarService: TitleBarService,
    private navTreeService: NavTreeService,
    private commandsService: CommandsService,
    private commandBarService: CommandBarService,
    private breadcrumbsService: BreadcrumbsService,
    private routerService: RouterService,
    private apps: ApplicationsService,
    private appService: AppService,
    log: LogService,
    private datePickerService: DatePickerService,
    private tagsService: TagsService,
    private goToService: GoToService,
    private contextMenuService: ContextMenuService,
    private embedService: EmbedService,
    private keyboardService: KeyboardService,
    private popupService: PopupService,
    private sessionStorageService: SessionStorageService,
    private clientStorage: ClientStorageService,
    private flagsService: FlagsService,
    private experienceErrorsService: ExperienceErrorsService,
    private globalError: GlobalErrorHandler,
    private windowService: WindowService,
    private workspaceService: WorkspacesService,
    private avatarListService: AvatarListService
  ) {
    this.logger = log.scope('HubService');
    this.routerNavigation$ = this.routerService.navigation$.pipe(filter<NavigationEnd>((e) => e instanceof NavigationEnd));
    this._readonly$ = new BehaviorSubject<boolean>(false);
    this._intercom$ = new BehaviorSubject<boolean>(true);
    this._panelVisible$ = new BehaviorSubject<boolean>(true);
    this._autoCompleteEnabled$ = new BehaviorSubject<boolean>(true);
    this._suggestionsEnabled$ = new BehaviorSubject<boolean>(true);
    this._autoFocus$ = new BehaviorSubject<boolean>(true);
    this._loading$ = new BehaviorSubject(false);
    this._placeholder$ = new BehaviorSubject('');
    this._overlayShown$ = new BehaviorSubject<boolean>(undefined);
    this._inputQuery$ = new BehaviorSubject<string>('');
    this._isLauncher$ = new ReplaySubject<boolean>(1);
    this._state$ = new BehaviorSubject({});
    this._textCleared$ = new Subject();
    this._openCollection$ = new Subject();

    this.viewReady = {};
    this.shouldSetViewReady = {};
    this._preventSearch$ = new BehaviorSubject(false);
    this._state$.subscribe((qp) => {
      this._queryParams = qp;
      this.inputQuery = this.query;
    });
    this.initRouteMetadata();
    this.initModifierUsageListener();
    this.registerRoutesToCommandBar();
    this.registerHelpToCommandBar();
    this.registerWebSearchToCommandBar();
    this.initBreadcrumbsLogic();
    this.initCurrentAssistant();

    this.routerService.queryParams$.subscribe((p) => {
      this.onParamsChanges(p);
    });
    this.routerService.activeRoute.data.subscribe((data) => {
      this.currentNode = data?.node;
    });
    this.contextMenuService.closed$.subscribe(() => this.changeFocusStateMultiCalls(true, false));

    this.routerService.data$.subscribe(({ node }) => {
      this.currentNode = node;
    });

    this.routerService.navigated$.subscribe((url) => {
      let redUrl = url;
      if (!isHttpOrHttps(url || '')) {
        redUrl = `${window.location.origin}${url || ''}`;
      }
      const urlParams = new URL(redUrl).searchParams;
      const query = urlParams.get('q');
      if (query != this.inputQuery) {
        this.inputQuery = query;
      }
    });

    this.appService.windowStyle$.subscribe(async (b) => {
      this.windowStyle = b;
      this.isBarMode = location.pathname == '/bar';
      const isLauncher = b != 'standard' || this.isBarMode;
      this._isLauncher$.next(isLauncher);
    });
  }

  setViewReady(componentName: string): void {
    if (!this.viewReady[componentName]) {
      this.shouldSetViewReady[componentName] = true;
      return;
    }
    this.viewReady[componentName].next(true);
  }

  registerViewReady(componentName: string): BehaviorSubject<boolean> {
    const shouldSetViewReady: boolean = this.shouldSetViewReady[componentName];
    this.viewReady[componentName] = new BehaviorSubject(shouldSetViewReady);
    if (shouldSetViewReady) delete this.shouldSetViewReady[componentName];
    return this.viewReady[componentName];
  }

  initModifierUsageListener() {
    fromEvent<KeyboardEvent>(window, 'keydown').subscribe((event) => {
      if (!(isKey(event, keyCodes.shift) || isKey(event, keyCodes.control) || isKey(event, keyCodes.alt) || isKey(event, keyCodes.meta))) {
        this.eventsService.event('keypress', {
          target: `${event.shiftKey ? 'Shift + ' : ''}${event.ctrlKey ? 'Ctrl + ' : ''}${event.altKey ? 'Alt + ' : ''}${
            event.metaKey ? 'Meta + ' : ''
          }${event.key}`,
          label: this.query,
          location: { title: this.currentLocation },
        });
      }
    });
  }

  @observable
  get changeFocusInput$(): Subject<FocusState> {
    return this._focusInput$;
  }

  @observable
  get focusInputState$(): Observable<boolean> {
    return this._focusInputState$;
  }

  get focusInputState(): boolean {
    return this._focusInputState$.value;
  }

  set focusInputState(value: boolean) {
    this._focusInputState$.next(value);
  }

  @observable
  get preventSearch$(): Observable<boolean> {
    return this._preventSearch$;
  }

  @observable
  get instructionsShown$(): Observable<boolean> {
    return this._instructionsShown$;
  }

  @observable
  get activePage$(): Observable<string> {
    return this.state$.pipe(
      map((s) => s?.ap),
      distinctUntilChanged()
    );
  }

  set preventSearch(value: boolean) {
    if (this.preventSearch === value) return;

    this._preventSearch$.next(value);
  }

  get preventSearch(): boolean {
    return this._preventSearch$.getValue();
  }

  get isLauncher$() {
    return this._isLauncher$.asObservable();
  }

  getIsLauncher(): Promise<boolean> {
    return firstValueFrom(this._isLauncher$);
  }

  get activeRoute$() {
    // Resolving the component data might be problematic when it's nested in other outlet
    // this hack finds the right 'route' node
    // https://github.com/angular/angular/issues/11812
    function traceCurrent(route: ActivatedRoute) {
      while (route.firstChild) route = route.firstChild;
      return route;
    }
    return this.routerNavigation$.pipe(
      map(() => this.route),
      map((route) => traceCurrent(route)),
      startWith(traceCurrent(this.route)), // Takes care of the initial state (after refresh / first load)
      filter((route) => route.outlet === 'primary')
    );
  }

  get currentLocation(): string {
    if (this.currentNode?.data?.location) return this.currentNode?.data?.location;
    if (this._location) return this._location;
    const irrelevantSegments = ['b', this.routerService.prefix];
    const active = this.routerService.activeRoute;
    const location = active?.snapshot?.data?.node?.data?.location;
    if (location) {
      return location;
    }
    const url = active?.snapshot?.url;
    if (this.currentNode?.location) {
      return this.currentNode.location;
    }
    const cleanPath = url?.reduce(
      (acc, curr) => (irrelevantSegments.includes(curr.path) ? acc : acc ? (acc += '/' + curr) : (acc += curr)),
      ''
    );
    return cleanPath || 'home';
  }

  set currentLocation(location: string) {
    this._location = location;
  }

  @observable
  get overlayShown$(): Observable<boolean> {
    return this._overlayShown$.pipe(
      filter((s) => s != undefined),
      distinctUntilChanged()
    );
  }

  set overlayShown(value: boolean) {
    this._overlayShown$.next(value);
  }

  get overlayShown(): boolean {
    return this._overlayShown$.value;
  }

  get enableCollectionIconFunc() {
    if (!this._enableCollectionIconFunc) {
      return false;
    }
    return this._enableCollectionIconFunc();
  }

  set enableCollectionIconFunc(func) {
    this._enableCollectionIconFunc = func;
  }

  get suggestionsDropdownVisible() {
    return this._suggestionsDropdownVisible;
  }
  set suggestionsDropdownVisible(v: boolean) {
    this._suggestionsDropdownVisible = v;
  }

  get openCollection() {
    return this._openCollection$;
  }

  get activePage(): PageType {
    return this.state[this.PAGE_STATE_KEY]?.[0];
  }

  get searchMethod(): SearchMethod {
    return this._searchMethod;
  }

  set searchMethod(method: SearchMethod) {
    this._searchMethod = method;
  }

  get focusPosition(): FocusPosition {
    return this._focusPosition$.value;
  }

  get focusPosition$(): Observable<FocusPosition> {
    return this._focusPosition$;
  }

  set focusPosition(value: FocusPosition) {
    this._focusPosition$.next(value);
  }

  get enablePagesSuggestion(): boolean {
    return this._enablePagesSuggestion;
  }
  set enablePagesSuggestion(value: boolean) {
    this._enablePagesSuggestion = value;
  }

  get globalAssistantId() {
    return firstValueFrom(this._globalAssistantId$.pipe(distinctUntilChanged()));
  }

  private initRouteMetadata() {
    const getPath = (route: ActivatedRoute) => route?.snapshot?.url?.map((u) => u.path)?.join('/');

    const didUrlChanged = (next: ActivatedRoute) => {
      if (this.lazyModules.includes(next.snapshot.data?.parent)) return false;
      return getPath(next) !== this.currentRoute;
    };

    // TODO: move activeRoute$ logic outside
    combineLatest([this.routerService.activeRoute$.pipe(pairwise()), this.overlayShown$]).subscribe(
      async ([[prevRoute, nextRoute], shown]: [[ActivatedRoute, ActivatedRoute], boolean]) => {
        if (!prevRoute || !nextRoute || shown === null || shown) return;

        const { data } = nextRoute.snapshot;
        const { suggestionsEnabled, intercom, pageViewName, readOnly, disableLoaderRemoval } = data || {};

        this.intercom = intercom !== false;
        this.titleBarService.locationTitle = this.currentLocation;
        if (didUrlChanged(nextRoute)) {
          this._suggestionsEnabled$.next(suggestionsEnabled === undefined ? true : suggestionsEnabled);
          this.currentRoute = getPath(nextRoute);
          const location = pageViewName || this.currentLocation;
          const isEmbedNotShown = this.isEmbed && !this.embedService.shown;
          if (!isEmbedNotShown && location !== 'signin') {
            this.eventsService.event('pageview', { location: { title: location } });
          }
        }
        this.disableLoaderRemoval = disableLoaderRemoval;
        if (readOnly !== undefined && this.readOnly !== readOnly) {
          this.readOnly = readOnly;
        }

        const node = data?.node;
        const isLauncher = await this.getIsLauncher();
        if (isLauncher && node?.id && node.id != 'search') {
          const tags = this.tagsService.all;
          if (!tags.find((t) => t.id === data.id)) {
            const pageTag = {
              icon: node.icon,
              id: node.id,
              label: '',
              title: node.title,
              type: 'launcher-active-page',
            };
            this.tagsService.all = [pageTag];
          }
        }
      }
    );
  }

  changeFocusState(value: boolean, force = true) {
    this._focusInput$.next({ value, force, multi: false });
  }

  changeFocusStateMultiCalls(value: boolean, force = true) {
    this._focusInput$.next({ value, force, multi: true });
  }

  clearState() {
    this.loading = false;
    this.fullFocus = true;
    this.changeFocusState(true);
    this.context = {};
    this.panelVisible = true;
    this.currentLocation = undefined;
    if (Object.keys(this.state)?.length) {
      this._state$.next({});
    }
    this.clearQueryParams();
    this.tagsService.reset();
  }

  public get panelVisible(): boolean {
    return this._panelVisible$.value;
  }

  @observable
  public get panelVisible$(): Observable<boolean> {
    return this._panelVisible$;
  }

  public set panelVisible(v: boolean) {
    this._panelVisible$.next(v);
  }

  public get readOnly(): boolean {
    return this._readonly$.value;
  }

  public set readOnly(v: boolean) {
    this._readonly$.next(v);
  }

  @observable
  public get readOnly$(): Observable<boolean> {
    return this._readonly$;
  }

  @observable
  public get textCleared$(): Observable<boolean> {
    return this._textCleared$;
  }

  set textCleared(v: boolean) {
    this._textCleared$.next(v);
  }

  @observable
  public get autoCompleteEnabled$(): Observable<boolean> {
    return this._autoCompleteEnabled$.pipe(distinctUntilChanged());
  }

  public get autoCompleteEnabled(): boolean {
    return this._autoCompleteEnabled$.value;
  }

  public set autoCompleteEnabled(v: boolean) {
    this._autoCompleteEnabled$.next(v);
  }

  public get suggestionsEnabled(): boolean {
    return this._suggestionsEnabled$.value;
  }

  public set suggestionsEnabled(v: boolean) {
    this._suggestionsEnabled$.next(v);
  }

  public get autoFocus(): boolean {
    return this._autoFocus$.value;
  }

  @observable
  public get autoFocus$(): Observable<boolean> {
    return this._autoFocus$;
  }

  public set autoFocus(v: boolean) {
    this._autoFocus$.next(v);
  }

  @observable
  public get intercom$(): Observable<boolean> {
    return this._intercom$.pipe(distinctUntilChanged());
  }

  set intercom(v: boolean) {
    this._intercom$.next(v);
  }

  private onParamsChanges(params: Params) {
    const nparams = {};
    for (const x of Object.entries(params)) {
      if (!x[1]) continue;
      nparams[x[0]] = !(x[1] instanceof Array) ? [x[1]] : x[1];
    }

    if (Object.keys(nparams)?.length || !isEqual(nparams, this.state)) {
      this._state$.next({ ...nparams });
    }
  }

  get query$(): Observable<string> {
    return this.state$.pipe(
      map((qp) => {
        return qp.q ? qp.q[0] : '';
      }),
      distinctUntilChanged()
    );
  }

  async openHomePage(q?: string, params?: { [name: string]: string | string[] }, addModulePrefix = true) {
    const isLauncher = await this.getIsLauncher();
    const path = isLauncher || this.isEmbed ? '/search' : '/';
    return this.openPage(path, q, params, addModulePrefix);
  }

  openPage(url: string, q?: string, state?: any, addModulePrefix = true) {
    if (q || state) {
      const query = new URLSearchParams();
      if (url.indexOf('?') == -1) url += '?';
      else if (!url.endsWith('?')) url += '&';
      if (q) query.set('q', q);
      if (state)
        Object.entries(state).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((v) => {
              if (!isNullOrUndefined(v)) {
                query.append(key, v.toString());
              }
            });
          } else {
            if (!isNullOrUndefined(value)) {
              query.set(key, value.toString());
            }
          }
        });
      url += query.toString();
      this.inputQuery = q || state.q;
    }
    if (url && !url.startsWith('?') && !url.startsWith('/')) url = '/' + url;
    return this.routerService.navigateByUrl(url, { skipLocationChange: false, replaceUrl: isEmbed() }, addModulePrefix);
  }

  @observable
  get placeholder$(): Observable<string> {
    return this._placeholder$;
  }

  //Bar base items
  get placeholder() {
    return this._placeholder$.value;
  }

  set placeholder(value: string) {
    if (this.placeholder !== value) {
      this._placeholder$.next(value);
    }
  }

  //Bar base items
  get query(): string {
    return this.getState('q')?.[0];
  }

  set query(value: string) {
    this.setState('q', !value ? null : value);
    this.inputQuery = value;
  }

  get inputQuery$(): Observable<string> {
    return this._inputQuery$;
  }

  get inputQuery(): string {
    return this._inputQuery$.value;
  }

  set inputQuery(value: string) {
    if (this.inputQuery === value) {
      return;
    }
    this._inputQuery$.next(value);
    if (this.searchMethod === 'Quick-Search') {
      this.query = value;
    }
  }

  get suggestionQuery(): string {
    return this.getState('sq')?.[0];
  }

  set suggestionQuery(value: string) {
    this.setState('sq', !value ? null : value);
  }

  public notifyTextCleared(selected: boolean) {
    this.textCleared = selected;
  }

  public getState(p: string): string[] {
    return this._state$.value[p] || [];
  }

  stateWithParams$(params: string[]): Observable<any> {
    let first = false;
    return this.state$.pipe(
      map((state) => {
        const mapState = {};
        Object.entries(state || {}).forEach(([k, v]) => {
          if (params.includes(k)) mapState[k] = v;
        });
        if (isEmpty(mapState)) {
          return null;
        }
        return mapState;
      }),
      filter((s) => {
        if (!!s && !first) {
          first = true;
        }
        return first;
      }),
      distinctUntilChanged((prev, current) => isEqual(prev, current))
    );
  }

  setState(p: string, v: string | string[]) {
    if (typeof v == 'string') v = [v];
    if (v?.length == 0) v = null;

    const qp = { ...this._queryParams };
    let changed = false;
    if (v) {
      for (const x of v) {
        if (qp[p]?.includes(x)) continue;
        changed = true;
      }

      for (const x of qp[p] || []) {
        if (v?.includes(x)) continue;
        changed = true;
      }

      if (changed) qp[p] = [...v];
    } else if (qp[p]) {
      qp[p] = null;
      changed = true;
    }

    if (changed) {
      this._queryParams = qp;
      this.stateChangedTime = Date.now();
      this.routerService.navigate(
        [],
        {
          relativeTo: this.routerService.activeRoute,
          queryParamsHandling: 'merge',
          queryParams: qp,
          skipLocationChange: this.isEmbed,
          replaceUrl: true,
        },
        false
      );
    }
  }

  public removeMultiState(removeStateKeys: string[]) {
    for (const key of removeStateKeys) {
      this.setState(key, null);
    }
  }

  public routerNavigate(url: string) {
    this.routerService.navigate(
      [url],
      {
        queryParamsHandling: 'merge',
        queryParams: this._queryParams,
        replaceUrl: true,
      },
      false
    );
  }

  public setStateAll(qp, mergeQp = false) {
    if (mergeQp) {
      qp = { ...this._queryParams, ...qp };
    }
    this._queryParams = qp;
    this.routerService.navigate(
      [],
      {
        relativeTo: this.routerService.activeRoute,
        queryParamsHandling: mergeQp ? 'merge' : '',
        queryParams: qp,
        skipLocationChange: this.isEmbed,
        replaceUrl: true,
      },
      false
    );
  }

  clearQueryParams(paramToKeep?: string[]) {
    if (paramToKeep) {
      const newQp = {};
      paramToKeep.forEach((param) => {
        newQp[param] = this._queryParams[param];
      });
      this._queryParams = newQp;
    } else {
      this._queryParams = {};
    }
    this.routerService.navigate(
      [],
      {
        relativeTo: this.routerService.activeRoute,
        queryParams: this._queryParams,
        skipLocationChange: this.isEmbed,
        replaceUrl: true,
      },
      false
    );
  }

  set loading(v: boolean) {
    this._loading$.next(v);
  }

  @observable
  get loading$(): Observable<boolean> {
    return this._loading$;
  }

  get loading(): boolean {
    return this._loading$.value;
  }

  get state(): Record<string, Array<any>> {
    return this._state$.value;
  }

  @observable
  get state$(): Observable<any> {
    return this._state$;
  }

  removeState(name: string, value?: string) {
    let v = this.state[name];
    if (!v || !v.includes(Array.isArray(value) ? value[0] : value)) return;
    v = (Array.isArray(v) ? v : [v]).filter((x) => x != value);
    this.setState(name, v);
  }

  addState(name: string, values: string[]) {
    const v = [...(this.state[name] || [])];
    values = values.filter((val) => !v.includes(val));
    if (!values.length) return;
    v.push(...values);
    this.setState(name, v);
  }

  private navIndex = 0;
  private isHistoryNavigation: boolean;

  get canGoBack() {
    return !!this.history[this.navIndex - 1];
  }

  get canGoForward() {
    return !!this.history[this.navIndex + 1];
  }

  goBack() {
    this.isHistoryNavigation = true;
    this.navIndex--;
    const previous: string = this.history[this.navIndex];
    this.routerService.navigateByUrl(previous);
  }

  goForward(): void {
    this.isHistoryNavigation = true;
    this.navIndex++;
    const next: string = this.history[this.navIndex];
    this.routerService.navigateByUrl(next);
  }

  private registerHelpToCommandBar() {
    const id = 'help_items';
    this.goToService.helpItems$.subscribe((children) => {
      if (this.helpSubscription) {
        this.helpSubscription.unsubscribe();
        this.helpSubscription = null;
      }
      this.commandBarService.remove(id);
      this.helpSubscription = this.commandBarService
        .add({
          id,
          children: children.map((c) => this.GoToItemToSearchPopupItem(c)),
          command: null,
          icon: null,
          title: 'Help',
          type: 'parent',
          visibility: null,
          data: { sortId: 'HELP' },
        })
        .subscribe(({ item }) => this.onCommandBarSelect(item));
    });
  }

  private registerWebSearchToCommandBar() {
    // Web Search
    const items = WEB_SEARCH_ITEMS.map((i) => this.webSearchToPopUpItem(i));
    if (!items.length) return;
    let initialRegister = false;
    const id = 'web_search_items';
    combineLatest([this.routerService.active$, this.routerService.activeRoute$])
      .pipe(
        map(([_, route]) => {
          const data = route.snapshot?.data;
          if (typeof data?.engine?.title === 'string') {
            return items.filter(
              (i) =>
                !i.id
                  .split('_')
                  .map((i) => i.toLowerCase())
                  .includes(data.engine.title.toLowerCase())
            );
          } else {
            return items;
          }
        })
      )
      .subscribe((children) => {
        this.commandBarService.remove(id);

        this.commandBarService
          .add({
            id,
            children: children,
            command: null,
            icon: null,
            title: 'Web',
            type: 'parent',
            visibility: null,
            data: { sortId: 'WEB_SEARCH' },
          })
          .subscribe(({ item }) => this.onCommandBarSelect(item));
        if (!initialRegister) {
          initialRegister = true;
        }
      });
  }

  private registerRoutesToCommandBar() {
    const groupId = 'navigation-items-parent';

    this.goToService.gotoItems$.subscribe(async (children) => {
      if (!children) {
        return;
      }
      if (this.goToCommandBarSubscription) {
        this.goToCommandBarSubscription.unsubscribe();
        this.goToCommandBarSubscription = undefined;
      }
      this.commandBarService.remove(groupId); // remove previous on update
      this.goToCommandBarSubscription = this.commandBarService
        .add({
          id: groupId,
          title: 'Go To',
          visibility: null,
          children: children.map((c) => this.GoToItemToSearchPopupItem(c)),
          type: 'parent',
          command: null,
          icon: null,
          data: { sortId: 'GOTO' },
        })
        .subscribe(({ item }) => this.onCommandBarSelect(item));
    });
  }

  private GoToItemToSearchPopupItem(goToItem: GoToItem): SearchPopupItem<'child'> {
    const { command, icon, id, title, subtitle, state } = goToItem;
    return {
      command,
      icon,
      id,
      title,
      type: 'child',
      visibility: state === 'static' ? 'always' : this.navTreeService.getVisibilityToSearchPopupItem(id, state === 'standard'),
      subtitle,
    };
  }

  async openUrl(url: string, extras?: NavigationBehaviorOptions, openExternal?: boolean, isAux?: boolean) {
    const isLauncher = await this.getIsLauncher();
    if (this.isExtension) {
      this.routerService.navigateByUrl(url, extras ,true ,true);
      return;
    }
    if (this.isEmbed) {
      return this.embedService.openUrl(url);
    }
    if (isLauncher || (isAux && this.isNative)) {
      return this.windowService.switchToStandard(url);
    }
    if (openExternal) {
      return window.open(url, '_blank');
    }
    this.routerService.navigateByUrl(url, extras);
  }

  async getTimePhrases(
    format: TimePhrases
  ): Promise<{ timeText: string; name: string; fullDetailsTime: string; preDisplay: 'created' | 'updated' }> {
    const displayModifiedTime = getTimeFromNowInText(format.modifiedTime)?.toLowerCase();
    let modifiedTimeActual = moment(format.modifiedTime)?.format(DateFormat.FULL_DATE_HOURS_A);
    let startModifiedPhrase: string;

    const accountId = format.modifiedBy;

    if (this.workspaceService.isMe(accountId)) {
      startModifiedPhrase = 'You';
    } else {
      const avatar = await this.avatarListService.getOwnerAvatar(accountId);
      startModifiedPhrase = capitalCase(avatar.name.replace('(Owner)', ''));
      if (avatar.isNameFromEmail) {
        modifiedTimeActual = `${avatar.email} ${modifiedTimeActual}`;
      }
    }

    return {
      name: startModifiedPhrase,
      timeText: displayModifiedTime,
      fullDetailsTime: modifiedTimeActual,
      preDisplay: format.modifiedTime === format.createdTime ? 'created' : 'updated',
    };
  }

  private async onCommandBarSelect({ command }: SearchPopupItem<'child'>) {
    if (isOpenPageCommand(command)) {
      this.tagsService.reset();
      this.clearState();
      // We need to call it here because results component is getting destroyed after navigation and doesn't reset tags (depends on updateState)
    }
    await this.commandsService.executeCommand(command);
    if (isOpenPageCommand(command)) {
      this.addState('search-trigger', ['command_bar']);
    }
    return;
  }

  private webSearchToPopUpItem(item: MenuItem): SearchPopupItem<'child'> {
    if (!item) return;

    const { title, icon, page } = item;

    return {
      id: `web-search_${title.toLowerCase()}`,
      title,
      command: <Commands.OpenPageCommand>{ type: 'open-page', url: page },
      icon,
      type: 'child',
      subtitle: 'Web Search',
      visibility: 'always',
    };
  }

  private initBreadcrumbsLogic() {
    this.routerService.activeRoute$.pipe(filter((route) => !!route?.snapshot?.data)).subscribe(async (route) => {
      const { data, url, params } = route.snapshot;
      const { node, title, id, icon, engine } = data ?? {};
      // Nodes
      if (node) {
        this.breadcrumbsService.items = await this.navTreeService.nodeToBreadcrumbs(node);
        return;
      }

      // Web Search
      if (engine) {
        const { title, icon } = engine;
        this.breadcrumbsService.items = [{ path: url.join('/'), title, icon }];
        return;
      }

      //full wiki card
      if (params?.param === 'a') {
        return;
      }

      // Apps
      if (params?.appId) {
        const { appId } = params;

        await this.apps.cacheLoaded;
        const { icon, name } = (await this.apps.one(appId)) ?? {};

        const all: Breadcrumb = { title: 'Connect Apps', path: 'connect/', icon: { value: 'icon-apps', type: 'font-icon' } };

        const app: Breadcrumb = {
          icon: { type: 'img', value: icon },
          title: name,
          path: url.join('/'),
        };

        const items = [all, app];

        if (title) {
          items.push({ title, path: url.join('/') });
        }

        this.breadcrumbsService.items = items;
        return;
      }

      // Default
      const path = '/' + url.map((s) => s.path).join('/');
      this.breadcrumbsService.items = [{ path, title: title ?? id ?? path, icon }];
    });
  }

  suggestionsToTag(s: Omnibox.Suggestion): Omnibox.Tag {
    return {
      id: s.id,
      icon: s.icon,
      label: '',
      title: s.title,
      type: 'active-page',
    };
  }

  async addActivePage(pageName: string, query?: string, assistantId?: string) {
    this.tagsService.all = [];
    let url = `/search?${this.PAGE_STATE_KEY}=${pageName}`;
    if (assistantId) {
      url += `&${PARAMS_STATE_KEYS.assistantId}=${assistantId}`;
    }
    if (query?.length) {
      url += `&q=${query}`;
    }
    this._state$.next({ ap: [pageName] });
    return this.routerService.navigateByUrl(url, { replaceUrl: this.isEmbed }, false);
  }

  removeActivePage(): boolean {
    const currentPage = this.activePage;
    if (!currentPage) {
      return false;
    }
    this.removeState(this.PAGE_STATE_KEY, currentPage);
    return true;
  }

  async getHighlightStyle(): Promise<HighlightStyle> {
    const isLauncher = await this.getIsLauncher();
    return this.isEmbed || isLauncher || this.currentLocation === 'home' ? 'bold' : 'default';
  }

  showDialog(
    dialogType: new (...args: any[]) => SuggestResyncOverlayComponent | WalkthroughGalleryComponent,
    name: 'SuggestResync' | 'instructions',
    observeable: Subject<boolean>,
    options: PopUpOptions = {},
    data?: any
  ) {
    if (this.dialogRef) return;
    this.dialogRef = this.popupService.open('center', dialogType, data, {
      ...options,
      closeOnClickOut: false,
      fullScreenDialog: true,
    });
    observeable.next(true);
    this.preventSearch = true;
    this.keyboardService.preventKeyboard = true;
    this.dialogRef.destroy$.subscribe(() => {
      observeable.next(false);
      this.reset();
    });

    this.dialogRef.compInstance.close.pipe(takeUntil(this.dialogRef.destroy$)).subscribe(() => {
      this.dialogRef.destroy();
      if (name === 'instructions') {
        this.storeWalkthroughState(WALKTHROUGH_MAX_STEPS + 1);
      }
    });

    if (name === 'instructions') {
      this.dialogRef.compInstance.updateStorage.subscribe((res) => {
        this.storeWalkthroughState(res);
      });
    }
  }

  private storeWalkthroughState(step: number, storeRemote = true) {
    if (step === undefined) return;
    this.sessionStorageService.getStore('local', 'account').entry<number>(WALKTHROUGH_LOCAL_KEY).set(step);
    if (storeRemote) {
      this.sessionStorageService.getStore('roaming', 'account').entry<number>(WALKTHROUGH_KEY).set(step);
    }
  }

  private reset() {
    this.keyboardService.preventKeyboard = false;
    this.preventSearch = false;
  }

  async initInstructions() {
    this.reset();
    // TODO: in case of browser extension we need to findout the real keyboard shortcut somehow
    let step = await this.sessionStorageService.getStore('local', 'account').entry<number>(WALKTHROUGH_LOCAL_KEY).get();
    //support for the last version - stored in the client storage
    if (!step || step <= WALKTHROUGH_MAX_STEPS) {
      step = await this.sessionStorageService.getStore('roaming', 'account').entry<number>(WALKTHROUGH_KEY).get();

      let storeRemote = false;
      if (!step) {
        const clientStorageValue: number | boolean = await this.clientStorage.get(INSTRUCTIONS_KEY);
        if (typeof clientStorageValue === 'number') {
          step = clientStorageValue;
        } else if (clientStorageValue === true) {
          step = WALKTHROUGH_MAX_STEPS + 1;
        }
        storeRemote = true;
      }
      this.storeWalkthroughState(step, storeRemote);
    }
    if (this.flagsService.isFlagOn('noInstructionsPopup')) {
      step = WALKTHROUGH_MAX_STEPS + 1;
    }
    const isLauncher = await this.getIsLauncher();
    if (this.isEmbed || isLauncher || step > WALKTHROUGH_MAX_STEPS) {
      this._instructionsShown$.next(false);
      return;
    }
    this._instructionsShown$.next(false);
    return;
    this.showDialog(
      WalkthroughGalleryComponent,
      'instructions',
      this._instructionsShown$,
      {
        backdropClass: 'walkthrough-backdrop',
        position: 'center',
      },
      { index: step || 1, maxSteps: WALKTHROUGH_MAX_STEPS }
    );
  }

  openStandardEmbed(url?: string, isSearch?: boolean) {
    url = url || `/${this.routerService.currentFullUrl}`;
    const isSearchPage = isSearch || url.startsWith('/search');
    this.embedService.openAuxWindow({
      url,
      hideOnClickOut: false,
      size: {
        small: { height: '600px', width: '910px' },
        medium: { height: '600px', width: '910px' },
        large: { height: '800px', width: '1200px' },
      },
      minHeight: 536,
      minWidth: 800,
      maxWidth: 1200,
      clearOnHide: true,
      isSearchPage,
    });
  }

  private async initCurrentAssistant() {
    if (!this.isEmbed) {
      this._globalAssistantId$.next(null);
      return;
    }
    this.embedService.options$.subscribe((op) => {
      if (!['search-page', 'quick-search'].includes(op.type)) {
        this._globalAssistantId$.next(null);
        return;
      }
      this._globalAssistantId$.next(op.assistantId || null);
    });
    let errorsSub: Subscription;
    this._globalAssistantId$.subscribe(async (id) => {
      if (errorsSub) {
        errorsSub.unsubscribe();
      }
      if (!id) {
        return;
      }

      errorsSub = this.experienceErrorsService.getErrorById$(id).subscribe((err) => {
        this.globalError.error = err?.message;
        this.routerService.navigateByUrl('/assistant-error');
      });
    });
  }
}
