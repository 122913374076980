import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { QuickLinks, Permissions } from '@local/client-contracts';
import { ManualPromise } from '@local/common';
import { generateId } from '@local/common-web';
import { PopupRef, UiIconModel, UInputComponent, FileUploadPreview } from '@local/ui-infra';
import { EventsService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { isHttpOrHttps, isValidPatternUrl, KeyName } from '@local/ts-infra';
import { isEmpty } from 'lodash';
import { BlobsService } from 'src/app/bar/services/blobs/blob.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { QuickLinksService } from 'src/app/bar/services/quick-links.service';
import { UrlResolverService } from 'src/app/bar/services/url-resolver.service';
import { ErrorQuickLinksConstants } from './error.const';

export interface QuickLinkViewModel {
  url: string;
  name: string;
  isShareWorkspace: boolean;
  imageUrl: string;
  blobId?: string;
}

export interface ErrorsViewModel {
  errorUrl: string;
  errorName: string;
}

@Component({
  selector: 'create-quick-links-popup',
  templateUrl: './create-quick-links-popup.component.html',
  styleUrls: ['./create-quick-links-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateQuickLinksPopupComponent implements OnDestroy {
  private readonly DEFAULT_ICON = `${window.location.origin}/assets/auth/icon-link.svg`;
  private readonly REGEX_NAME: string = '^[a-zA-Z0-9 _-]+$';
  readonly closeIcon: UiIconModel = {
    type: 'font',
    value: 'icon-Windows-close',
  };
  readonly accept = ['image/png', 'image/jpeg', 'image/svg+xml'];
  private model: QuickLinks.QuickLink;
  private lastMetaDataUrl = '';
  private keyHandlerId: string;
  private onClickSaveButton = false;
  private afterViewInitPromise: ManualPromise<void> = new ManualPromise();
  editQuickLink = false;
  viewModel: QuickLinkViewModel;
  errors: ErrorsViewModel;
  focusName: boolean;
  disabledButton = true;
  loadingButton = false;
  iconFile: FileUploadPreview = { url: this.DEFAULT_ICON };

  @ViewChild('inputUrl') inputUrl: UInputComponent;

  private get isEmptyPopup(): boolean {
    return !this.viewModel?.url && !this.viewModel?.name;
  }

  private get notErrorsPopup(): boolean {
    return !this.errors?.errorName && !this.errors?.errorUrl;
  }

  constructor(
    private ref: PopupRef<CreateQuickLinksPopupComponent, QuickLinks.QuickLink>,
    private cdr: ChangeDetectorRef,
    private hubService: HubService,
    private eventsService: EventsService,
    private keyboardService: KeyboardService,
    private urlResolverService: UrlResolverService,
    private quickLinksService: QuickLinksService,
    private blobsService: BlobsService
  ) {
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys: Array<KeyName>, event) => this.handleKeys(keys, event), 9);
  }

  ngOnInit(): void {
    this.initData();
  }

  ngAfterViewInit(): void {
    this.afterViewInitPromise.resolve();
  }

  ngOnDestroy(): void {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
      this.keyHandlerId = null;
    }
    if (!this.onClickSaveButton && !this.editQuickLink) {
      this.quickLinksService.delete(this.model.id);
    }
  }

  private async initData(): Promise<void> {
    this.errors = {
      errorName: '',
      errorUrl: '',
    };

    const inputData: QuickLinks.QuickLink = this.ref.data;

    this.viewModel = {
      url: inputData?.url || '',
      name: inputData?.name,
      isShareWorkspace: true,
      imageUrl: inputData?.imageUrl || '',
      blobId: inputData?.blobId,
    };

    if (!isEmpty(inputData)) {
      this.editQuickLink = true;
      this.model = inputData;
      this.viewModel.isShareWorkspace = this.model.isShared;
    } else {
      this.model = this.convertToQuickLink();
      this.quickLinksService.create(this.model);
    }

    this.iconFile = { url: this.model.imageUrl ? this.model.imageUrl : this.DEFAULT_ICON };
    this.cdr.markForCheck();
    await this.afterViewInitPromise;
    this.initFocus();
  }

  private initFocus() {
    this.focusName = !!this.editQuickLink;
    if (!this.editQuickLink) {
      this.inputUrl?.inputElement?.el.nativeElement.focus();
    }
    this.cdr.markForCheck();
  }

  async saveQuickLink(): Promise<void> {
    if (this.disabledButton) return;
    this.sendImpressionEvent(
      this.editQuickLink ? 'update' : 'add',
      this.quickLinksService.isOwnerOrAdmin && this.viewModel.isShareWorkspace ? 'shared' : 'private'
    );
    this.onClickSaveButton = true;
    return this.save();
  }

  private async save() {
    const actions: QuickLinks.UpdateAction[] = [];
    let isShared = false;
    if (this.viewModel.url !== this.model.url) {
      actions.push({
        field: 'url',
        type: 'Update',
        value: this.viewModel.url,
      });
    }
    if (this.viewModel.name !== this.model.name) {
      actions.push({
        field: 'name',
        type: 'Update',
        value: this.viewModel.name,
      });
    }
    if (this.viewModel.imageUrl !== this.model.imageUrl) {
      actions.push({
        field: 'imageUrl',
        type: 'Update',
        value: this.viewModel.imageUrl,
      });
    }
    if (this.viewModel.blobId && this.viewModel.blobId !== this.model.blobId) {
      actions.push({
        field: 'blobId',
        type: 'Update',
        value: this.viewModel.blobId,
      });
    }
    if (!this.editQuickLink && this.viewModel.isShareWorkspace && this.quickLinksService.isOwnerOrAdmin) {
      isShared = true;
    }
    return this.quickLinksService.update(this.model.id, actions, isShared, this.editQuickLink);
  }

  private convertToQuickLink(): QuickLinks.QuickLink {
    return {
      id: this.model?.id || generateId(),
      type: 'quick-link',
      url: this.viewModel.url,
      name: this.viewModel.name,
      blobId: this.viewModel.blobId,
      imageUrl: this.viewModel.imageUrl,
      isShared: this.viewModel.isShareWorkspace,
    };
  }

  closePopup(target?: string): void {
    if (target) {
      this.sendImpressionEvent(target, this.quickLinksService.isOwnerOrAdmin && this.viewModel.isShareWorkspace ? 'shared' : 'private');
    }
    if (!this.isUpdatedPopup()) {
      this.quickLinksService.closeCreateQuickLinkPopup();
      return;
    }
    this.quickLinksService.openWarningPopup();
  }

  isUpdatedPopup(): boolean {
    if (!this.editQuickLink) return !this.isEmptyPopup;
    const updated = this.quickLinksService.isEqual(this.model, this.convertToQuickLink());
    return !isEmpty(updated);
  }

  onUrlChange(event: string): void {
    if (this.viewModel.url?.length > 1 && event === '') {
      this.sendImpressionEvent('clear', this.quickLinksService.isOwnerOrAdmin && this.viewModel.isShareWorkspace ? 'shared' : 'private');
    }
    this.viewModel.url = event;
    if (event === '') {
      this.inputUrl?.inputElement?.el?.nativeElement?.focus();
    }
  }

  onNameChange(event: string): void {
    if (this.viewModel.name?.length > 1 && event === '') {
      this.sendImpressionEvent('clear', this.quickLinksService.isOwnerOrAdmin && this.viewModel.isShareWorkspace ? 'shared' : 'private');
    }
    this.viewModel.name = event?.trim();
    if (event === '') {
      this.focusName = true;
      this.cdr.markForCheck();
    }
    this.validateName();
  }

  onClickCheckbox(event: PointerEvent): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.editQuickLink) return;
    this.viewModel.isShareWorkspace = !this.viewModel.isShareWorkspace;
    this.updateDisabledButton();
    this.sendImpressionEvent(
      this.quickLinksService.isOwnerOrAdmin && this.viewModel.isShareWorkspace ? 'workspace' : 'private',
      'share_option'
    );
  }

  onFocus(target): void {
    this.sendImpressionEvent(`quick_link_${target}`);
  }

  updateDisabledButton() {
    this.disabledButton = !(this.isUpdatedPopup() && this.notErrorsPopup && !!this.viewModel.url && !!this.viewModel.name);
    this.cdr.markForCheck();
  }

  async onBlurUrl() {
    if (!this.viewModel.url) {
      this.errors.errorUrl = ErrorQuickLinksConstants.EMPTY_URL;
      this.disabledButton = true;
      this.loadingButton = false;
      this.cdr.markForCheck();
      this.sendImpressionEventError('enter_url');
      return;
    }
    this.loadingButton = true;
    this.cdr.markForCheck();
    this.viewModel.url = isHttpOrHttps(this.viewModel.url) ? this.viewModel.url : `https://${this.viewModel.url}`;
    if (!this.isValidUrl()) {
      this.loadingButton = false;
      return;
    }

    this.updateDisabledButton();

    const host = new URL(this.viewModel.url).origin;
    if (this.lastMetaDataUrl !== host) {
      const data = await this.urlResolverService.resolveSite(this.viewModel.url);
      this.viewModel.name = data?.siteName;
      this.viewModel.imageUrl = data?.icon || this.viewModel.imageUrl;
      if (!this.viewModel.blobId) {
        this.iconFile = { url: this.viewModel.imageUrl ? this.viewModel.imageUrl : this.DEFAULT_ICON };
      }
      this.lastMetaDataUrl = host;
      this.updateDisabledButton();
    }
    this.loadingButton = false;
    this.cdr.markForCheck();
  }

  private isValidUrl(): boolean {
    const url = this.viewModel.url;
    this.errors.errorUrl = '';
    if (!isValidPatternUrl(url)) {
      this.errors.errorUrl = ErrorQuickLinksConstants.INVALID_URL;
      this.sendImpressionEventError('invalid_url');
      this.disabledButton = false;
      return false;
    }
    return true;
  }

  private validateName() {
    this.focusName = false;
    this.errors.errorName = '';

    const name = this.viewModel.name;
    if (!name) {
      this.errors.errorName = ErrorQuickLinksConstants.EMPTY_NAME;
      this.disabledButton = true;
      this.focusName = true;
      this.cdr.markForCheck();
      this.sendImpressionEventError('enter_name');
      return;
    }

    if (!name.match(this.REGEX_NAME)) {
      this.errors.errorName = ErrorQuickLinksConstants.INVALID_NAME;
      this.disabledButton = true;
      this.focusName = true;
      this.cdr.markForCheck();
      this.sendImpressionEventError('invalid_name');
      return;
    }

    this.updateDisabledButton();
    this.focusName = true;
    this.cdr.markForCheck();
  }

  onClickUpload() {
    this.sendImpressionEvent('quick_link_image');
  }

  async onUpload(file: File) {
    const { name, type } = file;
    try {
      const shareOptions: Permissions.ShareOptions = {
        level: 'Following',
        followingIds: [this.model.id],
      };
      const blobId = (await this.blobsService.create(name, type, shareOptions))?.id;
      const uploadResponse = await this.blobsService.upload(file, blobId);
      if (uploadResponse.status >= 400) {
        this.onUploadError(name);
        return;
      }
      this.viewModel.blobId = blobId;
      this.updateDisabledButton();
      this.cdr.markForCheck();
    } catch {
      this.onUploadError(name);
    }
  }

  onUploadError(name?: string, errorMessage?: string) {
    this.errors.errorName = errorMessage ? errorMessage : `Upload of image "${name}" failed`;
    this.cdr.markForCheck();
    this.sendImpressionEventError('unsupported_image');
  }

  private sendImpressionEvent(target: string, label?: string): void {
    this.eventsService.event('quick_links.action', {
      name: 'edit_quick_link',
      location: { title: this.hubService.currentLocation },
      label,
      target,
    });
  }

  private sendImpressionEventError(label: string): void {
    this.eventsService.event('quick_links.errors', {
      target: 'edit_quick_link',
      location: { title: this.hubService.currentLocation },
      label,
    });
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    event.stopPropagation();
    if (keys.length === 2 && keys[0] === 'tab' && keys[1] === 'shift' && event.target?.['parentElement']?.id === 'inputUrl') {
      event.preventDefault();
    }
    const key = keys[0];
    switch (key) {
      case 'escape': {
        event.preventDefault();
        this.closePopup();
        break;
      }
      case 'tab': {
        if (
          event.target?.['parentElement']?.id === 'createButton' ||
          (this.disabledButton && event.target?.['parentElement']?.id === 'cancel')
        ) {
          this.inputUrl.inputElement.el.nativeElement.focus();
          event.preventDefault();
        }
        break;
      }
      default: {
        break;
      }
    }
  }
}
