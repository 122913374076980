import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { URowManagerComponent } from './u-row-manager.component';
import { UInputModule } from '../u-input/u-input.module';
import { UButtonModule } from '../u-button/u-button.module';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [URowManagerComponent],
  imports: [CommonModule, UInputModule, UButtonModule,  TooltipModule],
  exports: [URowManagerComponent],
})
export class URowManagerModule {}
