import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SessionInfo } from '@local/client-contracts';
import { INIT_POSITION_POPUP, PopupRef, PopupService, UiIconModel, UInputComponent } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SessionService } from '@shared/services/session.service';
import { filter, firstValueFrom, map, startWith } from 'rxjs';
import { ResultsService } from 'src/app/bar/services/results.service';
import { SearchOptions, SearchResultContext, SearchService, SearchSession } from 'src/app/bar/services/search';
import { cloneDeep } from 'lodash';
import { performanceCheckpoint } from '@local/common';
import { BrowseBarModel } from 'src/app/bar/components/browse-bar/browse-bar.component';
import { ChatsService } from '../../../chat-page/services/chats.service';
import { RouterService } from '@shared/services/router.service';
import { ServicesRpcService } from '@shared/services';
import { LoaderService } from '@shared/loader.service';
import {
  HomeSearchPopupComponent,
  HomeSearchPopupData,
} from '../../../home-search-popup/components/home-search-popup/home-search-popup.component';
import { isEnterKey, KeyName } from '@local/ts-infra';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { HomeSearchPopupService } from '../../../home-search-popup/services/home-search-popup.service';
import { HomeSearchSettings } from '../../../home-search-popup/home-search-settings';
import { LinkResourcesSourceSettings } from 'src/app/bar/services/search/client';
import { LinksService } from '@shared/services/links.service';

@UntilDestroy()
@Component({
  selector: 'home-side-panel',
  templateUrl: './home-side-panel.component.html',
  styleUrls: ['./home-side-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeSidePanelComponent implements OnInit {
  readonly subTitleMessage = 'How can I help you today?';
  readonly ENTER_INDICATION_INPUT_PADDING: number = 134;
  readonly INPUT_PADDING: number = 30;
  private readonly HOME_SIDE_PANEL_TAB_ID = 'home-side-panel';
  private readonly BROWSE_BAR_TELEMETRY = 'home_side_panel_filter_suggestion';
  private popupRef: PopupRef<HomeSearchPopupComponent, HomeSearchPopupData>;
  private searchSession: SearchSession;
  private query: string;
  userFirstName: string;
  search: string;
  searchIcon: UiIconModel = { type: 'font', value: 'icon-search-icon-fixed' };
  placeHolder = 'Search or ask anything';
  displayedContext: SearchResultContext;
  isSearchLoading: boolean;
  browseBarModel: BrowseBarModel = { items: [], isAppsItemsModel: true, redirectPath: '/search', telemetryName: this.BROWSE_BAR_TELEMETRY };
  stickyHeader = false;
  browseBarFetched = false;
  isSearchPopupOpen = false;
  selectedItem: boolean;

  @ViewChild('searchElement') searchElement: UInputComponent;

  constructor(
    private sessionService: SessionService,
    private searchHomeSidePanelService: HomeSearchPopupService,
    private searchService: SearchService,
    private cdr: ChangeDetectorRef,
    private popupService: PopupService,
    private chatsService: ChatsService,
    private routerService: RouterService,
    private services: ServicesRpcService,
    private resultsService: ResultsService,
    private loaderService: LoaderService,
    private keyboardService: KeyboardService,
    private linksService: LinksService
  ) {
    this.linksService.all$.pipe(untilDestroyed(this), startWith(null));
    this.searchSession = this.searchService.getOrCreateSearchSession(this.HOME_SIDE_PANEL_TAB_ID);
  }

  ngOnInit(): void {
    this.loaderService.ready$.next(false);
    this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 0);
    this.loadUserInfo();
    this.initBarItems();
  }

  checkViewReady() {
    if (this.browseBarFetched) {
      this.loaderService.ready$.next(true);
      this.focusSearchInput();
    }
  }

  private initBarItems() {
    this.services
      .observable('results.appbaritems$')
      .pipe(
        untilDestroyed(this),
        filter((res) => res)
      )
      .subscribe((items) => {
        performanceCheckpoint('bar_items_ready');
        this.browseBarModel.items = this.resultsService.getFilteredBarApps(items);
        this.browseBarFetched = true;
        this.checkViewReady();
        this.cdr.markForCheck();
      });
  }

  private loadUserInfo(): void {
    this.sessionService.current$
      .pipe(
        untilDestroyed(this),
        filter((session) => !!session),
        map((session: SessionInfo) => session.user)
      )
      .subscribe((u) => {
        performanceCheckpoint('user_fname_ready');
        this.userFirstName = u.firstName || '';
      });
  }

  async onSearch($event: string) {
    let isDefaultSearch = false;
    this.query = $event;
    this.search = this.query;
    if (!this.query || this.query.length <= 0 || this.query === '') {
      this.search = '';
      setTimeout(() => {
        this.searchFocus();
      }, 0);
      isDefaultSearch = true;
    }
    const options = await this.getSearhOptions(this.query);
    this.searchSession
      .search$(options)
      .pipe(untilDestroyed(this))
      .subscribe((res: SearchResultContext) => {
        let ctx = cloneDeep(res);
        if (!ctx.sources || ctx.sources.some((s) => !s.done && !['link-resources', 'web-search'].includes(s.source.type))) {
          return;
        }
        const linkSearch = ctx.sources?.find((x) => x.source.type == 'link-resources');
        if (linkSearch && !linkSearch.done) ctx.sources = ctx.sources.filter((x) => x.source.type != 'web-search');

        const groupSourcesDone = ctx.sources
          .filter((s) => s.source.type != 'web-search')
          .every((s) => s.done || (s.source.type === 'link-resources' && s.extra?.localDone));

        const sliceGroupsSize = !isDefaultSearch && groupSourcesDone;
        this.displayedContext = this.searchHomeSidePanelService.getSearchResults(this.query, ctx, sliceGroupsSize);

        if (this.search || isDefaultSearch) {
          this.isSearchLoading = false;
          if ((ctx.searchCompleted && isDefaultSearch) || groupSourcesDone) {
            if (!this.popupService.hasDialog || (Object.keys(this.popupService.refs).length === 1 && this.isSearchPopupOpen)) {
              this.openSearchPopup();
            }
          }
          this.cdr.markForCheck();
        }
      });
  }

  private async getSearhOptions(query: string): Promise<SearchOptions> {
    let view = !query?.length ? 'default' : 'search';
    if (query?.toLowerCase()?.trim()?.startsWith('go/')) {
      view = 'goLinks';
    }
    const sources = cloneDeep(HomeSearchSettings.sidePanelSettings[view]);
    for (const source of sources) {
      if (!source?.filters?.excludeFilters?.includes('Person')) {
        continue;
      }
      if (source.type === 'link-resources') {
        (source as LinkResourcesSourceSettings).tag = 'user_query-home-page';
        const peopleLinkId = await firstValueFrom(this.linksService.peopleLinkId$);
        if (peopleLinkId) {
          source.filters.excludeFilters.push('PeopleLink');
        }
      }
    }
    const options: SearchOptions = {
      resetSession: !query?.length,
      query: query,
      sources,
      trigger: 'user_query',
    };
    return options;
  }

  private searchFocus() {
    this.searchElement?.inputElement?.el?.nativeElement?.focus();
  }

  private setSearchPopupData(): HomeSearchPopupData {
    const data: HomeSearchPopupData = {
      popupWidth: this.searchElement['inputElement'].el.nativeElement.offsetWidth,
      search: this.search,
      browseBarModel: this.browseBarModel,
      searchData: {
        items: this.displayedContext.items,
        sessionId: this.displayedContext.sessionId,
        clientSearchId: this.displayedContext.clientSearchId,
        lastHeaderIndex: this.displayedContext.lastHeaderIndex,
        searchCompleted: this.displayedContext.searchCompleted,
        sources: this.displayedContext.sources,
      },
      isExtension: true,
    };
    return data;
  }

  openSearchPopup() {
    const data: HomeSearchPopupData = this.setSearchPopupData();
    if (this.popupRef) {
      this.popupRef.update(data);
      return;
    }

    const { x, y } = this.searchElement['inputElement'].el.nativeElement.getBoundingClientRect();
    const top: number = y + (this.stickyHeader ? 32 : 56);
    this.popupRef = this.popupService.open<HomeSearchPopupComponent, HomeSearchPopupData>(
      { left: x, top },
      HomeSearchPopupComponent,
      data,
      {
        position: INIT_POSITION_POPUP,
      }
    );

    this.isSearchPopupOpen = true;
    this.cdr.markForCheck();
    this.focusSearchInput();

    this.popupRef.compInstance.onClearSearch.pipe(untilDestroyed(this)).subscribe(async () => {
      await this.onSearch('');
      this.cdr.markForCheck();
    });

    this.popupRef.compInstance.onOpenChat.pipe(untilDestroyed(this)).subscribe(async (assistantId) => {
      this.chatsService.openChat(assistantId, this.search);
    });

    this.popupRef.compInstance.onReOpen.pipe(untilDestroyed(this)).subscribe(() => {
      this.openSearchPopup();
    });

    this.popupRef.compInstance.removeFocus.pipe(untilDestroyed(this)).subscribe(() => {
      this.removeFocusSearchInput();
    });

    this.popupRef.compInstance.onOpenPopup.pipe(untilDestroyed(this)).subscribe(() => {
      this.popupRef.destroy();
      this.popupRef = null;
      this.isSearchPopupOpen = false;
      this.cdr.markForCheck();
    });

    this.popupRef.compInstance.onClosePopup.pipe(untilDestroyed(this)).subscribe(() => {
      this.isSearchPopupOpen = false;
      this.search = '';
      this.cdr.markForCheck();
    });

    this.popupRef.compInstance.onSelectedIndex.pipe(untilDestroyed(this)).subscribe((value) => {
      this.selectedItem = !!value || value === 0;
      this.cdr.markForCheck();
    });

    this.popupRef.close$.pipe(untilDestroyed(this)).subscribe(() => {
      this.popupRef = null;
      this.isSearchPopupOpen = false;
      this.cdr.markForCheck();
    });
  }

  private removeFocusSearchInput() {
    if (!this.isInputFocused()) {
      return;
    }
    this.searchElement?.inputElement.el.nativeElement.blur();
  }

  private isInputFocused() {
    const input = this.searchElement?.inputElement?.el?.nativeElement;
    return document.activeElement === input;
  }

  private focusSearchInput() {
    if (this.isInputFocused() || this.popupService.hasDialog) {
      return;
    }
    this.searchFocus();
  }

  onEnterPressed() {
    const url = `/search?q=${this.search}`;
    this.search = '';
    this.routerService.navigateByUrl(url, null, true, true);
    this.isSearchPopupOpen = false;
    if (this.popupRef) {
      this.popupRef.destroy();
      this.popupRef = null;
    }
  }

  handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    const key = keys[0];

    if (isEnterKey(key) && this.searchKeyIsNotEmpty()) {
      this.onEnterPressed();
      event.stopPropagation();
      return;
    }

    if (isEnterKey(key) && this.popupRef) {
      event.stopPropagation();
      return;
    }
  }

  searchKeyIsNotEmpty() {
    return this.search && this.search.length > 0 && this.search !== '';
  }
}
