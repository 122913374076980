import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart } from '@angular/router';
import { Config } from '@environments/config';
import { Window } from '@local/client-contracts';
import { ManualPromise } from '@local/common';
import { DixieKeyValueStorage, EmbedSignInRequest, isBrowserExtension, isEmbed, isExternalEmbed, isTrustedOrigin } from '@local/common-web';
import { KeyName, getModifiers, isKey, keyCodes } from '@local/ts-infra';
import { Logger } from '@unleash-tech/js-logger';
import { Rpc } from '@unleash-tech/js-rpc';
import { BehaviorSubject, Observable, ReplaySubject, Subject, firstValueFrom, lastValueFrom, takeWhile } from 'rxjs';
import * as uuid from 'uuid';
import { CHAT_PAGE_PATH } from '../bar/utils/constants';
import { GlobalErrorHandler } from '../global-error-handler';
import { EventsService, LogService } from './services';
import { CustomKeyboardEvent, KeyboardService } from './services/keyboard.service';
import { RouterService } from './services/router.service';
import { SessionService } from './services/session.service';

export interface EmbedSideBarOptions {
  style: 'hidden' | 'large' | 'small';
}

export type EmbedType = 'assistant' | 'app' | 'quick-search' | 'search-page' | 'aux' | 'chat' | 'side-bar';

export interface EmbedOptions {
  id: string;
  origin: string;
  endpoint: string;
  type: EmbedType;
  externalOpenUrl?: boolean;
  externalDownload?: boolean;
  theme: 'light' | 'dark' | 'auto';
  sidebar?: EmbedSideBarOptions;
  slug?: string;

  popup: {
    hideOnClickout?: boolean;
    allowPin?: boolean;
    draggable: boolean;
    expandMode: 'inline' | 'external';
    borderRadius?: number;
    locationUrl?: string;
    sidebar: EmbedSideBarOptions;
    closeButton?: boolean;
  };

  inline: {};
  windowStyle?: Window.WindowStyle;
  hideBranding: boolean;
  shortcutKey?: string;
  shortcut?: {
    container?: string;
    key: string;
  };
  assistantId?: string;
}

export interface EmbedItemType {
  id: string;
  title: string;
  icon: string;
}

export interface EmbedItem {
  id: string;
  title: string;
  subtitle: string;
  type: string;
  icon: string;
  url: string;
  keywords: string[];
}

@Injectable({
  providedIn: 'root',
})
export class EmbedService {
  public channel$: ReplaySubject<MessagePort> = new ReplaySubject(1);
  public verified$: ReplaySubject<boolean> = new ReplaySubject(1);
  public authenticated$: ReplaySubject<boolean> = new ReplaySubject(1);
  public show$: Subject<void> = new Subject();
  public visible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public activate$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private initRequested: boolean;
  private _options$: ReplaySubject<EmbedOptions> = new ReplaySubject(1);
  private rpc: ManualPromise<Rpc> = new ManualPromise();
  private sessionService: ManualPromise<SessionService> = new ManualPromise();
  private callbacks = {};
  private callbackId = 0;
  private globalError: GlobalErrorHandler;
  private routerService: RouterService;
  private keyboardService: KeyboardService;
  private initPromise = new ManualPromise();
  public shown: boolean;
  private logger: Logger;
  private eventsService: ManualPromise<EventsService> = new ManualPromise();
  private _isExternalEmbed = null;
  public items: { [id: string]: EmbedItem } = {};
  public itemTypes: { [id: string]: EmbedItemType } = {};
  private keyHandlerId: string;
  private shortcutKeyOpenEmbed: string;
  private isLauncher: boolean;
  clearOnHideSub: any;
  private clearStateOnHide: boolean;
  private _assistantContext$: ReplaySubject<any> = new ReplaySubject(1);

  constructor() {
    (<any>self).webLoad = (<any>self).webLoad || { stage: { isEmbed: isEmbed() } };
    (<any>self).webLoad.stage['EmbedService-constructor'] = true;
    (<any>window).embedPromise.then((embedId) => {
      if (!embedId) return;
      window.addEventListener('message', (e) => this.processEmbedMessages(e));
      (<any>self).webLoad = (<any>self).webLoad || { stage: { isEmbed: isEmbed() } };
      (<any>self).webLoad.stage['hello-send'] = true;
      window.parent.postMessage({ type: 'unleash:hello' }, '*');
      // instead of using the inner navigator we propagate the copy command to the parent.
      if (navigator?.clipboard?.writeText) {
        navigator.clipboard.writeText = (text: string) => this.invoke('copy', text);
      }
    });
  }

  public get assistantContext$(): Observable<any> {
    return this._assistantContext$;
  }
  private registerKeyboardHandler() {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    }
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 20);
  }

  public handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent) {
    const modifiers = getModifiers(keys);
    if (!this.isLauncher && keys.length === 2) {
      if (modifiers[0] === keyCodes.commandOrControl.toLowerCase() && isKey(event, this.shortcutKeyOpenEmbed)) {
        event.stopPropagation();
        event.preventDefault();
        this.invoke('showMain');
        this.hide();
      }
    }
  }

  updateBackground(scheme: any) {
    this.invoke('updateBackground', scheme);
  }

  openAuxWindow(settings: Window.OpenAuxWindowOptions) {
    this.clearStateOnHide = settings.clearOnHide;
    this.invoke('openAuxWindow', settings);
  }

  async isBrowserExtension(): Promise<boolean> {
    return EmbedService.isBrowserExtension(await firstValueFrom(this.options$));
  }

  async isExternalWebSite(): Promise<boolean> {
    if (this._isExternalEmbed === null) {
      this._isExternalEmbed = isExternalEmbed((await firstValueFrom(this.options$))?.id);
    }
    return this._isExternalEmbed;
  }

  async isShowBranding(): Promise<boolean> {
    const options = await firstValueFrom(this.options$);
    return !options.hideBranding;
  }

  async isInline(): Promise<boolean> {
    const options = await firstValueFrom(this.options$);
    return !!options.inline;
  }

  async isFullApp(): Promise<boolean> {
    const options = await firstValueFrom(this.options$);
    return ['app', 'search-page'].includes(options.type);
  }

  isSearchEmbedType(type: EmbedType): boolean {
    return ['quick-search', 'search-page'].includes(type);
  }

  close() {
    this.invoke('close');
  }

  async toggleHideOnClickout() {
    const pOpts = (await firstValueFrom(this.options$)).popup;
    if (typeof pOpts.hideOnClickout == 'undefined') pOpts.hideOnClickout = false;
    else pOpts.hideOnClickout = !pOpts.hideOnClickout;
    this.invoke('toggleHideOnClickout');
  }

  init(
    rpc: Rpc,
    session: SessionService,
    globalError: GlobalErrorHandler,
    routerService: RouterService,
    eventsService: EventsService,
    logService: LogService,
    keyboardService: KeyboardService
  ) {
    this.logger = logService.scope('EmbedService');
    this.rpc.resolve(rpc);
    this.sessionService.resolve(session);
    this.eventsService.resolve(eventsService);

    firstValueFrom(session.current$).then((session) => {
      if (session) this.authenticated$.next(!!session);
    });
    this.globalError = globalError;
    this.routerService = routerService;
    this.keyboardService = keyboardService;
    this.registerKeyboardHandler();
    if (!this.initPromise.status) {
      this.initPromise.resolve(null);
    }
  }

  get options$() {
    return this._options$;
  }

  private invoke(method: string, ...args: any[]): Promise<any> {
    return new Promise(async (res, rej) => {
      const cid = ++this.callbackId;
      this.callbacks[cid] = (m) => {
        if (m.error) rej(m.error);
        else res(m.result);
      };
      (await firstValueFrom(this.channel$)).postMessage({ type: 'unleash:invoke', method, args, cid });
    });
  }

  async hide() {
    return this.invoke('hide');
  }

  private childWindows: Window[] = [];

  async download(url?: string) {
    await this.initPromise;

    const opts = await firstValueFrom(this.options$);
    if (opts.externalDownload) {
      if (await this.invoke('download', url)) {
        return;
      }
    }

    // create an a tag and initiating download
    const link = document.createElement('a');
    link.href = url;
    link.download = '';
    link.click();
  }
  async openUrl(url?: string, downloadUrl?: string, newWindow = true, resourceData?: any) {
    await this.initPromise;

    const opts = await firstValueFrom(this.options$);

    if (opts?.externalOpenUrl) {
      if (await this.invoke('openUrl', url, downloadUrl, newWindow, resourceData)) {
        return;
      }
    }
    if (!url) {
      url = `${location.pathname}${location.search}`;
    }

    const isExternal = await this.isExternalWebSite();
    const origin = !isExternal ? Config.baseUrl : location.origin;
    if (!url.startsWith('http')) {
      if (!url.startsWith('/')) {
        url = '/' + url;
      }
      url = `${origin}${url}`;
    }
    if (new URL(url).origin == origin && isExternal) {
      const service = await this.sessionService;
      const session = await firstValueFrom(service.current$);
      const id = uuid.v4();
      if (session) {
        url += '#user=' + session.user.id + '&id=' + id;
      }
    }

    this.childWindows.push(window.open(url, newWindow ? '_blank' : '_top'));
  }

  async openExpanded() {
    const opt = await firstValueFrom(this.options$);
    if (opt.popup.expandMode !== 'inline') {
      this.openUrl();
    }
  }

  private async processMessage(e: MessageEvent) {
    const type = e.data?.type;
    const cid = e.data?.cid;
    const method = e.data?.method;

    if (type == 'unleash:invoke:ack') {
      this.callbacks[cid](e.data);
      delete this.callbacks[cid];
      return;
    }
    if (type == 'unleash:invoke') {
      const result = null;
      let error = null;
      try {
        await this.initPromise;

        if (method == 'items.set') {
          this.items = {};
          for (const i of <EmbedItem[]>e.data?.args[0]) {
            this.items[i.id] = i;
          }
          return;
        }

        if (method == 'itemTypes.set') {
          this.itemTypes = {};
          for (const i of <EmbedItemType[]>e.data?.args[0]) {
            this.itemTypes[i.id] = i;
          }
          return;
        }
        if (method == 'items.add') {
          for (const i of <EmbedItem[]>e.data?.args[0]) {
            this.items[i.id] = i;
          }
          return;
        }
        if (method == 'items.remove') {
          for (const i of e.data?.args[0] || []) {
            delete this.items[i];
          }
          return;
        }
        if (method == 'destroy') {
          this.rpc.then((r) => {
            this.visible$.next(false);
            r.invoke('detachClient', true);
          });
        }
        if (method == 'setExternalOpenUrl') {
          (await firstValueFrom(this._options$)).externalOpenUrl = true;
        }
        if (method == 'setExternalDownload') {
          (await firstValueFrom(this._options$)).externalDownload = true;
        }
        if (method === 'setActivate') {
          this.activate$.next(e?.data?.args[0]);
        }
        if (method == 'hide') {
          this.shown = false;
          this.visible$.next(false);
        }
        if (method == 'load') {
          let url = e.data.args[0];
          if (!url.startsWith('/')) url = '/' + url;
          this.routerService.navigateByUrl(url, { state: { embed: true }, skipLocationChange: true, replaceUrl: true });
        }
        if (method == 'show') {
          const baseUrl = '/search';
          const opts = await firstValueFrom(this.options$);
          if (!['quick-search', 'search-page', 'assistant'].includes(opts?.type)) {
            this.invoke('shortcut');
          }
          this.isLauncher = opts?.type == 'quick-search';
          if (this.isLauncher) {
            this.clearOnHide();
          }

          if (opts.popup) {
            opts.popup.locationUrl = e.data.args[0]?.locationUrl;
          }

          if (e.data.args[0]?.isSearchPage) {
            opts.type = 'search-page';
          }

          if (e.data.args[0]?.url) {
            let url = e.data.args[0]?.url || baseUrl;
            const allowed = ['/window/', 'assistant/', `/${CHAT_PAGE_PATH}`, '/side-panel'];
            const enableSameNavPages = [`/${CHAT_PAGE_PATH}`, '/side-panel'];
            const reloadSameNav = enableSameNavPages.some((u) => url.startsWith(u));
            if (!url.startsWith(baseUrl) && !allowed.some((u) => url.startsWith(u)) && opts?.type !== 'assistant') {
              url = baseUrl;
            }
            this.routerService.navigateByUrl(url, {
              state: { embed: true },
              skipLocationChange: true,
              replaceUrl: true,
              onSameUrlNavigation: reloadSameNav ? 'reload' : null,
            });
          }
          if (e.data.args[0]?.mode == 'hidden') {
            return;
          }

          if (this.shown) {
            return;
          }
          this.shown = true;
          this._options$.next(opts);

          const label = (await this.isExternalWebSite())
            ? 'embed'
            : EmbedService.isBrowserExtension(opts)
              ? 'browser-extension'
              : opts.id.replace('extension:', '');
          const target = e.data.args[0]?.target;
          if (target) {
            const eventsService = await this.eventsService;
            eventsService.event('launch.embed', { target, label });
          }

          this.show$.next();
          this.visible$.next(true);
        }
        if (method == 'setAssistantContext') {
          this._assistantContext$.next(e.data.args[0]);
        }
        if (method == 'reset') {
          this.clearOnHide();
        }
        if (method == 'signout') {
          (await this.sessionService).signOut();
        }
        if (method == 'signin') {
          try {
            const options = await firstValueFrom(this._options$);
            const requestSignIn = e.data.args[0].user || { ...e.data.args[0] };
            await this.handleSignIn({ ...requestSignIn, embedId: options.id });
          } finally {
            const eventsService = await this.eventsService;
            eventsService.event('pageview', { location: { title: e.data?.args[0]?.location } });
          }
        }
        if (method == 'currentuser') {
          const t = await firstValueFrom((await this.sessionService).current$);
          return t.user.externalId || t.user.id;
        }
        if (method == 'shortcutKey') {
          const newShortcut = e.data?.args[0];
          if (newShortcut) {
            this.shortcutKeyOpenEmbed = newShortcut.toLowerCase().split('+')[1];
          }
        }
      } catch (e) {
        this.authenticated$.next(false);
        error = e;
        this.globalError.error = error;
      } finally {
        (await firstValueFrom(this.channel$)).postMessage({ type: 'unleash:invoke:ack', cid, result, error });
      }
    }
  }

  private async handleSignIn(user: EmbedSignInRequest) {
    if (!user.session) {
      if (!user.token) throw new Error('invalid token: ' + JSON.stringify(user));
      if (!user.id && user.token.startsWith('ey')) {
        try {
          const base64Url = user.token.split('.')[1];
          const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          user.id = JSON.parse(
            decodeURIComponent(
              atob(base64)
                .split('')
                .map((c) => {
                  return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
            )
          ).sub;
        } catch (e) {
          this.globalError.error = e;
          await this.initPromise;
          this.logger.error('failed to sign in embed ', e);
        }
      }
    }

    if (!user.id)
      // fallback to use the token itself as the user id, to enable caching of session when same token is delivered from parent
      user.id = await this.sha256(user.token);

    const t = await firstValueFrom((await this.sessionService).current$);

    if (user.session) {
      if (user.session.id != t?.id) {
        if (!user.session.createTime) {
          user.session.createTime = Date.now();
        }

        await (await this.sessionService).injectSession(user.session, true);
      }
    } else if (t?.user.externalId != user.id || !user.id) {
      await (await this.sessionService).embedSignIn(user);
    }

    await lastValueFrom((await this.sessionService).current$.pipe(takeWhile((x) => !x, true)));
    this.authenticated$.next(true);
    let ignoreNavigationId = 0;
    this.routerService.navigation$.subscribe((e) => {
      if (e instanceof NavigationStart) {
        if (e.navigationTrigger == 'popstate') ignoreNavigationId = e.id;
      }

      if (
        e instanceof NavigationEnd &&
        this.routerService.getCurrentNavigation()?.extras?.state?.embed != true &&
        e.id != ignoreNavigationId
      ) {
        this.invoke('updateUrl', e.url);
      }
    });
  }

  public static isBrowserExtension(options) {
    return isBrowserExtension(options.id, Config.browserExtension);
  }

  public async isTrustedOrigin() {
    const org = (await firstValueFrom(this.options$))?.origin;
    return isTrustedOrigin(org);
  }

  private async sha256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }
  private clearOnHide() {
    if (this.clearOnHideSub) {
      return;
    }
    this.clearOnHideSub = this.visible$.subscribe((v) => {
      if (!v && this.clearStateOnHide) {
        this.routerService.navigateByUrl('/search');
      }
      this.clearStateOnHide = false;
    });
  }

  private async processEmbedMessages(e: MessageEvent) {
    const type = e.data?.type;
    if (!type?.startsWith('unleash:')) {
      return;
    }

    if (e.data?.type == 'unleash:signin-required' && e.origin == location.origin && this.childWindows.includes(<any>e.source)) {
      const service = await this.sessionService;
      const session = await service.fork('web');
      e.source.postMessage({ type: 'unleash:signin', id: e.data.id, session }, { targetOrigin: location.origin });
      return;
    }

    if (type == 'unleash:init') {
      (<any>self).webLoad = (<any>self).webLoad || { stage: { isEmbed: isEmbed() } };
      (<any>self).webLoad.stage['init-recieved'] = true;
      if (this.initRequested) {
        throw new Error('received init message after initialization');
      }

      e.data.options.origin = e.origin;
      e.data.options.id = (<any>window).__embedId; // always prefer the embed if stored in the window object since the original id might be a slug
      e.data.options.slug = (<any>window).__embedSlug; // always prefer the embed if stored in the window object since the original id might be a slug

      if (!e.data.options.style) {
        e.data.options.style = e.data.options.popup ? 'popup' : 'inline';
      }

      if (e.data.options.type == 'launcher') {
        e.data.options.type = 'quick-search';
      }

      (<any>self).webLoad = (<any>self).webLoad || { stage: { isEmbed: isEmbed() } };
      (<any>self).webLoad.stage['has-options'] = true;
      this._options$.next(e.data.options);
      this.channel$.next(e.data?.port);
      this.initRequested = true;

      let valid = true;

      if (!(await this.isTrustedOrigin())) {
        if (await this.isExternalWebSite()) {
          // read from cache to improve performance
          let origins = [];

          try {
            await this.initPromise;
            const storage = DixieKeyValueStorage.create({ name: 'embed-cache' }, this.logger);
            origins = ((await storage.entry(e.data.options.id).get()) as string[]) || [];
          } catch (e) {
            console.error('failed to load embed from cache');
          }
          valid = origins.includes(e.origin);
          if (!valid) {
            valid = await (await this.rpc).invoke('verifyembed', false);
            if (!valid) {
              (<any>self).webLoad = (<any>self).webLoad || { stage: { isEmbed: isEmbed() } };
              (<any>self).webLoad.stage['verifyembed-failed'] = true;
              this.globalError.error = new Error(
                'Origin is not allowed to host the given embed. Please add the origin to the list of allowed origins in the settings page'
              );
            }
          }
        }
      }

      (<any>self).webLoad = (<any>self).webLoad || { stage: { isEmbed: isEmbed() } };
      (<any>self).webLoad.stage['verifyembed-success'] = true;
      if (e.data.options.style == 'newtab') {
        // mark this so we can treat the embed as normal page
        (<any>self).newTabPage = true;
      }

      e.data.port.postMessage({ type: 'unleash:init:ack', succeeded: valid });
      e.data.port.addEventListener('message', (e) => this.processMessage(e));
      this.verified$.next(valid);

      if (EmbedService.isBrowserExtension(e.data.options)) {
        this.authenticated$.next(true);
      }
      e.data.port.start();
    }
  }
}
