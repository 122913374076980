import { AfterViewInit, Component, ElementRef, Inject, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Applications, Filters, LinkSettings, Links, OAuth, OAuth1, OAuth2 } from '@local/client-contracts';
import { ManualPromise, OAuthCompletionRpcHandler as OAuthCompetionService } from '@local/common';
import { isNativeWindow } from '@local/common-web';
import { addParam } from '@local/ts-infra';
import { STYLE_SERVICE } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsService, LogService, NativeServicesRpcService, ServicesRpcService, componentServicesRpcProvider } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { LinksService } from '@shared/services/links.service';
import { OAuthCompletionRpcHandler } from '@shared/services/oauth-windows/oauth-completions-rpc-handler';
import { MixedOAuthSettings, OAuthNoOpWindow, OAuthWindow } from '@shared/services/oauth-windows/oauth-window';
import { OAuthWindowCollection } from '@shared/services/oauth-windows/oauth-windows-collection';
import { SessionService } from '@shared/services/session.service';
import { StyleService } from '@shared/services/style.service';
import { Logger } from '@unleash-tech/js-logger';
import { handler } from '@unleash-tech/js-rpc';
import { BehaviorSubject, Observable, Subject, filter, firstValueFrom, map } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { ResourceAccessType } from 'src/app/bar/components/rlp/choose-resource-access-type/choose-resource-access-type.component';
import { FrameRpcService } from './frame-rpc.service';
import { DuplicateLinkError, ExistingLinkInfo, LinkError, LinkResult, WrongAccountLinkError } from './models';
import { OAuth1SessionsRpcInvoker } from './oauth1-sessions-rpc-invoker';
import { OAuth2SessionsRpcInvoker } from './oauth2-sessions-rpc-invoker';

@UntilDestroy()
@Component({
  templateUrl: './link-settings-frame.component.html',
  providers: [componentServicesRpcProvider],
  styleUrls: ['./link-settings-frame.component.scss'],
})
export class LinkSettingsFrameComponent implements OnInit, OnDestroy, LinkSettings.Service, AfterViewInit {
  @ViewChild('iframe') private iframeRef: ElementRef;

  @Input() appId: string;
  @Input() source: string; //used for telemetry, which page redirected here.
  @Input() oauthSessionId: string;
  @Input() linkInfo?: ExistingLinkInfo;
  @Input() linkCreationOptions?: Links.ShareOptions;
  @Input() resourceAccessType: ResourceAccessType;

  @Output()
  isCreating: Observable<boolean>;

  @Output()
  isPreCreating: Observable<boolean>;

  @Output()
  isLoading: Observable<boolean>;

  @Output()
  inProgress: Observable<boolean>;

  @Output()
  isReload: Observable<boolean>;

  @Output()
  linkResult: Observable<LinkResult>;

  @Output()
  isShow: Observable<boolean>;
  sessionCompleted$ = new BehaviorSubject<boolean>(false);

  private isCreating$ = new BehaviorSubject<boolean>(false);
  private isPreCreating$ = new BehaviorSubject<boolean>(false);

  private isLoading$ = new BehaviorSubject<boolean>(true);
  private inProgress$ = new BehaviorSubject<boolean>(false);
  private linkResult$ = new Subject<LinkResult>();
  private show$ = new BehaviorSubject<boolean>(false);
  private reload$ = new BehaviorSubject<boolean>(false);

  private logger: Logger;
  private destroy$ = new Subject();
  private iframeRpcService: FrameRpcService;
  private app: Applications.DisplayItem;
  private oauth2Sessions: OAuth2.SessionService;
  private oauth1Sessions: OAuth1.SessionService;

  private oauthWindow: OAuthWindow;
  private oauthWindowOwner: boolean;
  private frameWindow: Window;
  private frameLoaded: ManualPromise<void>;

  private subject: Subject<string>;
  private createLink: Links.CreateLink;

  constructor(
    private host: ServicesRpcService,
    private native: NativeServicesRpcService,
    private logService: LogService,
    private sessionService: SessionService,
    private linksService: LinksService,
    private eventsService: EventsService,
    private ngzone: NgZone,
    private applications: ApplicationsService,
    @Inject(STYLE_SERVICE) private styleService: StyleService,
    private oauthWindows: OAuthWindowCollection
  ) {
    this.isCreating = this.isCreating$.pipe(distinctUntilChanged());
    this.isPreCreating = this.isPreCreating$.pipe(distinctUntilChanged());
    this.inProgress = this.inProgress$;
    this.isLoading = this.isLoading$;
    this.linkResult = this.linkResult$;
    this.isReload = this.reload$;
    this.isShow = this.show$.pipe(distinctUntilChanged());
  }

  async ngAfterViewInit() {
    const that = this;

    const el = this.iframeRef.nativeElement as HTMLIFrameElement;
    this.frameWindow = el.contentWindow;
    const anyWindow: any = this.frameWindow;
    this.frameLoaded = new ManualPromise<void>();

    this.app = await this.applications.one(this.appId);
    let oauthWindow: OAuthWindow;
    if (this.oauthSessionId) {
      this.oauthWindow = this.oauthWindows.get(this.oauthSessionId);
      this.oauthWindowOwner = false;
    } else {
      if (!isNativeWindow()) {
        const { id, window } = this.oauthWindows.create(this.appId, this.resourceAccessType);
        this.oauthWindowOwner = true;
        this.oauthSessionId = id;
        oauthWindow = this.oauthWindow = window;
      } else {
        oauthWindow = this.oauthWindow = new OAuthNoOpWindow();
      }
    }

    el.onload = () => {
      that.isLoading$.next(false);

      if (oauthWindow) {
        anyWindow.oAuthWindow = oauthWindow;
      }

      if (!that.frameLoaded.status) {
        that.frameLoaded.resolve();
      }
      that.frameLoaded = new ManualPromise<void>();
    };

    const settingsUrl = this.getSettingsUrl();
    const param = 'unleash-inject-apps-sdk=true';
    const frameUrl = addParam(settingsUrl, param);
    this.loadFrame(frameUrl);
    this.setRpc(this.frameWindow);
  }

  async ngOnInit(): Promise<void> {
    this.logger = this.logService.scope('LinkSettingsFrameComponent');
    this.oauth2Sessions = (this.native || this.host).invokeWith(OAuth2SessionsRpcInvoker, 'oauth2appsessions');
    this.oauth1Sessions = (this.native || this.host).invokeWith(OAuth1SessionsRpcInvoker, 'oauth1sessions');
  }

  private getSettingsUrl() {
    if (this.resourceAccessType === 'Permissions' && this.app.resourcePermissions) {
      return this.app.resourcePermissions?.url;
    }
    return this.app.link?.url;
  }

  private async applyDataSchemeOnIframeBody() {
    await this.frameLoaded;
    this.styleService.theme$.pipe(startWith(), untilDestroyed(this)).subscribe(async () => {
      this.iframeRpcService.styleInvoker.setScheme(await firstValueFrom(this.styleService.theme$));
    });
  }

  private loadFrame(url?: string) {
    this.isLoading$.next(true);
    this.preventIframeBlink();
    this.applyDataSchemeOnIframeBody();
    this.iframeRef.nativeElement.style.visibility = 'visible';
    if (url) {
      this.frameWindow.location.href = url;
    } else {
      this.frameWindow.location.reload();
    }
  }
  /** When the iframe initialized it has white background that disappears on load.
   * That cause annoying white flickering. This solves it.
   */
  private async preventIframeBlink() {
    if (!this.iframeRef) return;

    const el = this.iframeRef.nativeElement as HTMLIFrameElement;
    el.style.opacity = '0';

    await this.frameLoaded;
    el.style.opacity = '1';
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    if (this.oauthSessionId) {
      this.oauthWindows.remove(this.oauthSessionId);
    }
    if (this.iframeRpcService) this.iframeRpcService.destroy();
    this.host.destroy();
    window.focus();
  }

  async setRpc(iframeWindow: Window) {
    const oauthCompletion = new OAuthCompletionRpcHandler(new OAuthCompetionService(this.logger, this.oauth1Sessions, this.oauth2Sessions));
    const currentSession = await firstValueFrom(this.sessionService.current$);
    this.iframeRpcService = new FrameRpcService(this, oauthCompletion, this.ngzone, iframeWindow, this.logger, currentSession);
    try {
      this.iframeRpcService.setChannel(this.app.id);
    } catch (e) {
      this.linkResult$.next({ status: 'error', error: new LinkError('Failed to set channel on frame') });
    }
  }

  private oauthFlow<TRequest, TResult extends OAuth.SessionResult>(
    sessions: OAuth.SessionService<TRequest, TResult>,
    prepareOAuth: (mixedWindow: MixedOAuthSettings) => void
  ): Observable<TResult> {
    this.inProgress$.next(true);
    this.reload$.next(false);

    if (!this.oauthSessionId) {
      const w = this.oauthWindows.create(this.appId, this.resourceAccessType);
      this.oauthSessionId = w.id;

      if (w.window.settings instanceof MixedOAuthSettings) {
        prepareOAuth(w.window.settings);
      }
      w.window.open(true);
    } else if (this.oauthWindowOwner && this.oauthWindow.settings instanceof MixedOAuthSettings) {
      prepareOAuth(this.oauthWindow.settings);
      this.oauthWindow.changeLocation();
    }

    const subject = new Subject<TResult>();
    sessions.reset(this.oauthSessionId).then(() => {
      const creatingSubscription = sessions.creating$(this.oauthSessionId).subscribe(() => {
        this.isCreating$.next(true);
        if (creatingSubscription) {
          creatingSubscription.unsubscribe();
        }
      });

      sessions
        .completed$(this.oauthSessionId)
        .pipe(
          untilDestroyed(this),
          filter((res) => !!res)
        )
        .subscribe({
          next: (res) => {
            if (creatingSubscription) {
              creatingSubscription.unsubscribe();
            }
            this.inProgress$.next(false);
            this.oauthWindow.close();
            if (res.error) {
              const err = new Error(res.error);
              const strError = 'failed to get oauth callback: reason: ' + JSON.stringify(err);
              this.eventsService.event('links.error', {
                target: this.appId,
                label: 'error',
                exception: strError,
                location: { title: this.source },
              });
              subject.error(res);
            }

            subject.next(res);
            subject.complete();
          },
          error: (err) => {
            this.linkResult$.next({ status: 'error', error: new LinkError('OAuth failed', err) });
            subject.error(err);
          },
        });
    });
    return subject;
  }

  /* handlers */
  @handler
  oauth1Flow$(req: OAuth1.Request): Observable<OAuth1.Tokens> {
    return this.oauthFlow(this.oauth1Sessions, (mw) => mw.prepare('oauth1', req)).pipe(map((x) => x.tokens));
  }

  @handler
  oauth2Flow$(req: OAuth2.Request): Observable<OAuth2.Tokens> {
    return this.oauthFlow(this.oauth2Sessions, (mw) => mw.prepare('oauth2', req)).pipe(map((x) => x.tokens));
  }

  @handler
  async showProgressBar(): Promise<void> {
    this.inProgress$.next(true);
  }

  @handler
  async hideProgressBar(): Promise<void> {
    this.inProgress$.next(false);
  }

  @handler
  async fail(req: LinkSettings.FailRequest): Promise<void> {
    this.inProgress$.next(false);
    const result: LinkResult = { status: 'error' };
    if (req.error) {
      result.error = new LinkError(typeof req.error === 'string' ? req.error : req.error.message);
    }
    this.linkResult$.next(result);
    this.logger.error('Failed to create link for app', { error: req.error, appId: this.appId });
    await this.closeOAuth();
    this.eventsService.event('links.oauth_fail', {
      target: this.appId,
      label: JSON.stringify(req.error),
      location: { title: this.source },
    });
  }

  @handler
  async cancel(): Promise<void> {
    await this.closeOAuth();
    this.logger.info('Cancel link: ' + ' ' + this.appId);
    this.eventsService.event('links.cancel', { target: this.appId, location: { title: this.source } });

    this.linkResult$.next({ status: 'cancel' });
    return;
  }

  complete(timeFilter?: Filters.Values) {
    this.createLink.timeFilter = timeFilter;
    this.isCreating$.next(true);
    this.isPreCreating$.next(false);
    this.finishComplete().then((r) => {
      this.subject.next(r);
    });
  }

  async finishComplete() {
    return (async () => {
      let res: LinkResult;
      try {
        await this.closeOAuth();
        this.sessionCompleted$.next(true);
        const options = this.linkCreationOptions;

        if (this.linkInfo) {
          res = await this.update(this.createLink, this.linkInfo);
        } else {
          res = await this.create(this.createLink, options);
        }
        this.eventsService.event('links.oauth_success', { target: this.appId, location: { title: this.source } });
        return res.id!;
      } finally {
        this.inProgress$.next(false);
        if (res) {
          this.linkResult$.next(res);
        }
      }
    })();
  }

  @handler
  complete$(createLink: Links.CreateRequest): Observable<string> {
    this.inProgress$.next(true);
    this.subject = new Subject();
    this.iframeRef.nativeElement.style.visibility = 'hidden';
    this.createLink = {
      ...createLink,
      resourcePermissions: { enabled: this.resourceAccessType === 'Permissions', remote: createLink.resourcePermissions?.remote },
      syncType: createLink.syncType as Links.SyncType,
    };
    if (this.app.disableTimeFilter || this.linkInfo?.linkId) {
      this.complete();
    } else {
      this.isPreCreating$.next(true);
    }

    return this.subject.asObservable();
  }

  @handler
  async closeOAuth(err?: Error | string): Promise<void> {
    this.oauthWindow.close();
    let errorMessage: string;
    if (err) {
      let error = err;
      if (typeof err === 'string') {
        error = new Error(err);
        errorMessage = err;
      } else {
        errorMessage = err.message;
      }
      this.logger.error('got error on oauth flow', error);
    }
    this.eventsService.event(`links_oauth_${err ? 'fail' : 'success'}`, {
      target: this.appId,
      label: errorMessage,
      location: { title: this.source },
    });
  }

  @handler
  async reload(): Promise<void> {
    this.isCreating$.next(false);
    this.reload$.next(true);
    this.loadFrame();
  }

  @handler
  async show(): Promise<void> {
    this.show$.next(true);
  }

  private async create(createLink: Links.CreateLink, options?: Links.ShareOptions): Promise<LinkResult> {
    try {
      const result: Links.CreateResult = await this.linksService.create({
        ...createLink,
        appId: this.appId,
        shareOptions: options,
      });

      if (!result.succeeded && result.reason === 'DuplicateLink') {
        this.eventsService.event('links.error', { target: this.appId, label: 'DuplicateLink', location: { title: this.source } });
        return { status: 'error', error: new DuplicateLinkError() };
      } else {
        this.eventsService.event('links.success', { target: this.appId, location: { title: this.source } });
        return { status: 'success', createdLink: createLink, id: result.id };
      }
    } catch (e) {
      this.eventsService.event('links.error', { target: this.appId, label: JSON.stringify(e), location: { title: this.source } });
      return { status: 'error', error: e };
    }
  }

  private async update(createLink: Links.CreateLink, linkInfo: ExistingLinkInfo): Promise<LinkResult> {
    if (linkInfo.key !== createLink.key) {
      this.eventsService.event('links.refresh_stale', {
        target: this.appId,
        label: 'error',
        location: { title: this.source },
        exception: 'wrong account',
      });

      return { status: 'error', error: new WrongAccountLinkError(linkInfo.key) };
    }

    try {
      createLink.appId = this.appId;

      await this.linksService.refresh(linkInfo.linkId, createLink);

      this.eventsService.event('links.refresh_stale', {
        target: this.appId,
        label: 'success',
        location: { title: this.source },
        jsonData: JSON.stringify(linkInfo),
      });

      return { status: 'success' };
    } catch (error) {
      this.eventsService.event('links.refresh_stale', {
        target: this.appId,
        label: 'error',
        location: { title: this.source },
        jsonData: JSON.stringify(linkInfo),
        exception: JSON.stringify(error),
      });
      return { status: 'error' };
    }
  }
}
