import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes, UrlMatcher } from '@angular/router';
import { isNativeWindow } from '@local/common-web';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { Guard } from '@shared/guards';
import { AppComponent } from '../app.component';
import { InstallSlackComponent } from '../install-slack/install-slack/install-slack.component';
import { InstallZendeskComponent } from '../install-zendesk/install-zendesk/install-zendesk.component';
import { AttributesResolver } from './attributes-resolver.service';
import { ExternalLinkResolver } from './external-link.resolver';

let routes: Routes = [];

let onBoardingMatcher: UrlMatcher = (s, g, r) => {
  if (
    s[0]?.path == 'setup' ||
    s[0]?.path == 'signup' ||
    s[0]?.path == 'signin' ||
    s[0]?.path == 'empty' ||
    s[0]?.path == 'pricing' ||
    s[0]?.path == 'pricing-completed' ||
    s[0]?.path == 'desktop' ||
    s[0]?.path == 'error' ||
    s[0]?.path == 'select-account' ||
    s[0]?.path == 'user-created' ||
    s[0]?.path == 'join-workspace' ||
    s[0]?.path == 'install-extension'
  ) {
    return { consumed: [] };
  }

  return;
};

if (isNativeWindow()) {
  routes = [
    {
      matcher: onBoardingMatcher,
      loadChildren: () => import('../onboarding-native/onboarding.module').then((m) => m.OnboardingModule),
      data: { moduleName: 'onboarding-native' },
      resolve: { attribute: AttributesResolver },
    },
  ];
} else {
  routes = [
    {
      matcher: onBoardingMatcher,
      loadChildren: () => import('../onboarding-web/onboarding.module').then((m) => m.OnboardingModule),
      data: { moduleName: 'onboarding-web' },
      resolve: { attribute: AttributesResolver },
    },
  ];
}

routes = [
  ...routes,
  {
    path: 'developer',
    loadChildren: () => import('../developer/developer.module').then((m) => m.DeveloperModule),
    resolve: { attribute: AttributesResolver },
  },
  {
    path: 'install-slack',
    component: InstallSlackComponent,
  },
  {
    path: 'install-zendesk',
    component: InstallZendeskComponent,
  },
  {
    path: 'external-link',
    resolve: { ['external-link']: ExternalLinkResolver },
    component: AppComponent,
  }, // Never navigate to other route, just handles the opening of the url provided in the queryParam
  {
    matcher: (s, g) => {
      return { consumed: [] };
    },
    loadChildren: () => import('../bar/main.module').then((m) => m.MainModule),
    data: { moduleName: `main-${isNativeWindow() ? 'native' : 'web'}` },
    resolve: { attribute: AttributesResolver },
    canActivate: [Guard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    resolve: { attribute: AttributesResolver },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: location.protocol.startsWith('file'),
      enableTracing: false,
      preloadingStrategy: NoPreloading,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
