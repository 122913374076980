import { Injectable } from '@angular/core';
import { ContextMenuItemsMap, ContextMenuItemsType } from './context-menu-items-map';
import { ContextMenuItem } from '@shared/components';

@Injectable()
export class ContextMenuItemsService {
  getContextMenuItem(item: ContextMenuItemsType, data?: any): ContextMenuItem {
    return { ...ContextMenuItemsMap[item], data };
  }

  getContextMenuItems(items: ContextMenuItemsType[], data?: any): ContextMenuItem[] {
    const contextMenuItems: ContextMenuItem[] = [];
    for (const item of items) {
      contextMenuItems.push(this.getContextMenuItem(item, data));
    }
    return contextMenuItems;
  }
}
