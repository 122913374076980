import { Params } from '@angular/router';
import { Search } from '@local/client-contracts';
import { generateTitleUrl, removeAllButCharsAndNumbers } from '@local/ts-infra';
import { LoadPreviewParams } from 'src/app/bar/services/preview.service';
import { isWikiCardLocal } from '../../results';
import { PreviewKey, PreviewType } from '../../results/models/view-filters';

export const EMBED_SAVE_CARD_KEY_STORAGE = 'new-aux';

export function getUrlByPreviewKey(previewKey: PreviewKey, modal: LoadPreviewParams, query?: string, queryParam?: Params): string {
  const nameUrl = modal?.name ? removeAllButCharsAndNumbers(modal.name) : null;
  switch (previewKey) {
    case 'f':
    case 'p':
    case 'm':
      return `${previewKey}/${nameUrl ? nameUrl : getPreviewTypeByPreviewKey(previewKey)}/${modal.linkId}/${modal.resourceId}`;
    case 'rp':
      return `${previewKey}/${nameUrl ? nameUrl : getPreviewTypeByPreviewKey(previewKey)}/${modal.linkId}/${modal.resourceId}${
        query ? '?q=' + query : ''
      }`;
    case 'b':
      return `${previewKey}/${modal.id}`;
    case 'a': {
      if (['new', 'new-aux'].includes(modal.id)) {
        return `${previewKey}/new`;
      }
      if (!modal.name) {
        return null;
      }
      if (queryParam?.pm) {
        return `${generateTitleUrl(previewKey, modal.name, modal.id)}?pm=${queryParam.pm}`;
      }
      return generateTitleUrl(previewKey, modal.name, modal.id);
    }
    default:
      return generateTitleUrl(previewKey, modal.name, modal.id);
  }
}

export function getPreviewKeyByPreviewType(previewType: PreviewType): PreviewKey {
  switch (previewType) {
    case 'files':
      return 'f';
    case 'mail':
      return 'm';
    case 'people':
      return 'p';
    case 'wiki card':
      return 'a';
    case 'collection-file':
      return 'b';
    case 'relevant-people':
      return 'rp';
    default:
      return null;
  }
}

export function getPreviewTypeByPreviewKey(previewKey: PreviewKey): PreviewType {
  switch (previewKey) {
    case 'f':
      return 'files';
    case 'm':
      return 'mail';
    case 'p':
      return 'people';
    case 'a':
      return 'wiki card';
    case 'b':
      return 'collection-file';
    case 'rp':
      return 'relevant-people';
    default:
      return null;
  }
}

export function getPreviewId(previewType: PreviewType, item: Search.ResultResourceItem) {
  switch (previewType) {
    case 'wiki card':
      return isWikiCardLocal(item) ? item?.id : (item as Search.ResultResourceItem)?.resource?.externalId;
    default:
      return item?.id;
  }
}

export function getPreviewTypeByPath(url: string) {
  const purlPattern: RegExp = /purl=(.*?)%/;
  const match = purlPattern.exec(url);

  if (match && match[1]) {
    return getPreviewTypeByPreviewKey(match[1] as PreviewKey);
  }
  return;
}

export function getPreviewName(previewType: PreviewType, item: Search.ResultResourceItem) {
  switch (previewType) {
    case 'wiki card':
      return isWikiCardLocal(item) ? item?.view?.title.text : (item as Search.ResultResourceItem)?.resource?.name;
    case 'collection-file':
      return item?.view?.title.text;
    default:
      return item?.resource?.name;
  }
}
