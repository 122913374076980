import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Commands, QuickLinks } from '@local/client-contracts';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { isEnterKey, KeyName } from '@local/ts-infra';
import { QuickLinksService } from 'src/app/bar/services/quick-links.service';
import { ContextMenuComponent } from '../context-menu/context-menu.component';
import { ContextMenuData, ContextMenuItem } from '../context-menu/models';
import { PopupRef } from '@local/ui-infra';
import { ContextMenuService } from '../context-menu/context-menu.service';
import { ResultCommandService } from 'src/app/bar/services/commands/result-command.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';
import { BlobsService } from 'src/app/bar/services/blobs/blob.service';
import { EventsService } from '@shared/services';
import { TelemetryTrigger } from 'src/app/bar/views';

@UntilDestroy()
@Component({
  selector: 'quick-links-popup',
  templateUrl: './quick-links-popup.component.html',
  styleUrls: ['./quick-links-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickLinksPopupComponent implements OnDestroy {
  readonly DEFAULT_ICON = `${window.location.origin}/assets/auth/icon-link.svg`;
  private activeContextMenu: PopupRef<ContextMenuComponent, ContextMenuData>;
  private keyHandlerId: string;
  focusIndex = -1;
  quickLinks: QuickLinks.QuickLink[];
  state: 'loading' | 'empty' = 'loading';

  constructor(
    private cdr: ChangeDetectorRef,
    private quickLinksService: QuickLinksService,
    private keyboardService: KeyboardService,
    private contextMenuService: ContextMenuService,
    private resultCommandService: ResultCommandService,
    private blobsService: BlobsService,
    private eventsService: EventsService
  ) {
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys: Array<KeyName>, event) => this.handleKeys(keys, event), 9);
    this.quickLinksService.all$.subscribe((quickLinks) => {
      this.quickLinks = cloneDeep(quickLinks)?.sort((a, b) => b.modifiedTime - a.modifiedTime);
      this.quickLinks?.map(async (q) => {
        if (q.blobId) {
          q.imageUrl = await this.blobsService.getBlobFullUrl(q.blobId);
        }
      }) || [];
      this.state = quickLinks.length === 0 ? 'empty' : null;
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
      this.keyHandlerId = null;
    }
  }

  createQuickLink() {
    this.eventsService.event('quick_links.action', {
      location: { title: 'home' },
      target: this.state === 'empty' ? 'new_link' : undefined,
      name: 'create_quick_link',
    });
    this.quickLinksService.openCreateQuickLinkPopup();
  }

  openUrl(quickLink: QuickLinks.QuickLink, target: TelemetryTrigger) {
    if (!quickLink) return;
    this.sendImpressionEvent('open');
    this.resultCommandService.executeCommand({ type: 'open-url', url: quickLink.url } as Commands.OpenUrl, {}, 'quick-link', {
      location: { title: 'home' },
      target,
      resources: [{ appId: 'quick_link', type: 'quick_link' }],
    });
  }

  getTooltip(quickLink: QuickLinks.QuickLink): string {
    return `<b>${quickLink.name}</b><br/>${quickLink.url}`;
  }

  async openContextMenu(event: { x: number; y: number }, quickLink: QuickLinks.QuickLink, index: number) {
    if (!this.quickLinksService.isOwnerOrAdmin && quickLink.isShared) return;
    const items = await this.buildContextMenuItems(quickLink);
    this.focusIndex = index;
    this.activeContextMenu = this.contextMenuService.open(
      event,
      {
        items,
        onInvoke: (item: ContextMenuItem) => this.handleContextMenuCommand(item),
        minWidthItem: 90,
      },
      { position: 'right' }
    );
    this.activeContextMenu.destroy$.pipe(untilDestroyed(this)).subscribe(() => {
      this.focusIndex = -1;
      this.cdr.markForCheck();
    });
  }

  private buildContextMenuItems(quickLink: QuickLinks.QuickLink): ContextMenuItem[] {
    return [
      {
        id: 'edit',
        text: 'Edit',
        data: { quickLink },
        command: { type: 'edit', value: 'edit' } as Commands.DynamicCommand,
      },
      {
        id: 'delete',
        text: 'Delete',
        data: { quickLink },
        command: { type: 'delete', value: 'delete' } as Commands.DynamicCommand,
      },
    ];
  }

  async handleContextMenuCommand(command: ContextMenuItem) {
    if (command.id === 'delete') {
      this.sendImpressionEvent('delete');
      if (this.quickLinksService.isOwnerOrAdmin && command.data?.quickLink?.isShared) {
        this.quickLinksService.openWarningPopup(true, command.data?.quickLink?.id);
      } else {
        this.quickLinksService.delete(command.data?.quickLink?.id);
      }
    }
    if (command.id === 'edit') {
      this.sendImpressionEvent('edit');
      this.quickLinksService.openCreateQuickLinkPopup(command.data?.quickLink);
    }
  }

  private calcFocusIndex(step: number) {
    if (this.focusIndex < 0 && step === 3) {
      this.focusIndex = 0;
      this.cdr.markForCheck();
      return;
    }
    const index = this.focusIndex + step;
    if (index >= -1 && index <= this.quickLinks.length) {
      this.focusIndex = index;
    }
    this.cdr.markForCheck();
  }

  private sendImpressionEvent(target: string): void {
    this.eventsService.event('quick_links.action', {
      name: 'quick_link',
      location: { title: 'home' },
      target,
    });
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    event.stopPropagation();
    const key = keys[0];
    if (isEnterKey(key)) {
      this.openUrl(this.quickLinks[this.focusIndex], 'keyboard');
    }
    switch (key) {
      case 'escape':
        event.preventDefault();
        this.quickLinksService.closeQuickLinkPopup();
        break;
      case 'ArrowDown':
        this.calcFocusIndex(3);
        break;
      case 'ArrowUp':
        this.calcFocusIndex(-3);
        break;
      case 'ArrowLeft':
        this.calcFocusIndex(-1);
        break;
      case 'ArrowRight':
        this.calcFocusIndex(1);
        break;
    }
    event.preventDefault();
  }
}
