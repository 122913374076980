import { Component, EventEmitter, Input, model, Output } from '@angular/core';
import { CheckboxChangeEvent } from 'primeng/checkbox';

@Component({
  selector: 'u-checkbox',
  templateUrl: './u-checkbox.component.html',
  styleUrls: ['./u-checkbox.component.scss'],
})
export class UCheckboxComponent {
  checked = model();
  @Input() disabled = false;
  @Output() onChange = new EventEmitter<CheckboxChangeEvent>();
  @Input() label: string = null;
  @Input() value: any = null;
  @Input() name: any = null;
  @Input() binary = false;
  @Input() inputId: string = null;
  @Input() isNoColor = false;
  @Input() checkboxSize = '';
}
