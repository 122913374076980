import { Injectable } from '@angular/core';
import { getFileSize, convertFileSizeToBytes } from '@shared/utils';
import { getExtensionByFileName, getIconByExtension } from '@local/ts-infra';
import DOMPurify from 'dompurify';
import { cloneDeep } from 'lodash';
import { attachmentsRegex } from '../constants/frame-editor-utils';
import { Config } from '@environments/config';
import { FrameEditorConstants } from '../constants/frame-editor.constants';
import { LogService } from '@shared/services';
import { BlobsService } from 'src/app/bar/services/blobs/blob.service';
import { Logger } from '@unleash-tech/js-logger';

@Injectable()
export class FrameEditorRenderHtmlService {
  private readonly DEFAULT_VIDEO_WIDTH = 300;
  private readonly DEFAULT_VIDEO_HEIGHT = 150;
  private logger: Logger;

  constructor(
    logService: LogService,
    private blobsService: BlobsService
  ) {
    this.logger = logService.scope('FrameEditorRenderHtmlService');
  }

  async renderHtmlToEditor(content: string) {
    let html = DOMPurify.sanitize(cloneDeep(content), { ADD_TAGS: ['iframe'], ADD_ATTR: ['ext'] });
    //HACK to overcome the inline icon path
    html = html?.replaceAll('src="/assets/file-icons', `src="${Config.baseUrl}/assets/file-icons`);
    html = await this.insertImageToHtml(html);
    html = await this.insertVideoToHtml(html);
    html = this.retrieveAttachmentsToHtml(html);
    return html;
  }

  convertEditorHtmlForSave(content: string) {
    let updatedHtml = content;
    updatedHtml = this.removeClassFromAttachment(updatedHtml, FrameEditorConstants.CLASS_ACTIVE_ATTACHMENT);
    updatedHtml = this.removeAllImageSrc(updatedHtml);
    updatedHtml = this.removeAllVideoSrc(updatedHtml);
    const mediaIds = this.getMediaIds(updatedHtml) || [];
    const attachmentsIds = this.getAttachmentsIds(updatedHtml) || [];
    updatedHtml = this.extractAttachmentsFromEditor(updatedHtml);
    return { updatedHtml, mediaIds, attachmentsIds };
  }

  //image
  private async insertImageToHtml(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const imageElements = doc.querySelectorAll('img');
    const promises = Array.from(imageElements).map(async (img) => {
      if (img.id) {
        try {
          img.src = await this.blobsService.getBlobFullUrl(img.id);
        } catch (error) {
          img.src = '';
        }
      }
    });

    await Promise.all(promises);
    return doc.body.innerHTML;
  }

  private removeAllImageSrc(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const imageElements = doc.querySelectorAll('img');
    for (let index = 0; index < imageElements.length; index++) {
      const img = imageElements[index];
      if (img.id) {
        img.src = '';
      }
    }
    return doc.body.innerHTML;
  }

  //video
  private async insertVideoToHtml(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const videoElements = doc.querySelectorAll('video');
    const promises = Array.from(videoElements).map(async (vid) => {
      if (vid.id) {
        const source = vid.querySelector('source');
        if (source) {
          try {
            source.src = await this.blobsService.getBlobFullUrl(vid.id);
          } catch (error) {
            source.src = '';
          }
        }
      }
    });
    await Promise.all(promises);
    return doc.body.innerHTML;
  }

  private removeAllVideoSrc(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const videoElements = doc.querySelectorAll('video');
    for (const vid of videoElements) {
      const source = vid.querySelector('source');
      if (!vid.id) {
        let poster = vid?.poster;
        if (poster) {
          poster = poster.split('/')?.pop();
          vid.id = poster;
          vid.poster = '';
        }
      }
      // this fix an issue when the html element does not update the width and height
      if (!vid.width) {
        vid.width = this.DEFAULT_VIDEO_WIDTH;
      }
      if (!vid.height) {
        vid.height = this.DEFAULT_VIDEO_HEIGHT;
      }
      source.src = '';
    }
    return doc.body.innerHTML;
  }

  private getMediaIds(html: string): string[] {
    if (!html) {
      return [];
    }

    const mediaTags = html.match(attachmentsRegex.FIND_MEDIA_RXJS) || [];

    return mediaTags.map((tag) => {
      return tag.match(attachmentsRegex.FIND_ID_RXJS)?.[1];
    });
  }

  //file
  private retrieveAttachmentsToHtml(html: string) {
    // Create a temporary div element to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    // Find all attachments within the parsed HTML
    const allAttachments = tempDiv.querySelectorAll(`.${FrameEditorConstants.CLASS_ATTACHMENT}`);

    allAttachments.forEach((attach) => {
      if (!attach.innerHTML) {
        // to be able to be backward compatible
        const name = attach.getAttribute('name');
        const size = attach.getAttribute('size');
        const iconAttr = attach.getAttribute('icon');
        let ext = attach.getAttribute('ext');
        if (!ext) {
          ext = getExtensionByFileName(iconAttr ? `${name}.${iconAttr}` : name);
        }
        const icon = getIconByExtension(ext);
        attach.removeAttribute('ext');
        attach.removeAttribute('icon');
        attach.removeAttribute('size');
        attach.removeAttribute('name');
        attach.setAttribute('contenteditable', 'false');
        attach.innerHTML = this.getInnerAttachmentHtml(icon, name, Number(size), ext);
      }
    });

    return tempDiv.innerHTML;
  }

  getInnerAttachmentHtml(icon: string, name: string, size: number, ext: string) {
    return `<div class="details-attachment">
                <img class="${FrameEditorConstants.CLASS_ICON_ATTACHMENT}" src="${icon}" />
                <span class="${FrameEditorConstants.CLASS_NAME_ATTACHMENT}">${name}</span>
                <span  class="${FrameEditorConstants.CLASS_SIZE_ATTACHMENT}">${getFileSize(size)}</span>
                <span style="display:none" class="${FrameEditorConstants.CLASS_REAL_SIZE_ATTACHMENT}">${size}</span>
                <span style="display:none" class="${FrameEditorConstants.CLASS_EXT_ATTACHMENT}">${ext}</span>
              </div>
              <div class="action-attachment">
              <span class="icon-delete-attachment">t</span>                                 
              <div class="context-menu-attachment">•••</div>
              </div>
            `;
  }

  removeClassFromAttachment(html: string, classToRemoved: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const elementsWithClass = doc.querySelectorAll(`.${classToRemoved}`);
    elementsWithClass.forEach((element) => {
      element.classList.remove(classToRemoved);
    });

    return doc.body.innerHTML;
  }

  private extractAttachmentsFromEditor(html: string) {
    // Create a temporary div element to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    // Find all attachments within the parsed HTML
    const allAttachments = tempDiv.querySelectorAll(`.${FrameEditorConstants.CLASS_ATTACHMENT}`);

    allAttachments.forEach((attach) => {
      const ext = attach.querySelector(`.${FrameEditorConstants.CLASS_EXT_ATTACHMENT}`);
      if (ext) {
        attach.setAttribute('ext', ext.textContent);
      } else {
        const image = attach.querySelector(`.${FrameEditorConstants.CLASS_ICON_ATTACHMENT}`);
        let icon;
        if (!image || !image?.['src']?.length) {
          icon = null;
        } else {
          const spliter = image['src'].split('/');
          icon = spliter[spliter.length - 1].split('.')[0];
        }
        if (icon) {
          attach.setAttribute('icon', icon);
        }
      }

      const name = attach.querySelector(`.${FrameEditorConstants.CLASS_NAME_ATTACHMENT}`);
      const real_size = attach.querySelector(`.${FrameEditorConstants.CLASS_REAL_SIZE_ATTACHMENT}`);
      let actualSize;
      if (!real_size) {
        const size = attach.querySelector(`.${FrameEditorConstants.CLASS_SIZE_ATTACHMENT}`);
        actualSize = !size.textContent ? 0 : convertFileSizeToBytes(size.textContent);
        actualSize = !actualSize ? 0 : actualSize;
      } else {
        actualSize = real_size.textContent;
      }
      attach.setAttribute('name', name.textContent);
      attach.setAttribute('size', actualSize);

      attach.removeAttribute('contenteditable');
      attach.innerHTML = '';
    });

    return tempDiv.innerHTML;
  }

  private getAttachmentsIds(html: string): string[] {
    if (!html) {
      return [];
    }

    const attachmentTags = html.match(attachmentsRegex.FIND_ATTACHMENT_CLASS_RXJS) || [];

    return attachmentTags.map((tag) => {
      return tag.match(attachmentsRegex.FIND_ID_RXJS)?.[1];
    });
  }
}
