import { ChangeDetectionStrategy, Component, EventEmitter, Input, model, Output } from '@angular/core';
@Component({
  selector: 'u-toggle-switch',
  templateUrl: './u-toggle-switch.component.html',
  styleUrls: ['./u-toggle-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UToggleSwitchComponent {
  checked = model(false);
  @Input() disabled = false;
  @Input() type: string;
  @Output() onChange = new EventEmitter();
}
