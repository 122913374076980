import { Chats } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class ChatsRpcInvoker implements Chats.Service {
  @invoker
  get sessions$(): Observable<Chats.ChatSession[]> {
    return;
  }

  @invoker
  createSession(request: Chats.CreateChatRequest): Promise<void> {
    return;
  }

  @invoker
  createHistoryItem(history: Chats.ChatHistoryItem): Promise<void> {
    return;
  }

  @invoker
  getChatHistory$(request: Chats.GetChatHistoryRequest): Observable<Chats.GetChatHistoryResponse> {
    return;
  }

  @invoker
  deleteSession(id: string): Promise<void> {
    return;
  }

  @invoker
  update(request: Chats.UpdateChatRequest): Promise<void> {
    return;
  }
}
