import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'u-edit-text',
  templateUrl: './u-edit-text.component.html',
  styleUrls: ['./u-edit-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UEditTextComponent {
  private readonly MAX_LENGTH_DEFAULT = 524288;
  private _autoFocus: boolean = true;

  @Input() focusNoColor: boolean = false;
  @Input() maxLength: number = this.MAX_LENGTH_DEFAULT;
  @Input() ignoreEnter: boolean = false;
  @Input() placeholder: string = '';
  @Input() selectTextOnFocus: boolean = false;
  @Input() set autoFocus(val: boolean) {
    this._autoFocus = val;
    if (val) {
      this.focusContentEditable();
    }
  }

  @Output() onBlur = new EventEmitter<void>();
  @Output() onTextChange = new EventEmitter<string>();
  @Output() onClick = new EventEmitter<void>();
  @Output() onKeydown = new EventEmitter<KeyboardEvent>();
  @Output() onEnterPressed = new EventEmitter<void>();

  @ViewChild('contenteditable', { static: true }) contenteditable!: ElementRef;

  get autoFocus() {
    return this._autoFocus;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {}

  private focusContentEditable(): void {
    setTimeout(() => {
      const element = this.contenteditable.nativeElement;
      element.focus();

      if (this.selectTextOnFocus) {
        this.selectAllText();
      } else {
        this.setCursorToEnd();
      }
      this.cdr.markForCheck();
    }, 0);
  }

  private setCursorToEnd(): void {
    window.getSelection().selectAllChildren(this.contenteditable.nativeElement);
    window.getSelection().collapseToEnd();
    this.cdr.markForCheck();
  }

  private selectAllText(): void {
    const element = this.contenteditable.nativeElement;
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(element);

    selection?.removeAllRanges();
    selection?.addRange(range);
  }

  handleInput(event: Event): void {
    const textContent = (event.target as HTMLElement).innerText;
    if (textContent.length > this.maxLength) {
      const truncatedText = textContent.substring(0, this.maxLength);
      this.renderer.setProperty(this.contenteditable.nativeElement, 'innerText', truncatedText);
      this.setCursorToEnd();
    }
    this.onTextChange.emit(textContent);
  }

  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.onEnterPressed.emit();
      if (this.ignoreEnter) {
        event.preventDefault();
      }
    }

    this.onKeydown.emit(event);
  }

  handlePaste(event: ClipboardEvent): void {
    event.preventDefault();
    const clipboardText = event.clipboardData?.getData('text/plain') || '';
    const sanitizedText = clipboardText.replace(/\\s+/g, ' ').trim();

    const currentText = this.contenteditable.nativeElement.innerText || '';
    const availableSpace = this.maxLength - currentText.length;
    const textToInsert = sanitizedText.substring(0, availableSpace);

    document.execCommand('insertText', false, textToInsert);
    this.onTextChange.emit(this.contenteditable.nativeElement.innerText);
  }

  handleBlur(): void {
    this.onBlur.emit();
  }

  handleClick(): void {
    this.onClick.emit();
  }
}
