<div class="questionnaire-popup-wrapper" [ngClass]="{ 'window-mode': windowMode }">
  <div class="questionnaire-popup-container">
    <div class="questionnaire-popup-header" [class.add-drag]="windowMode">
      <div class="flex"></div>
      <stepper [selectedIndex]="currentStep$ | async" [steps]="steps"> </stepper>
      <u-icon
        class="close-icon"
        [class.remove-drag]="windowMode"
        (mousedown)="closePopup()"
        [model]="closeIcon"
        [styles]="{ fontSize: '12px' }"
      ></u-icon>
    </div>
    <div class="questionnaire-popup-content" [ngSwitch]="currentStep$ | async">
      <upload-questionnaire
        *ngSwitchCase="questionnaireEnum.Upload"
        [selectedAssistant]="selectedAssistant"
        [assistantsList]="assistantsList"
        (onUploadFile)="onUploadFile($event)"
        (nextStep)="goToMapping($event)"
      ></upload-questionnaire>
      <mapping-questionnaire
        *ngSwitchCase="questionnaireEnum.Mapping"
        [data]="data"
        (runProcess)="runProcess($event.updatedWorksheets, $event.processId)"
      ></mapping-questionnaire>
      <processing-questionnaire
        *ngSwitchCase="questionnaireEnum.Processing"
        [data]="data"
        (processingFinish)="processingFinish()"
        (goBack)="goBack($event)"
      ></processing-questionnaire>
      <summary-questionnaire
        *ngSwitchCase="questionnaireEnum.Summary"
        [data]="data"
        (onDownload)="onDownloadFile()"
      ></summary-questionnaire>
      <div class="loading" *ngSwitchCase="questionnaireEnum.Loading">
        <loader-overlay class="loader-overlay"></loader-overlay>
        <span class="loading-text text-bold" *ngIf="preparingUpdatedFile"> {{ questionnaireContent.preparing }}</span>
      </div>
    </div>
  </div>
</div>
