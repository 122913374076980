<div class="action-buttons-section-wrapper">
  <div class="action-buttons-section-container">
    <a class="dismiss-button" (click)="closePopup()">
      <u-icon class="close-button" [model]="{ type: 'font', value: 'icon-times-large' }"></u-icon>
    </a>
    <span class="popup-title">{{ model().popupTitle }}</span>
    <div class="action-buttons-section">
      @if (model().title) {
        <div class="section-title">
          <span class="section-title-text" [pTooltip]="model().titleTooltip" tooltipPosition="top" tooltipStyleClass="u-tooltip">{{
            model().title
          }}</span>
        </div>
      }
      @if (model().description) {
        <div class="section-description">
          <span
            class="section-description-text"
            [pTooltip]="model().descriptionTooltip"
            tooltipPosition="top"
            tooltipStyleClass="u-tooltip"
            >{{ model().description }}</span
          >
        </div>
      }
      <div class="section-buttons">
        @for (button of model().actionButtons; track $index) {
          <span class="section-button" [pTooltip]="button.tooltip" tooltipPosition="bottom" tooltipStyleClass="u-tooltip">{{
            button.text
          }}</span>
        }
      </div>
    </div>
    <u-button
      class="popup-button"
      [versionNumber]="2"
      [label]="'Got it'"
      typeElement="primary"
      [sizeElement]="'large'"
      (onClick)="closePopup()"
    ></u-button>
  </div>
</div>
