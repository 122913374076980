<div class="content empty-state" [class.content-launcher]="isLauncher">
  <u-icon
    *ngIf="svgUrl || iconSvg"
    class="image top-image"
    [class.no-animation]="focus$ | async"
    [class]="animation"
    [inSvg]="svgUrl"
    [model]="{ type: iconType, value: iconSvg}"
    [class.image-launcher]="isLauncher"
  ></u-icon>

  <u-icon *ngIf="icon" [model]="{ type: 'img', value: icon }"></u-icon>

  <h1 class="title" [class.title-launcher]="isLauncher" [innerHTML]="title"></h1>
  <p
    *ngIf="subtitle"
    class="sub-title"
    [ngClass]="{ 'subtitle-launcher': isLauncher, 'primary-subtitle': showAdditionalSubtitle, 'primary-subtitle-launcher': isLauncher }"
  >
    {{ subtitle }}
  </p>
  <ng-container *ngIf="showAdditionalSubtitle">
    <ng-content select="[subtitle]"></ng-content>
  </ng-container>
  <u-button *ngIf="buttonText" [label]="buttonText" type="primary" [styles]="{ width: '100%' }" (click)="actionClick.emit()"></u-button>
</div>
