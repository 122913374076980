import { Assistants } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class AssistantsRpcInvoker implements Assistants.Service {
  @invoker
  get llms$(): Observable<Assistants.AssistantLlmsResponseItem[]> {
    return;
  }

  @invoker
  ticket$(_req: Assistants.AssistantTicketRequest): Observable<Assistants.AssistantTicketContext> {
    return;
  }

  @invoker
  summaryTicket$(_req: Assistants.AssistantSummaryRequest): Observable<Assistants.AssistantSummaryContext> {
    return;
  }

  @invoker
  answers$(_req: Assistants.BaseAnswersSearchRequest): Observable<Assistants.AnswersSearchResponse> {
    return;
  }

  @invoker
  init(request: Assistants.InitRequest): Promise<void> {
    return;
  }
}
