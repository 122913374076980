import { Commands, Search } from '@local/client-contracts';
import { ContextMenuItem } from '@shared/components';
import { Dictionary } from 'lodash';
import { SearchResults } from '../../views';

export type ContextMenuItemsType =
  | 'copy'
  | 'copyVideo'
  | 'delete'
  | 'download'
  | 'link'
  | 'unlink'
  | 'openLink'
  | 'duplicate'
  | 'preview'
  | 'rename';

export const ContextMenuItemsMap: Dictionary<ContextMenuItem> = {
  copy: {
    id: 'copy',
    text: 'Copy',
    command: { type: 'copy' },
    icon: { type: 'font-icon', value: 'icon-copy2' },
  },
  copyVideo: {
    id: 'copy-video',
    text: 'Copy',
    command: { type: 'copy' },
    icon: { type: 'font-icon', value: 'icon-copy2' },
  },
  delete: {
    id: 'delete',
    text: 'Delete',
    command: { type: 'delete' },
    icon: { type: 'font-icon', value: 'icon-delete' },
  },
  download: {
    id: 'download',
    text: 'Download',
    command: { type: 'download-url' } as Commands.DownloadUrl,
    icon: { type: 'font-icon', value: 'icon-download2' },
  },
  link: {
    id: 'link',
    text: 'Edit Link',
    command: { type: 'link' },
    icon: { type: 'font-icon', value: 'icon-link' },
  },
  unlink: {
    id: 'unlink',
    text: 'Unlink',
    command: { type: 'unlink' },
    icon: { type: 'font-icon', value: 'icon-unlink' },
  },
  openLink: {
    id: 'openLink',
    text: 'Open',
    command: { type: 'openLink' },
    icon: { type: 'font-icon', value: 'icon-external-link' },
  },
  duplicate: {
    id: 'duplicate',
    text: 'Duplicate',
    command: { type: 'duplicate' },
    icon: { type: 'font-icon', value: 'icon-copy1' },
  },
  preview: {
    id: 'preview',
    text: 'Preview',
    command: { type: 'preview' } as Commands.Preview<Search.CollectionFileResultItem & SearchResults>,
    icon: { type: 'font-icon', value: 'icon-eye' },
  },
  rename: {
    id: 'rename',
    text: 'Rename',
    command: { type: 'rename-tab' },
    icon: { type: 'font-icon', value: 'icon-register' },
  },
};
