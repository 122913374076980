<div class="u-auto-complete-wrapper">
  <p-autoComplete
    [(ngModel)]="selectedItems"
    [suggestions]="suggestions"
    [ngClass]="{ close: closeSuggestions }"
    [placeholder]="placeholder"
    (completeMethod)="onCompleteMethod($event)"
    [completeOnFocus]="openListOnClick"
    [field]="field"
    [multiple]="multiple || this.viewMode === 'tag'"
    [group]="group"
    [autoHighlight]="true"
    [ngClass]="[maxChar + '-input']"
    [appendTo]="appendTo"
    [panelStyleClass]="'u-autocomplete-panel ' + (className ? className : '')"
    [disabled]="disabled"
    [optionDisabled]="'disable'"
    [class]="'u-autoComplete ' + (multiple ? 'autocomplete-multiple' : 'autocomplete-single')"
    (ngModelChange)="onChangeEvent($event)"
    (onFocus)="onFocusAutoComplete($event)"
    (onHide)="onHideAutoComplete($event)"
    (onBlur)="onBlurAutoComplete($event)"
    #autocomplete
  >
    <ng-template let-group pTemplate="group">
      <ng-container *ngTemplateOutlet="groupTemplate || defaultGroup; context: { $implicit: group }"></ng-container>
    </ng-template>

    <ng-template pTemplate="removetokenicon">
      <u-icon class="removetokenicon" [model]="{ type: 'font', value: 'icon-times' }" [styles]="{ fontSize: '14px' }"></u-icon>
    </ng-template>

    <ng-template let-item pTemplate="item">
      <ng-container *ngTemplateOutlet="itemTemplate || defaultItem; context: { $implicit: item }"></ng-container>
    </ng-template>

    <ng-template let-item pTemplate="footer">
      <ng-container *ngIf="displayCreateNew; else normalFooter">
        <div
          class="create-new"
          [ngClass]="{ 'empty-state': emptyState && !query }"
          *ngIf="(allowCreateNew && query) || (emptyState && !query)"
          (click)="createNew()"
        >
          <u-icon class="create-new-button" [model]="{ type: 'font', value: 'icon-plus-thick' }" [styles]="{ fontSize: '12px' }"></u-icon>
          <div class="create-new-title">Create new</div>
          <div class="create-new-input">{{ query }}</div>
        </div>
      </ng-container>
      <ng-template #normalFooter>
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      </ng-template>
    </ng-template>

    <ng-template let-item pTemplate="selectedItem">
      <ng-container *ngTemplateOutlet="selectedItemTemplate || defaultSelectedItem; context: { $implicit: item }"></ng-container>
    </ng-template>
  </p-autoComplete>
  <a *ngIf="displayDismissButton && dismissButton" class="u-auto-complete-button dismiss" (click)="onDismiss()">
    <u-icon class="close-button" [model]="{ type: 'font', value: 'icon-times-large' }"></u-icon>
  </a>
  <a *ngIf="arrowButton" class="u-auto-complete-button arrow" (click)="openDropdown()">
    <u-icon class="arrow-button" [model]="{ type: 'font', value: 'icon-chevron-down' }"></u-icon>
  </a>
</div>

<ng-template #defaultSelectedItem let-value pTemplate="selectedItem">
  <span>{{ value.name }}</span>
</ng-template>

<ng-template #defaultItem let-value pTemplate="item">
  <div class="default-p-autocomplete-item" *ngIf="value[field] !== 'No results'; else noResults">
    <div class="title ellipsis">{{ value[field] }}</div>
    <span *ngIf="descriptionField" class="subtitle ellipsis">{{ value[descriptionField] }}</span>
  </div>
  <ng-template #noResults>
    <div class="title ellipsis" (click)="$event.stopPropagation()">{{ value[field] }}</div>
  </ng-template>
</ng-template>

<ng-template #defaultGroup let-group pTemplate="group">
  <span>{{ group.name }}</span>
</ng-template>
