import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { UToggleButtonComponent } from './u-toggle-button.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [UToggleButtonComponent],
  imports: [CommonModule, ToggleButtonModule, FormsModule],
  exports: [UToggleButtonComponent],
})
export class UToggleButtonModule {}
