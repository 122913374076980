import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnInit, Output, ViewChild } from '@angular/core';
import { ImageCropperPopupData, SizeImage } from './image-cropper-popup-data';
import { FileUploadPreview, PopupRef, UInputComponent } from '@local/ui-infra';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Posts, Permissions } from '@local/client-contracts';
import { base64ToFile } from 'ngx-image-cropper';
import { BlobsService } from 'src/app/bar/services/blobs/blob.service';
import { Config } from '@environments/config';
import { isValidPatternUrl } from '@local/ts-infra';
import { cloneDeep } from 'lodash';
import { SizeImageConstants } from './size-image.const';

@Component({
  selector: 'image-cropper-popup',
  templateUrl: './image-cropper-popup.component.html',
  styleUrls: ['./image-cropper-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCropperPopupComponent implements OnInit {
  private readonly LINK_INPUT_MIN_CHAR = 5;
  readonly DESCRIPTION_SIZE_IMAGES = SizeImageConstants.DESCRIPTION_SIZE_IMAGES;
  stage: 'crop' | 'upload' = 'crop';
  private croppedImage: ImageCroppedEvent;
  private croppedImagePreview: FileUploadPreview;
  private imageName: string;
  private imageType: string;
  private carousel: Posts.CarouselData[] = [];
  carouselItem: Posts.CarouselData;
  isValidUrl = true;
  imageToCrop: File;
  link: string;
  showFileUploadError = false;
  isLoading = false;
  isEditMode: boolean;
  sizeImage: SizeImage;
  aspectRatio: number;

  @HostBinding('class')
  get stageClass() {
    return this.stage;
  }

  @ViewChild('inputLink') inputLink: UInputComponent;
  @Output() onCancel = new EventEmitter();
  @Output() onImageReady = new EventEmitter<Posts.CarouselData[]>();

  constructor(
    private ref: PopupRef<ImageCropperPopupComponent, ImageCropperPopupData>,
    private cdr: ChangeDetectorRef,
    private blobsService: BlobsService
  ) {}

  get supportedImageTypes() {
    return Config.createPostWidget.supportedImageTypes;
  }

  async ngOnInit(): Promise<void> {
    this.isEditMode = !isNaN(this.ref.data?.carouselEditIndex);
    this.stage = !this.isEditMode ? 'crop' : 'upload';
    this.carousel = cloneDeep(this.ref.data?.carousel);
    this.sizeImage = this.ref.data?.size || 'large';
    this.aspectRatio = this.sizeImage === 'large' ? 4 / 1 : 7 / 6;
    if (this.isEditMode) {
      this.isLoading = true;
      this.carouselItem = this.carousel[this.ref.data.carouselEditIndex];
      this.link = this.carouselItem.link;
      this.imageName = this.carouselItem.imageName;
      this.initImageToEdit();
    } else {
      this.imageToCrop = this.ref.data?.imageToCrop;
      this.imageType = this.imageToCrop?.type;
      this.link = this.ref.data?.link;
      this.imageName = this.imageToCrop.name;
      this.croppedImagePreview = null;
    }

    this.showFileUploadError = false;
    this.cdr.markForCheck();
  }

  private async initImageToEdit() {
    this.croppedImagePreview = {
      url: await this.blobsService.getBlobFullUrl(this.carouselItem.imageId),
      name: this.imageName,
    };
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  onCroppedImageReady(event: ImageCroppedEvent) {
    this.showFileUploadError = false;
    this.croppedImage = event;
    this.croppedImagePreview = {
      url: this.croppedImage.base64,
      name: this.imageName,
    };
    this.cdr.markForCheck();
  }

  fileChangeEvent(file: File) {
    if (!file) return;
    this.imageToCrop = file;
    this.imageType = this.imageToCrop.type;
    this.imageName = this.imageToCrop.name;
    this.stage = 'crop';
    this.cdr.markForCheck();
  }

  onLinkInputBlur(event) {
    if (!event) {
      this.isValidUrl = true;
      this.link = null;
      return;
    }
    event = event.startsWith('http://') || event.startsWith('https://') ? event : `https://${event}`;
    if (event?.length > this.LINK_INPUT_MIN_CHAR) {
      this.isValidUrl = isValidPatternUrl(event);
    }
    this.link = event;
    this.cdr.markForCheck();
  }

  onLinkInputChange(event) {
    if (!event) {
      this.isValidUrl = true;
      this.link = null;
      this.cdr.markForCheck();
    }
  }

  onNext() {
    this.uploadImage(this.imageName, this.imageType, this.croppedImage.base64);
  }

  tryAgainFileUpload() {
    this.onNext();
  }

  onSave() {
    if (this.link) {
      this.isValidUrl = isValidPatternUrl(this.link);
    }
    if (this.isValidUrl) {
      this.carouselItem.link = this.link;
      if (this.isEditMode) {
        this.carousel[this.ref.data.carouselEditIndex] = this.carouselItem;
      } else {
        this.carousel.push(this.carouselItem);
      }
      this.onImageReady.emit(this.carousel);
    }
  }

  private async uploadImage(imageName: string, fileType: string, imageUrl: string): Promise<string> {
    this.isLoading = true;
    this.showFileUploadError = false;

    try {
      const fileBlob = base64ToFile(imageUrl);
      const file: File = new File([fileBlob], imageName, {
        type: fileType,
      });
      const shareOptions: Permissions.ShareOptions = {
        level: 'Following',
        followingIds: [this.ref.data?.tabId],
      };

      const timeoutRef = this.startUploadTimeout();
      const blobId = (await this.blobsService.create(imageName, fileType, shareOptions))?.id;
      const uploadResponse = await this.blobsService.upload(file, blobId);
      this.stopUploadTimeout(timeoutRef);

      if (this.showFileUploadError) {
        return;
      }

      if (uploadResponse.status >= 400) {
        this.showFileUploadError = true;
        this.isLoading = false;
        this.cdr.markForCheck();
        return;
      }
      if (!blobId) {
        this.showFileUploadError = true;
        this.isLoading = false;
        this.cdr.markForCheck();
        return;
      }
      this.carouselItem = {
        imageId: blobId,
        imageName: this.imageName,
      };
      this.stage = 'upload';
      this.isLoading = false;
      this.focusInputLink();
      this.cdr.markForCheck();
    } catch (error) {
      this.showFileUploadError = true;
    }
  }

  private focusInputLink() {
    setTimeout(() => {
      this.inputLink?.inputElement?.el.nativeElement.focus();
      this.cdr.markForCheck();
    }, 0);
  }

  private startUploadTimeout() {
    return setTimeout(() => {
      this.showFileUploadError = true;
      this.cdr.markForCheck();
    }, Config.createPostWidget.uploadImageTimeoutMilliseconds);
  }

  private stopUploadTimeout(timeoutRef: any) {
    clearTimeout(timeoutRef);
  }
}
