<div class="rename-container">
  <u-edit-text
    [placeholder]="inputPlaceholder"
    [selectTextOnFocus]="true"
    [autoFocus]="true"
    (onTextChange)="onChangeName($event)"
    (blur)="rename()"
    (outsideClick)="rename()"
    (onEnterPressed)="rename()"
  ></u-edit-text>
</div>
