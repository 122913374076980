import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { isExtension } from '../../../../../../common/src/platform';

@Injectable({
  providedIn: 'root',
})
export class SearchGuard implements CanActivate {
  private readonly isExtension = isExtension();
  constructor() {}

  canActivate(): boolean {
    return !this.isExtension;
  }
}
