<ng-container *ngIf="model" [ngSwitch]="model?.type">
  <ng-container *ngSwitchCase="'img'">
    <div
      *ngIf="inSvg"
      class="icon in-svg"
      [svg]="inSvg"
      [svgIgnore]="inSvgIgnoreColors"
      [svgFallback]="fallback"
      [style]="styles"
      [id]="imageId"
      [class]="customClass"
      [ngClass]="[disabled ? 'disabled' : '']"
    ></div>

    <div
      *ngIf="iconCtx"
      class="icon"
      [icon]="model.value"
      [iconCtx]="iconCtx"
      [id]="imageId"
      [style]="styles"
      [ngClass]="[disabled ? 'disabled' : '']"
    >
      <ng-content></ng-content>
    </div>
    <ng-container *ngIf="!inSvg && !iconCtx">
      <img
        data-cy="u-icon-image"
        class="icon"
        [style]="styles"
        [icon]="model.value"
        [fallback]="fallback"
        [attr.referrerpolicy]="referrerpolicy"
        [reverseTheme]="reverseTheme"
        [id]="imageId"
        (error)="error.emit($event)"
        [class]="customClass"
        [ngClass]="[disabled ? 'disabled' : '']"
      />
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'font'">
    <i
      [style]="styles"
      class="font-icon"
      [ngClass]="[model.value, isBold ? 'font-bold' : '', customClass ?? '', disabled ? 'disabled' : '']"
      (click)="onClickFontIcon($event)"
    >
      <ng-content></ng-content>
    </i>
  </ng-container>
  <ng-container *ngSwitchCase="'font-icon'">
    <i
      [style]="styles"
      class="font-icon"
      [ngClass]="[model.value, isBold ? 'font-bold' : '', customClass ?? '', disabled ? 'disabled' : '']"
    >
      <ng-content></ng-content>
    </i>
  </ng-container>
  <ng-container *ngSwitchCase="'lottie-animation'">
    <ng-lottie [options]="animationValue()" (animationCreated)="animationCreated.emit($event)" />
  </ng-container>
</ng-container>
