import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  TrackByFunction,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Experiences, Results } from '@local/client-contracts';
import { AssistantsIconsConst, Constants } from '@local/common';
import { UButtonComponent, UInputComponent, UiIconModel } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Filter, FilterChangeData } from '@shared/components/filters/models';
import { EventsService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { getWidthBreakpointScreen, windowSizeObserver } from '@shared/utils';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { NgScrollbar } from 'ngx-scrollbar';
import { combineLatest, distinctUntilChanged, take } from 'rxjs';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';
import { FiltersService } from 'src/app/bar/services/filters.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { MultiChoicePopupService } from 'src/app/bar/services/multi-choice-popup.service';
import { NavTreeService } from 'src/app/bar/services/nav-tree.service';
import { SearchOptions, SearchResultContext, SearchService, SearchSession } from 'src/app/bar/services/search';
import { SearchParamsService } from 'src/app/bar/services/search-params.service';
import { AssistantsSourceSettings } from 'src/app/bar/services/search/client';
import { SidebarService } from 'src/app/bar/services/sidebar.service';
import { SortService } from 'src/app/bar/services/sort.service';
import { ExperienceSearchItem, HeaderItem, ScrollDirection, SortOption, getSortOptions, isHeader } from '../../../results';
import { ResultSettings } from '../../../results/utils/results-settings';
import { assistantContent } from '../../helpers/assistant.content';
import { ASSISTANTS_CARD_JUMP } from '../../models/assistant-card.model';
import { KeyName, isEnterKey, isKey, isModifierKey, keyCodes } from '@local/ts-infra';
import { Config } from '@environments/config';
import { InfiniteScrollService } from '../../../results/services/infinite-scroll.service';
import { InfiniteKeyboardHelperService } from '@shared/helper/infinite-keyboard-helper.service';
import { getAssistantDisplayName, getAssistantGroupName } from '@local/common-web';
import { TitleBarService } from '@shared/services/title-bar.service';

@UntilDestroy()
@Component({
  selector: 'assistants',
  templateUrl: './assistants.component.html',
  styleUrls: ['./assistants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssistantsComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly assistantsIcons = AssistantsIconsConst;
  assistants: ExperienceSearchItem[];
  assistantContent = assistantContent;
  assistantNotionUrl = Constants.ASSISTANT_NOTION_URL;
  assistantIcon: UiIconModel;
  isLauncher: boolean;

  // keyboard
  private keysHandlerId: string;
  selectedIndex = 0;
  private keyboardHelperService: InfiniteKeyboardHelperService;

  // scroll
  private scrollService: InfiniteScrollService;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  @ViewChildren('assistantItem', { read: ElementRef }) assistantsElements: QueryList<ElementRef>;

  // Search
  private searchSession: SearchSession;
  private query: string;
  currentState: any;
  displayContext: SearchResultContext;
  loaded: boolean;
  trackByAssistant: TrackByFunction<Experiences.ExperienceItem> = (index, assistant) => assistant.id;
  header: HeaderItem;
  emptyState = false;

  //filters
  filters: Filter[];
  typeFilter: string;
  displayTypeFilter: string;

  //header
  smallInputSearchFocus = false;
  @ViewChild('smallInputSearch') smallInputSearch: UInputComponent;
  sortOptions: SortOption[];
  layoutMode: Results.LayoutType = 'list';

  //size
  @ViewChild('contextContainer') contextContainer: ElementRef;
  private windowSize$ = windowSizeObserver();
  private contextObserver: ResizeObserver;
  smallScreen: boolean;

  get selected(): ExperienceSearchItem {
    return this.assistants[this.selectedIndex];
  }

  constructor(
    public hubService: HubService,
    private cdr: ChangeDetectorRef,
    private keyboardService: KeyboardService,
    private searchService: SearchService,
    private filtersService: FiltersService,
    public searchParamsService: SearchParamsService,
    private sidebarService: SidebarService,
    public sortService: SortService,
    private assistantService: ExperiencesService,
    private routerService: RouterService,
    private navTreeService: NavTreeService,
    private multiChoicePopupService: MultiChoicePopupService,
    private eventsService: EventsService,
    private titleService: TitleBarService
  ) {}

  ngOnDestroy(): void {
    this.keyboardService.unregisterKeyHandler(this.keysHandlerId);
    this.searchSession?.destroy();
    this.contextObserver?.disconnect();
  }

  ngOnInit() {
    this.hubService.readOnly = true;
    this.hubService.autoFocus = true;
    this.hubService.changeFocusStateMultiCalls(true, true);
    this.hubService.searchMethod = 'Quick-Search';

    let first = true;
    let nodeClicked = false;
    this.sidebarService.onNodeClick$.pipe(untilDestroyed(this)).subscribe((node) => {
      if (node === 'assistants' && this.query?.length) {
        nodeClicked = true;
      }
    });
    combineLatest([this.hubService.state$.pipe(untilDestroyed(this)), this.assistantService.visible$.pipe(untilDestroyed(this))]).subscribe(
      ([state, assistants]) => {
        this.emptyState = assistants?.length === 0 || Config.search.emptyStates.assistants;
        const newState = cloneDeep(state);
        delete newState.fyi; // ignore fyi state change
        if (!isEqual(newState, this.currentState)) {
          this.currentState = newState;
          this.search(first || nodeClicked ? 'navigation_tree' : null);
          first = false;
          nodeClicked = false;
        }
      }
    );
    this.sidebarService.activeNode$.pipe(untilDestroyed(this)).subscribe((node) => {
      if (!node) {
        return;
      }
      const nodePath = node.data?.title?.toLowerCase();
      const isAssistantType = Experiences.ExperienceTypeArray.includes(nodePath);
      this.assistantService.initAssistantPageFilters(isAssistantType ? nodePath : '');
    });
    this.hubService.isLauncher$.pipe(untilDestroyed(this)).subscribe((l) => (this.isLauncher = l));
    this.scrollService = new InfiniteScrollService();
    this.setUpKeyboardHelperService();
    this.setFilters();
    this.sortOptions = getSortOptions('assistants');
    this.checkSmallScreen();
    this.initLayout();
    this.assistantService.initSlackBotInstallInfo();
  }

  ngAfterViewInit(): void {
    this.initNgScrollerHeight();
  }

  // scroll
  initNgScrollerHeight() {
    this.windowSize$.pipe(untilDestroyed(this)).subscribe(() => {
      this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
      this.checkSmallScreen();
    });
    if (this.contextContainer) {
      const { nativeElement: el } = this.contextContainer;
      this.contextObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        this.calculateNgScrollerHeight(entries[0]?.target);
      });
      this.contextObserver.observe(el);
    }
  }

  private checkSmallScreen() {
    this.smallScreen = getWidthBreakpointScreen() === 'small';
    this.cdr.markForCheck();
  }

  calculateNgScrollerHeight(el) {
    if (!el) return;
    const boundingClientRect = el.getBoundingClientRect();
    el['style'].height = window.innerHeight - boundingClientRect.top + 'px';
  }

  //search
  async search(trigger?: string) {
    this.searchSession = this.searchService.getOrCreateSearchSession('assistants');
    const source = this.getSource();
    const searchTrigger = this.hubService.getState('search-trigger')[0] || trigger;
    this.query = this.hubService.query?.toLowerCase();
    const sort = this.searchParamsService.getSort();
    const options: SearchOptions = {
      resetSession: true,
      query: this.query,
      sources: [
        {
          ...source,
          filters: { preFilters: this.filtersService.getPreFilters(true), postFilters: this.filtersService.postFilters },
          sorting: sort,
        } as AssistantsSourceSettings,
      ],
      trigger: searchTrigger ? searchTrigger : 'user_query',
      telemetrySearchMethod: 'Quick-Search',
    };
    this.searchSession
      .search$(options)
      .pipe(untilDestroyed(this))
      .subscribe((ctx: SearchResultContext) => {
        this.displayContext = ctx;
        if (ctx.searchCompleted) {
          this.assistants = ctx?.items.filter((i) => ['assistant', 'header'].includes(i.type)) as ExperienceSearchItem[];
          this.keyboardHelperService?.onInit(this.selectedIndex, this.assistants);
          this.loaded = this.assistants && this.displayContext.searchCompleted;
          if (this.selected?.type === 'header') {
            this.updateSelectedIndex(1);
          }
          this.cdr.markForCheck();
          setTimeout(() => {
            this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
          }, 0);
        }
      });
  }

  private getSource() {
    return !isEmpty(this.currentState) ? ResultSettings.searchViewAssistants : ResultSettings.defaultAssistants;
  }

  //keyboard
  setUpKeyboardHelperService() {
    this.keyboardHelperService = new InfiniteKeyboardHelperService('horizontal');
    this.keyboardHelperService.updateCdr.pipe(untilDestroyed(this)).subscribe(() => this.cdr.markForCheck());
    this.keyboardHelperService.updateSelectedIndex.pipe(untilDestroyed(this)).subscribe((index) => {
      if (this.selectedIndex === null) {
        this.updateSelectedIndex(0);
      } else if (index === undefined) {
        this.selectedIndex = null;
      } else {
        this.selectedIndex = index;
      }
      this.cdr.markForCheck();
    });
    this.registerKeyHandler();
  }

  updateSelectedIndex(index: number) {
    this.selectedIndex = this.keyboardHelperService.selectedIndex = index;
    this.cdr.markForCheck();
  }

  private registerKeyHandler() {
    if (this.keysHandlerId) return;
    this.keysHandlerId = this.keyboardService.registerKeyHandler((keys, event) => {
      this.handleKeys(keys, event);
    }, 5);
  }

  private handleKeys(eventKeys: Array<KeyName>, event: CustomKeyboardEvent): void {
    const key = eventKeys[0];
    const modifiers: KeyName[] = eventKeys.filter((k) => isModifierKey(k));

    if (isEnterKey(key)) {
      if (!this.selected) return;
      if (isHeader(this.selected) && this.selected.isFooter) {
        this.resultHeaderClick(this.selected.group.name);
        return;
      }
      this.routerService.navigateByUrl(`assistant/${this.selected.id}`);
      this.endKeyboardClick(event);
      event.stopPropagation();
      return;
    }

    if (isKey(event, keyCodes.ArrowDown) || isKey(event, keyCodes.ArrowUp)) {
      if (this.selectedIndex === null && isKey(event, keyCodes.ArrowDown)) {
        this.updateSelectedIndex(0);
        this.hubService.focusPosition = null;
      } else {
        this.moveUpOrDown(isKey(event, keyCodes.ArrowDown) ? 'down' : 'up');
        if (this.smallInputSearch) {
          event.preventDefault();
        }
      }
    }
    if (this.layoutMode === 'gallery') {
      if (isKey(event, keyCodes.ArrowRight)) {
        if (this.selectedIndex === this.assistants.length - 1) {
          this.updateSelectedIndex(this.selectedIndex);
        } else {
          this.updateSelectedIndex(this.selectedIndex + 1);
        }
      }

      if (isKey(event, keyCodes.ArrowLeft)) {
        if (this.selectedIndex === 0) {
          this.updateSelectedIndex(0);
        } else {
          this.updateSelectedIndex(this.selectedIndex - 1);
        }
      }
    }
    this.scrollService.scrollIfNeeded(this.assistantsElements.toArray()[this.selectedIndex]);

    if (modifiers.length === 2 && modifiers.includes('shift') && modifiers.includes('control')) {
      if (isKey(event, keyCodes.c)) {
        this.assistantService.copyUrl(this.selected);
        this.endKeyboardClick(event);
      }
    }
    if (key === 'slash') {
      if (this.smallInputSearch && !this.smallInputSearchFocus) {
        this.smallInputSearch.focusInput();
        event.stopPropagation();
        event.preventDefault();
      }
    }
    this.cdr.markForCheck();
  }

  private endKeyboardClick(event: CustomKeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  private moveUpOrDown(direction: ScrollDirection) {
    const jump = this.layoutMode === 'list' ? 1 : ASSISTANTS_CARD_JUMP[getWidthBreakpointScreen()];
    switch (direction) {
      case 'up': {
        if (!this.selectedIndex) {
          this.selectedIndex = null;
          this.hubService.focusPosition = 'filters';
          this.cdr.markForCheck();
          return;
        }
        if (this.selectedIndex - jump > 0) {
          this.updateSelectedIndex((this.selectedIndex -= jump));
        } else {
          this.updateSelectedIndex(0);
        }
        break;
      }
      case 'down': {
        if (this.selectedIndex + jump < this.assistants.length) {
          this.updateSelectedIndex((this.selectedIndex += jump));
        } else {
          this.updateSelectedIndex(this.assistants.length - 1);
        }
        break;
      }
    }
  }

  //header functions

  updateQuery(event: string) {
    this.hubService.inputQuery = event;
    this.hubService.focusPosition = null;
    this.cdr.markForCheck();
  }

  openSort(elm: UButtonComponent) {
    const popupRefSort = this.sortService.openSortPopup(elm, { sortOptions: this.sortOptions });

    popupRefSort.close$.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  private initLayout() {
    this.assistantService.assistantsLayoutMode$.pipe(untilDestroyed(this)).subscribe((layout: Results.LayoutType) => {
      if (layout) {
        this.layoutMode = layout;
        this.cdr.markForCheck();
      }
    });
  }

  changeLayout() {
    this.layoutMode = this.layoutMode === 'list' ? 'gallery' : 'list';
    this.assistantService.saveAssistantsLayoutModeRoaming(this.layoutMode);
  }

  resultHeaderClick(group: string) {
    this.routerService.navigateByUrl(`/assistants/${group}`);
  }

  //filters
  setFilters() {
    combineLatest([
      this.filtersService.allFilters$,
      this.assistantService.assistantPageFilters$,
      this.navTreeService.currentNode$.pipe(distinctUntilChanged()),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([selectedFilters, filters, currentNode]) => {
        this.typeFilter = currentNode?.data?.filters?.['assistant-type'][0];
        if (!this.typeFilter) {
          this.displayTypeFilter = undefined;
        } else {
          this.displayTypeFilter = getAssistantDisplayName(this.typeFilter.toLowerCase() as Experiences.ExperienceType);
        }
        if (Experiences.ExperienceUpperCase.includes(this.typeFilter?.toLowerCase())) {
          this.titleService.active = this.typeFilter.toUpperCase();
        }
        this.assistantIcon = this.assistantsIcons[getAssistantGroupName(this.typeFilter, 'lower')];
        const filtersToRemove = [];
        if (!selectedFilters['assistant-type']?.includes('Slack')) {
          filtersToRemove.push('assistant-slack-workspace');
        }
        if (!selectedFilters['assistant-type']?.includes('Teams')) {
          filtersToRemove.push('assistant-team');
        }
        const newFilters = cloneDeep(filters?.filter((f) => !filtersToRemove.includes(f.name)) || []);
        for (const filter of newFilters) {
          const selected = new Set(selectedFilters[filter.name] || []);
          filter.values = filter.values?.map((v) => ({ ...v, selected: selected.has(v.value) }));
        }
        this.filters = newFilters;
        this.initInlineFilters(currentNode);
      });
  }

  private initInlineFilters(node: any) {
    const typeFilter = node?.data?.filters?.['assistant-type'];
    if (node?.data?.view?.name === 'assistant' && !!typeFilter) {
      this.filtersService.setFilters('assistant-type', [typeFilter], 'pre', false);
    }
  }

  onFilterChange(data: FilterChangeData) {
    const name = data.name;
    if (data.action === 'ClearAll') {
      this.filtersService.setFilters(name, [], 'pre');
      return;
    }
    if (name === 'assistant-type') {
      this.filtersService.removeAllFilters('all', false, ['assistant-createdBy']);
    }
    const values = data.current?.values?.map((v) => v.value);
    this.filtersService.setFilters(data.name, values, 'pre', false);
  }

  clearAllFilters() {
    this.filtersService.removeAllFilters('all', false);
  }

  private async onAddAssistantClick(app: string) {
    app = app.toLowerCase();
    this.eventsService.event('assistant.add_to', {
      name: `add_to_${app}`,
      location: { title: 'assistants' },
    });

    if (Experiences.ExperiencesWithEditMode.includes(app)) {
      if (Experiences.ExperiencesWithDraftMode.includes(app)) {
        this.assistantService.createDraftAssistant(app as Experiences.ExperienceType, { state: { mode: 'new' }, replaceUrl: false });
      } else {
        this.assistantService.createAssistant({ experienceType: app as Experiences.ExperienceType });
      }
    } else {
      this.routerService.navigateByUrl(`/assistants/add-to/${app}`);
    }
  }

  openAddAssistantOptionPopup(elm: UButtonComponent) {
    const popupRefNewCollection = this.multiChoicePopupService.openMultiChoicePopup(elm.button.nativeElement, false, 'assistants');
    popupRefNewCollection.compInstance.onClickEvent.pipe(take(1)).subscribe((kind) => {
      this.onAddAssistantClick(kind);
    });
  }

  navigateToLearnMore() {
    window.open(this.assistantNotionUrl);
  }
}
