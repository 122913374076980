<div class="container" [class]="{ stale: link?.syncStatus === 'stale', 'edit-mode': isPaletteOpen, active: active }">
  <div class="last-sync">
    <div class="last-sync-container" *ngIf="lastSync?.endTime">
      <u-icon class="last-sync-icon" [model]="{ type: 'font', value: 'icon-sync' }" [styles]="{ 'font-size': '12px' }"></u-icon>
      <div class="last-sync-text" [pTooltip]="tooltipModifiedSyncTime" tooltipPosition="top" tooltipStyleClass="u-tooltip">
        {{ preDisplayModifiedSyncTime }}{{ displayModifiedSyncTime }}
      </div>
    </div>
  </div>
  <ng-container *ngIf="!deleteInitiated">
    <div class="link-info" *ngIf="!isShared && link?.syncStatus !== 'stale'; else shared">
      <avatar
        [customClass]="link.color"
        [url]="link?.avatar.length ? link?.avatar : workspaceIcon"
        [size]="'none'"
        class="avatar-link"
      ></avatar>
      <h1 class="title has-link link-info-title" [pTooltip]="link.title" tooltipPosition="bottom" tooltipStyleClass="u-tooltip">
        <span class="title-ellipsis">
          {{ link?.title }}
        </span>
      </h1>
      <h3 class="subtitle has-link link-info-subtitle" [pTooltip]="link.title" tooltipPosition="bottom" tooltipStyleClass="u-tooltip">
        {{ link?.subtitle }}
      </h3>

      <ng-container *ngTemplateOutlet="linkTimeInfo"></ng-container>

      <h3
        class="clickable-subtitle"
        *ngIf="!isPaletteOpen"
        [pTooltip]="link.title"
        tooltipPosition="bottom"
        tooltipStyleClass="u-tooltip"
        [class]="link.color"
        #linkName
      >
        {{ link?.name }}
      </h3>
    </div>

    <ng-template #shared>
      <div class="shared" *ngIf="link?.syncStatus !== 'stale'">
        <div class="top-wrapper" [ngClass]="{ disabled: hideResults }">
          <div class="info-container">
            <avatar [customClass]="link.color" [url]="workspaceIcon" [size]="'none'" class="ws-avatar"></avatar>
            <div class="shared-title-container">
              <p
                class="shared-title has-link ellipsis"
                [style.color]="'var(--' + link.color + ')'"
                [pTooltip]="link.name"
                tooltipPosition="bottom"
                tooltipStyleClass="u-tooltip"
              >
                {{ link?.name }}
              </p>
              <u-icon
                class="restricted-icon"
                *ngIf="link?.shareOptions?.restricted"
                [model]="{ type: 'font', value: 'icon-lock-fill' }"
                [pTooltip]="restrictedTooltip"
                [escape]="false"
                tooltipStyleClass="u-tooltip restricted"
                tooltipPosition="top"
                (click)="openLearnMore($event)"
                [styles]="{ 'font-size': '13px', color: 'var(--' + link.color + ')' }"
              ></u-icon>
            </div>
            <p class="link-text key ellipsis" [pTooltip]="link?.key" tooltipPosition="bottom" tooltipStyleClass="u-tooltip">
              {{ link?.key }}
            </p>
            <ng-container *ngTemplateOutlet="linkTimeInfo"></ng-container>
          </div>
        </div>
        <div *ngIf="!isPaletteOpen" class="top-border"></div>
        <div class="bottom-visibility-container" *ngIf="!isPaletteOpen && !(link?.syncStatus == 'stale' && displaySharedLink)">
          <div class="text">
            <p>Link visibility</p>
            <u-icon
              class="info-icon"
              pTooltip="Show or hide resources from this link in your search results only"
              tooltipPosition="top"
              tooltipStyleClass="u-tooltip"
              *ngIf="isShared"
              [model]="{ type: 'font', value: 'icon-info' }"
            ></u-icon>
          </div>
          <ng-container *ngTemplateOutlet="linkVisivility"></ng-container>
        </div>
      </div>
    </ng-template>

    <ng-container *ngIf="link?.syncStatus === 'stale'" #stale>
      <div class="link-info none-shared">
        <div *ngIf="isPaletteOpen">
          <avatar
            [customClass]="link.color"
            [url]="link?.avatar.length ? link?.avatar : workspaceIcon"
            [size]="'none'"
            class="avatar"
          ></avatar>
          <h1 class="title has-link link-info-title">
            <span
              class="title-ellipsis"
              style="color: var(--color-primary)"
              [pTooltip]="link.key"
              tooltipPosition="top"
              tooltipStyleClass="u-tooltip"
            >
              {{ link?.name }}
            </span>
          </h1>
          <p class="link-text key ellipsis">
            {{ link?.key }}
          </p>

          <ng-container *ngTemplateOutlet="linkTimeInfo"></ng-container>

          <div class="button-wrapper" [class.member]="!(displaySharedLink || !isShared)">
            <p class="subtitle link-info-subtitle outdated-text {{ isShared ? 'open' : 'private' }}">
              Your account is outdated
              <u-icon
                class="font-info"
                [model]="{ type: 'font', value: 'icon-info' }"
                [pTooltip]="tooltipMessage"
                tooltipPosition="top"
                tooltipStyleClass="u-tooltip"
              ></u-icon>
            </p>
            <u-button
              *ngIf="displaySharedLink || !isShared"
              class="primary-button"
              label="Reconnect"
              (click)="onRefresh()"
              type="primary"
              [styles]="{ height: '28px', width: '96px' }"
            ></u-button>
          </div>
        </div>
        <ng-container *ngIf="!isPaletteOpen">
          <h1 class="title has-link link-info-title">
            <span class="title-ellipsis" [pTooltip]="link.key" tooltipPosition="top" tooltipStyleClass="u-tooltip">
              {{ link?.name }}
            </span>
          </h1>
          <p class="subtitle link-info-subtitle outdated-text">
            Your account is outdated
            <u-icon
              class="font-info"
              [model]="{ type: 'font', value: 'icon-info' }"
              [pTooltip]="tooltipMessage"
              tooltipPosition="top"
              tooltipStyleClass="u-tooltip"
            ></u-icon>
          </p>
          <div class="primary-button" *ngIf="displaySharedLink || !isShared">
            <u-button label="Reconnect" (click)="onRefresh()" type="primary" [styles]="{ height: '28px', width: '96px' }"></u-button>
          </div>
          <div class="see-details">
            <u-button
              label="See details"
              type="tertiary"
              class="icon-button"
              [styles]="{ 'font-weight': '400', 'line-height': '28px', 'padding-top': '4px' }"
            ></u-button>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div>
      <indicator
        class="linked"
        [name]="stateLink"
        [intent]="stateLink === 'Outdated' || stateLink === 'Failed' ? 'danger' : stateLink === 'Connected' ? 'success' : 'warning'"
        [blink]="stateLink === 'Syncing'"
      ></indicator>
      <u-icon
        class="shared-icon"
        [pTooltip]="sharedTooltip"
        tooltipPosition="top"
        tooltipStyleClass="u-tooltip"
        *ngIf="isShared"
        [model]="{ type: 'font', value: 'icon-shared-link' }"
      ></u-icon>
    </div>

    <ng-container *ngIf="isPaletteOpen">
      <div class="palette-tab" [class]="paletteClass">
        <span class="actions-title margin-bottom">Link color</span>

        <div class="palette-list" [class.edit-mode]="!!isPaletteOpen">
          <div
            *ngFor="let color of palette; index as i"
            class="palette-item"
            id="color"
            [class.current]="color === link.color"
            [ngClass]="color"
            (click)="onChangeColor(color, $event, i)"
          ></div>
        </div>
        <span class="actions-title margin-bottom">Link name</span>
        <input
          class="link-name sub-text"
          spellcheck="false"
          [class]="(editMode ? 'editable ' : '') + link.color"
          (focus)="setEditStyle(true)"
          (blur)="setEditStyle(false)"
          (keydown)="enforceMaxLength($event)"
          (click)="sentEditEvent('link_name')"
          [class.edit-mode]="!!isPaletteOpen"
          [class.not-valid]="!isValid && isValid !== undefined"
          pTooltip="Max 50 characters"
          tooltipPosition="bottom"
          tooltipStyleClass="u-tooltip"
          [(ngModel)]="linkName"
          #nameInput
          id="nameInput"
          [maxlength]="LINK_NAME_MAX_LENGTH"
        />
        <div class="tag-group" *ngIf="link.tagGroup">
          <div class="title-tags">
            <span *ngIf="link.tagGroup.title" class="actions-title title-text">{{ link.tagGroup.title }}</span>
            <u-icon
              *ngIf="link.tagGroup.icon"
              [pTooltip]="link.tagGroup.tooltip"
              tooltipPosition="top"
              tooltipStyleClass="u-tooltip"
              [model]="{ type: 'img', value: link.tagGroup.icon }"
              class="icon-title"
            ></u-icon>
          </div>
          <div class="tags" *ngIf="link.tagGroup?.tags?.length">
            <div *ngFor="let tag of link.tagGroup.tags?.slice(0, MAX_DISPLAY_TAGS); index as i" class="link-tag">
              <link-tag [tag]="tag" [viewMode]="'tag'"></link-tag>
            </div>
            <span
              *ngIf="link.tagGroup.tags.length - MAX_DISPLAY_TAGS > 0"
              class="link-tag more-tags"
              [pTooltip]="'see more'"
              tooltipPosition="top"
              tooltipStyleClass="u-tooltip"
              (click)="openTagsPopup()"
              >+{{ link.tagGroup.tags.length - MAX_DISPLAY_TAGS }}
            </span>
          </div>
        </div>
        <div *ngIf="showOpenLinkDropDown" class="open-results-using">
          <span class="actions-title">Open results using</span>
          <div class="open-link-options">
            <u-dropdown
              [options]="openLinkOptions"
              [selectedValue]="openLinkSelected"
              (onChange)="onOpenLinkSelect($event)"
              [styles]="{ width: '95%' }"
              optionLabel="label"
              placeholder="Select"
              [appendTo]="'body'"
            >
            </u-dropdown>
          </div>
        </div>

        <div class="time-filter" *ngIf="!app.disableTimeFilter && (link.timeFilter || link?.ownedByMe || displaySharedLink)">
          <div class="time-header">
            <div class="time-title">
              {{ timeFilterContent.title }}
            </div>
          </div>
          <div class="time-filter-container">
            <link-time-filter
              (filterChange)="onFilterChange($event)"
              [app]="{ id: link.appId }"
              [currentFilter]="link.timeFilter"
              [currentFilterPolicy]="link.filtersPolicy"
              [options]="{ appendTo: true, disable: disabledTimeFilter }"
              [isDisabledMode]="!link?.ownedByMe && !displaySharedLink"
              [keepPlaceholder]="false"
            ></link-time-filter>
          </div>
        </div>

        <u-button *ngIf="link?.ownedByMe && !isShared" [label]="'Delete link'" type="secondary-danger" class="delete-link" (onClick)="openDeletePopup()"></u-button>
      </div>
    </ng-container>

    <ng-container *ngIf="isPaletteOpen">
      <div *ngIf="isShared" class="edit-actions" [class]="paletteClass">
        <div class="access-container flex-column">
          <span class="actions-title">Connection type</span>
          <div class="visibility-content">
            <span class="sub-text">{{ connectionType }}</span>
          </div>
        </div>
        <div class="access-container flex-column">
          <span class="actions-title">Who has access</span>
          <div class="accesses-list" *ngIf="updateItem?.shareOptions?.level === 'Protected'">
            <div class="access-item sub-text" *ngFor="let item of groupsMembersList.slice(0, 4); index as i">
              {{ i < 3 ? item.name : '+' + (groupsMembersList.length - 3) }}
            </div>
            <div *ngIf="!groupsMembersList?.length" class="actions-title">{{ link.title || link.key }}</div>
          </div>

          <div class="flex-row" *ngIf="updateItem?.shareOptions?.level === 'Public'">
            <u-icon
              class="ws-icon sub-text"
              [model]="{ type: 'font', value: 'icon-settings-company' }"
              [styles]="{ fontSize: '16px' }"
            ></u-icon>
            <span class="ws-name sub-text"> {{ workspace?.name }}</span>
          </div>

          <h3 *ngIf="displaySharedLink" (click)="openUpdatePopup($event)" class="manage-acess clickable-subtitle">Manage access</h3>
        </div>

        <div class="link-visibility-container flex-column">
          <span class="actions-title">Link visibility</span>
          <div class="visibility-content">
            <span class="sub-text">Show resources from this link</span>
            <ng-container *ngTemplateOutlet="linkVisivility"></ng-container>
          </div>

          <u-button *ngIf="isShared && displaySharedLink" [label]="'Delete link'" type="secondary-danger" class="delete-link" (onClick)="openDeletePopup()"></u-button>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div *ngIf="deleteInitiated" class="container-loading">
    <div class="loader">
      <div class="loader-text">Are you sure you want to delete this account?</div>
      <div class="loader-link-name">{{ linkName }}</div>
    </div>
    <div class="confirm-buttons">
      <u-button label="Cancel" type="secondary" (onClick)="deleteInitiated = false" [styles]="{ width: '100%' }"> </u-button>
      <u-button label="Delete" class="delete-button" (click)="onDelete()" type="primary-danger" [styles]="{ width: '100%' }"></u-button>
    </div>
    <p class="description" *ngIf="isShared">Your workspace members will lose access to this link</p>
  </div>
</div>
<times-button
  id="remove-link"
  *ngIf="!isPaletteOpen && ((!deleteInitiated && !isShared) || (!deleteInitiated && isShared && displaySharedLink))"
  class="remove-button"
  (click)="openDeletePopup()"
></times-button>

<ng-template #linkVisivility>
  <u-toggle-switch
    class="toggle-button"
    [checked]="!hideResults"
    type="small"
    [pTooltip]="hideResults ? 'Show results' : 'Hide results'"
    tooltipPosition="top"
    tooltipStyleClass="u-tooltip"
    (onChange)="toggleLinkVisibility($event)"
    (click)="$event.stopPropagation()"
  >
  </u-toggle-switch>
</ng-template>

<ng-template #deletePopupContent>
  <div class="delete-name" [style]="{ color: 'var(--' + link?.color + ')' }">{{ link?.name }}</div>
  <div class="delete-comment">Your workspace members will lose access to this link</div>
</ng-template>

<ng-template #linkTimeInfo>
  <div *ngIf="isPaletteOpen" class="time-info">
    @if (displayCreatedTime) {
      <result-bullet-part [model]="displayCreatedTime" [fallback]="FALLBACK_ICON"></result-bullet-part>
    }
    @if (link?.modifiedTime && displayModifiedTime) {
      <result-bullet-part [model]="displayModifiedTime" [fallback]="FALLBACK_ICON"></result-bullet-part>
    }
  </div>
</ng-template>
