import { Injectable } from '@angular/core';
import { Flags } from '@local/client-contracts';
import { observable } from '@local/common';
import { FlagsRpcInvoker } from '@shared/services/invokers/flags.rpc-invoker';
import { Logger } from '@unleash-tech/js-logger';
import { firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { LogService } from './log.service';
import { ServicesRpcService } from './rpc.service';

@Injectable({
  providedIn: 'root',
})
export class FlagsService implements Flags.Service {
  private service: Flags.Service;
  private logger: Logger;
  private _all$: ReplaySubject<Flags.Flag[]> = new ReplaySubject(1);

  @observable
  get all$(): Observable<Flags.Flag[]> {
    return this._all$;
  }

  constructor(
    logService: LogService,
    private services: ServicesRpcService
  ) {
    this.logger = logService.scope('FlagsService');
    this.service = this.services.invokeWith(FlagsRpcInvoker, 'flags');
    this.service.all$.subscribe((a) => this._all$.next(a));
  }

  async isEnabled(flagName: string, linkId?: string): Promise<boolean> {
    const all = await firstValueFrom(this._all$);
    const flag = all?.find((f) => f.flag === flagName && (!linkId || f.linkId == linkId));
    if (flag) {
      return flag?.value;
    }
    return this.service.isEnabled(flagName, linkId);
  }
}
