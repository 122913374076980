import { SearchMethod } from '../hub.service';
import { SourceSettings } from './client';

export interface SearchOptions {
  query?: string;
  trigger?: string;
  resetSession?: boolean;
  telemetryPageName?: string;
  sources: SourceSettings[];
  includeNullResultContext?: boolean;
  telemetrySearchMethod?: SearchMethod;
}
