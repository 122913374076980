import { Wiki } from '@local/client-contracts';
import { WikiCardsService } from 'src/app/bar/services/wikis/wiki-cards.service';
import { WikisService } from 'src/app/bar/services/wikis/wikis.service';
import { WikiDraftsService } from 'src/app/bar/services/wikis/wiki-drafts.service';
import { Injectable } from '@angular/core';
import { concat } from 'lodash';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';
import { cutText } from '@local/ts-infra';
import { PreviewService } from 'src/app/bar/services/preview.service';
import { FrameEditorRenderHtmlService } from '../../frame-editor/services/frame-editor-render-html.service';
import { BlobsStorageService } from 'src/app/bar/services/blobs/blobs-storage.service';

export interface DraftSaveModel {
  cardId: string;
  htmlContent: string;
  title: string;
  contentText: string;
  accountId: string;
}

@Injectable()
export class WikiCardSaveFlowService {
  constructor(
    public wikiCardsService: WikiCardsService,
    private wikisService: WikisService,
    private wikiDraftsService: WikiDraftsService,
    private frameEditorStorageAttachmentsService: BlobsStorageService<Wiki.CardAttachment>,
    private showToasterService: ShowToasterService,
    private previewService: PreviewService,
    private frameEditorRenderHtmlService: FrameEditorRenderHtmlService
  ) {}

  updatePublishedCard(card: Wiki.Card) {
    const req: Wiki.UpsertCardRequest = { card };
    this.wikiCardsService.update(req);
  }

  createDraft(card: Wiki.Card, accountId: string): Wiki.Draft {
    const { id, attachments, content, contentText, title } = card;
    const newDraft = this.getDraft({ title, content, attachments, contentText }, accountId);
    const req: Wiki.CreateDraftRequest = { cardId: id, draft: newDraft };
    this.wikiDraftsService.create(req);
    return newDraft;
  }

  saveDraftCard(draftSaveModel: DraftSaveModel) {
    const updatedDraft: Wiki.Draft = this.initUpdatedDraft(draftSaveModel);
    if (!updatedDraft) return;
    const req: Wiki.UpdateDraftRequest = { cardId: draftSaveModel.cardId, draft: updatedDraft };
    this.wikiDraftsService.update(req);
    return updatedDraft;
  }

  async publishCard(req: Wiki.PublishDraftRequest, showToaster = true) {
    try {
      const updatedCard = await this.wikiDraftsService.publish(req);
      if (showToaster) {
        const toaster = this.showToasterService.showToaster({
          id: 'published-card',
          content: `A card ${cutText(req.draft.title, 40)} was successfully published`,
          icon: { type: 'font', value: 'icon-check-circle' },
          intent: 'primary',
          buttonText: 'Open',
        });
        toaster.compInstance.invoke.subscribe(() => {
          this.previewService.setPreviewState('popup', 0, {
            type: 'result',
            filterType: 'wiki-local',
            id: req.cardId,
            view: { title: { text: req.draft.title }, icon: null },
            action: { type: 'wiki card' },
          });
        });
      }
      return updatedCard;
    } catch (error) {
      this.showToasterService.showErrorToaster('publish-card-failed');
      throw error;
    }
  }

  private initUpdatedDraft(draftSaveModel: DraftSaveModel): Wiki.Draft {
    const { title, contentText, accountId } = draftSaveModel;
    const updatedTitle = title?.length > 0 ? title : 'Untitled';
    const { attachments, content } = this.extractFilesDataFromHtml(draftSaveModel);
    const updatedDraft = this.getDraft({ title: updatedTitle, content, attachments, contentText }, accountId);
    return updatedDraft;
  }

  private extractFilesDataFromHtml(draftSaveModel: DraftSaveModel): { attachments: Wiki.CardAttachment[]; content: string } {
    const { htmlContent, cardId } = draftSaveModel;
    if (!htmlContent) {
      return { attachments: [], content: '' };
    }
    const { updatedHtml, mediaIds, attachmentsIds } = this.frameEditorRenderHtmlService.convertEditorHtmlForSave(htmlContent);
    const mergeBlobIds = concat([], mediaIds, attachmentsIds);
    const attachments: Wiki.CardAttachment[] = this.frameEditorStorageAttachmentsService.getAttachments(cardId, mergeBlobIds);
    return { attachments, content: updatedHtml };
  }

  getDraft(draft: Omit<Wiki.Draft, 'contributors'>, accountId: string): Wiki.Draft {
    const { attachments, content, contentText, title } = draft;
    return {
      attachments: (attachments || []).filter((a) => !!a),
      content: content || '',
      contentText: contentText || '',
      title,
      contributors: null,
      modifiedBy: accountId,
      modifiedTime: Date.now(),
    };
  }

  async saveCardNewMode(cardData: Wiki.Card, parentId: string): Promise<Wiki.CreateCardResponse> {
    const upsertRequest: Wiki.UpsertCardRequest = {
      card: cardData,
      parentId: parentId,
      updateModifiedTime: true,
    };
    return this.wikisService.createCard(upsertRequest);
  }
}
