import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Wiki } from '@local/client-contracts';
import { isWikiCollection } from '@local/common-web';
import { PopupRef, UAutoCompleteComponent } from '@local/ui-infra';
import { Breadcrumb } from '@shared/services/breadcrumbs.service';
import { cloneDeep } from 'lodash';
import { firstValueFrom } from 'rxjs';
import { SelectedItemPopupData } from 'src/app/bar/components/selected-item-popup/models/select-item-base';
import { SelectedItem } from 'src/app/bar/components/selected-item-popup/selected-item-popup.component';
import { CollectionsUtilService } from 'src/app/bar/services/collections-util.service';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { WikiCardsService } from 'src/app/bar/services/wikis/wiki-cards.service';
import { WikisService } from 'src/app/bar/services/wikis/wikis.service';

@Component({
  selector: 'wiki-item-move-to-popup',
  templateUrl: './wiki-item-move-to-popup.component.html',
  styleUrls: ['./wiki-item-move-to-popup.component.scss'],
})
export class WikiItemMoveToPopupComponent implements OnInit {
  data: SelectedItemPopupData;
  breadcrumbsItems: Breadcrumb[];
  disableMoveButton = true;
  loadingSaveButton: boolean;

  selectedWikiItem: SelectedItem = null;
  currentWikiId: string;
  suggestionWikis: SelectedItem[];
  selectedFolderItem: SelectedItem = null;
  currentFolderId: string;
  suggestionFolders: SelectedItem[] = [];

  @Output() primaryButton = new EventEmitter<{ selectedWiki: SelectedItem; selectedFolder: SelectedItem }>();
  @ViewChild('uAutoCompleteFolder') uAutoCompleteFolder: UAutoCompleteComponent;

  constructor(
    private ref: PopupRef<WikiItemMoveToPopupComponent, SelectedItemPopupData>,
    private cdr: ChangeDetectorRef,
    private wikiCardsService: WikiCardsService,
    private wikisService: WikisService,
    private collectionsUtilService: CollectionsUtilService,
    private collectionsService: CollectionsService
  ) {}

  ngOnInit(): void {
    this.data = cloneDeep(this.ref.data);
    this.initData();
  }

  private async initData() {
    if (this.data.item) {
      const path = this.data.item.path;
      this.breadcrumbsItems = this.wikiCardsService.buildCardBreadcrumbs(path, null);
      this.initCurrentState(path);
    } else if (this.data.items) {
      const path = this.data.items[0]?.path;
      this.breadcrumbsItems = this.wikiCardsService.buildCardBreadcrumbs([path[0]], null);
      this.initCurrentState([path?.[0]]);
    }
    await Promise.all([this.initSelectedState(), this.initWikis()]);
    this.cdr.markForCheck();
  }

  private initCurrentState(path: Wiki.Path[]) {
    if (!path?.length) {
      return;
    }
    this.currentWikiId = path[0]?.id;
    if (path.length < 2) {
      return;
    }
    this.currentFolderId = path[path.length - 1]?.id;
  }

  private async initSelectedState() {
    if (!this.data?.autoCompleteSettings?.showSelected) {
      return;
    }
    if (this.currentWikiId) {
      const wiki = this.collectionsService.getCollectionById(this.currentWikiId);
      this.selectedWikiItem = (await this.collectionsUtilService.initSelectedItems([wiki]))[0];
      await this.onSelectWikiItem(this.selectedWikiItem);
    }
    if (this.currentFolderId && this.suggestionFolders) {
      this.selectedFolderItem = this.suggestionFolders.find((folder) => folder.id === this.currentFolderId);
    }
  }

  onCloseClick() {
    if (this.data.styleOptions?.disableCloseButton) {
      return;
    }
    this.ref.destroy();
  }

  async onSelectWikiItem(item: SelectedItem) {
    this.selectedFolderItem = null;
    if (!item?.id && !item?.isNew) {
      this.selectedWikiItem = null;
      this.disableMoveButton = true;
      this.cdr.markForCheck();
      return;
    }
    this.selectedWikiItem = item;
    this.cdr.markForCheck();
    setTimeout(() => {
      this.uAutoCompleteFolder.autoComplete.inputEL.nativeElement.focus();
    }, 10);
    const selectedWikiFolders = await this.wikisService.getFoldersByWikiId(item.id);
    this.suggestionFolders = this.initFolders(selectedWikiFolders);
    this.disableMoveButton = false;
    this.cdr.markForCheck();
  }

  private initFolders(folders: Wiki.FolderStructure[]): SelectedItem[] {
    const itemsList = [];
    let selectedFolder: SelectedItem;
    folders?.forEach((folder) => {
      if (folder.id === this.data?.item?.id || this.data?.items?.some((item) => item.id === folder.id)) {
        return;
      }
      const item = {
        name: folder.title,
        id: folder.id,
        icon: 'icon-folder-full',
        iconColor: 'var(--light-blue-500)',
        itemsNumber: folder.itemsCount,
        path: folder.path,
        disable: this.data.item
          ? !this.wikisService.validateLimitFolder(this.data.item, folder)
          : !this.wikisService.validateLimitFolderForMany(this.data.items, folder),
      };
      if (this.currentFolderId && item.id === this.currentFolderId) {
        selectedFolder = item;
      } else {
        itemsList.push(item);
      }
    });
    if (selectedFolder) {
      itemsList.unshift(selectedFolder);
    }
    return itemsList;
  }

  onSelectFolderItem(item) {
    if ((!item?.id && !item?.isNew) || item.disable) {
      this.selectedFolderItem = null;
      return;
    }
    this.selectedFolderItem = item;
    this.cdr.markForCheck();
  }

  onPrimaryClick() {
    this.loadingSaveButton = true;
    this.primaryButton.emit({
      selectedWiki: this.selectedWikiItem,
      selectedFolder: this.selectedFolderItem,
    });
  }

  private async initWikis() {
    const collections = await firstValueFrom(this.collectionsService.all$);
    const wikis = collections.filter((c) => isWikiCollection(c) && this.collectionsUtilService.canEdit(c));
    const expandedWiki = [];
    for (const wiki of wikis) {
      const cardsCount = await this.wikisService.getCountCardsWiki(wiki.id);
      expandedWiki.push({ ...wiki, cardsCount });
    }
    this.suggestionWikis = await this.collectionsUtilService.initSelectedItems(expandedWiki, this.currentWikiId);
  }
}
