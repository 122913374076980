import { StaticData } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class StaticDataRpcInvoker implements StaticData.Service {
  @invoker
  get data$(): Observable<StaticData.StaticDataAttributes> {
    return;
  }

  @invoker
  get filtersPolicy$(): Observable<StaticData.FiltersPolicy> {
    return;
  }

  @invoker
  get appsView$(): Observable<StaticData.AppsByView> {
    return;
  }

  @invoker
  getAppsByView$(view: StaticData.ViewName): Observable<string[]> {
    return;
  }
}
