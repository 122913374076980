<p-listbox
  #listbox
  [options]="items()"
  [(ngModel)]="selectedItem"
  [optionLabel]="labelField()"
  [listStyle]="listStyle()"
  [filter]="filter()"
  [group]="group()"
  [selectOnFocus]="selectOnFocus()"
  (onChange)="onChangeEvent($event)"
  tabindex="0"
>
  <ng-template let-item pTemplate="item" let-i="index">
    <ng-container *ngIf="itemTemplate(); else defaultItem">
      <ng-container *ngTemplateOutlet="itemTemplate(); context: { $implicit: item, index: i }"></ng-container>
    </ng-container>
    <ng-template #defaultItem>
      <div class="list-item">
        <span>{{ item[labelField()] }}</span>
      </div>
    </ng-template>
  </ng-template>

  <ng-template let-group pTemplate="group" let-i="index">
    @if (groupTemplate()) {
      <ng-container *ngTemplateOutlet="groupTemplate(); context: { $implicit: group, index: i }"></ng-container>
    } @else {
      <div class="group-item">
        <span>{{ group[labelField()] }}</span>
      </div>
    }
  </ng-template>
</p-listbox>
