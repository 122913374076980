import { Collections, Results, Search, Wiki } from '@local/client-contracts';
import { Action } from 'src/app/bar/views/results/models/view-filters';
import { MemorySearchSettings } from '../memory-search-client/memory-search-client';

export interface WikiCollectionItemSourceSettings extends MemorySearchSettings {
  type: 'wiki-collection-items';
  collectionId?: string;
  collection?: Collections.Collection;
  folderName?: string;
  convertItem?: boolean;
  isFavoriteItem?: boolean;
  withIcon?: boolean;
  searchView?: boolean;
  cache: 'first' | 'only' | 'skip';
  stateView?: boolean;
  fullPath?: boolean;
}

export interface WikiCardCollectionResultItem extends Search.WikiCardCollectionResultItem {
  iconIndicate?: Results.Icon | Results.FontIcon;
  path?: Wiki.Path[];
  action?: Action;
  updatedTime?: number;
}
