import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Config } from '@environments/config';
import { Tab } from '@local/ui-infra';
import { LoaderService } from '@shared/loader.service';
import { RouterService } from '@shared/services/router.service';
import { SIDE_PANEL_PAGE_PATH } from 'src/app/bar/utils/constants';

@Component({
  selector: 'side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePanelComponent {
  sidePanelTabs: Tab[] = [
    { id: 'chat', icon: 'icon-chat-dots', tooltip: 'Chat' },
    { id: 'home', icon: 'icon-search-icon-fixed', tooltip: 'Search' },
  ];
  selectedTab: Tab;

  constructor(
    private loaderService: LoaderService,
    private routerService: RouterService
  ) {}

  ngOnInit(): void {
    this.selectedTab = this.sidePanelTabs.find((s: Tab) => s.id == 'chat');
    this.loaderService.ready$.next(true);
  }

  setActiveTab(tabId: string): void {
    this.selectedTab = this.sidePanelTabs.find((s: Tab) => s.id == tabId);
    this.routerService.navigateByUrl(`${SIDE_PANEL_PAGE_PATH}/${tabId}`);
  }

  openInNewTab(): void {
    if (!this.selectedTab) {
      return;
    }

    if (this.selectedTab.id === 'home') {
      this.routerService.navigateByUrl('search', {}, true, true);
      return;
    }

    const urlParams = this.routerService.url.replace(/^\/side-panel/, '');
    this.routerService.navigateByUrl(urlParams,null, true, true);
  }
}
