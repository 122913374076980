<assistants-empty-state
  *ngIf="assistants?.length === 0 && displayContext?.searchCompleted && emptyState"
  class="assistants-empty-state"
></assistants-empty-state>
<div class="assistants-container" *ngIf="!emptyState">
  <div *ngIf="assistants?.length === 0 && displayContext?.searchCompleted" class="no-results">
    <h1 class="no-results-title headline-1">{{ assistantContent.noResults }}</h1>
    <q class="headline-1 bold" *ngIf="query">
      {{ query | truncate: 30 }}
    </q>
  </div>
  <div class="assistants-header">
    <div class="assistants-header-content">
      <div class="assistants-header-title">
        <u-icon *ngIf="assistantIcon" class="results-header-icon" [model]="assistantIcon"></u-icon>
        <div class="results-header-text">{{ displayTypeFilter ?? assistantContent.assistants }}</div>
      </div>
      <div class="assistants-header-buttons">
        <u-input
          #smallInputSearch
          class="small-input-search"
          data-cy="assistants-search"
          [inlineExtraIcon]="{ type: 'font', value: 'icon-search-icon-fixed' }"
          [placeholder]="'Search'"
          [isSearchInput]="true"
          (onChange)="updateQuery($event)"
          [model]="hubService.inputQuery"
          [ignoreEnter]="true"
          (onFocus)="smallInputSearchFocus = true"
          (onBlur)="smallInputSearchFocus = false"
          [extraIcon]="!smallInputSearchFocus && !hubService.inputQuery ? { type: 'font', value: 'icon-slash' } : null"
          [ngClass]="{ 'small-input-blur': !smallInputSearchFocus && hubService.inputQuery }"
        ></u-input>
        <u-button
          (onClick)="changeLayout()"
          [versionNumber]="2"
          [typeElement]="'secondary'"
          [styleType]="'outlined'"
          [fontIcon]="layoutMode === 'list' ? 'gallery-view' : 'list-view'"
          [iconStyles]="{ fontSize: '17px' }"
          [pTooltip]="layoutMode === 'list' ? 'Gallery layout' : 'List layout'"
          tooltipPosition="top"
          tooltipStyleClass="u-tooltip"
        >
        </u-button>
        <u-button
          #sortButtonRef
          class="sort-icon"
          elementSize="small"
          fontIcon="sort-list-down"
          type="secondary"
          [styles]="{ fontSize: '14px', height: '32px' }"
          [class.sort-active]="!!sortService?.popupSortRef"
          (onClick)="openSort(sortButtonRef)"
          [class.active]="searchParamsService.getSort()"
        ></u-button>
        <u-button
          #plusButton
          class="add-icon"
          elementSize="small"
          type="primary"
          [label]="smallScreen ? '' : assistantContent.newAssistant"
          fontIcon="plus"
          [iconStyles]="{ fontSize: '14px', 'font-weight': 'bold' }"
          [styles]="{ fontSize: '14px', width: smallScreen ? '32px' : '143px' }"
          (onClick)="openAddAssistantOptionPopup(plusButton)"
        ></u-button>
      </div>
    </div>
    <div class="assistants-header-subtitle">
      <span class="subtitle-text">{{ assistantContent.assistantSubtitle }}</span>
      <u-button style="display: inline-block" type="link" [label]="assistantContent.learnMore" (onClick)="navigateToLearnMore()"></u-button>
    </div>
    <results-filters-box
      id="filters"
      [filters]="filters"
      [enableClearAll]="true"
      (filterChange)="onFilterChange($event)"
      (clearAll)="clearAllFilters()"
      [separateLayout]="true"
    ></results-filters-box>
  </div>

  <div class="scrollbar-wrapper" #contextContainer>
    <ng-scrollbar *ngIf="assistants?.length > 0" visibility="hover">
      <div scrollViewport infiniteScroll [scrollWindow]="false">
        <div
          class="grid assistants-items"
          [ngClass]="{
            'assistants-items-list': layoutMode === 'list',
            'grid-cards': layoutMode === 'gallery',
          }"
        >
          <ng-container *ngIf="loaded; else loading">
            <ng-container *ngFor="let assistant of assistants; let i = index; trackBy: trackByAssistant">
              <ng-container [ngSwitch]="assistant.type">
                <ng-container *ngSwitchCase="'assistant'">
                  <assistant-item
                    #assistantItem
                    class="result-item"
                    [attr.data-index]="i"
                    [assistant]="assistant"
                    [viewMode]="layoutMode"
                    [selected]="i === selectedIndex"
                    (click)="updateSelectedIndex(i)"
                    (contextOpen)="updateSelectedIndex(i)"
                  >
                  </assistant-item>
                </ng-container>
                <ng-container *ngSwitchCase="'header'">
                  <result-header
                    [ngClass]="{ 'results-footer': assistant.isFooter, 'results-header': !assistant.isFooter }"
                    [model]="assistant"
                    [selected]="i === selectedIndex && assistant.isFooter"
                    (focusGroupClick)="resultHeaderClick(assistant.group?.name)"
                  ></result-header>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <gradient-overflow *ngIf="!isLauncher"></gradient-overflow>
    </ng-scrollbar>
  </div>
</div>
