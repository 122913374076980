import { Filters, MemorySearch, Search, Wiki } from '@local/client-contracts';
import { isWikiCollection } from '@local/common-web';
import { LogService } from '@shared/services';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { isEmpty } from 'lodash';
import { Observable, ReplaySubject, Subscription, firstValueFrom } from 'rxjs';
import { SearchResults, TelemetryTrigger } from 'src/app/bar/views';
import { CollectionsService } from '../../../collections.service';
import { StatsService } from '../../../stats.service';
import { WikiCardBuilderService } from '../../../wikis/wiki-cards-builder.service';
import { WikiCardsService } from '../../../wikis/wiki-cards.service';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { WikiCollectionItemSourceSettings } from './wiki-collection-items-source-settings';

export class WikiCollectionItemSearchClient extends MemorySearchClient<WikiCollectionItemSourceSettings> {
  private instances: { [sessionName: string]: { sub?: Subscription; refreshRunning?: boolean } } = {};

  constructor(
    logService: LogService,
    private collectionsService: CollectionsService,
    memorySearchService: MemorySearchService,
    private wikiCardsService: WikiCardsService,
    private statsService: StatsService,
    private cardBuilderService: WikiCardBuilderService
  ) {
    super(logService, memorySearchService, ['Alphabetical', 'Time']);
  }

  nextPage(request: SearchRequest<WikiCollectionItemSourceSettings>, response: SearchResponse, trigger: TelemetryTrigger): Promise<void> {
    return;
  }

  supportsMatch(response: SearchResponse): boolean {
    return false;
  }

  supportsFilters(filters: Filters.Values): boolean {
    return true;
  }

  async rank(queryTokens: string[], items: MemorySearch.Item[], settings: WikiCollectionItemSourceSettings): Promise<MemorySearch.Item[]> {
    //The sort of cards happening in the shared worker
    if ((!queryTokens?.length && !settings.sorting) || !isEmpty(settings.sorting)) {
      return this.defaultSort(items);
    }
    return this.memorySearchService.rank(queryTokens, items, settings.sorting);
  }

  getInput(request: SearchRequest<WikiCollectionItemSourceSettings>, response: SearchResponse): Observable<MemorySearch.Item[]> {
    const input$ = new ReplaySubject<MemorySearch.Item[]>(1);
    try {
      this.loadCards(request, response, input$);
    } catch (e) {
      input$.error(e);
    }
    return input$;
  }

  async loadCards(
    request: SearchRequest<WikiCollectionItemSourceSettings>,
    response: SearchResponse,
    input$: ReplaySubject<MemorySearch.Item[]>
  ) {
    let finished = false;
    const sessionName = request.sessionName;
    this.initSession(request.id, sessionName);

    const onInstanceSub = (finished, res) => {
      if (finished && res) {
        const instance = this.instances[sessionName];
        if (instance && !instance.refreshRunning && !response.cancelled) {
          this.instances[sessionName].refreshRunning = true;
          response.extra = res;
          this.refresh(request, response);
        }
      }
    };

    const updates: Observable<any>[] = [this.statsService.updated$, this.wikiCardsService.updated$];
    for (const update of updates) {
      this.instances[sessionName].sub.add(
        update.subscribe((res) => {
          onInstanceSub(finished, res);
        })
      );
    }

    const settings = request.sourceSettings;
    const filters = settings.filters;
    const postFilters = filters?.postFilters || {};

    if (settings?.collectionId) {
      postFilters['card-wiki-name'] = [settings?.collectionId];
    }
    if (settings?.folderName) {
      postFilters['card-folder-name'] = [settings?.folderName];
    }

    const searchRequest: Wiki.SearchRequest = {
      query: request.query,
      sorting: settings.sorting,
      postFilters: postFilters,
      preFilters: filters?.preFilters || {},
      maxCount: settings.maxCount || this.wikiCardsService.LIMIT_CARDS,
    };

    const cacheTask = this.wikiCardsService.search({ ...searchRequest, fromCache: true });

    if (cacheTask) {
      await this.getCards(cacheTask, settings, input$, response);
      finished = true;
      input$.complete();
    }
  }

  private async getCards(
    task: Promise<Wiki.SearchResponse>,
    settings: WikiCollectionItemSourceSettings,
    input$: ReplaySubject<MemorySearch.Item[]>,
    response: SearchResponse
  ) {
    if (response.cancelled) {
      input$.complete();
      return;
    }

    const res = await task;
    if (response.cancelled) {
      input$.complete();
      return;
    }
    let cards = res?.results;

    if (!cards?.length) {
      input$.next([]);
      return;
    }
    response.extra = { totalResults: res?.totalResults };

    cards = cards?.filter((c) => c)?.map((x) => ({ ...x, type: 'collection-wiki-item' }));

    const cardItems = cards.map((x) => {
      return { data: x, searchText: this.getText(x), sortValue: this.getSortText(settings.sorting, x) };
    });

    input$.next(cardItems || []);

    return cardItems;
  }

  private getSortText(sorting: Search.Sort, item: Wiki.Card): string | number {
    let sortValue: number | string;
    switch (sorting?.by) {
      case 'Alphabetical':
        sortValue = item?.title;
        break;
      case 'Timestamp':
        sortValue = item?.modifiedTime;
        break;
    }
    return sortValue;
  }

  private getText(item: Wiki.Card): string {
    const fileAttachments = item?.attachments?.filter((a) => a.type === 'File' || !a.type);
    const attachmentsName = fileAttachments?.map((at) => at.name).join(' ') || '';
    return `${item.title} ${attachmentsName}`.trim();
  }

  private initSession(id: number, sessionName: string): void {
    const subscription = this.instances[sessionName]?.sub;
    if (subscription) {
      this.destroy(id, sessionName);
    }
    this.instances[sessionName] = {};
    this.instances[sessionName].sub = new Subscription();
  }

  destroy(id: number, sessionName: string): void {
    this.instances[sessionName]?.sub?.unsubscribe();
    this.instances[sessionName] = null;
  }

  async getOutput(items: MemorySearch.Item[], sourceSettings?: WikiCollectionItemSourceSettings): Promise<SearchResults[]> {
    const tasks = [];

    const wikis = (await firstValueFrom(this.collectionsService.all$))?.filter((c) => !!isWikiCollection(c)) as Wiki.WikiCollection[];

    const wikisDictionary: { [id: string]: Wiki.WikiCollection } = {};

    wikis?.forEach((wiki) => {
      wikisDictionary[wiki.id] = wiki;
    });

    const statDictionary = await this.cardBuilderService.createStatDictionary(items.map((i) => i.data));

    for (const item of items) {
      tasks.push(
        this.cardBuilderService.buildCardResultView(
          item.data,
          wikisDictionary[item.data?.collectionId],
          statDictionary[item.data?.id],
          sourceSettings.withIcon,
          sourceSettings.searchView,
          sourceSettings.stateView,
          sourceSettings.fullPath
        )
      );
    }

    const cards = await Promise.all(tasks);

    return cards?.filter((c) => !!c);
  }
}
