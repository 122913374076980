import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FileUploadPreview, PopupRef, UInputComponent } from '@local/ui-infra';
import { CreatePostWidget, CreatePostPopupData } from './create-post-popup-data';
import { HomeTabsService } from 'src/app/bar/services/home-tabs.service';
import { distinctUntilChanged } from 'rxjs';
import { Commands, HomeTabs, Posts } from '@local/client-contracts';
import { SessionService } from '@shared/services/session.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Config } from '@environments/config';
import { AvatarItemModel } from 'src/app/bar/models/avatar-item.model';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { ContextMenuComponent, ContextMenuData, ContextMenuItem, ContextMenuService } from '@shared/components';
import { cloneDeep, isEqual } from 'lodash';
import { SelectItem } from 'primeng/api';
import { SizeImageConstants } from '@shared/components/image-cropper-popup/size-image.const';
import { SizeImage } from '@shared/components/image-cropper-popup/image-cropper-popup-data';
import { AvatarListService } from 'src/app/bar/services/avatar-list.service';
import { windowSizeObserver } from '@shared/utils';

interface AutocompleteTabItem extends HomeTabs.HomeTab {
  avatarList: AvatarItemModel[];
}

export interface CreatePostEvent {
  title: string;
  image: any;
  link: string;
}
@UntilDestroy()
@Component({
  selector: 'create-post-popup',
  templateUrl: './create-post-popup.component.html',
  styleUrls: ['./create-post-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePostPopupComponent implements OnInit, AfterViewInit {
  readonly fileUploadError: string = 'Upload failed please try again.';
  readonly MAX_IMAGES_TOOLTIP: string = `No more than ${Config.createPostWidget.maxImages} images is allowed.`;
  private readonly NO_RESULTS_TAB_NAME = 'No results';
  private readonly MAX_CHARACTERS_IN_TITLE = 39;
  private readonly MAX_CHARACTERS_IN_TITLE_DISPLAY = 33;
  readonly SELECTED_SIZE_OPTIONS = SizeImageConstants.SELECTED_SIZE_OPTIONS;
  readonly DESCRIPTION_SIZE_IMAGES = SizeImageConstants.DESCRIPTION_SIZE_IMAGES;
  readonly WARNING_TOOLTIP = SizeImageConstants.WARNING_MESSAGE;
  private activeContextMenu: PopupRef<ContextMenuComponent, ContextMenuData>;
  private username: string;
  private allPostTitles: string[];
  private _tab: HomeTabs.HomeTab;
  private currentWidget: HomeTabs.PostWidget;
  private isChanged = false;
  private windowSize$ = windowSizeObserver();
  private screenHeight: number;
  autoCompleteTabItems: AutocompleteTabItem[] = [];
  tabs: HomeTabs.HomeTab[];
  carouselHoveredIndex: boolean;
  data: CreatePostPopupData;
  croppedImage: FileUploadPreview;
  title: string;
  uploadedImage: File;
  carousel: Posts.CarouselData[] = [];
  displayImagesNames: { title: string; tooltip: string }[] = [];
  carouselAutoplay: boolean;
  showFileUploadError: boolean;
  isValidTitle = true;
  selectedSize: SizeImage;
  showWarningImage: boolean;

  @ViewChild('inputTitle') inputTitle: UInputComponent;
  @Output() onPublish = new EventEmitter<CreatePostWidget>();
  @Output() onCancel = new EventEmitter();
  @Output() onSaveState = new EventEmitter<CreatePostPopupData>();
  @Output() onChangeTab = new EventEmitter<string>();
  @Output() switchToImageCropper = new EventEmitter<SizeImage>();

  constructor(
    private ref: PopupRef<CreatePostPopupComponent, CreatePostPopupData>,
    private cdr: ChangeDetectorRef,
    private homeTabsService: HomeTabsService,
    private sessionService: SessionService,
    private collectionsService: CollectionsService,
    private contextMenuService: ContextMenuService,
    private avatarListService: AvatarListService
  ) {}

  get publishEnabled() {
    return this.title && !!this.carousel?.length && !!this.tab && this.carousel?.length <= this.maxImages && this.isValidTitle;
  }

  get saveEnabled() {
    return this.title && !!this.tab && this.isValidTitle && this.isChanged;
  }

  get showMaxImagesTooltip() {
    return this.carousel?.length > this.maxImages;
  }

  get carouselAutoPlayEnabled() {
    return this.carousel?.length > 1;
  }

  get maxImages() {
    return Config.createPostWidget.maxImages;
  }

  get supportedImageTypes() {
    return Config.createPostWidget.supportedImageTypes;
  }

  get disableAutoplayButton() {
    return this.carousel?.length <= 1;
  }

  get disableFileUpload() {
    return this.carousel?.length >= Config.createPostWidget.maxImages;
  }

  get tab(): HomeTabs.HomeTab {
    return this._tab;
  }

  set tab(value: HomeTabs.HomeTab) {
    this._tab = value;
  }

  get isMaxHightPopup(): boolean {
    return this.screenHeight < 700 && this.carousel?.length > 3;
  }

  ngOnInit(): void {
    this.data = cloneDeep(this.ref.data);
    this.title = this.data?.title;
    this.carousel = this.data?.carousel ?? [];
    this.croppedImage = this.data?.croppedImage;
    this.carouselAutoplay = this.data?.carouselAutoplay;
    this.uploadedImage = this.data?.uploadedImage;
    this.tab = this.data?.tab;
    this.selectedSize = this.data?.sizeImage ?? 'large';
    this.initCarousel();
    this.initTabs();
    this.initUserName();
    this.initPostTitles();
    this.validateTitle();

    this.windowSize$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.screenHeight = value.height;
      this.cdr.markForCheck();
    });

    const tab = this.tabs?.find((t) => t.id === this.data.currentPostWidget?.tabId);
    this.currentWidget = tab?.widgets.find((w) => w.id === this.data.currentPostWidget?.id) as HomeTabs.PostWidget;
    this.isChanged = this.isPostChanged();
    this.cdr.markForCheck();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.inputTitle?.inputElement?.el.nativeElement.focus();
      this.cdr.markForCheck();
    }, 0);
  }

  private initCarousel() {
    for (let index = 0; index < this.carousel.length; index++) {
      const titleText = this.carousel[index].imageName;
      this.displayImagesNames[index] = { title: titleText, tooltip: '' };
      if (titleText.length > this.MAX_CHARACTERS_IN_TITLE) {
        const parts = titleText.split('.');
        const extension = parts[parts.length - 1];
        this.displayImagesNames[index].title = titleText.substring(0, this.MAX_CHARACTERS_IN_TITLE_DISPLAY) + '...' + extension;
        this.displayImagesNames[index].tooltip = titleText;
      }
    }
    this.cdr.markForCheck();
  }

  private initTabs() {
    this.homeTabsService.all$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(async (all: HomeTabs.HomeTab[]) => {
      this.tabs = all;
      if (!this.tab) {
        this.tab = all.find((t) => t.id === 'home');
      }
      for (const tab of all) {
        const avatarList =
          tab.id === 'home'
            ? [this.avatarListService.createWorkspaceAvatar()]
            : await this.avatarListService.createAvatarList(tab.accountId, tab.shareOptions, true);
        this.autoCompleteTabItems.push({
          ...tab,
          avatarList,
        });
      }
      this.cdr.markForCheck();
    });
  }

  private initPostTitles() {
    let postWidgets = (this.tab?.widgets?.filter((w) => w.type === 'post') || []) as HomeTabs.PostWidget[];
    if (this.data?.currentPostWidget) {
      postWidgets = postWidgets.filter((w) => w.id !== this.data.currentPostWidget.id);
    }
    this.allPostTitles = postWidgets.map((pw) => pw.post.title);
  }

  private initUserName() {
    this.sessionService.current$.pipe(untilDestroyed(this)).subscribe((session) => {
      this.username = session?.user?.firstName;
      this.cdr.markForCheck();
    });
  }

  isPostChanged() {
    if (this.currentWidget?.post.title !== this.title) {
      return true;
    }
    if (!isEqual(this.currentWidget?.post.carousel, this.data.carousel)) {
      return true;
    }
    if (this.currentWidget?.tabId !== this.tab?.id) {
      return true;
    }
    if (!!this.data.carouselAutoplay !== !!this.carouselAutoplay) {
      return true;
    }
    if (this.data?.sizeImage && this.data?.sizeImage !== this.selectedSize) {
      return true;
    }
    return false;
  }

  onTitleInputChange(event) {
    if (!event) {
      this.isValidTitle = true;
    }
    this.title = event;
    this.validateTitle();
    this.isChanged = this.isPostChanged();
    this.cdr.markForCheck();
  }

  fileChangeEvent(file: File) {
    this.showFileUploadError = false;
    this.emitState(file);
    this.switchToImageCropper.emit(this.selectedSize);
    this.isChanged = this.isPostChanged();
  }

  private emitState(uploadedFile?: File) {
    this.onSaveState.emit({
      ...this.data,
      uploadedImage: uploadedFile,
      title: this.title,
      carousel: this.carousel,
      carouselAutoplay: this.carouselAutoplay,
      tab: this.tab,
      carouselEditItemIndex: undefined,
      sizeImage: this.selectedSize,
    });
  }

  onCancelImageCrop() {
    this.clearUploadState();
  }

  private clearUploadState() {
    this.uploadedImage = this.croppedImage = null;
    this.cdr.markForCheck();
  }

  removeCarouselItem(index) {
    this.carousel.splice(index, 1);
    if (this.carousel?.length <= 1) {
      this.carouselAutoplay = false;
    }
    this.isChanged = this.isPostChanged();
    this.cdr.markForCheck();
  }

  onPublishPost() {
    this.onPublish.emit({
      post: {
        title: this.title,
        carousel: this.carousel,
        carouselAutoplay: this.carouselAutoplay,
        creator: this.username,
      },
      tabId: this.tab?.id,
      size: this.selectedSize,
      warningImageSize: this.showWarningImage,
    });
  }

  onAutoCompleteChange(tab: HomeTabs.HomeTab) {
    if (!tab || tab.name === this.NO_RESULTS_TAB_NAME) {
      this.tab = null;
      this.cdr.markForCheck();
      return;
    }
    this.tab = tab;
    this.initPostTitles();
    this.validateTitle();
    this.isChanged = this.isPostChanged();
  }

  carouselAutoplayToggle(event) {
    this.carouselAutoplay = event.checked;
    this.emitState();
    this.isChanged = this.isPostChanged();
    this.cdr.markForCheck();
  }

  onSelectedSizeImage(option: SelectItem) {
    this.selectedSize = option.value;
    this.showWarningImage = this.carousel?.length && this.selectedSize !== (this.data?.sizeImage ?? 'large');
    this.emitState();
    this.isChanged = this.isPostChanged();
    this.cdr.markForCheck();
  }

  drop(event: CdkDragDrop<Posts.CarouselData[]>) {
    moveItemInArray(this.carousel, event.previousIndex, event.currentIndex);
    this.isChanged = this.isPostChanged();
    this.cdr.markForCheck();
  }

  isDirty() {
    // todo continue
  }

  // CONTEXT MENU
  async openContextMenu(event: { x: number; y: number }, carouselItem: Posts.CarouselData, index: number) {
    this.activeContextMenu?.destroy();
    this.activeContextMenu = null;
    const items = await this.buildContextMenuItems(index);
    this.activeContextMenu = this.contextMenuService.open(
      event,
      {
        items,
        onInvoke: (item: ContextMenuItem) => this.handleContextMenuCommand(item),
        minWidthItem: 90,
      },
      { position: 'right' }
    );
    this.activeContextMenu.destroy$.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  openEditMode(itemIndex: number) {
    this.onSaveState.emit({
      ...this.data,
      carouselEditItemIndex: itemIndex,
    });
    this.switchToImageCropper.emit(this.selectedSize);
  }

  private buildContextMenuItems(editItemIndex: number): ContextMenuItem[] {
    return [
      {
        id: 'edit',
        text: 'Edit',
        data: { editItemIndex },
        command: { type: 'edit', value: 'edit' } as Commands.DynamicCommand,
      },
    ];
  }

  private async handleContextMenuCommand(command: ContextMenuItem) {
    // todo add telemetry
    const editItemIndex: number = command.data.editItemIndex;
    if (command.id === 'edit') {
      this.openEditMode(editItemIndex);
    }
  }

  private validateTitle(value?: string) {
    this.isValidTitle = !this.allPostTitles.includes(value ?? this.title);
  }
}
