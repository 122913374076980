import { Injectable } from '@angular/core';
import { WikiCardsService } from './wiki-cards.service';
import { take } from 'rxjs';
import { RequestsHistoryPopupComponent } from '../../components/verifications/requests-history-popup/requests-history-popup.component';
import { RequestData, RequestsHistoryData } from '../../components/verifications/requests-history-popup/requests-history-models';
import { Accounts, Wiki } from '@local/client-contracts';
import { PopupRef, PopupService } from '@local/ui-infra';
import { AccountsService } from '@shared/services/accounts.service';
import { keyBy } from 'lodash';
import { ShowToasterService } from '../show-toaster.service';
import { PreviewService } from '../preview.service';
import { EditPopupComponent, EditPopupData } from '../../components/edit-popup/edit-popup.component';

@Injectable()
export class WikiCardsVerificationsService {
  private requestVerificationPopupRef: PopupRef<EditPopupComponent, EditPopupData>;
  private requestsHistoryPopupRef: PopupRef<RequestsHistoryPopupComponent, RequestsHistoryData>;
  private workspaceMemberDetailsMap: { [id: string]: Accounts.WorkspaceAccount } = {};

  constructor(
    private popupService: PopupService,
    private accountsService: AccountsService,
    private showToasterService: ShowToasterService,
    private wikiCardsService: WikiCardsService,
    private previewService: PreviewService
  ) {
    this.accountsService.all$.subscribe((all) => {
      this.workspaceMemberDetailsMap = keyBy(all, 'id');
    });
  }

  openRequestVerificationPopup() {
    if (this.requestVerificationPopupRef) {
      this.requestVerificationPopupRef.destroy();
    }
    this.requestVerificationPopupRef = this.popupService.open<EditPopupComponent, EditPopupData>(
      'center',
      EditPopupComponent,
      {
        title: 'Request verification',
        primaryButtonLabel: 'Send request',
        secondaryButtonLabel: 'Cancel',
        textareaPlaceholder: 'What is the reason for verification?',
        minLength: 10,
        showCloseIcon: false,
        warning_app_data: {
          content: {
            secondaryButton: 'Cancel',
            primaryButton: 'Discard & Leave',
          },
          message: 'Are you sure you want to leave without <br> sending the request?',
        },
      },
      {
        position: 'center',
        backdropStyle: 'blur-2',
        closeOnClickOut: false,
      }
    );
    this.requestVerificationPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.requestVerificationPopupRef = null;
    });
    return this.requestVerificationPopupRef;
  }

  createVerificationRequest(id: string, message: string, titleCard: string) {
    this.wikiCardsService.createVerificationRequest(message, id).then(() => {
      const toaster = this.showToasterService.showToaster({
        id: 'verification-request-sent',
        title: 'Verification request sent',
        intent: 'primary',
        buttonText: 'Open card',
      });
      toaster.compInstance.invoke.subscribe(() => {
        this.previewService.setPreviewState('popup', 0, {
          type: 'result',
          filterType: 'wiki-local',
          id,
          view: { title: { text: titleCard }, icon: null },
          action: { type: 'wiki card' },
        });
        toaster.destroy();
      });
    });
  }

  openRequestHistoryPopup(card: Wiki.Card) {
    if (!card?.verification?.requests?.length) {
      return;
    }
    if (this.requestsHistoryPopupRef) {
      this.requestsHistoryPopupRef.destroy();
    }
    const requests: RequestData[] = card.verification.requests.map((r) => {
      const user = this.workspaceMemberDetailsMap[r.accountId];
      return {
        message: r.message,
        date: r.createdAt,
        userImage: user?.picture,
        userName: user?.name,
      };
    });
    const data: RequestsHistoryData = {
      isVerifier: card.verification.policy.isVerifier,
      requests,
    };
    this.requestsHistoryPopupRef = this.popupService.open<RequestsHistoryPopupComponent, RequestsHistoryData>(
      'center',
      RequestsHistoryPopupComponent,
      data,
      {
        position: 'center',
        backdropStyle: 'blur-2',
      }
    );
    this.requestsHistoryPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.requestsHistoryPopupRef = null;
    });
    this.requestsHistoryPopupRef.compInstance.approveRequests.subscribe(() => {
      this.wikiCardsService.approveVerificationRequests(card.id);
    });
  }
}
