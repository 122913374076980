import { ChangeDetectionStrategy, Component, ElementRef, input, model, output, ViewChild } from '@angular/core';
import { UBaseInfraComponent } from '../u-base-infra.component';
import { UTypeElement } from '../../types';

@Component({
  selector: 'u-toggle-button',
  templateUrl: './u-toggle-button.component.html',
  styleUrl: './u-toggle-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UToggleButtonComponent extends UBaseInfraComponent {
  checked = model<boolean>();
  onIcon = input<string>();
  offIcon = input<string>();
  onLabel = input<string>();
  offLabel = input<string>();
  outlined = input<boolean>();
  activeType = input<UTypeElement>('secondary');
  onChange = output<{ originalEvent: Event; checked: boolean }>();

  @ViewChild('pToggleButton', { read: ElementRef }) pToggleButtonRef: ElementRef;

  getIconRef() {
    return this.pToggleButtonRef?.nativeElement?.querySelector('.p-button-icon');
  }

  getBaseClasses() {
    return {
      ...super.getBaseClasses(),
      'u-toggle-button-outlined': this.outlined(),
      'u-active-secondary': this.activeType() === 'secondary',
      'u-active-success': this.activeType() === 'success',
    };
  }
}
